import { put, call, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import { LogoutCreators } from "../Redux/SessionRedux";
import { normalize } from "normalizr";
import { selectNormalizrSchema } from "../Redux/SchemaRedux";

export function* entitiesSaga(entity, apiFunc, data) {
  const response = yield call(apiFunc, data);

  if (response.ok) {
    const schemaList = yield select(selectNormalizrSchema);
    const { result, entities } = normalize(response.data, schemaList);
    yield put(entity.success(data, result, { entities, schema: schemaList }, response.data.meta));
  } else {
    if (response.status === 401) {
      yield put(LogoutCreators.request());
    } else {
      yield put(entity.failure(data, response.data));
    }
  }
}

export function* entitiesUpdateSaga({ type, data }) {
  const schemaList = yield select(selectNormalizrSchema);
  const { result, entities } = normalize(data, schemaList);
  yield put({ type: `${type}_ENTITIES`, data: result, entities: { entities, schema: schemaList } });
}

export function* entitiesSagaDebounced(entity, apiFunc, data) {
  yield delay(300);
  yield call(entitiesSaga, entity, apiFunc, data);
}

export function* entitiesSagaCleanup(action) {
  const { entity } = action;
  yield put({ type: `${entity.toUpperCase()}_CLEANUP` });
}

export function *entitiesActionDoneSaga({ action, data }) {
  const schemaList = yield select(selectNormalizrSchema);
  const { result, entities } = normalize(data, schemaList);
  yield put({ type: action, data: result, entities: { entities, schema: schemaList } });
}