import React from 'react';

export default function BgButtonFlat(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 695 249"
      preserveAspectRatio="none"
      {...props}
    >
      <defs>
        <filter
          id="7mhj76yzba"
          width="149.3%"
          height="285.1%"
          x="-24.6%"
          y="-92.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="48" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="16.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.0289852106 0 0 0 0 0.522729846 0 0 0 0 0.464869147 0 0 0 0.425972465 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        fill="#FFF"
        fill-opacity=".764"
        fill-rule="evenodd"
        filter="url(#7mhj76yzba)"
        transform="translate(32)"
      >
        <rect width="631" height="168" rx="84" />
      </g>
    </svg>
  );
}
