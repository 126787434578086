import React from "react";
import Loadable from "react-loadable";

import PageWrapper from "../Components/PageWrapper";
import LodableLoading from "../Components/LodableLoading";

import SchemaBrowserPage from "./SchemaBrowserPage";
import ResourceBrowserPage from "./ResourceBrowserPage";
import ResourceEditorPage from "./ResourceEditorPage";
import ResourceImportPage from "./ResourceImportPage";
import { Route, Switch } from "react-router-dom";

const IVStudioPage = Loadable({
    loader: () => import("../IVStudio/Page"),
    loading: LodableLoading
});

const RawVideoStudioPage = Loadable({
    loader: () => import("../RawVideoStudio/Page"),
    loading: LodableLoading
});

const WorksheetStudioPage = Loadable({
    loader: () => import("../WorksheetStudio/Page"),
    loading: LodableLoading
});

const WorksheetOSMOStudioPage = Loadable({
    loader: () => import("../WorksheetOSMOStudio/Page"),
    loading: LodableLoading
});

const TUStudioPage = Loadable({
    loader: () => import("../TUStudio/Page"),
    loading: LodableLoading
});
const GrammerValidatorStudioPage = Loadable({
    loader: () => import("../GrammerValidatorStudio/page"),
    loading: LodableLoading
});
function ResourcesPage() {
    return (
        <PageWrapper>
            <Switch>
                <Route exact path="/resources" component={SchemaBrowserPage} />
                <Route exact path="/resources/:resource_name" component={ResourceBrowserPage} />
                <Route exact path="/resources/:resource_name/import" component={ResourceImportPage} />
                <Route exact path="/resources/:resource_name/new" component={ResourceEditorPage} />
                <Route
                    exact
                    path="/resources/:resource_name/:resource_id"
                    component={ResourceEditorPage}
                />
                <Route
                    exact
                    path="/resources/interactive_videos/:resource_id/studio"
                    component={IVStudioPage}
                />
                <Route
                    exact
                    path="/resources/raw_videos/:resource_id/studio"
                    component={RawVideoStudioPage}
                />
                <Route
                    exact
                    path="/resources/worksheets/:resource_id/studio-backup"
                    component={WorksheetStudioPage}
                />
                <Route
                    exact
                    path="/resources/worksheets/:resource_id/studio"
                    component={WorksheetOSMOStudioPage}
                />
                <Route
                    exact
                    path="/resources/ela_interactive_videos/:resource_id/studio"
                    component={IVStudioPage}
                />
                <Route
                    exact
                    path="/resources/teaching_units/:resource_id/studio"
                    component={TUStudioPage}
                />
                <Route
                    exact
                    path="/resources/english_rules/:resource_id/studio"
                    component={GrammerValidatorStudioPage}
                />
            </Switch>
        </PageWrapper>
    );
}

export default ResourcesPage;
