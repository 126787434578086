import Immutable from "seamless-immutable";
import { denormalize } from "normalizr";

import { createActions } from "reduxsauce";
import { user } from "../Services/Api/Schema";

const { Types, Creators } = createActions(
	{
		init: ["wsURL"],
		subscribe: ["channel"],
		unsubscribe: ["channel"],
		perform: ["channel", "action", "data"],
		disconnect: []
	},
	{ prefix: "ACTION_CABLE_" }
);

export { Types, Creators };

const reducer = (
	state: Object = Immutable({ subscriptions: [], activeUsers: [] }),
	{ type, subscription, data }: Object
) => {
	if (type === "ACTION_CABLE_CONNECTED") {
		return state.merge({ connected: true });
	}

	if (type === "ACTION_CABLE_DISCONNECTED") {
		return state.merge({ connected: false });
	}

	if (type === "ANNOUNCE_USERS_ENTITIES") {
		return state.merge({ activeUsers: data.users });
	}
	return state;
};

export const key = "actionCable";
export const selectActionCableConnectionState = state => state[key].connected;
export const selectActionCableActiveUsers = state => {
	try {
		return denormalize(state[key].activeUsers, [user], state.entities);
	} catch (e) {
		return [];
	}
};

export default reducer;
