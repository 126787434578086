import React, { useState, useEffect } from 'react';
import SplitterLayout from 'react-splitter-layout';
import GameContainer from '../games/GameContainer';
import MetadataGenerator from './MetadataGenerator';
import CONST from '../utils/Constants';
import EditorTemplateTypes from '../utils/EditorTemplateTypes';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import 'react-splitter-layout/lib/index.css';
import './styles/EditorContainer.scss';
// import QueData from '../utils/QuestionData';

const defaultPayload = {
  context: {
    html: '',
    title: '',
    title_image: '',
  },
};

const defaultData = {
  id: '',
  template: '',
  container: {
    evaluate: true,
    metadata: {},
    answers: {},
    editor_context: {},
  },
};

function getRcData(data) {
  return {
    id: '',
    template: 'rc',
    container: {
      evaluate: true,
      metadata: {
        payload: {
          type: 'text',
          context: {
            html: _get(data, 'context.html', ''),
          },
        },
        title: {
          type: 'text',
          context: {
            html: _get(data, 'context.title', ''),
          },
        },
        title_image: {
          type: 'text',
          context: {
            html: _get(data, 'context.title_image', ''),
          },
        },
      },
      answers: {},
      editor_context: {},
    },
  };
}

function defaultCleanupForTTS(text) {
  let textTTS = text.replace(/&nbsp;/g, ' ');
  // remove img tags from tts
  var imgRegex = new RegExp('<img[^>]*?>', 'g');
  while (imgRegex.test(textTTS)) {
    textTTS = textTTS.replace(textTTS.match(imgRegex), '');
  }
  return textTTS;
}

function EditorContainer({
  data,
  templateType,
  updateRawData,
  updatePayload,
  payload = {},
  payloadId,
}) {
  const [type, setType] = useState('');
  const [questionData, setQuestionData] = useState({});
  const [payloadData, setPayloadData] = useState(payload);

  useEffect(() => {
    let updateTemplate = {};
    const payloadConfig = payload || defaultPayload;
    setPayloadData(payloadConfig);

    if (templateType === 'rc') {
      data = getRcData(data);
    }
    updateTemplate = data || defaultData;
    updateTemplate.template = templateType;
    setType(templateType);

    // const dummyTemplateType = 'drag_v2';
    // updateTemplate.template = dummyTemplateType;
    // setType(dummyTemplateType);
    setQuestionData(updateTemplate); //QueData['ic_mcq']
  }, [templateType, payloadId]);

  const updateQuestionData = (metadata) => {
    setQuestionData((prevData) => {
      let updateMetaData = _cloneDeep(prevData);
      updateMetaData['container']['metadata'] = metadata;
      return updateMetaData;
    });
  };

  const generateAudioAttributes = () => {
    let qData = questionData;
    let audio = [];
    EditorTemplateTypes[type] &&
      _get(EditorTemplateTypes[type], 'editorComponents', []).forEach(
        (template) => {
          const {
            keyName,
            type,
            content_guidelines: {
              voice = '',
              ttsTransform,
              disableDefaultTTSTransform = false,
              contentType,
            },
          } = template;
          if (type === 'textEditor' && voice) {
            let text = _get(
              qData,
              `container.metadata.${keyName}.context.html`,
              ''
            );
            if (!disableDefaultTTSTransform) {
              text = defaultCleanupForTTS(text);
            }
            if (ttsTransform) {
              text = ttsTransform(text);
            }
            if (text) {
              audio.push({ text, attribute_name: keyName, voice });
            }
          }
          if (type === 'combinedTextEditor') {
            const selectedContentOptionKey = _get(
              qData,
              `container.metadata.${keyName}.contentType`,
              ''
            );
            const contentTypeOptions = _get(contentType, `options`);
            const selectedContentTypeOption = (contentTypeOptions || []).find(
              (el) => el.key === selectedContentOptionKey
            );
            const {
              voice: voiceOption,
              ttsTransform: ttsTransformContentOption,
            } = selectedContentTypeOption || {};
            if (voiceOption) {
              const textRaw = _get(
                qData,
                `container.metadata.${keyName}.context.html`,
                ''
              );
              let audioText = _get(
                qData,
                `container.metadata.${keyName}.context.audioText`,
                ''
              );

              let text = audioText || textRaw;
              if (!disableDefaultTTSTransform) {
                text = defaultCleanupForTTS(text);
              }
              if (ttsTransformContentOption) {
                text = ttsTransformContentOption(text);
              } else if (ttsTransform) {
                text = ttsTransform(text);
              }
              if (text) {
                audio.push({
                  text,
                  attribute_name: keyName,
                  voice: voiceOption,
                });
              }
            }
          }
          if (type === 'addOptionsV2') {
            const optionsData = _get(
              qData,
              `container.metadata.${keyName}`,
              ''
            );
            optionsData.forEach((option, index) => {
              const selectedContentOptionKey = _get(option, `contentType`, '');
              const contentTypeOptions = _get(contentType, `options`);
              const selectedContentTypeOption = (contentTypeOptions || []).find(
                (el) => el.key === selectedContentOptionKey
              );
              const {
                voice: voiceOption,
                ttsTransform: ttsTransformContentOption,
              } = selectedContentTypeOption || {};
              if (voiceOption) {
                let text = _get(option, 'context.html', '');
                const optionId = _get(option, 'id', '');
                if (!disableDefaultTTSTransform) {
                  text = defaultCleanupForTTS(text);
                }
                if (ttsTransformContentOption) {
                  text = ttsTransformContentOption(text);
                } else if (ttsTransform) {
                  text = ttsTransform(text);
                }

                if (text) {
                  audio.push({
                    option_id: optionId,
                    text,
                    attribute_name: keyName,
                    voice: voiceOption,
                  });
                }
              }
            });
          }
        }
      );
    qData['raw_question_audios'] = audio;
    return qData;
    // setQuestionData(qData)
  };

  const updateAnswers = (data) => {
    try {
      if (!(data && typeof data === 'object' && data.constructor === Object)) {
        throw new Error('Parameter is not a Object');
      }

      setQuestionData((prevData) => {
        let updateMetaData = { ...prevData };
        updateMetaData['container']['answers'] = data;
        return updateMetaData;
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updatePayloadData = (data) => {
    try {
      if (!(data && typeof data === 'object' && data.constructor === Object)) {
        throw new Error('Parameter is not a Object');
      }

      setPayloadData(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getEditorRawQuestionData = () => {
    if (type === 'rc') {
      const text = _get(
        questionData,
        'container.metadata.payload.context.html',
        ''
      );
      const title = _get(
        questionData,
        'container.metadata.title.context.html',
        ''
      );
      const titleImage = _get(
        questionData,
        'container.metadata.title_image.context.html',
        ''
      );
      if (validateData()) {
        updateRawData &&
          updateRawData({
            context: { html: text, title: title, title_image: titleImage },
          });
      }
    } else {
      if (payloadId) {
        let updateMetaData = { ...questionData };
        updateMetaData['container']['external_data'] = {
          hasPayload: true,
          type_id: payloadId,
        };
        setQuestionData(updateMetaData);
      }
      if (validateData()) {
        const finalData = generateAudioAttributes();
        console.log('finalData--ela-lib', finalData);
        updateRawData && updateRawData(finalData);
        updatePayload && updatePayload(payloadData);
      }
    }
  };

  const validateData = () => {
    let str = '';
    const { disableAnswers } = EditorTemplateTypes[type];
    const {
      container: { metadata, answers },
    } = questionData;
    let TemplateInfo = _get(EditorTemplateTypes[type], 'editorComponents', []);
    TemplateInfo.forEach((obj) => {
      const key = obj.keyName;
      const label = obj.labelName;
      const minCount = _get(obj, 'content_guidelines.minCount', 1);
      const showCondition = _get(obj, 'content_guidelines.showCondition', '');
      const contentTypeConfig = _get(obj, 'content_guidelines.contentType', {});
      const name = showCondition.keyName;
      const value = metadata[name];
      let validaterFn = obj.validaterFn;
      const selectedContentTypeOptionKey = _get(
        metadata,
        [key, contentTypeConfig.key],
        null
      );
      const selectedContentTypeOption = selectedContentTypeOptionKey
        ? contentTypeConfig.options.find(
            (el) => el.key === selectedContentTypeOptionKey
          )
        : null;
      if (selectedContentTypeOption && selectedContentTypeOption.validaterFn) {
        validaterFn = selectedContentTypeOption.validaterFn;
      }
      const isTextOptionalContentOption = _get(
        selectedContentTypeOption,
        'isTextOptional',
        false
      );
      const isTextOptional = _get(obj, 'isTextOptional', false);

      const validateText = !isTextOptionalContentOption && !isTextOptional;

      if (validaterFn) {
        const errors = validaterFn({
          template: obj,
          metadata,
          answers,
          option: metadata[key],
        });
        if (errors) {
          str += errors;
        }
      }
      if (showCondition && showCondition.value !== value) {
        return;
      }
      if (
        (obj.type === 'textEditor' || obj.type === 'combinedTextEditor') &&
        validateText
      ) {
        if (
          metadata[key].context.html
            .replace(/&nbsp;/g, '')
            .replace(/(<br ?\/?>)*/g, '')
            .trim() === ''
        ) {
          str += `Please add ${label} \n`;
        }
      } else if (obj.type === 'addOptions' || obj.type === 'addOptionsV2') {
        if (metadata[key]) {
          metadata[key].forEach((option, idx) => {
            if (option.type === 'text') {
              if (
                option.context.html
                  .replace(/&nbsp;/g, '')
                  .replace(/(<br ?\/?>)*/g, '')
                  .trim() === ''
              ) {
                str += `${label} ${idx + 1} can't be empty \n`;
              }
            }
          });

          if (metadata[key].length < minCount) {
            str += `Minimum ${minCount} ${label} have to be added \n`;
          }
        } else {
          str += `Minimum ${minCount} ${label} have to be added \n`;
        }
      }
    });

    if (
      !disableAnswers &&
      !Object.keys(answers).length &&
      !metadata['allCorrect']
    ) {
      str += `Please select answers \n`;
    }

    if (str) {
      alert(str);
    } else {
      return true;
    }
  };

  return (
    <SplitterLayout secondaryInitialSize={360} secondaryMinSize={360}>
      <div className={'editor-container'}>
        {type && (
          <MetadataGenerator
            type={type}
            updateQuestionData={updateQuestionData}
            metadata={questionData.container.metadata}
          />
        )}
        {type && (
          <div className="submit-btn-wrapper">
            <button className="submit-btn" onClick={getEditorRawQuestionData}>
              Submit
            </button>
          </div>
        )}
      </div>
      <div className={'game-container'}>
        <GameContainer
          // type={type}
          mode={CONST.MODE.EDITOR}
          payload={payloadData}
          data={questionData}
          updatePayload={updatePayloadData}
          updateAnswers={updateAnswers}
        />
      </div>
    </SplitterLayout>
  );
}

export default EditorContainer;
