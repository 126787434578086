import React from 'react';
import './ProgressBar.scss';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import CorrectIconProgressBar from '../../../assets/v2-assets/correctIconProgressBar.svg';
import WrongIconProgressBar from '../../../assets/v2-assets/wrongIconProgressBar.svg';

function isCorrectHelper(gameProgressStatus, i) {
  return (
    gameProgressStatus[i]['isCompleted'] && gameProgressStatus[i]['isCorrect']
  );
}

function isWrongHelper(gameProgressStatus, i) {
  return (
    gameProgressStatus[i]['isCompleted'] && !gameProgressStatus[i]['isCorrect']
  );
}

function StepProgressBar({
  noOfQuestions,
  currentQueIndex,
  gameProgressStatus,
  type,
}) {
  const progressPercent = (currentQueIndex / (noOfQuestions - 1)) * 100;

  const progressDotGenerator = () => {
    let steps = [];
    for (let i = 0; i < noOfQuestions; i++) {
      let isCorrect = isCorrectHelper(gameProgressStatus, i);
      let isWrong = isWrongHelper(gameProgressStatus, i);

      steps.push(
        <Step transition="scale" key={i}>
          {({ index }) => {
            const isCurrent =
              currentQueIndex === index && !gameProgressStatus[i].isCompleted;
            return (
              <div className="progress-dot-v2">
                {isCorrect && (
                  <div className="img-container">
                    <img src={CorrectIconProgressBar} />
                  </div>
                )}
                {isWrong && (
                  <div className="img-container">
                    <img src={WrongIconProgressBar} />
                  </div>
                )}
                {isCurrent && <div className="current-inner" />}
              </div>
            );
          }}
        </Step>
      );
    }
    return steps;
  };

  return (
    <div className={'progress-holder-v2'}>
      <ProgressBar percent={progressPercent} filledBackground="#faf5fc">
        {progressDotGenerator()}
      </ProgressBar>
    </div>
  );
}

export default StepProgressBar;
