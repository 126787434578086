import React from 'react';

export default function BackgroundButton(props) {
    return (
        <svg viewBox="0 0 568 179" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" {...props}>
            <defs>
                <rect id="path-1" x="0" y="9" width="534" height="145" rx="72.5"></rect>
                <filter x="-5.2%" y="-15.9%" width="110.5%" height="138.6%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="8.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values={props.filterTwo} type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
                <rect id="path-3" x="0" y="0" width="534" height="140" rx="70"></rect>
                <filter x="-7.8%" y="-29.6%" width="115.5%" height="159.3%" filterUnits="objectBoundingBox" id="filter-4">
                    <feGaussianBlur stdDeviation="25" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
                    <feOffset dx="0" dy="-33" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
                    <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
                    <feColorMatrix values={props.filterFour} type="matrix" in="shadowInnerInner1"></feColorMatrix>
                </filter>
            </defs>
            <g id="Page-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="FITB-Assets" transform="translate(-1253.000000, -1162.000000)">
                    <g id="Next-button-Copy" transform="translate(1270.000000, 1165.000000)">
                        <g id="Rectangle-Copy-9">
                            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                            <use fill={props.fillcolor} fill-rule="evenodd" xlinkHref="#path-1"></use>
                        </g>
                        <g id="Rectangle">
                            <use fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-3"></use>
                            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlinkHref="#path-3"></use>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

BackgroundButton.defaultProps = {
    fillcolor: "#80BFB8",
    filterFour: "0 0 0 0 0.192156863 0 0 0 0 0.639215686 0 0 0 0 0.596078431 0 0 0 0.345689467 0",
    filterTwo: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.370847902 0"
}
