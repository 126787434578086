// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["params", "data", "entities"],
		success: ["params", "data", "entities", "meta"],
		failure: ["params", "error", "entities"]
	},
	{ prefix: "FILES_IMPORT_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
	return state.merge({ importing: true, error: null });
};

const success = (state: Object, { data }: Object) => {
	return state.merge({
		importing: false,
		data: [data.file, ...state.data],
		error: null
	});
};

const failure = (state: Object, { error }: Object) => {
	return state.merge({ importing: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
