import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import compose from "recompose/compose";
import { connect } from "react-redux";

import { withRouter } from "react-router";

import CameraIcon from "@material-ui/icons/Camera";
import DeleteIcon from "@material-ui/icons/DeleteForever";

import TitleBar from "../Components/TitleBar";
import Loading from "../Components/Loading";
import ClickCopyText from "../Components/ClickCopyText";
import ProgressButton from "../Components/ProgressButton";
import ProjectValidationErrors from "../Components/ProjectValidationErrors";
import _get from "lodash/get";

import {
  CreateCreators as ProjectSnapshotCreators,
  ListCreators as ProjectSnapshotListCreators,
  DeleteCreators as ProjectSnapshotDeleters,
  selectProjectSnapshots,
  selectProjectSnapshotsRequestState
} from "../Redux/ProjectSnapshotsRedux";

import fecha from "fecha";

const styles = theme => ({
  drawerPaper: {
    width: 320,
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
    height: "100vh",
    overflow: "auto",
    position: "relative",
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: 500
    },
    [theme.breakpoints.up("lg")]: {
      width: 800
    }
  },
  flex: {
    display: "flex",
  },
  rootNodeView: {
    height: "auto",
    minHeight: "100%"
  },
  btnIcon: {
    marginLeft: theme.spacing(1)
  }
});

class ProjectSnapshotsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createError: null
    };
  }

  componentDidMount() {
    const { project, workspace } = this.props;
    this.props.listSnapshots(workspace.id, project.id);
  }

  closeNodePanel = () => {
    const { history, project, workspace, rootNode } = this.props;
    history.push(`/workspaces/${workspace.id}/projects/${project.id}/tree/${rootNode.id}`);
  };

  onSnapshotClick = event => {
    const { project, workspace } = this.props;
    this.setState({ createError: null });
    this.props.takeSnapshot(workspace.id, project.id);
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.projectSnapshotsRequests.creating &&
      !nextProps.projectSnapshotsRequests.creating
    ) {
      if (nextProps.projectSnapshotsRequests.error) {
        this.setState({
          createError: nextProps.projectSnapshotsRequests.error
        });
      }
    }
  }

  handleDelete(snapshot) {
    const { project, workspace } = this.props;
    this.props.deleteSnapshot(workspace.id, project.id, snapshot.id);
  }

  render() {
    let { classes, projectSnapshots = [], projectSnapshotsRequests, project } = this.props;
    const { createError } = this.state;

    const canTakeSnapShot = _get(projectSnapshotsRequests, "meta.abilities.create_project_snapshot")

    return (
      <Drawer
        variant="persistent"
        classes={{ paper: classes.rootNodeView }}
        anchor="right"
        open={true}
        onClose={this.closeNodePanel}
      >
        <div className={classes.drawerPaper}>
          <TitleBar
            title="Project Snapshots"
            subtitle={
              <div className={ classes.flex }>
                {canTakeSnapShot && <ProgressButton
                inProgress={projectSnapshotsRequests.creating}
                onClick={this.onSnapshotClick}
                disabled={project.is_published || project.is_frozen}
              >
                Take a Snapshot <CameraIcon className={ classes.btnIcon }/>
              </ProgressButton>}
              <Button color="default" onClick={this.closeNodePanel} className={ classes.btnIcon }>
                Close
              </Button>
              </div>
            }
          />

          <Grid container direction="row" justify="center" alignContent="center">
            {createError && (
              <Grid item xs={12}>
                {createError.errors && <ProjectValidationErrors project={ project } errors={createError.errors} />}
                <Typography align="center" color="secondary">{createError.error || "Snapshot creation failed!" }</Typography>
              </Grid>
            )}
          </Grid>
          {projectSnapshotsRequests.listing && <Loading center />}
          {projectSnapshots && (
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>URL</TableCell>
                  {canTakeSnapShot &&<TableCell>Created</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {projectSnapshots.map(snapshot => {
                  return (
                    <TableRow key={snapshot.id}>
                      <TableCell>{snapshot.id}</TableCell>
                      <TableCell>
                        <ClickCopyText
                          copyText={snapshot.s3_url}
                          text={"..." + snapshot.s3_url.substr(snapshot.s3_url.length - 35)}
                        />
                      </TableCell>
                      {canTakeSnapShot && <TableCell>
                        {fecha.format(Date.parse(snapshot.created_at), "ddd MMMM Do, YYYY hh:mm A")}
                        <IconButton onClick={ this.handleDelete.bind(this, snapshot) }><DeleteIcon/></IconButton>
                      </TableCell>}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </Drawer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  listSnapshots: (w, p) => dispatch(ProjectSnapshotListCreators.request(w, p)),
  takeSnapshot: (w, p) => dispatch(ProjectSnapshotCreators.request(w, p)),
  deleteSnapshot: (w, p, s) => dispatch(ProjectSnapshotDeleters.request(w, p, s)),
});

const mapStateToProps = (state, ownProps) => {
  return {
    projectSnapshots: selectProjectSnapshots(state),
    projectSnapshotsRequests: selectProjectSnapshotsRequestState(state)
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProjectSnapshotsPage);
