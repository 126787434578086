// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    request: ["resourceName", "resourceID", "action", "params"],
    success: ["params", "data", "entities"],
    failure: ["params", "error"]
  },
  { prefix: "RESOURCES_ACTIONS_" }
);

export { Creators, Types };

export const request = (state: Object, action: Object) => {
  return state.merge({ actions: { ...(state.actions || {}), [action.action]: { requesting: true, error: null, data: null } }});
};

export const success = (state: Object, { data, params }: Object) => {
  return state.merge({ actions: { ...(state.actions || {}), [params.action]: { requesting: false, error: null, data } }});
};

export const failure = (state: Object, { error, params }: Object) => {
  return state.merge({ actions: { ...(state.actions || {}), [params.action]: { requesting: false, error, data: null } }});
};

export default {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure
};
