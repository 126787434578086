import React from 'react';
import get from 'lodash/get';
import LabelCard from './LabelCard';
import CKEditorCard from './CKEditorCard';

function OptionCard({
  onOptionChange,
  charCount = -1,
  removeButtons = '',
  ...labelProps
}) {
  const { data, index, keyName } = labelProps;

  return (
    <LabelCard {...labelProps}>
      <CKEditorCard
        value={get(data, 'context.html') || ''}
        updateContent={(event) => onOptionChange(keyName, index, event)}
        charCount={charCount}
        removeButtons={removeButtons}
      />
    </LabelCard>
  );
}

export default OptionCard;
