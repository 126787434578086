import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default props => {
	return (
		<SvgIcon viewBox="0 0 100 100" {...props}>
			<path d="M50,55c-2.757,0-5-2.243-5-5s2.243-5,5-5c2.756,0,5,2.243,5,5S52.757,55,50,55z" />
			<path d="M50,5C25.146,5,5,25.146,5,50c0,17.175,9.625,32.098,23.772,39.682l4.743-8.817C22.506,74.96,15,63.342,15,50  c0-19.299,15.701-35,35-35c19.298,0,35,15.701,35,35c0,13.341-7.506,24.959-18.516,30.864l4.742,8.817C85.375,82.1,95,67.176,95,50  C95,25.146,74.854,5,50,5z" />
			<path d="M38.25,72.059l4.737-8.809C38.242,60.729,35,55.738,35,50c0-8.271,6.729-15,15-15c8.271,0,15,6.729,15,15  c0,5.738-3.241,10.729-7.986,13.25l4.736,8.809C69.627,67.846,75,59.54,75,50c0-13.785-11.215-25-25-25S25,36.215,25,50  C25,59.54,30.373,67.846,38.25,72.059z" />
		</SvgIcon>
	);
};
