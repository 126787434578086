import React from 'react'
import backIcon from "./../../../assets/images/backIcon.svg";
import BookmarkIcon from "./../../../assets/images/bookmarkIcon";
import "./headerMain.scss";
import ScoreBox from "./scoreBox";
import Microphone from "./microphone";
import { useGameContext } from "../../GameContext.jsx";
import Parser from 'html-react-parser';

function HeaderMain({ onBack, micStatus, setMicStatus, showMic, showBookmark, showTitle, hideScore = false }) {
    const { storyDetails, onBookmark, bookmarkStatus } = useGameContext();

    return (
        <div className="header-main">
            <div className="section left">
                <div className="back-btn">
                    <img src={backIcon} onClick={() => onBack()} alt="" />
                </div>
                {showTitle &&
                    <div className="header-title">
                        {Parser(storyDetails.title || "")}
                    </div>
                }
            </div>
            <div className="section right">
                {showMic &&
                    <div className="microphone" style={{ opacity: !micStatus && "0.6" }} onClick={() => setMicStatus(s => !s)}>
                        <Microphone />
                    </div>
                }
                {
                    showBookmark &&
                    <div className="bookmark">
                        <BookmarkIcon onClick={() => onBookmark(!bookmarkStatus)} filled={bookmarkStatus} />
                    </div>
                }
                {!hideScore &&
                    <div className="score">
                        <ScoreBox />
                    </div>
                }
            </div>
        </div>
    )
}

export default HeaderMain;
