// eslint-disable-next-line
String.prototype.underscoreToLabel = String.prototype.underscoreToLabel || 
	function() {
		var txt = this;
		return txt.replace(/(^|_)(.)/g, function(match, p1, p2) {
	      return (p1 === "_" ? " " : "") + p2.charAt(0).toUpperCase() + p2.substr(1).toLowerCase();
	    });
	}

// eslint-disable-next-line
String.prototype.trunc = String.prototype.trunc ||
      function(n){
          return (this.length > n) ? this.substr(0, n-4) + '...' : this;
      };