import React, { PureComponent } from "react";
import _debounce from "lodash/debounce";
import keycode from "keycode";

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

const SearchInput = props => (
	<Input
		placeholder="Search"
		{...props}
		startAdornment={
			<InputAdornment position="start">
				<Search />
			</InputAdornment>
		}
	/>
);


class DebouncedSearchInput extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { value: props.initialValue || "" };
	}

	componentDidMount() {
		document.addEventListener("keydown", this.searchFocuser)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.searchFocuser)
	}

	searchFocuser = (event) => {
		if((event.target === document.body || event.target.tagName === 'A') && keycode(event) === '/') {
			this.inputRef.focus();
			event.preventDefault();
		}
	}

	onChange = event => {
		this.setState({ value: event.target.value });
		this.debouncedChange();
	};

	debouncedChange = _debounce(() => {
		this.props.onChange({ target: { value: this.state.value } });
	}, 300);

	keyDownTransforms = event => {
		if (keycode(event) === "enter") {
			this.onChange(event);
		}
	};

	handlePaste = event => {
		if(!this.props.enablePaste) {
			return;
		}

        const paste = (event.clipboardData || window.clipboardData).getData(
            "text"
        );
        if (paste.indexOf("\n") === -1) {
            return;
        }

        event.preventDefault();
        
        if(this.state.value.startsWith("nn:")) {
        	this.setState({ value: '"' + paste.replace(/[\r\n]+$/g, '').replace(/[\r\n]+/g, '"|"') + '"' })
        }
        else {
        	this.setState({ value: paste.replace(/[\r\n]+$/g, '').replace(/[\r\n]+/g, ':*|') + ":*" })
        }

        this.debouncedChange();
    }

	render() {
		const { onChange, initialValue, ...other } = this.props;
		
		delete other.enablePaste;

		return (
			<SearchInput
				{...other}
				inputRef={ ref => this.inputRef = ref }
				onPaste={this.handlePaste}
				value={this.state.value}
				onChange={this.onChange}
				onKeyDown={this.keyDownTransforms}
			/>
		);
	}
}

export default DebouncedSearchInput;
