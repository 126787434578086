import Button from '@material-ui/core/Button';
import React, {useEffect, useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _debounce from 'lodash/debounce';

export default function SimpleDialog({
    title,
    description,
    handleOnDone,
    handleOnClose,
    open
  }) {
    
    const [visible, setVisible] = useState(open);
  
    function emptyCallback(){};
  
    handleOnClose = _debounce(handleOnClose || emptyCallback , 100);
    handleOnDone = _debounce(handleOnDone || emptyCallback, 100);
  
    function handleDialogToogle(){
      setVisible(!visible);
      handleOnClose();
    }
  
    function handleDialogOkay(){
      setVisible(!visible);
      handleOnDone();
    }
  
    useEffect(function cb(){
      setVisible(open);
    },[open])
  
    return (
      <Dialog
        open={visible}
        aria-labelledby="alert-dialog-title"
        onClose={handleDialogToogle}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogToogle}>
            Cancel
          </Button>
          <Button autoFocus color="primary" onClick={handleDialogOkay}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }