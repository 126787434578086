import { schema as Schema } from "normalizr";

// import Immutable from 'seamless-immutable'
export const schema = new Schema.Entity("schema", {}, { idAttribute: "name" });
export const node = new Schema.Entity("nodes", {}, { idAttribute: "scoped_id" });
export const nodes = [node];
node.define({ children: nodes });

export const project = new Schema.Entity("projects", {
  tree: nodes
});

export const workspace = new Schema.Entity("workspaces", {
  projects: [project]
});

export const user = new Schema.Entity("users");
export const application_version = new Schema.Entity("application_versions");
export const application = new Schema.Entity("applications", {
  versions: [application_version]
});

export const kg_item = new Schema.Entity("kg_items");
export const file = new Schema.Entity("files");
export const gameTemplate = new Schema.Entity("game_templates");

export const projectSnapshot = new Schema.Entity("project_snapshots");
export const projectSchema = new Schema.Entity("project_schemas");

export const activity = new Schema.Entity("activities", {
  user: user
});

export const issue_comment = new Schema.Entity("issue_comments");
export const issue = new Schema.Entity("issues", {
  comments: [issue_comment]
});

export const resource_package = new Schema.Entity("resource_packages");
export const team = new Schema.Entity("teams");

export const schemaMap = {
  schema: [schema],

  user,
  users: [user],

  application,
  applications: [application],


  application_version,
  application_versions: [application_version],

  kg_item,
  kg_items: [kg_item],

  file,
  files: [file],

  workspace,
  workspaces: [workspace],

  project,
  projects: [project],

  node,
  nodes: nodes,

  project_snapshot: projectSnapshot,
  project_snapshots: [projectSnapshot],

  game_template: gameTemplate,
  game_templates: [gameTemplate],

  project_schema: projectSchema,
  project_schemas: [projectSchema],

  activity,
  activities: [activity],

  issue,
  issues: [issue],

  issue_comment,
  issue_comments: [issue_comment],

  resource_package,
  resource_packages: [resource_package],

  team,
  teams: [team]
};

/*
const originalData = {"workspaces": [{
  "id": 1,
  "title": "Test",
  "projects": [{"id": 2}, {"id": 3}]
}]}

const normalizedData = normalize(originalData, {"workspaces": [workspace]});

console.log(normalizedData)

const back = denormalize({ workspaces: [ 1 ]}, { workspaces: [workspace] }, normalizedData.entities)
console.log(back)
*/
