import React from "react";
import Grid from "@material-ui/core/Grid";

export default ({ children, classes={} }) => (
	<Grid container className={ classes.container }>
		<Grid item xs={12} md={12} lg={12} xl={12}>
			{children}
		</Grid>
	</Grid>
);
