const constant = {
  CEFR: [
    {
      value: "A1",
      label: "A1"
    },
    {
      value: "A2",
      label: "A2"
    },
    {
      value: "B1",
      label: "B1"
    },
    {
      value: "B2",
      label: "B2"
    },
    {
      value: "C1",
      label: "C1"
    },
    {
      value: "C2",
      label: "C2"
    }
  ],
  Chapter: [
    {
      value: "Verb",
      label: "Verb"
    },
    {
      value: "Adverb",
      label: "Adverb"
    },
    {
      value: "Conjunction",
      label: "Conjunction"
    },
    {
      value: "Preposition",
      label: "Preposition"
    },
    {
      value: "Adjective",
      label: "Adjective"
    },
    {
      value: "Adposition",
      label: "Adposition"
    },
    {
      value: "Subordinating conjunction",
      label: "Subordinating conjunction"
    },
    {
      value: "Auxiliary",
      label: "Auxiliary"
    },
    {
      value: "Interjection",
      label: "Interjection"
    },
    {
      value: "Noun",
      label: "Noun"
    },
    {
      value: "Pronoun",
      label: "Pronoun"
    }
  ],
  Chapterwithid: [
    {
      id: 1,
      name: "Verb",
      value: "Verb",
      label: "Verb",
      sequence_number: 1
    },
    {
      id: 2,
      name: "Adverb",
      value: "Adverb",
      label: "Adverb",
      sequence_number: 2
    },
    {
      id: 3,
      name: "Conjunction",
      value: "Conjunction",
      label: "Conjunction",
      sequence_number: 3
    },
    {
      id: 4,
      value: "Preposition",
      name: "Preposition",
      label: "Preposition",
      sequence_number: 4
    },
    {
      id: 5,
      name: "Adjective",
      value: "Adjective",
      label: "Adjective",
      sequence_number: 5
    },
    {
      id: 6,
      name: "Adposition",
      value: "Adposition",
      label: "Adposition",
      sequence_number: 6
    },
    {
      id: 7,
      name: "Subordinating conjunction",
      value: "Subordinating conjunction",
      label: "Subordinating conjunction",
      sequence_number: 7
    },
    {
      id: 8,
      name: "Auxiliary",
      label: "Auxiliary",
      value: "Auxiliary",
      sequence_number: 8
    },
    {
      id: 9,
      name: "Interjection",
      label: "Interjection",
      value: "Interjection",
      sequence_number: 9
    },
    {
      id: 10,
      label: "Noun",
      name: "Noun",
      value: "Noun",
      sequence_number: 10
    },
    {
      id: 11,
      name: "Pronoun",
      label: "Pronoun",
      value: "Pronoun",
      sequence_number: 11
    }
  ],
  parselabel: [
    { value: "UNKNOWN", label: "UNKNOWN" },
    { value: "ABBREV", label: "ABBREV" },
    { value: "ACOMP", label: "ACOMP" },
    { value: "ADVCL", label: "ADVCL" },
    { value: "ADVMOD", label: "ADVMOD" },
    { value: "AMOD", label: "AMOD" },
    { value: "APPOS", label: "APPOS" },
    { value: "ATTR", label: "ATTR" },
    { value: "AUX", label: "AUX" },
    { value: "AUXPASS", label: "AUXPASS" },
    { value: "CC", label: "CC" },
    { value: "CCOMP", label: "CCOMP" },
    { value: "CONJ", label: "CONJ" },
    {
      value: "CSUBJ",
      label: "CSUBJ"
    },
    { value: "CSUBJPASS", label: "CSUBJPASS" },
    { value: "DEP", label: "DEP" },
    { value: "DET", label: "DET" },
    { value: "DISCOURSE", label: "DISCOURSE" },
    { value: "DOBJ", label: "DOBJ" },
    { value: "EXPL", label: "EXPL" },
    { value: "GOESWITH", label: "GOESWITH" },
    { value: "IOBJ", label: "IOBJ" },
    { value: "MARK", label: "MARK" },
    { value: "MWE", label: "MWE" },
    { value: "MWV", label: "MWV" },
    { value: "NEG", label: "NEG" },
    { value: "NN", label: "NN" },
    { value: "NPADVMOD", label: "NPADVMOD" },
    { value: "NSUBJ", label: "NSUBJ" },
    { value: "NSUBJPASS", label: "NSUBJPASS" },
    { value: "NUM", label: "NUM" },
    { value: "NUMBER", label: "NUMBER" },
    { value: "P", label: "P" },
    { value: "PARATAXIS", label: "PARATAXIS" },
    { value: "PARTMOD", label: "PARTMOD" },
    { value: "PCOMP", label: "PCOMP" },
    { value: "POBJ", label: "POBJ" },
    { value: "POSS", label: "POSS" },
    { value: "POSTNEG", label: "POSTNEG" },
    { value: "PRECOMP", label: "PRECOMP" },
    { value: "PRECONJ", label: "PRECONJ" },
    { value: "PREDET", label: "PREDET" },
    { value: "PREF", label: "PREF" },
    { value: "PREP", label: "PREP" },
    { value: "PRONL", label: "PRONL" },
    { value: "PRT", label: "PRT" },
    { value: "PS", label: "PS" },
    { value: "QUANTMOD", label: "QUANTMOD" },
    { value: "RCMOD", label: "RCMOD" },
    { value: "RCMODREL", label: "RCMODREL" },
    { value: "RDROP", label: "RDROP" },
    { value: "REF", label: "REF" },
    { value: "REMNANT", label: "REMNANT" },
    { value: "REPARANDUM", label: "REPARANDUM" },
    { value: "ROOT", label: "ROOT" },
    { value: "SNUM", label: "SNUM" },
    { value: "SUFF", label: "SUFF" },
    { value: "TMOD", label: "TMOD" },
    { value: "TOPIC", label: "TOPIC" },
    { value: "VMOD", label: "VMOD" },
    { value: "VOCATIVE", label: "VOCATIVE" },
    { value: "XCOMP", label: "XCOMP" },
    { value: "SUFFIX", label: "SUFFIX" },
    { value: "TITLE", label: "TITLE" },
    { value: "ADVPHMOD", label: "ADVPHMOD" },
    { value: "AUXCAUS", label: "AUXCAUS" },
    { value: "AUXVV", label: "AUXVV" },
    { value: "DTMOD", label: "DTMOD" },
    { value: "FOREIGN", label: "FOREIGN" },
    { value: "KW", label: "KW" },
    { value: "LIST", label: "LIST" },
    { value: "NOMC", label: "NOMC" },
    { value: "NOMCSUBJ", label: "NOMCSUBJ" },
    { value: "NOMCSUBJPASS", label: "NOMCSUBJPASS" },
    { value: "NUMC", label: "NUMC" },
    { value: "COP", label: "COP" },
    { value: "DISLOCATED", label: "DISLOCATED" },
    { value: "ASP", label: "ASP" },
    { value: "GMOD", label: "GMOD" },
    { value: "GOBJ", label: "GOBJ" },
    { value: "INFMOD", label: "INFMOD" },
    { value: "MES", label: "MES" },
    { value: "NCOMP", label: "NCOMP" }
  ],
  proper: [{ value: "PROPER", label: "PROPER" }],
  person: [
    { value: "PERSON_UNKNOWN", label: "PERSON_UNKNOWN" },
    { value: "FIRST", label: "FIRST" },
    { value: "SECOND", label: "SECOND" },
    { value: "THIRD", label: "THIRD" },
    { value: "REFLEXIVE", label: "REFLEXIVE" }
  ],
  voice: [
    { value: "VOICE_UNKNOWN", label: "VOICE_UNKNOWN" },
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "CAUSATIVE", label: "CAUSATIVE" },
    { value: "PASSIVE", label: "PASSIVE" }
  ],
  tense: [
    { value: "TENSE_UNKNOWN", label: "TENSE_UNKNOWN" },
    { value: "CONDITIONAL_TENSE", label: "CONDITIONAL_TENSE" },
    { value: "FUTURE", label: "FUTURE" },
    { value: "PAST", label: "PAST" },
    { value: "PRESENT", label: "PRESENT" },
    { value: "IMPERFECT", label: "IMPERFECT" },
    { value: "PLUPERFECT", label: "PLUPERFECT" }
  ],
  number: [
    { value: "NUMBER", label: "NUMBER" },
    { value: "SINGULAR", label: "SINGULAR" },
    { value: "PLURAL", label: "PLURAL" },
    { value: "DUAL", label: "DUAL" }
  ],
  lemma: [
    { value: "BE", label: "BE" },
    { value: "DO", label: "DO" }
  ],
  pos: [
    { value: "UNKNOWN", label: "UNKNOWN" },
    { value: "ADJ", label: "ADJ" },
    { value: "ADP", label: "ADP" },
    { value: "ADV", label: "ADV" },
    { value: "CONJ", label: "CONJ" },
    { value: "DET", label: "DET" },
    { value: "NOUN", label: "NOUN" },
    { value: "NUM", label: "NUM" },
    { value: "PRON", label: "PRON" },
    { value: "PRT", label: "PRT" },
    { value: "PUNCT", label: "PUNCT" },
    { value: "VERB", label: "VERB" },
    { value: "X", label: "X" },
    { value: "AFFIX", label: "AFFIX" }
  ],
  spacy_label: [
    { value: "ROOT", label: "ROOT" },
    { value: "acl", label: "acl" },
    { value: "acomp", label: "acomp" },
    { value: "advcl", label: "advcl" },
    { value: "advmod", label: "advmod" },
    { value: "agent", label: "agent" },
    { value: "amod", label: "amod" },
    { value: "appos", label: "appos" },
    { value: "attr", label: "attr" },
    { value: "aux", label: "aux" },
    { value: "auxpass", label: "auxpass" },
    { value: "case", label: "case" },
    { value: "cc", label: "cc" },
    { value: "ccomp", label: "ccomp" },
    { value: "compound", label: "compound" },
    { value: "conj", label: "conj" },
    { value: "cop", label: "cop" },
    { value: "csubj", label: "csubj" },
    { value: "csubjpass", label: "csubjpass" },
    { value: "dative", label: "dative" },
    { value: "dep", label: "dep" },
    { value: "det", label: "det" },
    { value: "dobj", label: "dobj" },
    { value: "expl", label: "expl" },
    { value: "intj", label: "intj" },
    { value: "mark", label: "mark" },
    { value: "meta", label: "meta" },
    { value: "neg", label: "neg" },
    { value: "nmod", label: "nmod" },
    { value: "nn", label: "nn" },
    { value: "nounmod", label: "nounmod" },
    { value: "npadvmod", label: "npadvmod" },
    { value: "npmod", label: "npmod" },
    { value: "nsubj", label: "nsubj" },
    { value: "nsubjpass", label: "nsubjpass" },
    { value: "nummod", label: "nummod" },
    { value: "obj", label: "obj" },
    { value: "obl", label: "obl" },
    { value: "oprd", label: "oprd" },
    { value: "parataxis", label: "parataxis" },
    { value: "pcomp", label: "pcomp" },
    { value: "pobj", label: "pobj" },
    { value: "poss", label: "poss" },
    { value: "preconj", label: "preconj" },
    { value: "predet", label: "predet" },
    { value: "prep", label: "prep" },
    { value: "prt", label: "prt" },
    { value: "punct", label: "punct" },
    { value: "quantmod", label: "quantmod" },
    { value: "relcl", label: "relcl" },
    { value: "root", label: "root" },
    { value: "xcomp", label: "xcomp" }
  ],
  spacy_pos: [
    {
      Tag: "$",
      POS: "SYM",
      Morphology: "",
      Description: "symbol, currency",
      value: "$",
      label: "$"
    },
    {
      Tag: "``",
      POS: "PUNCT",
      Morphology: "PunctType=quot PunctSide=ini",
      Description: "opening quotation mark",
      value: "``",
      label: "``"
    },
    {
      Tag: "''",
      POS: "PUNCT",
      Morphology: "PunctType=quot PunctSide=fin",
      Description: "closing quotation mark",
      value: "''",
      label: "''"
    },
    {
      Tag: ",",
      POS: "PUNCT",
      Morphology: "PunctType=comm",
      Description: "punctuation mark, comma",
      value: ",",
      label: ","
    },
    {
      Tag: "-LRB-",
      POS: "PUNCT",
      Morphology: "PunctType=brck PunctSide=ini",
      Description: "left round bracket",
      value: "-LRB-",
      label: "-LRB-"
    },
    {
      Tag: "-RRB-",
      POS: "PUNCT",
      Morphology: "PunctType=brck PunctSide=fin",
      Description: "right round bracket",
      value: "-RRB-",
      label: "-RRB-"
    },
    {
      Tag: ".",
      POS: "PUNCT",
      Morphology: "PunctType=peri",
      Description: "punctuation mark, sentence closer",
      value: ".",
      label: "."
    },
    {
      Tag: ":",
      POS: "PUNCT",
      Morphology: "",
      Description: "punctuation mark, colon or ellipsis",
      value: ":",
      label: ":"
    },
    {
      Tag: "ADD",
      POS: "X",
      Morphology: "",
      Description: "email",
      value: "ADD",
      label: "ADD"
    },
    {
      Tag: "AFX",
      POS: "ADJ",
      Morphology: "Hyph=yes",
      Description: "affix",
      value: "AFX",
      label: "AFX"
    },
    {
      Tag: "CC",
      POS: "CCONJ",
      Morphology: "ConjType=comp",
      Description: "conjunction, coordinating",
      value: "CC",
      label: "CC"
    },
    {
      Tag: "CD",
      POS: "NUM",
      Morphology: "NumType=card",
      Description: "cardinal number",
      value: "CD",
      label: "CD"
    },
    {
      Tag: "DT",
      POS: "DET",
      Morphology: "",
      Description: "determiner",
      value: "DT",
      label: "DT"
    },
    {
      Tag: "EX",
      POS: "PRON",
      Morphology: "AdvType=ex",
      Description: "existential there",
      value: "EX",
      label: "EX"
    },
    {
      Tag: "FW",
      POS: "X",
      Morphology: "Foreign=yes",
      Description: "foreign word",
      value: "FW",
      label: "FW"
    },
    {
      Tag: "GW",
      POS: "X",
      Morphology: "",
      Description: "additional word in multi-word expression",
      value: "GW",
      label: "GW"
    },
    {
      Tag: "HYPH",
      POS: "PUNCT",
      Morphology: "PunctType=dash",
      Description: "punctuation mark, hyphen",
      value: "HYPH",
      label: "HYPH"
    },
    {
      Tag: "IN",
      POS: "ADP",
      Morphology: "",
      Description: "conjunction, subordinating or preposition",
      value: "IN",
      label: "IN"
    },
    {
      Tag: "JJ",
      POS: "ADJ",
      Morphology: "Degree=pos",
      Description: "adjective",
      value: "JJ",
      label: "JJ"
    },
    {
      Tag: "JJR",
      POS: "ADJ",
      Morphology: "Degree=comp",
      Description: "adjective, comparative",
      value: "JJR",
      label: "JJR"
    },
    {
      Tag: "JJS",
      POS: "ADJ",
      Morphology: "Degree=sup",
      Description: "adjective, superlative",
      value: "JJS",
      label: "JJS"
    },
    {
      Tag: "LS",
      POS: "X",
      Morphology: "NumType=ord",
      Description: "list item marker",
      value: "LS",
      label: "LS"
    },
    {
      Tag: "MD",
      POS: "VERB",
      Morphology: "VerbType=mod",
      Description: "verb, modal auxiliary",
      value: "MD",
      label: "MD"
    },
    {
      Tag: "NFP",
      POS: "PUNCT",
      Morphology: "",
      Description: "superfluous punctuation",
      value: "NFP",
      label: "NFP"
    },
    {
      Tag: "NIL",
      POS: "X",
      Morphology: "",
      Description: "missing tag",
      value: "NIL",
      label: "NIL"
    },
    {
      Tag: "NN",
      POS: "NOUN",
      Morphology: "Number=sing",
      Description: "noun, singular or mass",
      value: "NN",
      label: "NN"
    },
    {
      Tag: "NNP",
      POS: "PROPN",
      Morphology: "NounType=prop Number=sing",
      Description: "noun, proper singular",
      value: "NNP",
      label: "NNP"
    },
    {
      Tag: "NNPS",
      POS: "PROPN",
      Morphology: "NounType=prop Number=plur",
      Description: "noun, proper plural",
      value: "NNPS",
      label: "NNPS"
    },
    {
      Tag: "NNS",
      POS: "NOUN",
      Morphology: "Number=plur",
      Description: "noun, plural",
      value: "NNS",
      label: "NNS"
    },
    {
      Tag: "PDT",
      POS: "DET",
      Morphology: "",
      Description: "predeterminer",
      value: "PDT",
      label: "PDT"
    },
    {
      Tag: "POS",
      POS: "PART",
      Morphology: "Poss=yes",
      Description: "possessive ending",
      value: "POS",
      label: "POS"
    },
    {
      Tag: "PRP",
      POS: "PRON",
      Morphology: "PronType=prs",
      Description: "pronoun, personal",
      value: "PRP",
      label: "PRP"
    },
    {
      Tag: "PRP$",
      POS: "DET",
      Morphology: "PronType=prs Poss=yes",
      Description: "pronoun, possessive",
      value: "PRP$",
      label: "PRP$"
    },
    {
      Tag: "RB",
      POS: "ADV",
      Morphology: "Degree=pos",
      Description: "adverb",
      value: "RB",
      label: "RB"
    },
    {
      Tag: "RBR",
      POS: "ADV",
      Morphology: "Degree=comp",
      Description: "adverb, comparative",
      value: "RBR",
      label: "RBR"
    },
    {
      Tag: "RBS",
      POS: "ADV",
      Morphology: "Degree=sup",
      Description: "adverb, superlative",
      value: "RBS",
      label: "RBS"
    },
    {
      Tag: "RP",
      POS: "ADP",
      Morphology: "",
      Description: "adverb, particle",
      value: "RP",
      label: "RP"
    },
    {
      Tag: "SP",
      POS: "SPACE",
      Morphology: "",
      Description: "space",
      value: "SP",
      label: "SP"
    },
    {
      Tag: "SYM",
      POS: "SYM",
      Morphology: "",
      Description: "symbol",
      value: "SYM",
      label: "SYM"
    },
    {
      Tag: "TO",
      POS: "PART",
      Morphology: "PartType=inf VerbForm=inf",
      Description: "infinitival “to”",
      value: "TO",
      label: "TO"
    },
    {
      Tag: "UH",
      POS: "INTJ",
      Morphology: "",
      Description: "interjection",
      value: "UH",
      label: "UH"
    },
    {
      Tag: "VB",
      POS: "VERB",
      Morphology: "VerbForm=inf",
      Description: "verb, base form",
      value: "VB",
      label: "VB"
    },
    {
      Tag: "VBD",
      POS: "VERB",
      Morphology: "VerbForm=fin Tense=past",
      Description: "verb, past tense",
      value: "VBD",
      label: "VBD"
    },
    {
      Tag: "VBG",
      POS: "VERB",
      Morphology: "VerbForm=part Tense=pres Aspect=prog",
      Description: "verb, gerund or present participle",
      value: "VBG",
      label: "VBG"
    },
    {
      Tag: "VBN",
      POS: "VERB",
      Morphology: "VerbForm=part Tense=past Aspect=perf",
      Description: "verb, past participle",
      value: "VBN",
      label: "VBN"
    },
    {
      Tag: "VBP",
      POS: "VERB",
      Morphology: "VerbForm=fin Tense=pres",
      Description: "verb, non-3rd person singular present",
      value: "VBP",
      label: "VBP"
    },
    {
      Tag: "VBZ",
      POS: "VERB",
      Morphology: "VerbForm=fin Tense=pres Number=sing Person=three",
      Description: "verb, 3rd person singular present",
      value: "VBZ",
      label: "VBZ"
    },
    {
      Tag: "WDT",
      POS: "DET",
      Morphology: "",
      Description: "wh-determiner",
      value: "WDT",
      label: "WDT"
    },
    {
      Tag: "WP",
      POS: "PRON",
      Morphology: "",
      Description: "wh-pronoun, personal",
      value: "WP",
      label: "WP"
    },
    {
      Tag: "WP$",
      POS: "DET",
      Morphology: "Poss=yes",
      Description: "wh-pronoun, possessive",
      value: "WP$",
      label: "WP$"
    },
    {
      Tag: "WRB",
      POS: "ADV",
      Morphology: "",
      Description: "wh-adverb",
      value: "WRB",
      label: "WRB"
    },
    {
      Tag: "XX",
      POS: "X",
      Morphology: "",
      Description: "unknown",
      value: "XX",
      label: "XX"
    },
    {
      Tag: "_SP",
      POS: "SPACE",
      Morphology: "",
      Description: "",
      value: "_SP",
      label: "_SP"
    }
  ]
};

export const multiSelectEnabledResourcesMap = ["teaching_unit_speech_response_map"]

export default constant;
