import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import compose from "recompose/compose";

class ImagePreview extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onLoad = () => {
		this.setState({
			error: false,
			width: this.img.naturalWidth,
			height: this.img.naturalHeight
		});
	};

	onError = event => {
		this.setState({ error: true, width: null, height: null });
	};

	render() {
		const { classes, src } = this.props;
		const { width, height, error = false } = this.state;

		if (!src || src.length === 0) return null;

		return (
			<div className={classes.flex}>
				<div className={classes.previewCanvas}>
					<img
						ref={ref => (this.img = ref)}
						src={src}
						alt="preview"
						onLoad={this.onLoad}
						onError={this.onError}
					/>
				</div>
				<div className={classes.attrs}>
					<Typography variant="caption">
						{width && height && `Resolution: ${width}x${height}`}
						{error && "Image failed to load"}
					</Typography>
				</div>
			</div>
		);
	}
}

const styles = theme => ({
	flex: { display: "flex", padding: theme.spacing(1) },
	attrs: { padding: theme.spacing(1) },
	previewCanvas: {
		backgroundImage:
			"linear-gradient(45deg, #aaa 25%, transparent 25%), linear-gradient(-45deg, #aaa 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #aaa 75%), linear-gradient(-45deg, transparent 75%, #aaa 75%)",
		backgroundSize: "20px 20px",
		backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
		animation: "$marchingSquares 3s linear infinite",
		display: "inline-block",
		fontSize: 0,
		"& img": {
			maxWidth: 200
		}
	},
	"@keyframes marchingSquares": {
		"0%": { backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px" },
		"100%": { backgroundPosition: "0 20px, 0 30px, 10px 10px, -10px 20px" }
	}
});

export default compose(withStyles(styles))(ImagePreview);
