import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Pagination from "./Pagination";
import {Grid} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SearchInput from "./SearchInput";


const styles = theme => ({
	inlineList: {
		paddingLeft: theme.spacing(2)
	},
	inline: {
		padding: theme.spacing(1)
	},
	textField: {
		margin: theme.spacing(1),
		width: "calc(100% - " + 64 + "px)"
	},
	margin: {
		marginLeft: theme.spacing(2)
	}
});

const ROWS_PER_PAGE = 15;

class StringsTableForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			error: null,
			search: ''
		}
	}


	renderRow = (row, idx) => {
		let c;

		switch (row.type) {
			case "random":
				c = this.renderRandom(row, [idx]);
				break;
			case "lookup":
				c = this.renderLookup(row, [idx]);
				break;
			case "equals":
			default:
				c = this.renderSingle(row, [idx]);
				break;
		}

		const { disabled, classes } = this.props;
		const { search } = this.state

		return (
			<TableRow key={idx}>
				<TableCell>
					<TextField
						className={ classes.textField }
						onPaste={this.handlePaste.bind(this)}
						placeholder="paste form a sheet for bulk insert"
						onChange={this.handleChange.bind(this, [idx])}
						name="key"
						disabled={disabled || search}
						value={row.key}
					/>
				</TableCell>
				<TableCell>
					<Select
						name="type"
						value={row.type || ""}
						disabled={disabled}
						onChange={this.handleChange.bind(this, [idx])}
					>
						<MenuItem value="equals">{"="}</MenuItem>
						<MenuItem value="random">one of</MenuItem>
						<MenuItem value="lookup">when</MenuItem>
					</Select>
				</TableCell>
				{c}
				{ !disabled && <TableCell>
					<IconButton color="secondary" onClick={ this.removeItem.bind(this, null, idx) }>
						<DeleteIcon/>
					</IconButton>
				</TableCell> }
			</TableRow>
		);
	};

	renderSingle = (row, prefix) => {
		const { classes, disabled } = this.props;
		return (
			<TableCell>
				<TextField
					disabled={disabled}
					onChange={this.handleChange.bind(this, prefix)}
					name="value"
					value={row.value}
					className={classes.textField}
				/>
			</TableCell>
		);
	};

	renderRandom = (row, prefix) => {
		const { classes, disabled } = this.props;

		return (
			<TableCell>
				{row.choices.map((c, idx) => {
					return (
						<div key={idx}>
							<TextField
								disabled={disabled}
								className={classes.textField}
								onChange={this.handleChange.bind(
									this,
									prefix.concat(["choices"])
								)}
								name={idx}
								value={c}
							/>
							{ !disabled && <IconButton
								color="primary"
								onClick={this.removeItem.bind(
									this,
									prefix.concat(["choices"]),
									idx
								)}
							>
								<DeleteIcon />
							</IconButton> }
						</div>
					);
				})}
				{!disabled && (
					<IconButton
						color="primary"
						onClick={this.addRandom.bind(this, prefix)}
					>
						<AddIcon />
					</IconButton>
				)}
			</TableCell>
		);
	};

	renderLookup = (row, prefix) => {
		const { classes, disabled } = this.props;
		return (
			<TableCell>
				<Table padding="none" size="small">
					{row.lookups.map((lk, idx) => {
						const { value, key } = lk;
						return (
							<TableRow key={key}>
								<TableCell>
									<TextField
										className={classes.textField}
										onChange={this.handleChange.bind(
											this,
											prefix.concat(["lookups", idx])
										)}
										name="key"
										value={key}
										disabled={key === "default" || disabled}
									/>
								</TableCell>
								<TableCell>then</TableCell>
								<TableCell>
									<TextField
										className={classes.textField}
										onChange={this.handleChange.bind(
											this,
											prefix.concat(["lookups", idx])
										)}
										name="value"
										disabled={disabled}
										value={value}
									/>
								</TableCell>
								{ !disabled && <TableCell>
									<IconButton
										color="primary"
										onClick={this.removeItem.bind(
											this,
											prefix.concat(["lookups"]),
											idx
										)}
										disabled={key === "default" || disabled}
									>
										<DeleteIcon />
									</IconButton>
								</TableCell> }
							</TableRow>
						);
					})}
				</Table>
				{!disabled && (
					<IconButton
						color="primary"
						onClick={this.addLookup.bind(this, prefix)}
					>
						<AddIcon />
					</IconButton>
				)}
			</TableCell>
		);
	};

	handleChange = (prefix, event) => {
		const { value, name } = this.props;
		let newValue = value.setIn(
			prefix.concat(event.target.name),
			event.target.value
		);
		this.props.onChange({ target: { value: newValue, name } });
	};

	handlePaste(event) {
		let data = (event.clipboardData || window.clipboardData).getData("text");
		event.preventDefault();
		try {
			data = TransFormToObjectArray(parseTSV(data));
		} catch (e) {
			this.setState({
				...this.state,
				error: e.message
			})
			return
		}
		this.setState({
			...this.state,
			error: null
		})
		const oldValues = [...this.props.value]
		oldValues.shift()
		this.props.onChange({target: {value: data.concat(...oldValues), name: this.props.name}});
	}

	addNewString = () => {
		const { value, name } = this.props;
		const newValue = [{
			key: "",
			type: "equals",
			value: "",
			choices: [],
			lookups: []
		},...value]
		this.props.onChange({ target: { value: newValue, name } });
	};

	addLookup = prefix => {
		const { value, name } = this.props;
		prefix = prefix.concat(["lookups"]);
		const existing = value.getIn(prefix);
		const defKey =
			existing.length === 0
				? "default"
				: "" + (Object.keys(existing).length - 1);
		const newLookups = value
			.getIn(prefix)
			.concat({ key: defKey, value: "" });
		const newValue = value.setIn(prefix, newLookups);
		this.props.onChange({ target: { value: newValue, name } });
	};

	addRandom = prefix => {
		const { value, name } = this.props;
		const newChoices = value.getIn(prefix.concat("choices")).concat("");
		const newValue = value.setIn(prefix.concat("choices"), newChoices);
		this.props.onChange({ target: { value: newValue, name } });
	};

	removeItem = (prefix, idx) => {
		const { value, name } = this.props;
		if( prefix === null ) {
			const newValue = value.asMutable();
			newValue.splice(idx, 1)
			this.props.onChange({ target: { value: newValue, name } });
		}
		else {
			const newArray = value.getIn(prefix).asMutable();
			newArray.splice(idx, 1);
			const newValue = value.setIn(prefix, newArray);
			this.props.onChange({ target: { value: newValue, name } });
		}

	};
	handlePageChange(page){
		this.setState({
			...this.state,
			page: page
		})
	}

	handleSearch(event) {
		this.setState({
			...this.state,
			page: 1,
			search: event.target.value,
		})
	}

	render() {
		const { classes, disabled, label, value } = this.props;
		const { page, search } = this.state;
		const offset = (page - 1) * ROWS_PER_PAGE;
		let rowsCount = 0;
		return (
			<div className={classes.inline}>
				<Grid container justify="space-between">
					<Grid item>
						<Typography variant="caption">{ label }</Typography>
						{!disabled && (
							<IconButton color="primary" onClick={this.addNewString} className={classes.margin}>
								<AddIcon />
							</IconButton>
						)}
					</Grid>
					<Grid item>
						<SearchInput placeholder="Search for key" onChange={this.handleSearch.bind(this)}/>
					</Grid>
				</Grid>
				{this.state.error && <Chip  color="secondary" label={this.state.error} variant="outlined" /> }
				<Table size="small">
					<TableBody>
						{
							value.map((row, idx) => {
								if(row["key"].includes(search)){
									rowsCount++;
									if((rowsCount - 1) >= offset && (rowsCount - 1) < (offset + ROWS_PER_PAGE)) {
										return this.renderRow(row, idx)
									}
								}
							})
						}
					</TableBody>
				</Table>

				<Pagination
					pagination={{
						"current_page": page,
						"total_pages": Math.max(Math.ceil(rowsCount / ROWS_PER_PAGE), 1),
					}}
					onPageChange={this.handlePageChange.bind(this)}
				/>
			</div>
		);
	}
}

// support for bulk insert
function TransFormToObjectArray(array) {
	const transformed = {};
	if(!Array.isArray(array)){
		throw new Error('improper sheet copied');
	}
	array.forEach((row, index) => {
		const key = getColumnFromRow(row, 0);
		let type = getColumnFromRow(row, 1);
		let value = getColumnFromRow(row, 2);

		switch (type.toLowerCase()) {
			case "one of":
				type = 'random'
				break;
			case "when":
				type = 'lookup';
				break;
			case "equals":
				type = 'equals';
				break;
			default:
				type = '';
				break;
		}
		if (key !== '') {
			if (transformed.hasOwnProperty(key)) {
				if (transformed[key]["choices"].length > 0 && type === 'random') {
					transformed[key]['choices'].push(value)
				} else if (transformed[key]["lookups"].length > 0 && type === 'lookup') {
					const condition = transformed[key]["lookups"].length - 1;
					if(condition > 9){
						throw new Error(`cannot have more than 9 cases for when check key ${key}`)
					}
					transformed[key]['lookups'].push({"key": condition, "value": value })
				} else {
					throw new Error(`Invalid State check row ${index+1} key ${key}`);
				}
			} else {
				transformed[key] = {
					key,
					type,
					value: type === 'equals' ? value : "",
					choices: type === 'random' ? [value] : [],
					lookups: type === 'lookup' ? [{"key": "default", "value": value}] : [],
				}
			}
		} else {
			throw new Error(`key cannot be empty check row ${index+1} column 1`);
		}

	})
	return Object.values(transformed);
}

function getColumnFromRow(row, index, def = '') {
	return row.length > index ? row[index] : def;
}

export function parseTSV(text){
	const data = []
	const rows = text.split(/\r?\n/);
	for(const row of rows){
		data.push(row.split("\t"));
	}
	return data;
}

export default withStyles(styles)(StringsTableForm);
