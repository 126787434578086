import React from "react";
import ReactDOM from "react-dom";
import loadscript from "load-script";

import "./index.css";
import "./polyfills"
import App from "./App";
import AppConfig from "./Config/AppConfig";

require("react-hot-loader/patch");

ReactDOM.render(<App />, document.getElementById("root"));
document.title = `CMS ${new Date().getFullYear()}`

if (AppConfig.RAVEN_DSN) {
	loadscript(
		"https://cdn.ravenjs.com/3.19.1/raven.min.js",
		{ attrs: { crossOrigin: "anonymous" } },
		function() {
			const Raven = window.Raven;

			Raven.config(AppConfig.RAVEN_DSN).install();
			Raven.setExtraContext({ BUILD: AppConfig.VERSION })
		}
	);
}
