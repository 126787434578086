import React from "react";
import Select from "react-select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import constantVal from "./constValue";
import style from "./style/style.module.css";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles(theme => ({
    chip: {
        padding: "10px",
        minWidth: "25%",
        width: "75%"
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400
    }
}));

export default function SpacyTag(props) {
    const classes = useStyles();
    const { pos = [], label = [] } = props.data;
    let posValue = pos.map(v => {
        return { label: v, value: v };
    });
    let labelValue = label.map(v => {
        return { label: v, value: v };
    });
    let posDesc = [];
    pos.forEach(val => {
        let v = constantVal.spacy_pos.filter(i => i.value === val);
        posDesc.push(v[0]);
    });
    let switchCheck, switchlabel;
    if (props.title === "sequence_spacy") {
        switchCheck = props.data.optional
        switchlabel = "Optional";
    } else if (props.title === "negation") {
        switchCheck = true
        switchlabel = "Absence";
    } else {
        switchCheck = props.data.absence
        switchlabel = "Absence";
    }
    return (
        <div className={style.content}>
            <div className={style.deleteicon}>
                <DeleteIcon onClick={() => props.handledeleteregexrow(props.id)} />
            </div>
            <div className={style.sequenceChip}>
                <div className={style.chips}>
                    <FormControlLabel
                        className="switch"
                        control={
                            <Switch
                                color="primary"
                                checked={switchCheck}
                                onChange={e => {
                                    props.handleRegex(
                                        props.id,
                                        switchlabel.toLowerCase(),
                                        // e.target.checked.toString(),
                                        e.target.checked
                                    );
                                }}
                            />
                        }
                        label={switchlabel}
                    />

                    <Select
                        className={classes.chip}
                        placeholder="Tag"
                        value={posValue}
                        isMulti
                        options={constantVal.spacy_pos}
                        onChange={obj => {
                            props.handleSelectMulti(props.id, "pos", obj);
                        }}
                    />

                    <Select
                        className={classes.chip}
                        placeholder="Label/Dependency Parse"
                        value={labelValue}
                        isMulti
                        options={constantVal.spacy_label}
                        onChange={value => {
                            props.handleSelectMulti(props.id, "label", value);
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        id="standard-name"
                        label="Content"
                        value={props.data.content}
                        onChange={event => {
                            props.handleRegex(
                                props.id,
                                "content",
                                event.target.value.toLowerCase()
                            );
                        }}
                        margin="normal"
                    />
                </div>
                {posDesc.length !== 0 && (
                    <React.Fragment>
                        <div className={style.grid}>
                            <div>POS</div>
                            <div>TAG</div>
                            <div>Morphology</div>
                            <div>Description</div>
                        </div>
                        <hr width="100%" />
                    </React.Fragment>
                )}

                <div className={style.grid}>
                    {posDesc.map((val, i) => (
                        <React.Fragment key={i}>
                            <div>{val.POS}</div>
                            <div>{val.Tag}</div>
                            <div>{val.Morphology}</div>
                            <div>{val.Description}</div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div >
    );
}
