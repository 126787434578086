import React from 'react';
import _get from 'lodash/get';
import CKEditorCard from './CKEditorCard';
import isEmpty from 'lodash/isEmpty';

function VideoComponent({
  item,
  heading,
  videoUrlkeyName,
  videoTokenkeyName,
  updateData,
}) {
  const videoUrl = _get(item, videoUrlkeyName);
  const videoToken = _get(item, videoTokenkeyName);
  return (
    <div className="video-input-wrapper">
      <div className="heading">{heading}</div>
      <div>
        <label for="video-input-url">Url</label>
        <input
          className="video-input-style"
          value={videoUrl}
          onChange={(e) => updateData(videoUrlkeyName, e.target.value)}
          id="video-input-url"
        />
      </div>
      <div>
        <label for="video-input-token">Token</label>
        <input
          className="video-input-style"
          value={videoToken}
          onChange={(e) => updateData(videoTokenkeyName, e.target.value)}
          id="video-input-token"
        />
      </div>
    </div>
  );
}

function ExtraAudioText({
  item,
  heading,
  template,
  updateData,
  data,
  extraProps,
  metadata,
}) {
  const { keyName } = template;
  const updateEditorValue = (keyName, event) => {
    let data = event.editor.getData();
    updateData(keyName, data);
  };

  const {
    content_guidelines: { charCount, removeButtons, enableParagraph },
    keyName: keyNameExtraProps,
    labelName,
  } = extraProps;
  const value = _get(data, `${keyName}.context.${keyNameExtraProps}`, '');

  return (
    <div key={keyName + keyNameExtraProps} className={'editor-block'}>
      <div className="heading">{labelName}</div>
      <CKEditorCard
        label={''}
        value={value}
        name={keyName}
        removeButtons={removeButtons}
        charCount={charCount}
        enableParagraph={enableParagraph}
        updateContent={(e) =>
          updateEditorValue(`${keyName}.context.${keyNameExtraProps}`, e)
        }
        hideEditor={false}
      />
    </div>
  );
}

function ExtraMapper({ item, template, updateData, selectedOption, data }) {
  const { keyName } = template;
  const extraPropsKey = `${item}Props`;
  const extraProps = selectedOption[extraPropsKey] || {};
  switch (item) {
    case 'dashVideoComponent':
      return (
        <VideoComponent
          heading="Dash Video"
          videoUrlkeyName={`${keyName}.context.dashVideoUrl`}
          videoTokenkeyName={`${keyName}.context.dashVideoToken`}
          item={item}
          updateData={updateData}
        />
      );
    case 'hlsVideoComponent':
      return (
        <VideoComponent
          heading="HLS Video"
          videoUrlkeyName={`${keyName}.context.hlsVideoUrl`}
          videoTokenkeyName={`${keyName}.context.hlsVideoToken`}
          item={item}
          updateData={updateData}
        />
      );
    case 'extraAudioText':
      return (
        <ExtraAudioText
          item={item}
          template={template}
          updateData={updateData}
          extraProps={extraProps}
          data={data}
        />
      );
    default:
      return null;
  }
}

export default function CombinedTextEditorElement({
  template,
  initializerFn,
  data,
  metadata,
  updateData,
}) {
  const {
    keyName,
    labelName,
    content_guidelines: {
      contentType: {
        key: contentTypeKey,
        defaultOption: defaultContentTypeOption,
        options: contentTypeOptions,
      } = {},
      removeButtons = '',
      charCount = -1,
      default_text = '',
      enableParagraph = false,
      instruction,
      transformContent = false,
      showQuestionInstruction = {},
    },
  } = template;

  const value = _get(data, `${keyName}.context.html`, '');
  const contentTypeRaw = _get(data, `${keyName}.${contentTypeKey}`, '');
  const contentType = contentTypeRaw || defaultContentTypeOption;
  const contentTypeSelectedOption = contentTypeOptions?.find(
    (el) => el.key === contentType
  );

  const keyPathArr = keyName.split('.');
  const keyNameParentPath = keyPathArr.splice(0, keyPathArr.length - 1);
  const keyNameLast = keyPathArr[0];

  const updateEditorValue = (keyName, event, transformContent) => {
    let data = event.editor.getData();
    if (transformContent && transformContent instanceof Function) {
      data = transformContent(data);
    }
    updateData(keyName, data);
  };

  let parent;
  if (keyNameParentPath.length > 0) {
    parent = _get(data, keyNameParentPath);
  } else {
    parent = data;
  }

  if (!parent || !parent.hasOwnProperty(keyNameLast)) {
    initializerFn({
      keyName,
      defaultContentTypeOption,
      defaultText: default_text,
      contentTypeOptionKey: contentTypeKey,
    });
  }
  // showQuestionInstruction
  let name = _get(showQuestionInstruction, 'keyName', '');
  let key = _get(metadata, name, '');
  let isSelected = metadata[name];

  let contentTypeOptionsComponent;
  if (contentTypeOptions) {
    contentTypeOptionsComponent = (
      <div className="content-type-select-container">
        <select
          defaultValue={defaultContentTypeOption}
          value={contentTypeOptions.findIndex((el) => el.key === contentType)}
          onChange={(e) => {
            updateData(
              `${keyName}.${contentTypeKey}`,
              contentTypeOptions[e.target.value].key
            );
          }}
        >
          <option selected={true} disabled="disabled">
            Select Value
          </option>
          {contentTypeOptions.map((opt, key) => (
            <option value={key}>{opt.label}</option>
          ))}
        </select>
      </div>
    );
  }

  const {
    hideEditor,
    removeButtons: removeButtonsOverride,
    charCount: charCountOverride,
    enableParagraph: enableParagraphOverride,
    extras = [],
  } = contentTypeSelectedOption || {};

  return (
    <div key={keyName + key} className={'editor-block'}>
      <CKEditorCard
        label={labelName}
        value={value}
        name={keyName}
        removeButtons={removeButtonsOverride || removeButtons}
        charCount={charCountOverride || charCount}
        enableParagraph={enableParagraphOverride || enableParagraph}
        instruction={instruction}
        shouldShowInstruction={
          isEmpty(showQuestionInstruction) ||
          isSelected === _get(showQuestionInstruction, 'value', '')
        }
        updateContent={(e) =>
          updateEditorValue(`${keyName}.context.html`, e, transformContent)
        }
        hideEditor={hideEditor}
      >
        {contentTypeOptionsComponent}
        {extras.map((el) => (
          <ExtraMapper
            item={el}
            template={template}
            updateData={updateData}
            data={data}
            selectedOption={contentTypeSelectedOption}
          />
        ))}
      </CKEditorCard>
    </div>
  );
}
