import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import compose from "recompose/compose";
import copy from 'copy-to-clipboard';

import LaunchIcon from "./LaunchIcon";
import Button from "@material-ui/core/Button";
import CopyIcon from "@material-ui/icons/FileCopyOutlined"

const styles = theme => ({
	errorList: {
		listStyle: "none",
		"& a, a:active, a:visited": {
			color: theme.palette.primary.main
		}
	},
	smallIcon: {
		fontSize: 18,
		top: theme.spacing(0.5),
		position: 'relative',
		marginRight: theme.spacing(1)
	},
	info: {
		fontSize: 10,
		color: "#555"
	}
});

class ProjectValidationErrors extends PureComponent {
	typeError = (node_id, node_nickname, parent_node_nickname, type, possible_types) => {
		if (possible_types.length === 0) {
			return `Unexpected child ${node_nickname}, child of ${parent_node_nickname}`
		}
		return `Invalid resource ${node_nickname} of type ${type} child of ${parent_node_nickname}, Possible types are: ${possible_types}`;
	};

	duplicateError = (node_id, node_nickname, duplicates) => {
		return `Duplicate resources ${duplicates.join(
			", "
		)} child of ${node_nickname}`;
	};

	missingResourceError = (node_id, node_nickname, required_type) => {
		return `Missing ${required_type} resource, child of ${node_nickname}`
	};

	minCountResourceError = (node_id, node_nickname, required_type, count) => {
		return `Need atleast ${count} ${required_type} resource, child of ${node_nickname}`
	};

	maxCountResourceError = (node_id, node_nickname, required_type, count) => {
		return `Need atmost ${count} ${required_type} resource, child of ${node_nickname}`
	};

	multipleFoundForSingleError = (node_id, node_nickname, type) => {
		return `Multiple resources found for ${type}, expects only 1, child of ${node_nickname}`
	}

	resourceValidationError = (resource_nickname, resourceName, resource_id, error, node_ids) => {
		const { project, classes } = this.props;

		return (
			<span>
				{ node_ids.slice(0,4).map(
					node_id => <Link key={ node_id } to={`/workspaces/${project.workspace_id}/projects/${project.id}/tree/${node_id}`}>
							<LaunchIcon className={ classes.smallIcon }/>
						</Link>
				) }
				{
					node_ids.length > 4 && <span className={ classes.info }>{ ` + ${node_ids.length-4} others. `}</span>
				}
				<Link to={`/resources/${resourceName}/${resource_id}`}>
					{resource_nickname}
				</Link>{" " + (resourceName.replace(/ies$/,'y').replace(/s$/, '')) + " " + error}
			</span>
		);
	};

	renderError = error => {
		const [errType, ...err] = error;
		const func = errType.charAt(0).toLowerCase() + errType.slice(1);
		if( this[func] ) {
			return this[func].apply(this, err);
		}
		else {
			return "Unknown error! " + errType;
		}
	};

	onCopyClick = (event) => {
		const { errors, project } = this.props;
		const text = errors.map((err) => {
			if(err[0].startsWith("Resource")) {
				const [errType, nickname, resource_name, resource_id, ...other] = err;
				const node_url = `${window.origin}/resources/${resource_name}/${resource_id}`;
				return [nickname, node_url, errType, ...other].join("\t");	
			}
			else {
				const [errType, node_id, node_nickname] = err;
				const node_url = `${window.origin}/workspaces/${project.workspace_id}/projects/${project.id}/tree/${node_id}`
				return [node_nickname, node_url, errType].join("\t");	
			}
		}).join("\n\r");
		copy(text);
	}

	render() {
		const { errors, classes, project } = this.props;

		return (
			<ul className={ classes.errorList }>
				<li>{ errors.length } Errors <Button size="small" onClick={ this.onCopyClick }><CopyIcon/></Button></li>
				{errors.map((err, index) => (
					<li key={index}>
						{ !err[0].startsWith("Resource") && <Link to={`/workspaces/${project.workspace_id}/projects/${project.id}/tree/${err[1]}`}>
							<LaunchIcon className={ classes.smallIcon }/>
						</Link> }
						{this.renderError(err)}
					</li>
				))}
			</ul>
		);
	}
}

export default compose(withStyles(styles))(ProjectValidationErrors);
