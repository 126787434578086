import apisauce from "apisauce";
import qs from "qs";

import Config from "../../Config/AppConfig";


const api = apisauce.create({
    baseURL: Config.apiBaseURL,
    headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "X-CMS-WEB-Version": Config.VERSION
    },
    timeout: 50000
});
const ela_api = apisauce.create({
    baseURL: Config.elaApiBaseURL,
    headers: {
        'X-TNL-ENG-RULES-API-KEY': Config.ela_api_key,
    },
    timeout: 50000
});

api.addResponseTransform(response => {
    response.data = response.data || {};

    if (
        !response.ok &&
        response.problem &&
        (response.problem === apisauce.TIMEOUT_ERROR ||
            response.problem === apisauce.NETWORK_ERROR ||
            response.problem === apisauce.CONNECTION_ERROR)
    ) {
        let message = "";
        switch (response.problem) {
            case apisauce.TIMEOUT_ERROR:
                message = "Request timed out";
                break;
            case apisauce.NETWORK_ERROR:
                message = "Network Error";
                break;
            case apisauce.CONNECTION_ERROR:
                message = "Connection Error";
                break;
            default:
                break;
        }
        response.data.error = message;
    }
});

export const setHeader = api.setHeader;

export const createWorkspace = ({ workspaceParams }) => {
    return api.post(`/workspaces`, workspaceParams, {});
};

export const listWorkspaces = ({ params }) => {
    return api.get("/workspaces?" + qs.stringify(params), {}, {});
};

export const fetchWorkspace = ({ workspaceId }) => {
    return api.get(`/workspaces/${workspaceId}`, {}, {});
};

export const updateWorkspace = ({workspaceId, params}) => {
    return api.put(`/workspaces/${workspaceId}`, params, {});
};

export const listProjects = ({ workspaceId, params }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects?` + qs.stringify(params),
        {},
        {}
    );
};

export const fetchProject = ({ workspaceId, projectId }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects/${projectId}`,
        {},
        {}
    );
};

export const publishProject = ({ workspaceId, projectId }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects/${projectId}/publish`,
        {},
        {}
    );
};

export const listProjectSnapshots = ({ workspaceId, projectId }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects/${projectId}/snapshots`,
        {},
        {}
    );
};

export const createProjectSnapshot = ({ workspaceId, projectId }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects/${projectId}/snapshots?async=1`,
        {},
        {}
    );
};

export const deleteProjectSnapshot = ({ workspaceId, projectId, snapshotId }) => {
    return api.delete(
        `/workspaces/${workspaceId}/projects/${projectId}/snapshots/${snapshotId}`,
        {},
        {}
    );
};

export const rebaseProject = ({ workspaceId, projectId }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects/${projectId}/rebase`,
        {},
        {}
    );
};

export const createProject = ({ workspaceId, projectParams }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects`,
        projectParams,
        {}
    );
};

export const updateProject = (workspaceId, projectId, params) => {
    return api.put(
        `/workspaces/${workspaceId}/projects/${projectId}`,
        params,
        {}
    );
};

export const createNode = ({ workspaceId, projectId, nodeParams }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes`,
        nodeParams,
        {}
    );
};

export const fetchNode = ({ workspaceId, projectId, nodeId }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}`,
        {},
        {}
    );
};

export const fetchNodePreviousVersion = ({ workspaceId, projectId, nodeId }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/previous_version`,
        {},
        {}
    );
};

export const fetchNodeTree = ({ workspaceId, projectId, nodeId, params }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/tree?` + qs.stringify(params),
        {},
        {}
    );
};

export const moveNode = ({ workspaceId, projectId, nodeId, moveParams }) => {
    return api.put(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/move`,
        moveParams,
        {}
    );
};

export const deleteNode = ({ workspaceId, projectId, nodeId }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/delete`,
        {},
        {}
    );
};

export const undeleteNode = ({ workspaceId, projectId, nodeId }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/undelete`,
        {},
        {}
    );
};

export const revertNode = ({ workspaceId, projectId, nodeId }) => {
    return api.post(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/revert`,
        {},
        {}
    );
};

export const searchNode = ({ workspaceId, projectId, nodeId, params }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/search?` + qs.stringify(params),
        {},
        {}
    );
};

export const saveNodeResource = ({ workspaceId, projectId, nodeId, params }) => {
    return api.put(
        `/workspaces/${workspaceId}/projects/${projectId}/nodes/${nodeId}/resource`,
        params,
        {}
    );
};

export const fetchResourceReferences = ({ resourceName, resourceID }) => {
    return api.get(
        `/resources/${resourceName}/id/${resourceID}/references`,
        {},
        {}
    );
};
export const validateRule = ( payload ) => {
    return ela_api.post(
        '/internal/v1/rules/evaluate',
        payload
    )
}
export const loginUser = ({ params }) => {
    if (params.password_login) {
        return api.post(`/auth/login`, params);
    }
    return api.get(`/auth/google/callback`, params);
};

export const logoutUser = () => {
    return api.post(`/auth/logout`);
};

export const createFile = ({ file }) => {
    const fileParams = {
        filename: file.name,
        content_type: file.type,
        filesize: file.size,
        path: file.fullPath
    };
    return api.post(`/files`, fileParams, {});
};

export const swapFile = ({ swapParams }) => {
    return api.post(`/files/swap`, swapParams, {});
};

export const listFiles = ({ params }) => {
    return api.get(`/files?` + qs.stringify(params));
};

export const listDetailFiles = ({ params }) => {
    return api.get(`/files_detail?` + qs.stringify(params));
};

export const fetchSchema = () => {
    return api.get(`/resources`);
};

export const listResources = ({ resourceName, params }) => {
    return api.get(`/resources/${resourceName}/id?` + qs.stringify(params));
};

export const createResource = ({ resourceName, params }) => {
    return api.post(`/resources/${resourceName}/id`, params);
};

export const fetchResource = ({ resourceName, resourceID, studio = false }) => {
    const studioSuffix = studio ? "/studio" : "";
    return api.get(
        `/resources/${resourceName}/id/${resourceID}${studioSuffix}`
    );
};

export const fetchIvDetailsResource = ({resourceID, resourceType}) => {
    let params = {
        iv_id: resourceID,
        iv_type: resourceType
    }
    return api.get(`/iv_processing_reqs/processing_status`, params);
};

export const createIvRequest = ({resourceID, resourceType}) => {
    let params = {
        iv_id: resourceID,
        iv_type: resourceType
    }
    return api.post(`/iv_processing_reqs`, params);
};

export const saveResource = ({
    resourceName,
    resourceID,
    studio = false,
    params
}) => {
    const studioSuffix = studio ? "/studio" : "";
    return api.put(
        `/resources/${resourceName}/id/${resourceID}${studioSuffix}`,
        params,
        {}
    );
};

export const searchResource = ({ params }) => {
    return api.get(`/resources/search?` + qs.stringify(params));
};

export const doActionResource = ({ resourceName, resourceID, action, params }) => {
    if (!params) params = {};
    params.run_action = action;
    return api.post(
        `/resources/${resourceName}/id/${resourceID}/perform_action`,
        params,
        {}
    );
};

export const importResource = ({ resourceName, params }) => {
    return api.post(`/resources/${resourceName}/import`, params);
};

export const fetchResourceUpdates = ({ workspaceId, projectId }) => {
    return api.get(
        `/workspaces/${workspaceId}/projects/${projectId}/resource_updates`
    );
};

export const saveResourceUpdates = ({ workspaceId, projectId, params }) => {
    return api.put(
        `/workspaces/${workspaceId}/projects/${projectId}/resource_updates`,
        params,
        {}
    );
};

export const updateFile = ({ fileId, params }) => {
    return api.put(`/files/${fileId}`, params, {});
};

export const importFile = ({ params }) => {
    return api.post(`/files/import`, params, {});
};

export const fetchGameTemplate = ({ gameTemplateId }) => {
    return api.get(`/game_templates/${gameTemplateId}`);
};

export const saveGameTemplate = ({ gameTemplateId, params }) => {
    return api.put(`/game_templates/${gameTemplateId}`, params, {});
};

export const fetchAutocomplete = (path, { params }) => {
    return api.get(`${path}?` + qs.stringify(params));
};
export const resolvePasteAutocomplete = (path, { params }) => {
    return api.post(`${path}`, params);
};

export const listActivities = ({ params }) => {
    return api.get(`/activities?` + qs.stringify(params), {}, {});
}

export default api;