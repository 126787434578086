// @flow weak

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";

const styleSheet = theme => ({
  btnContainer: {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    display: "flex"
  },
  pageCenter: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: "center"
  },
  blur: {
    backdropFilter: "blur(4px)",
    backgroundColor: "rgba(255, 255, 255, .5)"
  },
  primaryColor: {
    color: theme.palette.primaryColor
  }
});

function Loading(props) {
  const { classes, center, blur, size = 80 } = props;

  return (
    <div
      className={classNames(classes.btnContainer, {
        [classes.pageCenter]: center,
        [classes.blur]: blur
      })}
    >
      <CircularProgress
        size={size}
        classes={{ colorPrimary: classes.primaryColor }}
        color={"primary"}
      />
    </div>
  );
}

export default withStyles(styleSheet)(Loading);
