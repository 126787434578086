const config = {
  uiColor: '#FFFFFF',
  cdnUrl: '//cdnjs.cloudflare.com/ajax/libs/ckeditor/4.6.2/ckeditor.js',
  assets_languages: ['en'],
  image_file_types: ['jpg', 'jpeg', 'png', 'gif', 'tiff'],
  toolbar: [
    // { name: 'editing', items: [ 'Scayt' ] },
    { name: 'insert', items: ['Image'] },
    // { name: 'tools', items: [ 'Maximize' ] },
    { name: 'document', items: ['Source'] },
    '/',
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'],
    },
    {
      name: 'paragraph',
      items: ['NumberedList', 'BulletedList', '-', '-', 'Blockquote'],
    },
    // { name: 'styles', items: [ 'styles', 'Format', 'fillInTheBlank', 'audioPhoneme', 'audioBreak', 'audioProsody' ] },
    { name: 'styles', items: ['styles', 'Format', 'fillInTheBlank'] },
  ],
  toolbar_mini: [
    { name: 'styles', items: ['Font', 'FontSize'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    {
      name: 'basicstyles',
      groups: ['basicstyles', 'cleanup'],
      items: ['Bold', 'Italic', 'Subscript', 'Superscript'],
    },
    { name: 'insert', items: ['Image'] },
  ],
  publicUrl: process.env.PUBLIC_URL,
  removeButtons:
    'Underline,Anchor,Unlink,HorizontalRule,Maximize,styles,Format,About',
  image_previewText: 'Your preview will appear here after upload',
  enterMode: 2,
  allowedContent: {
    'tnl-filler': {
      attributes: ['answer_ids', 'size', 'type', 'id', 'contenteditable'],
    },
    table: { attributes: ['align', 'cellpadding', 'cellspacing', 'border'] },
    'tr td': { attributes: ['align', 'valign'] },
    img: {
      attributes: [
        '!src',
        'alt',
        'title',
        'style',
        'height',
        'margin',
        'width',
      ],
      classes: { tip: true },
    },
    a: {
      attributes: [
        'href',
        'title',
        'alt',
        'src',
        'title',
        'style',
        'height',
        'margin',
        'width',
      ],
    },
    $1: {
      elements: {
        h1: true,
        h2: true,
        p: true,
        div: true,
        br: true,
        sup: true,
        sub: true,
        a: true,
        img: true,
        u: true,
        b: true,
        strong: true,
        span: true,
        i: true,
        ul: true,
        ol: true,
        li: true,
        em: true,
        filler: true,
        table: true,
        thead: true,
        tbody: true,
        tr: true,
        td: true,
      },
      attributes: ['data-*]', 'id'],
    },
    break: { attributes: ['is'] },
    phoneme: { attributes: ['is'] },
    prosody: { attributes: ['is'] },
  },
  contentsCss: `${process.env.PUBLIC_URL}/css/web.css`,
  extraPlugins:
    'fillintheblanks,wordcount,notification,audioBreak,audioPhoneme,audioProsody',
  addQueryString: function (url, params) {
    var queryString = [];

    if (!params) {
      return url;
    } else {
      for (var i in params) {
        queryString.push(i + '=' + encodeURIComponent(params[i]));
      }
    }

    return url + (url.indexOf('?') !== -1 ? '&' : '?') + queryString.join('&');
  },
  filebrowserParams: function () {
    let csrfToken;
    let csrfParam;
    let meta;
    let metas = document.getElementsByTagName('meta');
    let params = {};

    for (var i = 0; i < metas.length; i++) {
      meta = metas[i];

      switch (meta.name) {
        case 'csrf-token':
          csrfToken = meta.content;
          break;
        case 'csrf-param':
          csrfParam = meta.content;
          break;
        default:
          continue;
      }
    }
    let token = window.localStorage.token || '';
    if (token.split('bearer ')[1]) {
      params['authenticity_token'] = token.split('bearer ')[1];
    }
    if (csrfToken !== undefined && csrfToken !== undefined) {
      params[csrfParam] = csrfToken;
    }

    return params;
  },
};

const filebrowserImageUploadPath = 'ckeditor/pictures';

const imageApiHost = process.env.REACT_APP_CKEDITOR_FILE_UPLOAD_API_HOST || '/';

config.filebrowserImageUploadUrl = `${imageApiHost}${filebrowserImageUploadPath}`;

export default config;
