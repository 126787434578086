import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import { projectSnapshot } from "../../Services/Api/Schema";

import listReducer, { Types as ListTypes, Creators as ListCreators } from "./list";
import deleteReducer, { Types as DeleteTypes, Creators as DeleteCreators } from "./delete";
import createReducer_, { Types as CreateTypes, Creators as CreateCreators } from "./create";

export const INITIAL_STATE = Immutable({
  data: false,
  error: null
});

export const selectProjectSnapshots = state => {
  try {
    return denormalize(state[key].data, [projectSnapshot], state.entities);
  } catch (e) {
    return null;
  }
};

export const selectProjectSnapshotsRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};

export const key = "projectSnapshots";

export { ListCreators, ListTypes };
export { CreateCreators, CreateTypes };
export { DeleteCreators, DeleteTypes };

export default createReducer(INITIAL_STATE, {
  ...listReducer,
  ...createReducer_,
  ...deleteReducer
});
