// @flow

import createStore, { history } from "./createStore";
import rootSaga from "../Sagas/";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import EntitiesReducer from "./EntitiesRedux";
import ActionCableReducer, { key as ActionCableKey } from "./ActionCableRedux";
import IvDetailsReducer, { key as IvDetailsKey } from "./IvDetailsRedux";
import IvCreateReducer, { key as IvCreateKey } from "./IvCreateRequestRedux";
import WorkspacesReducer, { key as WorkspacesKey } from "./WorkspacesRedux";
import ProjectsReducer, { key as ProjectsKey } from "./ProjectsRedux";
import ProjectSnapshotsReducer, {
    key as ProjectSnapshotsKey
} from "./ProjectSnapshotsRedux";
import FilesReducer, { key as FilesKey } from "./FilesRedux";
import NodesReducer, { key as NodesKey } from "./NodesRedux";
import SchemaReducer, { key as SchemaKey } from "./SchemaRedux";
import ResourcesReducer, { key as ResourcesKey } from "./ResourcesRedux";
import ResourceUpdatesReducer, {
    key as ResourceUpdatesKey
} from "./ResourceUpdatesRedux";
import GameTemplatesReducer, {
    key as GameTemplatesKey
} from "./GameTemplatesRedux";
import ActivitiesReducer, {
    key as ActivitiesKey
} from "./ActivitiesRedux";

import StartupReducer, { key as StartupKey } from "./StartupRedux";

import SessionReducer, { key as SessionKey } from "./SessionRedux";

const createReducer = asyncReducers =>
    combineReducers({
        router: connectRouter(history),
        entities: EntitiesReducer,
        [SessionKey]: SessionReducer,
        [ActionCableKey]: ActionCableReducer,
        [WorkspacesKey]: WorkspacesReducer,
        [ProjectsKey]: ProjectsReducer,
        [FilesKey]: FilesReducer,
        [NodesKey]: NodesReducer,
        [StartupKey]: StartupReducer,
        [ResourcesKey]: ResourcesReducer,
        [ResourceUpdatesKey]: ResourceUpdatesReducer,
        [SchemaKey]: SchemaReducer,
        [ProjectSnapshotsKey]: ProjectSnapshotsReducer,
        [GameTemplatesKey]: GameTemplatesReducer,
        [ActivitiesKey]: ActivitiesReducer,
        [IvDetailsKey]: IvDetailsReducer,
        [IvCreateKey]: IvCreateReducer,
        ...asyncReducers
    });

export default () => {
    let asyncReducers = {};
    let sagaRegistry = {};

    const store = createStore(createReducer(asyncReducers), rootSaga);

    store.injectReducer = reducer => {
        const newReducers = {
            ...reducer,
            ...asyncReducers
        };
        if (
            Object.keys(newReducers).length !==
            Object.keys(asyncReducers).length
        ) {
            asyncReducers = newReducers;
            store.replaceReducer(createReducer(asyncReducers));
        }
    };

    store.injectSaga = saga => {
        if(sagaRegistry[saga]) return;

        store.sagaMiddleware.run(saga)
        sagaRegistry[saga] = true;
    }

    return store;
};
