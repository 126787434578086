import React, { Component } from "react";
import LoginPage from "./LoginPage";
import Loading from "../Components/Loading";

import { connect } from "react-redux";
import { selectCurrentUser } from "../Redux/SessionRedux";
import { selectStartup } from "../Redux/StartupRedux";
import { selectSchema, selectSchemaRequests } from "../Redux/SchemaRedux";

import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";

class EnsureLoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { startup, schemaRequests, schema } = nextProps;

    if (!this.props.startup.inited && startup.inited) {
      if (startup.error) {
        if (window.localStorage && !window.localStorage["hardRefresh"]) {
          window.localStorage["hardRefresh"] = true;
          window.location.reload(true);
        }
      } else {
        delete window.localStorage["hardRefresh"];

        if(window.Raven && nextProps.currentUser) {
          window.Raven.setUserContext({
              email: nextProps.currentUser.email
          })
        }
      }
    }

    if( startup.inited && this.props.schemaRequests.fetching && !schemaRequests.fetching && schema) {
      this.props.subscribeChannel("AnnounceChannel")
    }

    if(schemaRequests.error && schemaRequests.error.error === "client_too_old") {
      window.location.reload(true);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    window.Raven && window.Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    let { children, currentUser, startup, schema, schemaRequests } = this.props;

    if (!startup.inited) {
      return (
        <div className="waiting">
          <Loading center />Initializing...
        </div>
      );
    }

    if (startup.error) {
      return (
        <div className="errorDiv">
          <h1>There was an unexpected error!</h1>
          <h2>Cache issue?</h2>
          <p>
            To troubleshoot this, you can try a hard refresh, in which you force your browser to
            bypass the cache on your computer and re-download everything for the web page you are
            viewing from the server. This will typically ensure that you’re viewing the most recent
            version of your page. In Windows the shortcut for this is “Ctrl + F5” and on a Mac the
            shortcut is “Cmd + Shift + R.”
          </p>

          <h2>Still not loading?</h2>
          <p>
            Wait 2 minutes and then Contact devs{" "}
            <span role="img" aria-label="Pray">
              🙏
            </span>
          </p>

          <h2>Technical info</h2>
          <span>{JSON.stringify(startup.error)}</span>
        </div>
      );
    }

    if (!currentUser) {
      return <LoginPage />;
    }

    if (schemaRequests.error) {
      return (
        <div className="errorDiv">
          <h1>There was an unexpected error!</h1>
          <h2>Schema failed to load</h2>
          <p>
            Usually this happens when you don't have a good Internet connection or our servers are down. 
            Check your wifi and make sure the internet is working.
          </p>

          <h2>Still not loading?</h2>
          <p>
            Wait 2 minutes and then Contact devs{" "}
            <span role="img" aria-label="Pray">
              🙏
            </span>
          </p>

          <h2>Technical info</h2>
          <span>{JSON.stringify(schemaRequests.error)}</span>
        </div>);
    }

    if (!schema) {
      return (
        <div className="waiting">
          <Loading center />Waiting for schema...
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className="errorDiv">
        <h1>There was an uncaught exception!</h1>
        <div
          className="snap"
          onClick={() =>
            window.Raven && window.Raven.lastEventId() && window.Raven.showReportDialog()
          }
        >
          <p>We're sorry — something's gone wrong.</p>
          <p>The team has been notified{window.Raven && <span>, if you would like to fill out a report, <a href="#click">click here</a></span> }.</p>
        </div>
        </div>
      );
    }

    return children;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: selectCurrentUser(state),
    startup: selectStartup(state),
    schema: selectSchema(state),
    schemaRequests: selectSchemaRequests(state)
  };
};

const mapDispatchToProps = dispatch => ({
  subscribeChannel: (a) => dispatch(ActionCableCreators.subscribe(a)),
  unsubscribeChannel: (a) => dispatch(ActionCableCreators.unsubscribe(a)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnsureLoggedIn);
