import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import compose from "recompose/compose";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

function ProgressButton({
    classes,
    children = "Save",
    disabled = false,
    error = null,
    inProgress = false,
    variant = "contained",
    color = "secondary",
    ...other
}) {
    return (
        <div className={classes.buttonBox}>
            {error && <Chip classes={{ root: classes.error }} label={error} />}
            <Button
                variant={variant}
                disabled={!!(inProgress || disabled)}
                color={color}
                {...other}
            >
                {children}
            </Button>
            {inProgress && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                />
            )}
        </div>
    );
}

ProgressButton.propTypes = {
    inProgress: PropTypes.bool,
    disabled: PropTypes.bool
};

const styles = theme => ({
    root: {},
    buttonBox: {
        position: "relative",
        display: "inline-flex"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    error: {
        background: theme.palette.error["500"],
        color: "white",
        marginRight: theme.spacing(1)
    }
});

export default compose(withStyles(styles))(ProgressButton);
