import React from 'react'

function bookmarkIcon({ filled, onClick }) {
    return (
        <div onClick={onClick}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 9H12C11.4477 9 11 9.44772 11 10V24.5811C11 25.4727 12.0785 25.9186 12.7081 25.2873L18.0732 19.9076L23.2919 25.1406C23.9215 25.7718 25 25.326 25 24.4344V10C25 9.44772 24.5523 9 24 9Z" stroke="white" fill={filled ? "white" : "none"} stroke-opacity="0.8" stroke-width="2" />
            </svg>
        </div>
    )
}

export default bookmarkIcon
