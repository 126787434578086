import React from 'react';
import data from './lottie/coin.json';
import Lottie from 'react-lottie';

function CoinAnimation() {
    const defaultHintLottieAnimOptions = {
        loop: true,
        autoplay: true,
        animationData: data,
        rendererSettings: {
            preserveAspectRatio: 'none',
        },
    };

    return <Lottie options={defaultHintLottieAnimOptions} width="70px" />;
}

export default CoinAnimation;
