export const unityrgba2rgba = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: (parseInt(result[4], 16) * 1.0) / 255
      }
    : null;
};

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export const rgba2unityrgba = rgb => {
  return (
    "#" +
    componentToHex(rgb.r) +
    componentToHex(rgb.g) +
    componentToHex(rgb.b) +
    componentToHex(Math.round(rgb.a * 255.0))
  );
};

export const unityrgba2css = hex => {
  const color = unityrgba2rgba(hex);
  if (!color) return null;

  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
};
