import _shuffle from 'lodash/shuffle';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';

function videoComponentValidater({ template, answers, metadata }) {
  let errors = '';
  if (!_get(metadata, `${template.keyName}.context.dashVideoUrl`, '').trim()) {
    errors += 'Please add dash video url \n';
  }
  if (
    !_get(metadata, `${template.keyName}.context.dashVideoToken`, '').trim()
  ) {
    errors += 'Please add dash video token \n';
  }
  if (!_get(metadata, `${template.keyName}.context.hlsVideoUrl`, '').trim()) {
    errors += 'Please add HLS video url \n';
  }
  if (!_get(metadata, `${template.keyName}.context.hlsVideoToken`, '').trim()) {
    errors += 'Please add HLS video token \n';
  }
  return errors;
}

function imageAndAudioValidater({ template, answers, metadata }) {
  let errors = '';
  var imgRegex = new RegExp('<img[^>]*?>', 'g');

  const questionText = _get(metadata, `${template.keyName}.context.html`, '');
  const cleanedQuertionText = questionText
    .replace(imgRegex, '')
    .replace(/(&nbsp;)+/g, '')
    .replace(/(<br ?\/?>)*/g, '')
    .replace(/\s\s+/g, ' ')
    .trim();

  if (cleanedQuertionText) {
    alert(cleanedQuertionText);
    errors += 'Question can only contain image, no text allowed\n';
  }
  if (!_get(metadata, `${template.keyName}.context.audioText`, '').trim()) {
    errors += 'Please add audio text in question\n';
  }
  return errors;
}

const CONTENT_TYPE_META_DATA = {
  key: 'contentType',
  defaultOption: 'textOnly',
};

const TEXT_ONLY_CONTENT = {
  key: 'textOnly',
  label: 'Text Only',
  removeButtons:
    'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
};

const AUDIO_ONLY_CONTENT = {
  key: 'audioOnly',
  label: 'Audio Only',
  voice: 'Matthew',
  removeButtons:
    'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
};

const TEXT_AND_AUDIO_CONTENT = {
  key: 'textAndAudio',
  label: 'Text and Audio Both',
  voice: 'Matthew',
  removeButtons:
    'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
};

const IMAGE_AND_AUDIO_CONTENT = {
  key: 'imageAndAudio',
  label: 'Image and Audio Both',
  voice: 'Matthew',
  removeButtons:
    'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
  extras: ['extraAudioText'],
  validaterFn: imageAndAudioValidater,
  extraAudioTextProps: {
    keyName: 'audioText',
    labelName: 'Audio Text',
    content_guidelines: {
      removeButtons:
        'Source,Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
      charCount: 60,
    },
  },
};

const VIDEO_ONLY_CONTENT = {
  key: 'videoOnly',
  label: 'Video Only',
  hideEditor: true,
  extras: ['dashVideoComponent', 'hlsVideoComponent'],
  validaterFn: videoComponentValidater,
  isTextOptional: true,
};

const TEXT_AND_VIDEO_CONTENT = {
  key: 'textAndVideo',
  label: 'Text And Video',
  extras: ['dashVideoComponent', 'hlsVideoComponent'],
  validaterFn: videoComponentValidater,
};

const CONTENT_TYPE_CONFIG = {
  ...CONTENT_TYPE_META_DATA,
  options: [TEXT_ONLY_CONTENT, AUDIO_ONLY_CONTENT, TEXT_AND_AUDIO_CONTENT],
};

export default {
  brevity: {
    hasBackground: true,
    backgroundImgFileExtension: 'svg',
    editorComponents: [
      {
        type: 'addCheckbox',
        keyName: 'replace',
        labelName: 'Reveal without replacement',
        content_guidelines: {
          default_checked: false,
        },
      },
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          voice: 'Matthew',
          charCount: 60,
        },
      },
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          voice: 'Matthew',
          charCount: 75,
          transformContent: (text) =>
            text
              .trim()
              .replace(/(&nbsp;)+/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s\s+/g, ' '),
        },
      },
      {
        type: 'textEditor',
        keyName: 'hint',
        labelName: 'Hint',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          // voice: 'Allison'
          charCount: 100,
        },
      },
      {
        type: 'textEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          // voice: 'Matthew'
          charCount: 100,
        },
      },
    ],
  },

  ic_mcq: {
    hasBackground: false,
    editorComponents: [
      {
        type: 'addCheckbox',
        keyName: 'isImageTypeQuestion',
        labelName: 'Is Image Type Question',
        content_guidelines: {
          default_checked: false,
        },
      },

      {
        type: 'addCheckbox',
        keyName: 'allCorrect',
        labelName: 'Are All Answers Correct',
        content_guidelines: {
          default_checked: false,
        },
      },
      // {
      //   type: 'textEditor',
      //   keyName: 'question',
      //   labelName: 'Question',
      //   content_guidelines: {
      //     removeButtons: '',
      //     charCount: 20
      //   }
      // },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 17,
          maxCount: 4,
          minCount: 2,
        },
      },
    ],
  },

  drag: {
    backgroundImgFileExtension: 'svg',
    hasBackground: true,
    editorComponents: [
      {
        type: 'addRadioButtons',
        keyName: 'rearrangementOrInsrtionType',
        labelName: 'Select one of the following?',
        content_guidelines: {
          default_selected: 'rearrangementTypeQuestion',
          radioOptions: [
            {
              radioKeyName: 'rearrangementTypeQuestion',
              radioLabel: 'Rearrangement type Question',
            },
            {
              radioKeyName: 'insertionTypeQuestion',
              radioLabel: 'Insertion type question',
            },
          ],
        },
      },
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 62,
        },
      },
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 80,
          instruction: 'Use | or press enter to create chunks of words.',
          transformContent: (text) =>
            text
              .trim()
              .replace(/(&nbsp;)+/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s\s+/g, ' '),
          showQuestionInstruction: {
            keyName: 'rearrangementOrInsrtionType',
            value: 'rearrangementTypeQuestion',
          },
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 12,
          showCondition: {
            keyName: 'rearrangementOrInsrtionType',
            value: 'insertionTypeQuestion',
          },
        },
      },
      {
        type: 'textEditor',
        keyName: 'hint',
        labelName: 'Hint',
        isTextOptional: true,
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 56,
        },
      },
      {
        type: 'textEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 90,
        },
      },
    ],
  },

  ic_mcq_vertical: {
    hasBackground: false,
    editorComponents: [
      {
        type: 'addCheckbox',
        keyName: 'allCorrect',
        labelName: 'Are All Answers Correct',
        content_guidelines: {
          default_checked: false,
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 46,
          maxCount: 2,
          minCount: 2,
        },
      },
    ],
  },

  fib_tap: {
    hasBackground: true,
    backgroundImgFileExtension: 'svg',
    editorComponents: [
      {
        type: 'addRadioButtons',
        keyName: 'textOrAudioType',
        labelName: 'Select one of following Type',
        content_guidelines: {
          default_selected: 'textAndAudio',
          radioOptions: [
            {
              radioKeyName: 'textOnly',
              radioLabel: 'Text Only',
            },
            {
              radioKeyName: 'textAndAudio',
              radioLabel: 'Text and Audio Both',
            },
          ],
        },
      },
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt',
          charCount: 200,
          voice: 'Matthew',
          ttsTransform: (text) => {
            return text
              .replace(/&nbsp;/g, ' ')
              .replace(
                /[\s]*<tnl-filler[^>]*>[^<]*<\/tnl-filler>[\s]*/gm,
                ' dash '
              );
          },
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          maxCount: 4,
          minCount: 2,
        },
      },
      {
        type: 'textEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 400,
        },
      },
    ],
  },

  bbq: {
    hasBackground: true,
    backgroundImgFileExtension: 'png',
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 100,
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          minCount: 8,
          maxCount: 12,
        },
      },
    ],
  },

  rc: {
    hasBackground: false,
    disableAnswers: true,
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'title_image',
        labelName: 'Title Image',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          instruction: 'Use this to upload title image',
        },
      },
      {
        type: 'textEditor',
        keyName: 'title',
        labelName: 'Title',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
        },
      },
      {
        type: 'textEditor',
        keyName: 'payload',
        labelName: 'Payload',
        content_guidelines: {
          removeButtons:
            'Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          enableParagraph: true,
        },
      },
    ],
  },

  rc_mcq: {
    hasBackground: false,
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 78,
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 69,
          maxCount: 4,
          minCount: 2,
        },
      },
    ],
  },

  mcq: {
    hasBackground: true,
    backgroundImgFileExtension: 'svg',
    editorComponents: [
      {
        type: 'addRadioButtons',
        keyName: 'textOrAudioType',
        labelName: 'Select one of following Type',
        content_guidelines: {
          default_selected: 'textOnly',
          radioOptions: [
            {
              radioKeyName: 'textOnly',
              radioLabel: 'Text Only',
            },
            {
              radioKeyName: 'audioOnly',
              radioLabel: 'Audio Only',
            },
            {
              radioKeyName: 'textAndAudio',
              radioLabel: 'Text and Audio Both',
            },
          ],
        },
      },
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons: '',
          voice: 'Matthew',
          charCount: 60,
        },
      },
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons: '',
          voice: 'Matthew',
          charCount: 150,
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons: '',
          charCount: 60,
          maxCount: 4,
          minCount: 2,
        },
      },
      {
        type: 'textEditor',
        keyName: 'hint',
        labelName: 'Hint',
        isTextOptional: true,
        content_guidelines: {
          removeButtons: '',
          charCount: 60,
        },
      },
      {
        type: 'textEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons: '',
          charCount: 100,
        },
      },
    ],
  },

  spellathon: {
    hasBackground: true,
    backgroundImgFileExtension: 'png',
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
        },
      },
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 70,
        },
      },
      {
        type: 'textEditor',
        keyName: 'helper',
        labelName: 'Helper Text',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 11,
          transformContent: (text) =>
            _shuffle(
              text
                .trim()
                .replace(/&nbsp;/g, '')
                .replace(/(<([^>]+)>)/gi, '')
                .toUpperCase()
                .replace(/[^A-Z-]/g, '')
                .split('')
            ).join(''),
        },
      },
    ],
  },

  mtf: {
    hasBackground: true,
    backgroundImgFileExtension: 'png',
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
        },
      },
      {
        type: 'addDropdown',
        keyName: 'optionCount',
        labelName: 'Total Options',
        content_guidelines: {
          options: [
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
          ],
          addElements: [
            {
              type: 'addOptions',
              keyName: 'static_elements',
            },

            {
              type: 'addOptions',
              keyName: 'movable_elements',
            },
          ],
        },
      },
      {
        type: 'addOptions',
        keyName: 'static_elements',
        labelName: 'Static Elements',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          shouldShowButton: false,
          shouldAllowDelete: false,
        },
      },

      {
        type: 'addOptions',
        keyName: 'movable_elements',
        labelName: 'Movable Elements',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          shouldShowButton: false,
          shouldAllowDelete: false,
        },
      },

      {
        type: 'textEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 300,
        },
      },
    ],
  },

  tof: {
    hasBackground: true,
    backgroundImgFileExtension: 'png',
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 62,
        },
      },
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 150,
        },
      },
      {
        type: 'textEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons: '',
          charCount: 90,
        },
      },
      {
        type: 'addDropdown',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          options: [
            {
              label: 'Correct/Incorrect',
              value: [
                {
                  id: 1,
                  type: 'text',
                  context: {
                    html: 'Correct',
                  },
                },
                {
                  id: 2,
                  type: 'text',
                  context: {
                    html: 'Incorrect',
                  },
                },
              ],
            },
            {
              label: 'True/False',
              value: [
                {
                  id: 1,
                  type: 'text',
                  context: {
                    html: 'True',
                  },
                },
                {
                  id: 2,
                  type: 'text',
                  context: {
                    html: 'False',
                  },
                },
              ],
            },
            {
              label: 'Custom',
              value: 'Custom',
              defaultValue: [
                {
                  id: 1,
                  type: 'text',
                  context: {
                    html: '',
                  },
                },
                {
                  id: 2,
                  type: 'text',
                  context: {
                    html: '',
                  },
                },
              ],
              option: {
                type: 'addOptions',
                keyName: 'options',
                labelName: 'Options',
                content_guidelines: {
                  removeButtons:
                    'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
                  charCount: 12,
                  maxCount: 12,
                  minCount: 2,
                },
              },
            },
          ],
        },
      },
    ],
  },
  bolo: {
    hasBackground: true,
    disableAnswers: true,
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 17,
          minCount: 3,
        },
      },
    ],
  },
  enunciate: {
    hasBackground: true,
    disableAnswers: true,
    editorComponents: [
      {
        type: 'addRadioButtons',
        keyName: 'textOrAudioType',
        labelName: 'Select one of following Type',
        content_guidelines: {
          default_selected: 'textAndAudio',
          radioOptions: [
            {
              radioKeyName: 'textOnly',
              radioLabel: 'Text Only',
            },
            {
              radioKeyName: 'textAndAudio',
              radioLabel: 'Text and Audio Both',
            },
          ],
        },
      },
      {
        type: 'textEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 70,
        },
      },
      {
        type: 'textEditor',
        keyName: 'question',
        labelName: 'Sentence',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          voice: 'Matthew',
          charCount: 70,
          transformContent: (text) =>
            text
              .trim()
              .replace(/(&nbsp;)+/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s\s+/g, ' '),
        },
      },
    ],
  },
  mcq_v2: {
    hasBackground: true,
    backgroundImgFileExtension: 'svg',
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          // ttsTransform: (text) => {},
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 150,
          // ttsTransform: (text) => {},
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'addOptionsV2',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          maxCount: 4,
          minCount: 2,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'hint',
        labelName: 'Hint',
        isTextOptional: true,
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          // ttsTransform: (text) => {},
          contentType: {
            key: 'contentType',
            defaultOption: 'textOnly',
            options: [
              ...CONTENT_TYPE_CONFIG.options,
              {
                key: 'picture',
                label: 'Picture',
                removeButtons:
                  'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
              },
              {
                key: 'automatic',
                label: 'Automatic',
                hideEditor: true,
                validaterFn: ({ template, answers, metadata }) => {
                  const options = _get(metadata, 'options', []);
                  if (options.length < 4) {
                    return '4 options are required for automatic hint\n';
                  }
                  return '';
                },
              },
            ],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 150,
          // ttsTransform: (text) => {},
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
    ],
  },
  fib_tap_v2: {
    hasBackground: true,
    backgroundImgFileExtension: 'svg',
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          // ttsTransform: (text) => {},
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt',
          charCount: 200,
          ttsTransform: (text) => {
            return text
              .replace(/&nbsp;/g, ' ')
              .replace(
                /[\s]*<tnl-filler[^>]*>[^<]*<\/tnl-filler>[\s]*/gm,
                ' dash '
              );
          },
          contentType: {
            ...CONTENT_TYPE_META_DATA,
            options: [
              {
                key: 'textOnly',
                label: 'Text Only',
                removeButtons:
                  'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt',
              },
              {
                key: 'textAndAudio',
                label: 'Text and Audio Both',
                voice: 'Matthew',
                removeButtons:
                  'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt',
              },
            ],
          },
        },
      },
      {
        type: 'addOptionsV2',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          maxCount: 4,
          minCount: 2,
          contentType: {
            ...CONTENT_TYPE_META_DATA,
            options: [TEXT_ONLY_CONTENT, TEXT_AND_AUDIO_CONTENT],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'hint',
        labelName: 'Hint',
        isTextOptional: true,
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          // ttsTransform: (text) => {},
          contentType: {
            key: 'contentType',
            defaultOption: 'textOnly',
            options: [
              {
                key: 'textOnly',
                label: 'Text Only',
              },
              {
                key: 'audioOnly',
                label: 'Audio Only',
                voice: 'Matthew',
              },
              {
                key: 'textAndAudio',
                label: 'Text and Audio Both',
                voice: 'Matthew',
              },
              {
                key: 'picture',
                label: 'Picture',
                removeButtons:
                  'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
              },
              {
                // behaviour has to be decided
                key: 'automatic',
                label: 'Automatic',
                hideEditor: true,
              },
            ],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 400,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
    ],
  },
  brevity_v2: {
    hasBackground: true,
    backgroundImgFileExtension: 'svg',
    editorComponents: [
      {
        type: 'addCheckbox',
        keyName: 'replaceReveal',
        labelName: 'Reveal without replacement',
        content_guidelines: {
          default_checked: false,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          // ttsTransform: (text) => {},
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt',
          voice: 'Matthew',
          charCount: 75,
          ttsTransform: (text) =>
            text
              .trim()
              .replace(/(&nbsp;)+/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s\s+/g, ' '),
          contentType: {
            ...CONTENT_TYPE_META_DATA,
            options: [TEXT_ONLY_CONTENT, TEXT_AND_AUDIO_CONTENT],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'hint',
        labelName: 'Hint',
        isTextOptional: true,
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 100,
          // ttsTransform: (text) => {},
          contentType: {
            key: 'contentType',
            defaultOption: 'textOnly',
            options: [
              {
                key: 'textOnly',
                label: 'Text Only',
              },
              {
                key: 'audioOnly',
                label: 'Audio Only',
                voice: 'Matthew',
              },
              {
                key: 'textAndAudio',
                label: 'Text and Audio Both',
                voice: 'Matthew',
              },
              {
                key: 'picture',
                label: 'Picture',
                removeButtons:
                  'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
              },
            ],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 100,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
    ],
  },
  enunciate_v2: {
    hasBackground: true,
    disableAnswers: true,
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Sentence',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          voice: 'Matthew',
          charCount: 70,
          contentType: {
            ...CONTENT_TYPE_META_DATA,
            options: [
              TEXT_ONLY_CONTENT,
              AUDIO_ONLY_CONTENT,
              TEXT_AND_AUDIO_CONTENT,
              TEXT_AND_VIDEO_CONTENT,
              VIDEO_ONLY_CONTENT,
            ],
          },
          transformContent: (text) =>
            text
              .trim()
              .replace(/(&nbsp;)+/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s\s+/g, ' '),
        },
      },
    ],
  },
  spellathon_v2: {
    hasBackground: true,
    backgroundImgFileExtension: 'png',
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          // ttsTransform: (text) => {},
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          voice: 'Matthew',
          charCount: 70,
          ttsTransform: (text) =>
            text
              .trim()
              .replace(/(&nbsp;)+/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s\s+/g, ' '),
          contentType: {
            ...CONTENT_TYPE_META_DATA,
            options: [
              TEXT_ONLY_CONTENT,
              AUDIO_ONLY_CONTENT,
              TEXT_AND_AUDIO_CONTENT,
              IMAGE_AND_AUDIO_CONTENT,
            ],
          },
        },
      },
      {
        type: 'addDropdownV2',
        keyName: 'wordBuilderType',
        labelName: 'Word Builder Type',
        content_guidelines: {
          defaultOption: 'Ordered',
          options: [
            { label: 'Ordered', value: 'Ordered' },
            { label: 'Unordered', value: 'Unordered' },
          ],
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'helper',
        labelName: 'Letter Pad',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 11,
          transformContent: (text) => {
            const cleanTextArray = text
              .trim()
              .replace(/&nbsp;/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .toUpperCase()
              .replace(/[^A-Z-]/g, '')
              .split('');

            const numberOfDistinctItems = _uniq(cleanTextArray).length;

            const shuffleText = (text) => {
              return _shuffle(text).join('');
            };

            let shuffledText = shuffleText(cleanTextArray);

            while (
              cleanTextArray.join('') === shuffledText &&
              numberOfDistinctItems > 1
            ) {
              shuffledText = shuffleText(cleanTextArray);
            }
            return shuffledText;
          },
          contentType: {
            key: 'contentType',
            defaultOption: 'withHelper',
            options: [
              { label: 'With helper text', key: 'withHelper' },
              {
                label: 'Without helper text',
                key: 'exact',
                validaterFn: ({ template, answers, metadata }) => {
                  if (
                    answers?.text?.length !==
                    metadata.helper.context.html.length
                  ) {
                    return 'There are no extra letters allowed\n';
                  }
                  return false;
                },
              },
            ],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'hint',
        labelName: 'Hint',
        isTextOptional: true,
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 100,
          // ttsTransform: (text) => {},
          contentType: {
            key: 'contentType',
            defaultOption: 'automaticText',
            options: [
              {
                key: 'automaticText',
                label: 'Automatic Text',
                hideEditor: true,
              },
              {
                key: 'picture',
                label: 'Picture',
                removeButtons:
                  'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
              },
              {
                key: 'automaticReplace',
                label: 'Automatic Word Replace',
                hideEditor: true,
              },
            ],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 100,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
    ],
  },
  bbq_v2: {
    hasBackground: true,
    backgroundImgFileExtension: 'png',
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          voice: 'Matthew',
          charCount: 100,
          contentType: {
            ...CONTENT_TYPE_META_DATA,
            options: [
              {
                key: 'textOnly',
                label: 'Text Only',
                removeButtons:
                  'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
              },
              AUDIO_ONLY_CONTENT,
              {
                key: 'textAndAudio',
                label: 'Text and Audio Both',
                voice: 'Matthew',
                removeButtons:
                  'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
              },
            ],
          },
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          minCount: 8,
          maxCount: 12,
        },
      },
    ],
  },
  drag_v2: {
    backgroundImgFileExtension: 'svg',
    hasBackground: true,
    editorComponents: [
      {
        type: 'addRadioButtons',
        keyName: 'rearrangementOrInsrtionType',
        labelName: 'Select one of the following?',
        content_guidelines: {
          default_selected: 'rearrangementTypeQuestion',
          radioOptions: [
            {
              radioKeyName: 'rearrangementTypeQuestion',
              radioLabel: 'Rearrangement type Question',
            },
            {
              radioKeyName: 'insertionTypeQuestion',
              radioLabel: 'Insertion type question',
            },
          ],
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 62,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 80,
          instruction: 'Use | or press enter to create chunks of words.',
          transformContent: (text) =>
            text
              .trim()
              .replace(/(&nbsp;)+/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s\s+/g, ' '),
          showQuestionInstruction: {
            keyName: 'rearrangementOrInsrtionType',
            value: 'rearrangementTypeQuestion',
          },
          contentType: {
            ...CONTENT_TYPE_META_DATA,
            options: [TEXT_ONLY_CONTENT],
          },
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 12,
          showCondition: {
            keyName: 'rearrangementOrInsrtionType',
            value: 'insertionTypeQuestion',
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'hint',
        labelName: 'Hint',
        isTextOptional: true,
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 56,
          contentType: {
            key: 'contentType',
            defaultOption: 'textOnly',
            options: [
              {
                key: 'textOnly',
                label: 'Text Only',
              },
              {
                key: 'audioOnly',
                label: 'Audio Only',
                voice: 'Matthew',
              },
              {
                key: 'textAndAudio',
                label: 'Text and Audio Both',
                voice: 'Matthew',
              },
            ],
          },
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 90,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
    ],
  },
  bolo_v2: {
    hasBackground: true,
    disableAnswers: true,
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 17,
          minCount: 3,
        },
      },
    ],
  },
  mtf_v2: {
    hasBackground: true,
    backgroundImgFileExtension: 'png',
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 60,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
      {
        type: 'addDropdown',
        keyName: 'optionCount',
        labelName: 'Total Options',
        content_guidelines: {
          options: [
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
          ],
          addElements: [
            {
              type: 'addOptions',
              keyName: 'static_elements',
            },

            {
              type: 'addOptions',
              keyName: 'movable_elements',
            },
          ],
        },
      },
      {
        type: 'addOptions',
        keyName: 'static_elements',
        labelName: 'Static Elements',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          shouldShowButton: false,
          shouldAllowDelete: false,
        },
      },

      {
        type: 'addOptions',
        keyName: 'movable_elements',
        labelName: 'Movable Elements',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 16,
          shouldShowButton: false,
          shouldAllowDelete: false,
        },
      },

      {
        type: 'combinedTextEditor',
        keyName: 'explanation',
        labelName: 'Explanation',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 300,
          contentType: CONTENT_TYPE_CONFIG,
        },
      },
    ],
  },
};
