// @flow

import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
	startup: [],
	inited: ["error"]
});

export const INITIAL_STATE = {
	startup: false,
	inited: false
};

export const startup = (state: Object) => {
	return { ...state, startup: true };
};

export const inited = (state: Object, { error }: Object) => {
	return { ...state, inited: true, error };
};

export const appUpdate = (state: Object, { registration }: Object) => {
	return { ...state, appUpdate: true, registration };
}

export const StartupTypes = Types;

export { Types, Creators };

export const key = "startup";

export const selectStartup = state => state[key];

export default createReducer(INITIAL_STATE, {
	[Types.STARTUP]: startup,
	[Types.INITED]: inited,
	"APP_UPDATE": appUpdate
});
