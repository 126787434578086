import React, { useState, useEffect } from 'react'
import pointTag from "./../../../assets/images/pointTag.svg";
import BookmarkIcon from "./../../../assets/images/bookmarkIcon";
import "./progressBar.scss";
import styled from 'styled-components';
import { useGameContext } from "./../../GameContext.jsx";


const Trail = styled.div`
  background-color: ${({ theme }) => theme.light3};
`;

function ProgressBar({ currentPage }) {
    const [assetsLoaded, setAssetsLoaded] = useState({ pointTag: false })
    const [progress, setProgress] = useState(0);
    const { noOfPages, bookmarkStatus, onBookmark } = useGameContext();

    useEffect(() => {
        setProgress(currentPage / noOfPages * 100)
    }, [noOfPages, currentPage]);

    const show = assetsLoaded.pointTag ? true : false;
    return (
        <>
            <div className="progress-wrapper" style={{ opacity: show ? 1 : 0 }}>
                <div className="progress-bar">
                    <Trail className="trail" >
                        <div className="progress" style={{
                            width: `${progress}%`
                        }}>
                        </div>
                        <div className="tag">
                            <img src={pointTag} onLoad={() => {
                                setAssetsLoaded(s => {
                                    return { ...s, pointTag: true }
                                })
                            }} />
                        </div>
                    </Trail>
                </div>
                <div className="bookmark">
                    <BookmarkIcon onClick={() => onBookmark(!bookmarkStatus)} filled={bookmarkStatus} />
                </div>
            </div>
        </>
    )
}

export default ProgressBar;
