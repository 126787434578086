import { put, call } from "redux-saga/effects";
import { LogoutCreators } from "../Redux/SessionRedux";
import { normalize, schema } from "normalizr";
import { schemaMap } from "../Services/Api/Schema";

const makeSchema = sch => {
  var resourceSchema = sch.reduce((y, x) => {
    const s = new schema.Entity(x.name);
    y[x.name_singular] = s;
    y[x.name] = [s];
    return y;
  }, {});

  const unionSchema = new schema.Union(resourceSchema, (value, parent, key) => {
    return parent.resource_name;
  });

  const resourceUpdate = new schema.Entity("resource_updates", {
    to: unionSchema
  });

  resourceSchema["resource_update"] = resourceUpdate;
  resourceSchema["resource_updates"] = [resourceUpdate];
  resourceSchema["search_results"] = [unionSchema];

  return resourceSchema;
};

export function* schemaSaga(entity, startupCreators, apiFunc, data) {
  const response = yield call(apiFunc, data);
  if (response.ok) {
    const dynamicSchema = makeSchema(response.data.schema);
    const { result, entities } = normalize(response.data, schemaMap);
    const mergedSchema = { ...schemaMap, ...dynamicSchema };

    yield put(entity.success(data, result, mergedSchema, { entities, schema: mergedSchema }, response.data.abilities));
  } else {
    if (response.status === 401) {
      yield put(LogoutCreators.request());
    } else {
      yield put(entity.failure(data, response.data));
    }
  }
}
