import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Pagination from "./Pagination";
import {FormControl, Grid, InputLabel, OutlinedInput} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SearchInput from "./SearchInput";
import _maxBy from 'lodash/maxBy';


const styles = theme => ({
    inlineList: {
        paddingLeft: theme.spacing(2)
    },
    inline: {
        padding: theme.spacing(1)
    },
    textField: {
        margin: theme.spacing(1),
        width: "calc(100% - " + 64 + "px)"
    },
    margin: {
        marginLeft: theme.spacing(2)
    }
});

const ROWS_PER_PAGE = 15;

class BooleanExpressions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            search: ''
        }
    }


    renderRow = (row, idx) => {
        const {disabled, classes, errors} = this.props;
        const {search} = this.state
        let errorInExpression
        let errorInResourceNickname
        errors && errors.forEach((el) => {
            if (el["index"] === idx) {
                if (el.path === "expression") {
                    errorInExpression = el.msg
                }
                if (el.path === "recommendation,nickname") {
                    errorInResourceNickname = el.msg
                }
            }
        })
        return (
            <TableRow key={idx}>
                <TableCell>
                    <TextField
                        label="priority"
                        type="number"
                        className={classes.textField}
                        onChange={this.handleChange.bind(this, [idx])}
                        name="priority"
                        inputProps={{min: 0, inputMode: 'numeric', pattern: '[0-9]*', value: row.priority}}
                        disabled
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        label="expression"
                        className={classes.textField}
                        error={errorInExpression}
                        helperText={errorInExpression}
                        placeholder="A&(!B|C)"
                        onChange={this.handleChange.bind(this, [idx])}
                        name="expression"
                        disabled={disabled || search || row.priority === 0}
                        value={row.expression}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        name="type"
                        value="equals"
                        disabled
                    />
                </TableCell>
                {this.renderSingle(row, errorInResourceNickname, [idx])}
                {!disabled && row.priority !== 0 &&<TableCell>
                    <IconButton color="secondary" onClick={this.removeItem.bind(this, idx)}>
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>}
            </TableRow>
        );
    };

    renderSingle = (row, error, prefix) => {
        prefix = prefix.concat("recommendation")
        const {classes, disabled, types} = this.props;
        const {nickname, resource_type} = row.recommendation
        return (
            <>
                <TableCell>
                    <FormControl fullWidth>
                        <InputLabel id='resource_type'>type</InputLabel>
                        <Select value={resource_type}
                                disabled={disabled}
                                name="resource_type"
                                labelId="resource_type"
                                label="Resource Type"
                                onChange={this.handleChange.bind(this, prefix)}>
                            {types.map((type) => (
                                <MenuItem value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    <TextField
                        disabled={disabled}
                        onChange={this.handleChange.bind(this, prefix)}
                        name="nickname"
                        label="nickname"
                        error={error}
                        helperText={error}
                        value={nickname}
                        className={classes.textField}
                    />
                </TableCell>
            </>
        );
    };

    handleChange = (prefix, event) => {
        const {value, name} = this.props;
        let newValue = value.setIn(
            prefix.concat(event.target.name),
            event.target.name === "priority" ? event.target.valueAsNumber : event.target.value
        );
        this.props.onChange({target: {value: newValue, name}});
    };

    addNewExpression = () => {
        let {value, name, defaultExpression} = this.props;
        if (!value || value.length === 0) {
            value = [{
                expression: defaultExpression,
                priority: 0,
                recommendation: {
                    resource_type: "",
                    nickname: ""
                }
            }]
        }
        let nextPriority = _maxBy(value, 'priority')['priority'] + 1
        const newValue = [{
            expression: "",
            priority: nextPriority,
            recommendation: {
                resource_type: "",
                nickname: ""
            }
        }, ...value]
        this.props.onChange({target: {value: newValue, name}});
    };


    removeItem = (idx) => {
        const {value, name} = this.props;
        const newValue = value.asMutable();
        newValue.splice(idx, 1)
        for (let i = 0; i < newValue.length; i++) {
            newValue[i] = newValue[i].setIn(["priority"], newValue.length - i - 1)
        }
        this.props.onChange({target: {value: newValue, name}});
    };

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
    }

    handleSearch(event) {
        this.setState({
            ...this.state,
            page: 1,
            search: event.target.value,
        })
    }

    render() {
        const {classes, disabled, label, value, errors} = this.props;
        const {page, search} = this.state;
        const offset = (page - 1) * ROWS_PER_PAGE;
        let rowsCount = 0;
        let errMessage
        if (errors) {
            console.log(errors)
        }
        errors && errors.forEach(err => {
            if (typeof err === 'string'){
                errMessage = err
            } else if (typeof err["index"] === 'undefined') {
                errMessage = err["msg"]
            }
        })
        return (
            <div className={classes.inline}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="caption">{label}</Typography>
                        {!disabled && (
                            <IconButton color="primary" onClick={this.addNewExpression} className={classes.margin}>
                                <AddIcon/>
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item>
                        <SearchInput placeholder="expression" onChange={this.handleSearch.bind(this)}/>
                    </Grid>
                </Grid>
                {errMessage && <Chip color="secondary" label={errMessage} variant="outlined"/>}
                <Table size="small">
                    <TableBody>
                        {
                            value.map((row, idx) => {
                                if (row["expression"].includes(search)) {
                                    rowsCount++;
                                    if ((rowsCount - 1) >= offset && (rowsCount - 1) < (offset + ROWS_PER_PAGE)) {
                                        return this.renderRow(row, idx)
                                    }
                                }
                            })
                        }
                    </TableBody>
                </Table>

                <Pagination
                    pagination={{
                        "current_page": page,
                        "total_pages": Math.max(Math.ceil(rowsCount / ROWS_PER_PAGE), 1),
                    }}
                    onPageChange={this.handlePageChange.bind(this)}
                />
            </div>
        );
    }
}


export default withStyles(styles)(BooleanExpressions);
