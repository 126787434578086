import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Editor from "../ela-lib/src/components/editorComponents/EditorContainer";
import ReadAloudEditor from "../ela-readaloud/src/components/editor/EditorContainer";
import ReactJson from "react-json-view";
import _get from "lodash/get";
import _clonedeep from "lodash/cloneDeep";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import LinkIcon from "@material-ui/icons/Link";
import Search from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import ResourceSearchDialog from "../Dialogs/ResourceSearchDialog";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { selectSchemaByName } from "../Redux/SchemaRedux";
import {
  FetchRcMcqCreators as ResourcesFetchCreators,
  selectResourceById,
  selectResources,
  selectResourcesRequestState
} from "../Redux/ResourcesRedux";
import compose from "recompose/compose";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const styles = theme => ({
  inlineList: {
    paddingLeft: theme.spacing(2)
  },
  inline: {
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5)
  },
  ckeditor: {
    position: "relative",
    zIndex: 0,
    paddingBottom: theme.spacing(100)
  },
  referenceInputAdornment: {
    width: "50%",
    flexShrink: 0
  },
  referenceButton: {
    width: "100%"
  },
  textField: {
    margin: theme.spacing(1),
    width: "calc(100% - " + theme.spacing(1) + "px)"
  },
  leftSpacing: {
    marginLeft: theme.spacing(2)
  },
});

class ElaGamesForm extends Component {
  constructor(props) {
    super(props);
    // this.childState = React.useRef(null);
    this.audios = null;
    this.rc_changed = false;
    const { templateType, value } = props;
    if (templateType === "rc_mcq") {
      this.state = {};
      const id = _get(value, `payload.rc_id`) || null
      this.state = {
        rc: {
          id
        },
        alertBoxOpen: false
      };
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    const id = _get(this.props, `value.payload.rc_id`) || null
    if (id) {
      this.props.fetchResource(
        "reading_comprehensions",
        id
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { resourcesRCRequests } = nextProps;
    const curr_rc_id = _get(this.props, `value.payload.rc_id`) || null;
    const next_rc_id = _get(nextProps, `value.payload.rc_id`) || null;

    if (next_rc_id && !curr_rc_id) {
      this.props.fetchResource(
        "reading_comprehensions",
        next_rc_id
      );
    }

    if (resourcesRCRequests && this.props.resourcesRCRequests &&
      this.props.resourcesRCRequests.fetching_rc_mcq &&
      !resourcesRCRequests.fetching_rc_mcq
    ) {
      const state_rc_id = _get(this.state, `rc.id`) || null;

      let resource = null;
      if (this.rc_changed) {
        const resources = _get(nextProps, `rc_state.entities.reading_comprehensions`) || []
        resource = Object.values(resources).find(res => res.id === state_rc_id)

      } else {
        resource = _get(nextProps, `rc_resource`) || null;
      }
      this.rc_changed = false;
      const payload = _get(resource, `payload`) || null;
      const id = _get(resource, `id`) || null;
      const nickname = _get(resource, `nickname`) || null;
      if (payload) {
        this.setState({
          rc: {
            id,
            nickname,
            payload: payload
          }
        });
      }
    }
  }


  handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      ...this.state,
      alertBoxOpen: !this.state.alertBoxOpen,
    })
  };

  showReferenceBrowser = (resource, key, prefix, hint) => {
    this.setState({
      showResourceSearch: {
        resourceName: resource,
        key,
        prefix,
        hint
      }
    });
  };

  clearReference = (key, prefix) => {
    this.setState({
      rc: {
        id: null,
        nickname: "",
        payload: null
      }
    });
  }

  handleSearchDialogClose = resource => {
    if (resource) {
      console.log("[ELaGamesForm] - rc - ", resource);
      // this.props.fetchResource(
      //   "reading_comprehensions",
      //   resource.id
      // );
      this.setState({
        rc: {
          id: resource.id,
          nickname: resource.nickname
        },
        showResourceSearch: null
      }, () => {
        this.rc_changed = true;
        this.props.fetchResource(
          "reading_comprehensions",
          resource.id
        );
      });

    } else {
      this.setState({ showResourceSearch: null });
    }

  };

  handleChange = (prefix, event) => {
    const { value, name } = this.props;
    let newValue = value.setIn(
      prefix.concat(event.target.name),
      event.target.value
    );
    this.audios = _get(newValue, "raw_question_audios") || null;
    this.props.onChange({ target: { value: newValue, name } });
  };

  updateRawData = dataReceived => {
    console.log("---DATAAA === ", dataReceived);
    this.setState({
      ...this.state,
      alertBoxOpen: true,
    });
    const { name } = this.props;
    console.log("[ElaGamesForm]---DATA - ", dataReceived);

    console.log("[ElaGamesForm]--Game Data - ", dataReceived);

    this.audios = _get(dataReceived, "raw_question_audios") || null;
    if (this.state.rc) {
      dataReceived.rc_id = this.state.rc.id;
      dataReceived.rc_payload = this.state.rc.payload;
    }
    this.props.onChange({ target: { value: dataReceived, name } });
  };

  updatePayload = data => {
    console.log("[ElaGamesForm][updatePayload] ---PayloadDATAAA === ", data);
    if (this.state.rc) {
      this.setState({
        rc: {
          ...this.state.rc,
          payload: data
        }
      })
    }
  };

  renderEditor = (templateType, editorData, payload, payloadId) => {
    if (!templateType) return null;
    if (templateType === "read_aloud_mcq")
      return <ReadAloudEditor
        templateType={templateType}
        data={editorData}
        updateRawData={this.updateRawData}
      />
    return <Editor
      data={editorData}
      // childRef={childState}
      templateType={templateType}
      updateRawData={this.updateRawData}
      updatePayload={this.updatePayload}
      payload={payload}
      payloadId={payload ? payloadId : null}
    />
  }

  render() {
    const { resource, showResourceSearch, snack } = this.state;
    const id = _get(this.state, 'rc.id') || null
    const nickname = _get(this.state, 'rc.nickname') || null
    const payload = _get(this.state, 'rc.payload') || null

    // const { value, classes, label, templateType } = this.props;
    const { value, classes, label, templateType, disableEditor } = _clonedeep(this.props);
    const resource_name = "reading_comprehensions";
    const key = "reading_comprehension";
    const prefix = "";
    const hint = "";
    const referencedNickname = id ? (
      this.state.rc.nickname
    ) : (
      <i>Not found!</i>
    );
    const startAdornment = (<InputAdornment
      position="start"
      classes={{
        root: classes.referenceInputAdornment
      }}
    >
      <Button
        component={Link}
        to={nickname}
        disabled={!id}
        color="primary"
        size="small"
        classes={{ root: classes.referenceButton }}
      >
        {/** reference nickname */}
        {referencedNickname}
        <LinkIcon className={classes.leftSpacing} />
      </Button>
      {!disableEditor && (
        < IconButton
          onClick={this.showReferenceBrowser.bind(
            this,
            resource_name,
            key,
            prefix,
            hint
          )}
        >
          <Search />
        </IconButton>
      )
      }
      {!disableEditor &&
        (
          <IconButton
            onClick={this.clearReference.bind(
              this,
              key,
              prefix
            )}
          >
            <ClearIcon />
          </IconButton>
        )
      }
    </InputAdornment >);

    let question_payload =
      value.payload === undefined ? undefined : value.payload;
    this.audios = _get(question_payload, "raw_question_audios") || null;
    return (
      <div className={classes.inline}>
        <div className={classes.ckeditor}>
          <Snackbar open={!!this.state.alertBoxOpen} autoHideDuration={2000} onClose={this.handleCloseAlert}>
            <Alert severity="success" onClose={this.handleCloseAlert}>
              Submitted
						</Alert>
          </Snackbar>
          <Typography variant="caption">{label}</Typography>
          {templateType === "rc_mcq" && <TextField
            rows={3}
            rowsMax={5}
            name={key}
            label="Reading Comprehension"
            className={classes.textField}
            value={id}
            InputProps={{
              startAdornment
            }}
          />}
          <ResourceSearchDialog
            open={!!showResourceSearch}
            {...showResourceSearch}
            onClose={this.handleSearchDialogClose}
          />
          {this.renderEditor(templateType, question_payload?.template !== templateType ? undefined : question_payload, payload, id)}
        </div>
        <div className={classes.inline}>
          {this.audios !== null && <ReactJson src={this.audios} />}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchResource: (a, b) =>
    dispatch(ResourcesFetchCreators.request(a, b, true))
});

const mapStateToProps = (state, ownProps) => {
  // let { resourceName } = ownProps;
  let { templateType } = ownProps;
  const resource_name = "reading_comprehensions";
  console.log("[MSTP][Ela] ownProps - ", ownProps);
  const rc_id = _get(ownProps, `value.payload.rc_id`) || null
  if (templateType === "rc_mcq") {
    return {
      rc_state: state,
      rc_resource: selectResourceById(state, resource_name, rc_id),
      rc_resources: selectResources(
        state,
        resource_name
      ),
      resourcesRCRequests: selectResourcesRequestState(state)
    };
  }
  return {
    rc_resources: null
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withRouter
)(ElaGamesForm);
