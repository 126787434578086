import React, { useState, useEffect } from 'react';
import uuid from 'uuid';
import EditorTemplateTypes from '../utils/EditorTemplateTypes';
import CKEditorCard from './utils/CKEditorCard';
import OptionCard from './utils/OptionCard';
import CombinedTextEditorElement from './utils/CombinedTextEditorElement';
import _get from 'lodash/get';
import _set from 'lodash/set';
import './styles/MetadataGenerator.scss';
import { isEmpty } from 'lodash';

function MetaDataGenerator({ type, updateQuestionData, metadata }) {
  const [data, setMetadata] = useState(metadata);
  const [customIndex, setCustomIndex] = useState(-1);
  const [templateDetails, setTemplateDetails] = useState(
    EditorTemplateTypes[type]
  );

  console.log('metadata', metadata);

  useEffect(() => {
    setMetadata(metadata);
  }, [metadata]);

  const renderCKEditor = ({
    keyName,
    labelName,
    content_guidelines: {
      removeButtons = '',
      charCount = -1,
      default_text = '',
      enableParagraph = false,
      instruction,
      transformContent = false,
      showQuestionInstruction = {},
    },
  }) => {
    let value = _get(data, `${keyName}.context.html`, '');
    let name = _get(showQuestionInstruction, 'keyName', '');
    let key = _get(metadata, name, '');
    if (!data.hasOwnProperty(keyName)) {
      updateCKEditorText(keyName, default_text);
    }
    let isSelected = metadata[name];
    return (
      <div key={keyName + key} className={'editor-block'}>
        <CKEditorCard
          label={labelName}
          value={value}
          name={keyName}
          removeButtons={removeButtons}
          charCount={charCount}
          enableParagraph={enableParagraph}
          instruction={instruction}
          shouldShowInstruction={
            isEmpty(showQuestionInstruction) ||
            isSelected === _get(showQuestionInstruction, 'value', '')
          }
          updateContent={(e) => updateValue(keyName, e, transformContent)}
        />
      </div>
    );
  };

  const updateCKEditorText = (keyName, text) => {
    setMetadata((prevData) => {
      prevData[keyName] = getEditorData(text);
      updateQuestionData(prevData);
      return prevData;
    });
  };

  const updateValue = (keyName, event, transformContent) => {
    let data = event.editor.getData();
    if (transformContent && transformContent instanceof Function) {
      data = transformContent(data);
    }
    updateCKEditorText(keyName, data);
  };

  const getEditorData = (editorData = '', id = null) => {
    let obj = {
      type: 'text',
      context: {
        html: editorData,
      },
    };
    if (id) {
      obj['id'] = id;
    }
    return obj;
  };

  const renderOptions = ({
    keyName,
    labelName,
    content_guidelines: {
      removeButtons = '',
      charCount = -1,
      maxCount = -1,
      showCondition,
      shouldShowButton = true,
      shouldAllowDelete = true,
    },
  }) => {
    const elements = data[keyName] || null;
    let name = _get(showCondition, 'keyName', '');
    let isSelected = metadata[name];
    return (
      <div key={keyName}>
        {(isEmpty(showCondition) ||
          isSelected === _get(showCondition, 'value', '')) &&
          elements &&
          elements.map((data, index) => {
            return (
              <OptionCard
                key={data.id}
                index={index}
                text={labelName}
                data={data}
                charCount={charCount}
                shouldAllowDelete={shouldAllowDelete}
                removeButtons={removeButtons}
                onOptionChange={handleChangeOption}
                keyName={keyName}
                handleDeleteClick={handleDeleteOption}
              />
            );
          })}

        {shouldShowButton &&
          (isEmpty(showCondition) || isSelected === showCondition.value) &&
          renderButtonBlock(
            'Add ' + labelName,
            keyName,
            maxCount !== -1 && elements && elements.length >= maxCount
          )}
      </div>
    );
  };

  const handleChangeOption = (keyName, index, event) => {
    setMetadata((prevData) => {
      prevData[keyName][index]['context']['html'] = event.editor.getData();
      updateQuestionData(prevData);
      return prevData;
    });
  };

  const handleAddOption = (keyName) => {
    setMetadata((prevData) => {
      if (prevData[keyName] && prevData[keyName].length) {
        prevData[keyName] = [...prevData[keyName], getEditorData('', uuid())];
      } else {
        prevData[keyName] = [getEditorData('', uuid())];
      }
      updateQuestionData(prevData);
      return prevData;
    });
  };

  const handleDeleteOption = (keyName, index) => {
    setMetadata((prevData) => {
      prevData[keyName].splice(index, 1);
      updateQuestionData(prevData);
      return prevData;
    });
  };

  const deleteAllOptions = (keyName) => {
    setMetadata((prevData) => {
      prevData[keyName] = [];
      updateQuestionData(prevData);
      return prevData;
    });
  };

  const renderButtonBlock = (text, keyName, disabled) => {
    return (
      <div className={'button-block'}>
        <button
          className={'button'}
          type="button"
          disabled={disabled}
          onClick={() => handleAddOption(keyName)}
        >
          {text}
        </button>
      </div>
    );
  };

  const renderCheckbox = ({
    keyName,
    labelName,
    content_guidelines: { default_checked = false },
  }) => {
    const isChecked = !!data[keyName];
    if (!data.hasOwnProperty(keyName)) {
      handleChangeChk(keyName, default_checked);
    }
    return (
      <div key={keyName} className="editor-checkbox-container">
        <input
          type="checkbox"
          className="checkbox-style"
          defaultChecked={default_checked}
          checked={isChecked}
          onChange={() => handleChangeChk(keyName, !isChecked)}
        />
        <label>{labelName}</label>
      </div>
    );
  };

  const handleChangeChk = (keyName, isChecked) => {
    console.log(keyName, isChecked, 161);
    setMetadata((prevData) => {
      prevData[keyName] = isChecked;
      // resetMetadata(
      //   ['question', 'instruction', 'hint', 'explanation'],
      //   prevData
      // );
      updateQuestionData(prevData);
      return prevData;
    });
  };

  const renderRadioButtons = ({
    keyName,
    labelName,
    content_guidelines: { default_selected = '', radioOptions = [] },
  }) => {
    const isSelected = data[keyName];
    if (!data.hasOwnProperty(keyName)) {
      handleChangeChk(keyName, default_selected);
    }
    return (
      <div key={keyName} className="editor-radio-container">
        <span className="radio-group-label"> {labelName} </span>
        {radioOptions.map((opt) => {
          return (
            <label key={opt.radioKeyName}>
              <input
                type="radio"
                className="radio-style"
                checked={isSelected === opt.radioKeyName}
                onChange={() => handleChangeChk(keyName, opt.radioKeyName)}
              />
              {opt.radioLabel}
            </label>
          );
        })}
      </div>
    );
  };
  const selectChange = (keyName, valueObject, content_guidelines) => {
    const { value, option, defaultValue } = valueObject || {};
    const template = { ...templateDetails };
    const addElements = content_guidelines.addElements;
    if (addElements) {
      addElements.forEach((element) => {
        if (element.type === 'addOptions') {
          deleteAllOptions(element.keyName);
          times(value)(() => {
            handleAddOption(element.keyName);
          });
        }
      });
    }
    if (value === 'Custom') {
      setCustomIndex(template['editorComponents'].length);
      template['editorComponents'].push(option);
      setTemplateDetails(template);
      handleChangeChk(keyName, defaultValue);
    } else {
      if (customIndex > -1) {
        template['editorComponents'].splice(customIndex, 1);
        setCustomIndex(-1);
      }
      handleChangeChk(keyName, value);
    }
  };

  const times = (x) => (f) => {
    if (x > 0) {
      f();
      times(x - 1)(f);
    }
  };

  const renderDropDown = (template) => {
    const { keyName, labelName, content_guidelines } = template;
    return (
      <div className="selectBox">
        <label>{labelName}</label>
        <select
          onChange={(e) =>
            selectChange(
              keyName,
              e.target.value && content_guidelines.options[e.target.value],
              content_guidelines
            )
          }
        >
          <option selected={true} disabled="disabled">
            Select Value
          </option>
          {content_guidelines.options.map((opt, key) => (
            <option value={key}>{opt.label}</option>
          ))}
        </select>
      </div>
    );
  };

  const updateData = (keyPath, data) => {
    setMetadata((prevData) => {
      _set(prevData, keyPath, data);
      updateQuestionData(prevData);
      return prevData;
    });
  };

  const renderCombinedTextEditor = (template) => {
    const initializerFn = ({
      keyName,
      defaultContentTypeOption,
      defaultText,
      contentTypeOptionKey = 'contentType',
    }) => {
      updateData(keyName, {
        [contentTypeOptionKey]: defaultContentTypeOption,
        context: {
          html: defaultText,
        },
      });
    };
    return (
      <CombinedTextEditorElement
        template={template}
        initializerFn={initializerFn}
        data={data}
        metadata={metadata}
        updateData={updateData}
      />
    );
  };

  const getDefaultOptionData = (defaultContentTypeOption) => {
    return {
      id: uuid(),
      contentType: defaultContentTypeOption,
      context: {
        html: '',
      },
    };
  };

  const renderOptionsV2 = (template) => {
    const {
      keyName,
      labelName,
      hidden,
      content_guidelines: {
        maxCount = -1,
        shouldShowButton = true,
        contentType,
      },
    } = template;
    const elements = data[keyName] || null;
    let isVisible = true;
    if (hidden) {
      isVisible = hidden({ template, metadata });
    }

    const defaultContentTypeOption = contentType
      ? contentType.options.find((el) => el.key === contentType.defaultOption)
      : null;

    const defaultContentTypeOptionType = defaultContentTypeOption
      ? defaultContentTypeOption.key
      : 'textOnly';

    if (isVisible && !data.hasOwnProperty(keyName)) {
      updateData(keyName, [getDefaultOptionData(defaultContentTypeOptionType)]);
    }

    return (
      <div key={keyName}>
        {isVisible &&
          elements &&
          elements.map((el, index) => {
            return (
              <CombinedTextEditorElement
                template={{ ...template, keyName: `${keyName}.${index}` }}
                initializerFn={({
                  keyName,
                  defaultContentTypeOption,
                  defaultText,
                }) => {}}
                data={data}
                metadata={metadata}
                updateData={updateData}
              />
            );
          })}

        {shouldShowButton && isVisible && (
          <div className={'button-block'}>
            <button
              className={'button'}
              type="button"
              disabled={
                maxCount !== -1 && elements && elements.length >= maxCount
              }
              onClick={() => {
                let newData;
                if (metadata[keyName] && metadata[keyName].length) {
                  newData = [
                    ...metadata[keyName],
                    getDefaultOptionData(defaultContentTypeOptionType),
                  ];
                } else {
                  newData = [
                    getDefaultOptionData(defaultContentTypeOptionType),
                  ];
                }
                updateData(keyName, newData);
              }}
            >
              Add {labelName}
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderDropDownV2 = (template) => {
    const {
      keyName,
      labelName,
      content_guidelines: { options, defaultOption },
    } = template;

    if (!data.hasOwnProperty(keyName)) {
      updateData(keyName, defaultOption || options[0].value);
    }

    return (
      <div className="selectBox">
        <label>{labelName}</label>
        <select
          value={_get(data, keyName)}
          onChange={(e) => updateData(keyName, e.target.value)}
        >
          <option disabled="disabled">Select Value</option>
          {options.map((opt, key) => (
            <option value={opt.key}>{opt.label}</option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div>
      {templateDetails ? (
        templateDetails['editorComponents'].map((template) => {
          if (template.type === 'textEditor') {
            return renderCKEditor(template);
          } else if (template.type === 'addOptions') {
            return renderOptions(template);
          } else if (template.type === 'addCheckbox') {
            return renderCheckbox(template);
          } else if (template.type === 'addRadioButtons') {
            return renderRadioButtons(template);
          } else if (template.type === 'addDropdown') {
            return renderDropDown(template);
          } else if (template.type === 'combinedTextEditor') {
            return renderCombinedTextEditor(template);
          } else if (template.type === 'addOptionsV2') {
            return renderOptionsV2(template);
          } else if (template.type === 'addDropdownV2') {
            return renderDropDownV2(template);
          }
          return null;
        })
      ) : (
        <div> NOTHING.... </div>
      )}
    </div>
  );
}

export default MetaDataGenerator;
