import React, { useState } from 'react';
import LoadGame from './GameFactory';
import './styles/GameContainer.scss';
import ProgressBar from '../utilComponents/progressBar/ProgressBar';
import useBackground from '../utilComponents/useBackground/useBackground';
import Header from '../utilComponents/header/Header';
import CONST from '../utils/Constants';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import EditorTemplateTypes from '../utils/EditorTemplateTypes';
import BridgeUtils from '../utils/JsBridge';
import { KINGDOM } from '../utils/Olaps';
import { GameContainerContext } from './GameContainerContext';

function GameContainer({
  showProgress,
  data,
  config,
  payload,
  mode,
  resourceType,
  currentQueIndex = 0,
  noOfQuestions = 1,
  completedCurrent,
  gameProgressStatus,
  isLastCompleted,
  ...props
}) {
  const [retryCount, setRetryCount] = useState(0);
  const [gameClosed, setGameClosed] = useState(false);
  const { nickname, rawQuestion, template, questionRubrics } = data;
  const type =
    mode === CONST.MODE.QUESTION ? rawQuestion['template'] : template;
  const hasBackground = _get(EditorTemplateTypes[type], 'hasBackground', false);
  const backgroundImgFileExtension = _get(
    EditorTemplateTypes[type],
    'backgroundImgFileExtension',
    'svg'
  );
  let imageUrl = useBackground(type, hasBackground, backgroundImgFileExtension);
  imageUrl = typeof imageUrl === 'object' ? imageUrl.default : imageUrl;
  const locale = _get(config, 'currentScreen', '');
  const isMandatoryPermissionCheck = _get(
    config,
    'isMandatoryPermissionCheck',
    false
  );
  const teaching_unit_nickname = _get(config, 'tuNickname', '');

  const onRetry = () => {
    setRetryCount(retryCount + 1);
  };

  const onGameEnd = () => {
    setGameClosed(true);
  };

  const sendOlapEvents = (olapData) => {
    const { qListId } = props;
    let obj = {
      kingdom: KINGDOM,
      family: teaching_unit_nickname,
      genus: nickname,
      species: type,
      tribe: qListId,
      locale: locale,
      value1: config.libraryPageType,
      value2: config.libraryCardType,
    };
    BridgeUtils.sendAnalyticsEvent({ ...obj, ...olapData });
  };

  const sendEvaluationData = (
    evalData,
    attempts,
    qRubrics = _get(questionRubrics, '0')
  ) => {
    const resData = config['rubricConfig'];
    const rubricArray =
      resData && !_isEmpty(resData) ? JSON.parse(resData) : [];

    const rubricObj = rubricArray.find((rubric) => {
      const templates = rubric['templates'];
      return templates && templates.includes(type);
    });

    const rubricConfig = rubricObj
      ? rubricObj.payload
      : _get(rubricArray, '0.payload', {});
    let obj = {
      questionId: nickname,
      completion: (currentQueIndex + 1) / noOfQuestions,
      attempts: attempts,
      template: type,
      totalQuestions: noOfQuestions,
      currentQuestion: currentQueIndex + 1,
    };
    if (qRubrics && rubricConfig) {
      obj['rubric'] = getRubric(
        _get(evalData, 'isCorrect', true),
        attempts,
        qRubrics,
        rubricConfig
      );
      // skills.forEach((skill) => {
      //   skill['score'] = actualScore * skill['weightage'];
      // });
    }
    BridgeUtils.sendEvaluationData({ ...obj, ...evalData });
  };

  const multiRubricsEvaluation = (evalData = []) => {
    const resData = config['rubricConfig'];
    const rubricArray =
      resData && !_isEmpty(resData) ? JSON.parse(resData) : [];

    const rubricObj = rubricArray.find((rubric) => {
      const templates = rubric['templates'];
      return templates && templates.includes(type);
    });
    const rubricConfig = rubricObj
      ? rubricObj.payload
      : _get(rubricArray, '0.payload', {});

    let obj = {
      questionId: nickname,
      completion: 1,
      attempts: 1,
      template: type,
      totalQuestions: noOfQuestions,
      currentQuestion: currentQueIndex + 1,
      type: CONST.RESOURCE_TYPE.QUESTION_LIST,
      isMultipleRubrics: true,
    };
    let finalRubric;
    let correctOptions = 0;
    evalData.forEach((evaluation) => {
      const rubric = getRubric(
        evaluation.isCorrect,
        1,
        evaluation.questionRubrics,
        rubricConfig
      );
      if (evaluation.isCorrect) {
        correctOptions++;
      }
      if (!finalRubric) {
        finalRubric = rubric;
      } else {
        finalRubric['score'] += rubric['score'];
        finalRubric['actual_score'] += rubric['actual_score'];
        let finalSkills = finalRubric['skills'] || [];
        let skills = rubric['skills'];
        skills.forEach((skill) => {
          let finalSkill = finalSkills.find((obj) => obj.name === skill.name);
          finalSkill['score'] += skill['score'];
          finalSkill['actual_score'] += skill['actual_score'];
        });
      }
    });
    obj['rubric'] = finalRubric;
    obj['totalOptions'] = evalData.length;
    obj['correctOptions'] = correctOptions;
    obj['isCumulativeRubrics'] = true;
    BridgeUtils.sendEvaluationData(obj);
  };

  const getRubric = (isCorrect, attempts, qRubrics, rubricConfig) => {
    let skills = [];
    const difficultyLevel = _get(qRubrics, 'skills.level_of_difficulty', '');
    const multiplier = _get(rubricConfig, `multipliers.${difficultyLevel}`, 1);
    const evaluationMultiplier = isCorrect ? 1 : 0;
    const skillsCount = _get(qRubrics, 'skills.number_of_skills_tested', 1);
    const scores = _get(rubricConfig, 'scores', []);
    const scoreObj = scores.find((o) => o.count === skillsCount);
    if (scoreObj) {
      const score = scoreObj['score'] * multiplier;
      const actualScore = (score / attempts) * evaluationMultiplier;
      let rubric = {
        score: score,
        actual_score: actualScore,
        level_of_difficulty: difficultyLevel,
      };

      let weightageArray = scoreObj['weightage'];
      weightageArray.sort((a, b) => b - a);

      for (let i = 0; i < skillsCount; i++) {
        const obj = {};
        switch (i) {
          case 0:
            obj['name'] = _get(qRubrics, 'skills.primary_skill', '');
            obj['score'] = (score * weightageArray[0]) / 100;
            obj['actual_score'] = (actualScore * weightageArray[0]) / 100;
            break;
          case 1:
            obj['name'] = _get(qRubrics, 'skills.secondary_skill', '');
            obj['score'] = (score * weightageArray[1]) / 100;
            obj['actual_score'] = (actualScore * weightageArray[1]) / 100;
            break;
          case 2:
            obj['name'] = _get(qRubrics, 'skills.tertiary_skill', '');
            obj['score'] = (score * weightageArray[2]) / 100;
            obj['actual_score'] = (actualScore * weightageArray[2]) / 100;
            break;
          default:
            break;
        }
        skills.push(obj);
      }
      rubric['skills'] = skills;
      return rubric;
    }
  };

  return (
    <div className="game-container">
      <div className="bg-container">
        <img src={imageUrl} />
      </div>
      {mode === CONST.MODE.EDITOR && type && !type.startsWith('ic') && (
        <Header
          sendOlapEvents={sendOlapEvents}
          currentQueIndex={currentQueIndex}
          noOfQuestions={noOfQuestions}
          onGameEnd={onGameEnd}
        />
      )}
      {!gameClosed && (
        <GameContainerContext>
          <LoadGame
            mode={mode}
            data={
              mode === CONST.MODE.QUESTION
                ? _get(rawQuestion, 'payload', {})
                : data
            }
            raw_question_audios={_get(rawQuestion, 'raw_question_audios', [])}
            type={type}
            payload={payload}
            onRetry={onRetry}
            isLastCompleted={isLastCompleted}
            completedCurrent={completedCurrent}
            sendOlapEvents={sendOlapEvents}
            retryCount={retryCount}
            singleAttempt={locale === 'diagnosticTest'}
            sendEvaluationData={sendEvaluationData}
            sendMultiRubricEvaluationData={multiRubricsEvaluation}
            questionRubrics={questionRubrics}
            isMandatoryPermissionCheck={isMandatoryPermissionCheck}
            currentQueIndex={currentQueIndex}
            noOfQuestions={noOfQuestions}
            {...props}
          >
            {mode === CONST.MODE.QUESTION &&
              (showProgress || (type && !type.startsWith('ic'))) && (
                <Header
                  sendOlapEvents={sendOlapEvents}
                  currentQueIndex={currentQueIndex}
                  noOfQuestions={noOfQuestions}
                  onGameEnd={onGameEnd}
                >
                  {showProgress && (
                    <ProgressBar
                      noOfQuestions={noOfQuestions}
                      currentQueIndex={currentQueIndex}
                      gameProgressStatus={gameProgressStatus}
                      type={type}
                    />
                  )}
                </Header>
              )}
          </LoadGame>
        </GameContainerContext>
      )}
    </div>
  );
}

export default GameContainer;
