import Constants from './Constants';


export function getCleanedMigratedData(data, resourceType) {
  const cleanedData = {
    resourceData: {},
    qListId: null,
    listType: null,
  };
  cleanedData.listType = data?.listType;

  switch (resourceType) {
    case Constants.RESOURCE_TYPE.READING_COMPREHENSION:
      cleanedData.resourceData = data;
      break;
    case Constants.RESOURCE_TYPE.READ_ALOUD:
      cleanedData.resourceData = data;
      break;

    case Constants.RESOURCE_TYPE.QUESTION_LIST:
      cleanedData.resourceData = data['questions'];
      cleanedData.qListId = data['nickname'];
      break;

    case Constants.RESOURCE_TYPE.QUESTION:
      cleanedData.resourceData = data;
      break;
    default:
      break;
  }

  return cleanedData;
}


export function isLetter(c) {
  return c.toLowerCase() != c.toUpperCase();
}


const SpecialChar = {
  ' ': true,
  '.': true,
  '\n': true,
  '!': true,
  ',': true,
};

export const getFinalWord = (str) => {
  const len = str.length;
  let first = "";
  let second = "";

  let notFound = false;
  //prefix
  for (let i = 0; i <= len / 2; i++) {
    let char = str.charAt(i);

    const isSpecial = !isLetter(char);
    if (!isSpecial) {
      notFound = true;
    }
    if (notFound) {
      first += char;
    }

  }

  //   //suffix
  notFound = false;
  for (let i = 0; i < (len / 2) - 1; i++) {
    let char = str.charAt(len - 1 - i);
    const isSpecial = !isLetter(char);
    if (!isSpecial) {
      notFound = true;
    }
    if (notFound) {
      second = char + second;
    }
  }

  const res = first + second;
  return res.toLowerCase();
}

export function commonPrefixUtil(str1 = "", str2 = "") {
  let result = "";
  let lastSpaceInd = 0;
  let n1 = str1.length, n2 = str2.length;
  // Compare str1 and str2
  for (let i = 0, j = 0; i <= n1 - 1 && j <= n2 - 1; i++, j++) {
    if (str1[i] != str2[j]) {
      break;
    }
    result += str1[i];
  }

  return result;
}



export const parseUrl = () => {
  const query = {};
  const splitLocation = window.location.href.split('?');
  if (splitLocation.length > 1) {
    const queryString = splitLocation[1];
    var pairs = queryString.split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
  }
  return query;
}

export var Timer = function () {
  var timerId;
  var value = 0;

  this.pause = function () {
    clearInterval(timerId);
  };

  this.resume = function () {
    clearInterval(timerId);
    timerId = setInterval(() => {
      value += 1;
    }, 1000);
  };

  this.getTime = () => {
    return value;
  }
};

export * from './adjustImage';
