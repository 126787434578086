import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Column, Table, AutoSizer } from "react-virtualized";

import classNames from "classnames";

const styles = theme => ({
	markedAsDeleted: {
		background:
			"repeating-linear-gradient(-55deg, #ddd, #ddd 10px,#fff 10px,#fff 20px)"
	},
	headerColumn: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontSize: 12,
		fontWeight: 200,
		textTransform: "none",
		color: "rgba(0,0,0,0.54)",
		paddingLeft: theme.spacing(1),
		paddingTop: theme.spacing(1)
	},
	tableRow: {
		alignItems: "flex-end"
	}
});

class ResourceDataGrid extends Component {
	render() {
		const {
			schema,
			fieldRenderer,
			deleteRenderer,
			prefix,
			rows = [],
			classes,
			onPaste
		} = this.props;
		const columnWidths = [0.55, 0.35, 0.06, 0.04];

		return (
			<AutoSizer disableHeight>
				{({ width }) => (
					<Table
						width={width}
						height={400}
						headerClassName={classes.headerColumn}
						headerHeight={20}
						rowHeight={60}
						rowCount={rows.length}
						rowClassName={({ index }) => {
							return classNames(classes.tableRow, {
								[classes.markedAsDeleted]: (rows[index] || {})
									._destroy
							});
						}}
						rowGetter={({ index }) => rows[index]}
					>
						{schema.columns.map((spec, idx) => (
							<Column
								key={spec.name}
								label={spec.name.underscoreToLabel()}
								dataKey={spec.name}
								width={width * columnWidths[idx]}
								flexGrow={1}
								cellRenderer={({ rowIndex }) =>
									fieldRenderer(
										rows[rowIndex],
										spec,
										prefix.concat([rowIndex]),
										true,
										{
											onPaste: event =>
												onPaste(event, rowIndex)
										}
									)
								}
							/>
						))}
						<Column
							key={"delete"}
							dataKey={"id"}
							width={
								width * columnWidths[columnWidths.length - 1]
							}
							label={ "" }
							cellRenderer={({ rowIndex }) =>
								deleteRenderer(prefix.concat([rowIndex]))
							}
						/>
					</Table>
				)}
			</AutoSizer>
		);
	}
}

export default withStyles(styles)(ResourceDataGrid);
