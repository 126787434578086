// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["params"],
		success: ["params", "data", "entities"],
		failure: ["params", "error"]
	},
	{ prefix: "GAME_TEMPLATES_CREATE_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
	return state.merge({ creating: true, error: null });
};

const success = (state: Object, { data }: Object) => {
	return state.merge({
		creating: false,
		data: [...state.data, data.game_template],
		error: null
	});
};

const failure = (state: Object, { error }: Object) => {
	return state.merge({ creating: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
