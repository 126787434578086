import React, { useState } from "react";
import Button from '../common/Button';
import coinIcon from "./../../assets/images/coinIcon.svg";
import "./Assessment.scss";
import { useGameContext } from "../GameContext";
import HeaderMain from "../MainLayout/Header/headerMain";
import styled from "styled-components";
import Sunrays from "../../assets/images/sunrays";
import Refresh from '@material-ui/icons/Refresh';
import starIcon from "./../../assets/images/star.svg";
import { useTheme } from "styled-components";

const Title = styled.div`
  text-shadow: 0 2px 4px ${({ theme }) => theme.dark1}; 
`;

const TagBackground = styled.div`
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.dark1}, inset 0 1px 0 0 rgba(255, 255, 255, 0.44);
  background-image: linear-gradient(154deg, ${({ theme }) => theme.light3} 4%, ${({ theme }) => theme.light1} 84%);
`;

const Wrapper = styled.div`
  box-shadow: inset 0 -2px 2px 0 rgba(255, 255, 255, 0.31), 0 4px 6px 0 ${({ theme }) => theme.light3};
  background-image: linear-gradient(104deg, ${({ theme }) => theme.light2} 10%, ${({ theme }) => theme.primaryColor} 99%);
`;


const Trail = styled.div`
  background-color: ${({ theme }) => theme.light3};
  width:${({ width }) => width}%;
`;

const PointerDot = styled.div`
  left: ${({ marginPercentage }) => marginPercentage}%; 
`;

const Dialogue = styled.div`
  left: ${({ marginPercentage }) => marginPercentage}%; 
`;

const StoryList = styled.div`
  width: ${({ noOfStories }) => noOfStories * 210}px;
`;

const Success = () => {
  const { } = useGameContext();
  const [currentScore, setCurrentScore] = useState(270);
  const [previousScore, setPreviousScore] = useState(150)
  const [totalScore, setTotalScore] = useState(500);
  const { readTimer, getRecommendedStories, setQuestionStarted, closeGame, setCompleted } = useGameContext();
  const theme = useTheme();

  const recommendedStories = getRecommendedStories() || [];
  const previousScorePercentage = (previousScore / totalScore) * 100;
  const currentScorePercentage = (currentScore / totalScore) * 100;

  return (
    <div className="assessment-screen-container">
      <Wrapper
        className="assessment-wrapper"
      >
        <div className="assessment-header">
          <HeaderMain onBack={() => closeGame()} showMic={false} showBookmark />
        </div>
        <div className="assessment-content" style={{ display: "none" }}>
          <Title className="title">That was great,</Title>
          <Title className="title">Natasha!</Title>
          <div className="title subtitle">
            You earned more stars this time
          </div>
        </div>
        <div className="score-div">
          <div className="sunrays">
            <div className="sunrays-rotate">
              <Sunrays colour={theme.light2} />
            </div>
          </div>
          <div className="score-board">
            <div className="details">
              <div className="detail">
                <div className="detail-title">
                  New High score
                </div>
                <div className="score">
                  {currentScore} stars
                </div>
              </div>
              <div className="detail">
                <div className="detail-title">
                  Time taken
                </div>
                <div className="time-taken">
                  {readTimer.getTime() / 60} <span>mins</span>
                </div>
              </div>
            </div>
            <div className="progress-bar-wrapper">
              <div className="progress-bar">
                <div className="tag">
                  <img src={coinIcon} />
                </div>
                <TagBackground className="tag-background">
                </TagBackground>
                <Trail width={currentScorePercentage} className="trail" >
                  <div className="shine"></div>
                  <div className="previous-score">
                    <PointerDot marginPercentage={previousScorePercentage} className='pointer-dot'></PointerDot>
                    <Dialogue marginPercentage={(Math.max(previousScore, 200) / totalScore) * 100} className="dialogue">
                      <div className="dialogue-pointer"></div>
                      <div className="dialogue-box">
                        <div className="previous-score-title">
                          Previous high score
                        </div>
                        <div className="previous-score-value">
                          {previousScore} stars
                        </div>
                      </div>
                    </Dialogue>
                  </div>
                </Trail>
              </div>
            </div>
          </div>
          <div className="score-board lower-board">
            <div className="title">
              {currentScore - previousScore} Stars
              </div>
            <div className="subtitle">
              Earned for this attempt
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="assessment-main-content">
        <Button
          label={"Retry this story"}
          size="md"
          onClick={() => { setCompleted(false) }}
          prefixIcon={<Refresh />}
          btnTheme="primaryOutlined"
          isBold
        />
        <div className="more-coins">
          <div className="text">
            Collect more coins by participating a question
          </div>
          <Button
            label={"Play"}
            size="sm"
            btnTheme="primary"
            isBold
            onClick={() => { setQuestionStarted(true) }}
          />
        </div>

        <div className="story-recommendation">
          <div className="text">
            Read a story and earn stars
          </div>
          <div className="story-list-wrapper">
            <StoryList noOfStories={recommendedStories.length} className="story-list">
              {
                recommendedStories.map((story) => {
                  return <div className='story-thumbnail'>
                    <div className="difficulty-tag">
                      {story.difficulty}
                    </div>
                    <img src={story.imgSrc} className="story-image" />
                    <div className="story-desc">
                      <div className="story-title">
                        {story.title}
                      </div>
                      <div className="story-stars">
                        <img src={starIcon} />
                        <div className="stars-count">
                          {story.stars} Stars
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </StoryList>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Success;
