import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import ProgressButton from "./ProgressButton";
import TitleBar from "./TitleBar";

import compose from "recompose/compose";
import { connect } from "react-redux";

import {
    CreateCreators as WorkspacesCreateCreators,
    selectWorkspacesRequestState
} from "../Redux/WorkspacesRedux";

import _get from "lodash/get";

const styles = theme => ({
    inner: {
        padding: theme.spacing(1)
    },
    drawerPaper: {
        width: 320,
        display: "flex",
        flexWrap: "wrap",
        alignContent: "flex-start",
        height: "100vh",
        overflow: "auto",
        position: "relative",
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            width: 500
        },
        [theme.breakpoints.up("lg")]: {
            width: 800
        }
    },
    createClInput: {
        width: "100%",
        marginBottom: theme.spacing(1),
        fontSize: "1em"
    },
    checkBoxContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
    formControl: {
        minWidth: 200
    },
});

class NewWorkspaceForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            workspaceName: "",
            schemaType: ""
        };
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.workspacesRequests.creating &&
            !nextProps.workspacesRequests.creating
        ) {
            if (nextProps.workspacesRequests.error) {
                this.setState({
                    createError: nextProps.workspacesRequests.error
                });
            }
        }
    }

    onCreate = () => {
        let sourceProps = {};
        if (this.props.sourceWorkspace) {
            sourceProps = { source_workspace: this.props.sourceWorkspace.id };
        }
        this.props.createWorkspace({
            name: this.state.workspaceName,
            schema_type: this.state.schemaType,
            ...sourceProps
        });
    };

    render() {
        const { classes, workspacesRequests, sourceWorkspace } = this.props;
        const { createError } = this.state;

        return (
            <div className={classes.drawerPaper}>
                <TitleBar
                    title={
                        sourceWorkspace
                            ? `Clone "${sourceWorkspace.name}"`
                            : "New Workspace"
                    }
                />
                <Grid
                    container
                    direction="column"
                    alignItems="stretch"
                    className={classes.inner}
                >
                    <Grid item>
                        <TextField
                            value={this.state.workspaceName}
                            name="workspaceName"
                            label="Workspace name"
                            disabled={workspacesRequests.creating}
                            error={!!_get(createError, "errors.name")}
                            helperText={_get(createError, "errors.name")}
                            onChange={event =>
                                this.setState({
                                    [event.target.name]: event.target.value
                                })
                            }
                            className={classes.createClInput}
                            type="text"
                        />
                    </Grid>
                    {!sourceWorkspace && <Grid item>
                        <FormGroup
                            row
                            classes={{ root: classes.checkBoxContainer }}
                        >
                            <FormControl
                                className={classes.formControl}
                                error={!!_get(createError, "errors.schema_type")}
                            >
                                <InputLabel htmlFor="age-helper">
                                    Schema
                                </InputLabel>
                                <Select
                                    value={this.state.schemaType}
                                    name="schemaType"
                                    onChange={event =>
                                        this.setState({
                                            [event.target.name]: event.target.value
                                        })
                                    }
                                    input={
                                        <Input
                                            name={"schema"}
                                            id={`schema-helper`}
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="CourseData">Course Data Schema</MenuItem>
                                    <MenuItem value="AppData">App Data Schema</MenuItem>
                                    <MenuItem value="LowerCourseData">Lower Course Data Schema</MenuItem>
                                    <MenuItem value="ElaCourseData">Ela Course Data Schema</MenuItem>
                                    <MenuItem value="ElaRewardData">Ela Reward Data Schema</MenuItem>
                                    <MenuItem value="ElaGlobalCourseData">Ela Global Course Data Schema</MenuItem>
                                    <MenuItem value="ReadAloudCourseData">Read Aloud Course Data Schema</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {_get(createError, "errors.schema_type")}
                                </FormHelperText>
                            </FormControl>
                        </FormGroup>
                    </Grid>}
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignContent="flex-end"
                            className={classes.buttonContainer}
                        >
                            <Grid item>
                                <ProgressButton
                                    error={createError && createError.error}
                                    inProgress={workspacesRequests.creating}
                                    color="secondary"
                                    onClick={this.onCreate}
                                >
                                    Create
                                </ProgressButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    createWorkspace: n => dispatch(WorkspacesCreateCreators.request(n))
});

const mapStateToProps = (state, ownProps) => {
    return {
        workspacesRequests: selectWorkspacesRequestState(state)
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(NewWorkspaceForm);
