import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Editor from "../ela-readaloud/src/components/editor/EditorContainer";
import _clonedeep from "lodash/cloneDeep"
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
const styles = theme => ({
	inlineList: {
		paddingLeft: theme.spacing(2)
	},
	inline: {
		paddingRight: theme.spacing(5),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	ckeditor: {
		position: "relative",
		paddingBottom: theme.spacing(90)
	}
});

class ReadAloudForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alertBoxOpen: false
		}
	}

	handleChange = (prefix, event) => {
		const { value, name } = this.props;
		let newValue = value.setIn(
			prefix.concat(event.target.name),
			event.target.value
		);
		this.props.onChange({ target: { value: newValue, name } });
	};

	handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			...this.state,
			alertBoxOpen: !this.state.alertBoxOpen,
		})
	};

	updateRawData = (dataReceived) => {
		const { name } = this.props;
		let read_aloud = {
			version: "1.0",
			context: dataReceived.context
		};
		this.setState({
			...this.state,
			alertBoxOpen: true,
		});
		console.log("[RCForm]-- Data - ", read_aloud);
		this.props.onChange({ target: { value: read_aloud, name } });
	};

	render() {
		const { value, classes, label } = _clonedeep(this.props);
		return (
			<div className={classes.inline}>
				<div className={classes.ckeditor}>
					<Typography variant="caption">{label}</Typography>
					<Editor
						// childRef={this.childState}
						templateType={"read_aloud"}
						data={value ? value : {}}
						updateRawData={this.updateRawData}
					/>
					<Snackbar open={!!this.state.alertBoxOpen} autoHideDuration={2000} onClose={this.handleCloseAlert}>
						<Alert severity="success" onClose={this.handleCloseAlert}>
							Submitted
						</Alert>
					</Snackbar>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(ReadAloudForm);
