import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import { workspace } from "../../Services/Api/Schema";

import fetchReducer, { Types as FetchTypes, Creators as FetchCreators } from "./fetch";
import createReducer_, { Types as CreateTypes, Creators as CreateCreators } from "./create";
import listReducer, { Types as ListTypes, Creators as ListCreators } from "./list";
import updateReducer, { Types as UpdateTypes, Creators as UpdateCreators } from "./update"

export const INITIAL_STATE = Immutable({
  data: false,
  error: null
});

export const key = "workspaces";

export const selectWorkspaces = state => {
  try {
    return denormalize(state[key].data, [workspace], state.entities);
  } catch (e) {
    return null;
  }
};

export const selectWorkspacesById = (state, id) => {
  try {
    return denormalize(
      state.entities.workspaces.getIn([parseInt(id, 10)]),
      workspace,
      state.entities
    );
  } catch (e) {
    return null;
  }
};

export const selectWorkspacesRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};

export { FetchCreators, FetchTypes };
export { CreateCreators, CreateTypes };
export { ListCreators, ListTypes };
export { UpdateCreators, UpdateTypes };

export default createReducer(INITIAL_STATE, {
  ...fetchReducer,
  ...createReducer_,
  ...listReducer,
  ...updateReducer
});
