import React, { useState } from 'react';
import '../styles/LabelCard.scss';
import ClassNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function LabelCard({
  index,
  text,
  keyName,
  children,
  shouldAllowDelete = true,
  handleDeleteClick,
}) {
  const [open, setOpen] = useState(true);

  return (
    <div className={'label-outer-box'}>
      <div className={'box'} key={index}>
        <div
          className={ClassNames('header-box', {
            'header-bottom': shouldAllowDelete,
          })}
        >
          <h4 className={'header-text'}>{`${text} ${
            index >= 0 ? `#${index + 1}` : ''
          }`}</h4>
          {open ? (
            <KeyboardArrowUpIcon
              className={'collapse-icon'}
              onClick={() => {
                setOpen(!open);
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              className={'collapse-icon'}
              onClick={() => {
                setOpen(!open);
              }}
            />
          )}
          {shouldAllowDelete && (
            <div className={'delete-btn'}>
              <DeleteIcon
                className={'delete-icon'}
                onClick={() => handleDeleteClick(keyName, index)}
              />
            </div>
          )}
        </div>
        {open && children}
      </div>
    </div>
  );
}

export default LabelCard;
