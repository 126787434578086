import { useState, useEffect } from 'react';

function useBackground(type, hasBackground, backgroundImgFileExtension) {
  const [imageUrl, setImageUrl] = useState(
    type && hasBackground
      ? require(`../../../assets/backgrounds/${type}.${backgroundImgFileExtension}`)
      : ''
  );

  useEffect(() => {
    setImageUrl(
      type && hasBackground
        ? require(`../../../assets/backgrounds/${type}.${backgroundImgFileExtension}`)
        : ''
    );
  }, [type, backgroundImgFileExtension, hasBackground]);

  return imageUrl;
}

export default useBackground;
