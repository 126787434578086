import { put, call } from "redux-saga/effects";
import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";

export function* announceAppearSaga(data) {
	if (data.channel === "AnnounceChannel") {
		yield put(ActionCableCreators.perform(data.channel, "appear"));

		//May be its reconnecting after long time
		yield call(updateClientSaga);
	}
}

export function* updateClientSaga() {
	if ("serviceWorker" in navigator) {
		if( navigator.serviceWorker.controller ) {
			const registration = yield navigator.serviceWorker.ready;
			console.log("Updating client code ...")
			registration.update();
		}
	}
}
