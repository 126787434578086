import DumData from '../utils/DummyData';

/* eslint-disable no-unused-vars */

export default class DummyBridgeUtils {
  static startListeningText = '';
  static spokenText = '';
  static SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  static mic = new this.SpeechRecognition();
  static tts = window.speechSynthesis;
  static ttsMessage = null;
  static autoTimer = null;

  static dummyResourceData = DumData;

  static dummySuccessCallback(cb) {
    clearTimeout(this.autoTimer);
    this.autoTimer = setTimeout(() => {
      cb(true);
    }, 2000);
  }

  static dummyList = [
    'above',
    'chart',
    'dinner',
    'end',
    'action',
    'email',
    'chair',
    'about',
    'asd',
    'asdsadsad',
    'tet',
    'across',
    'activity',
    'actor',
    'actress',
    'cheap',
    'check',
    'cheese',
    'dirty',
    'discuss',
    'dish',
    'enjoy',
    'enough',
  ];

  static response = {
    optionId: 'a0b13de4-0166-4fb7-b6ba-9cf418109a2f',
    response: {
      Id: '176f835e225d49d68cc9ef49dc23f0e1',
      Text: 'Far away lived a fea king in a',
      Offset: 19100000,
      Duration: 6600000,
    },
  };

  static newRes = {
    "Id": "7b2e340ba2ba435a8859562f61f03b4a",
    "Text": "far away lived a sea king in a beautiful castle",
    "Offset": 50000000,
    "Duration": 32100000,
    "DiffPatch": [
      {
        "check": "EQUAL",
        "Text": "Far away"
      },
      {
        "check": "EQUAL",
        "Text": ","
      },
      {
        "check": "INSERT",
        "Text": "was a"
      },
      {
        "check": "EQUAL",
        "Text": "lived a Sea King in a"
      },
      {
        "check": "DELETE",
        "Text": "Beautiful"
      },
      {
        "check": "EQUAL",
        "Text": "castle"
      }
    ]
  }


  static dummyTTSCallback(cb) {
    const timer = Math.floor(Math.random() * 1000) + 2000;

    this.autoTimer = setTimeout(() => {
      const items = Math.floor(Math.random() * 3) + 1;
      cb(this.dummyList.splice(0, items).join(' '));
      if (this.dummyList.length !== 0) {
        this.dummyTTSCallback(cb);
      }
    }, timer);
  }

  static dummyFn(cb) {
    this.autoTimer = setTimeout(() => {
      cb({
        url: 'https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand3.wav',
        response: this.dummyEnunciateParaWithRemoval,
      });
    }, 3000);
  }

  static observeAppLifeCycle(cb) {
    window.oal = (callStatus) => cb(callStatus);
  }

  static checkInternetConnectivity(cb) {
    window.cic = (callStatus) => cb(callStatus);
  }

  static onCallListener(cb) {
    window.cl = (callStatus) => cb(callStatus);
  }

  static onBuddyClicked(cb) {
    window.obc = (callStatus) => cb(callStatus);
  }

  static onGameStatusChange(cb) {
    cb();
  }


  static dummyFnReadAloud(cb) {
    this.autoTimer = setTimeout(() => {
      cb(this.response);
    }, 1000);
  }

  static micStart(mic) {
    try {
      mic.start();
    }
    catch {
      console.log("Already listening")
    }
  }

  static stopReadAlong(cb) {
    try {
      this.mic.abort();
      cb(true);
    }
    catch {
      console.log("Already stopped")
    }
  }

  static textToSpeech(text, cb) {
    const tts = this.tts;
    this.ttsMessage = new SpeechSynthesisUtterance(text);
    // var voices = tts.getVoices();
    // msg.voice = voices[40];
    this.ttsMessage.rate = 1.2;
    this.ttsMessage.volume = 0.4;
    this.ttsMessage.pitch = 1;
    if (this.ttsMessage) {
      this.ttsMessage.addEventListener('end', function (event) {
        // this.ttsMessage = null;
        cb(true);
      });
    }
    tts.speak(this.ttsMessage);
  }

  static startListening(cb) {
    // this.mic.continuous = true;
    this.mic.interimResults = true;
    this.mic.lang = 'en-IN';
    try {
      this.mic.start();
    }
    catch {
      console.log("Already listening")
    }


    this.mic.addEventListener('end', () => this.micStart(this.mic));

    this.mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result, index) => {
          return result.transcript
        })
        .join('');
      // console.log(this.ttsMessage?.ttsMessage)
      // if (!this.ttsMessage?.ttsMessage) {
      cb({ response: { Text: transcript } });
      // }
    };
  }

  static stopListening() {
    this.mic.stop();
  }
}
