export const themes =
{
    theme1: {
        primaryColor: "#855aff",
        dark1: "#754de8",
        light1: "#ac8fff",
        light2: "#ab93e8",
        light3: "#e6deff",
    },
    theme2: {
        primaryColor: "#3074f8",
        dark1: "#4d66e8",
        light1: "#5f9bff",
        light2: "#67b7f1",
        light3: "#c3ddff"
    },
    theme3: {
        primaryColor: "#ff5cab",
        dark1: "#ab2a74",
        light1: "#fc67bc",
        light2: "#f383e8",
        light3: "#ffc6eb"
    },
    theme4: {
        primaryColor: "#ff795b",
        dark1: "rgba(185, 78, 31, 0.9)",
        light1: "#ff8a5d",
        light2: "#feb55f",
        light3: "#ffdbc8"
    },
    teme5: {
        primaryColor: "#4d8714",
        dark1: "#3b6e0b",
        light1: "#58991b",
        light2: "#7dc73d",
        light3: "#a6e66b"
    },
    theme6: {
        primaryColor: "#f38503",
        dark1: "rgba(156, 56, 0, 0.7)",
        light1: "#ffac26",
        light2: "#f2c907",
        light3: "#fff3d8"
    }
}


export const themeArr = Object.values(themes);