import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";

import PlusOne from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ImportIcon from "@material-ui/icons/ArrowDownward";
import ListIcon from "@material-ui/icons/ViewList";

import Dropzone from "react-dropzone";

import FileCard from "../Components/FileCard";

import compose from "recompose/compose";
import { connect } from "react-redux";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

import {
    CreateCreators as FilesCreateCreators,
    ListCreators as FilesListCreators,
    ImportCreators as FilesImportCreators,
    selectFiles,
    selectFilesRequestState
} from "../Redux/FilesRedux";
import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";

import { Link } from "react-router-dom";

import _get from "lodash/get";

const styles = theme => ({
    dropZone: {
        width: "calc(100% - " + theme.spacing(2) + ")",
        minHeight: "90%",
        padding: theme.spacing(1),
        border: `1px dashed #fff`
    },
    dropZoneActive: {
        border: `1px dashed #9a9a9a`
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    fileHover: {
        background: "green"
    },
    uploadCard: {
        margin: theme.spacing(1)
    },
    inner: {
        padding: theme.spacing(1)
    },
    closeButton: {
        position: "fixed",
        right: 0
    },
    chooseFileButton: {
        minHeight: 48
    },
    popover: {
        padding: theme.spacing(3)
    },
});

class UploadDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            importURL: "",
            uploadByMe: true
        };
    }
    onDrop = files => {
        console.log(files);
        files.forEach(file => {
            this.props.createFile(file);
        });
    };

    getDataTransferItems = evt => {
        return getDroppedOrSelectedFiles(evt).then(list => {
            return list.map(({ fileObject, fullPath, ...rest }) => {
                fileObject.fullPath = fullPath;
                return fileObject;
            });
        });
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.open && nextProps.open) {
            const params = {
                filter_by_me: this.state.uploadByMe ? 1 : 0
            };
            this.props.listFiles(params);
            this.props.subscribeChannel("FileChannel");
        }

        if (!nextProps.open && this.props.open) {
            this.props.unsubscribeChannel("FileChannel");
        }

        if (
            this.props.filesRequests.importing &&
            !nextProps.filesRequests.importing
        ) {
            if (nextProps.filesRequests.error) {
                this.setState({
                    importError: nextProps.filesRequests.error
                });
            } else {
                this.setState({
                    showImportModal: null,
                    importURL: ""
                });
            }
        }
    }

    onLoadMore = () => {
        const { filesRequests } = this.props;
        const pagination = _get(filesRequests, "meta.pagination");
        const { uploadByMe } = this.state;

        const params = {
            filter_by_me: uploadByMe ? 1 : 0,
            page: {
                number: pagination.next_page
            }
        };
        this.props.listFiles(params);
    };

    onImportURL = event => {
        this.setState({ showImportModal: event.currentTarget });
    };

    onImportClose = () => {
        this.setState({ showImportModal: null });
    };

    onImportSubmit = () => {
        this.props.importFile({ url: this.state.importURL });
    };

    onFilterClick = uploadByMe => {
        this.setState({ uploadByMe });
        const params = {
            filter_by_me: uploadByMe ? 1 : 0
        };
        this.props.listFiles(params);
    };

    render() {
        const { classes, files, onCancel, filesRequests, open } = this.props;
        let dropzoneRef;

        const hasCreateAccess = _get(
            filesRequests,
            "meta.abilities.create_file"
        );
        const hasImportAccess = _get(
            filesRequests,
            "meta.abilities.import_file"
        );
        const pagination = _get(filesRequests, "meta.pagination");

        const { showImportModal, importError, uploadByMe } = this.state;

        const hlProps = {
            color: "primary",
            variant: "outlined"
        };

        const ByMeProps = uploadByMe ? hlProps : {};
        const ByAllProps = uploadByMe ? {} : hlProps;

        return (
            <Dropzone
                disableClick
                ref={node => {
                    dropzoneRef = node;
                }}
                className={classes.dropZone}
                disabled={!hasCreateAccess}
                activeClassName={classes.dropZoneActive}
                onDrop={this.onDrop}
                getDataTransferItems={this.getDataTransferItems}
            >
                {open && (
                    <Tooltip
                        title="Close upload panel, uploads will continue"
                        placement="left"
                    >
                        <IconButton
                            onClick={onCancel}
                            classes={{ root: classes.closeButton }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Grid container>
                    <ButtonGroup>
                        <Button
                            size="small"
                            {...ByMeProps}
                            onClick={this.onFilterClick.bind(this, true)}
                        >
                            By Me
                        </Button>
                        <Button
                            size="small"
                            {...ByAllProps}
                            onClick={this.onFilterClick.bind(this, false)}
                        >
                            By Everyone
                        </Button>
                    </ButtonGroup>
                    <Button
                        size="small"
                        color="primary"
                        component={Link}
                        to="/files"
                    >
                        <ListIcon /> View All
                    </Button>
                </Grid>
                <Grid container>
                    {hasCreateAccess && (
                        <Grid item xs={12} sm={6} md={3}>
                            <Card classes={{ root: classes.uploadCard }}>
                                <CardContent>
                                    <Button
                                        color="secondary"
                                        size="small"
                                        classes={{
                                            root: classes.chooseFileButton
                                        }}
                                        onClick={() => dropzoneRef.open()}
                                    >
                                        Choose
                                        <PlusOne
                                            className={classes.rightIcon}
                                        />
                                    </Button>
                                    {hasImportAccess && (
                                        <Button
                                            color="secondary"
                                            size="small"
                                            classes={{
                                                root: classes.chooseFileButton
                                            }}
                                            onClick={this.onImportURL}
                                        >
                                            Import URL
                                            <ImportIcon
                                                className={classes.rightIcon}
                                            />
                                        </Button>
                                    )}
                                </CardContent>
                            </Card>
                            {hasImportAccess && (
                                <Popover
                                    open={Boolean(showImportModal)}
                                    anchorEl={showImportModal}
                                    onClose={this.onImportClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}
                                >
                                    <div className={classes.popover}>
                                        <TextField
                                            value={this.state.importURL}
                                            onChange={event =>
                                                this.setState({
                                                    importURL:
                                                        event.target.value
                                                })
                                            }
                                            label="Import URL"
                                            error={!!importError}
                                            helperText={
                                                (importError &&
                                                    importError.error) ||
                                                "(Support only tnl-tmp manifest now)"
                                            }
                                        />
                                        <Button
                                            color="secondary"
                                            onClick={this.onImportSubmit}
                                        >
                                            Import
                                        </Button>
                                    </div>
                                </Popover>
                            )}
                        </Grid>
                    )}
                    {files &&
                        files.map(file => {
                            return <FileCard key={file.id} file={file} />;
                        })}
                </Grid>
                {pagination && pagination.next_page && (
                    <Grid container justify="center" alignContent="center">
                        <Button color="primary" onClick={this.onLoadMore}>
                            Load more
                        </Button>
                    </Grid>
                )}
            </Dropzone>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    subscribeChannel: a => dispatch(ActionCableCreators.subscribe(a)),
    unsubscribeChannel: a => dispatch(ActionCableCreators.unsubscribe(a)),
    listFiles: p => dispatch(FilesListCreators.request(p, true)),
    createFile: n => dispatch(FilesCreateCreators.request(n)),
    importFile: n => dispatch(FilesImportCreators.request(n))
});

const mapStateToProps = state => ({
    files: selectFiles(state),
    filesRequests: selectFilesRequestState(state)
});

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UploadDrawer);
