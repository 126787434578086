import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import BridgeUtils from '../utils/JsBridge';

const ParentContext = createContext(null);

export const GameContainerContext = ({ children }) => {
  const [appLifeCycle, setAppLifeCycle] = useState('Active');
  const [isInternetAvailable, setIsInternetAvailable] = useState(true);
  // we are maintaing depth of react game to check on android back click
  const [stateDepth, setStateDepth] = useState(0);
  let refStateDepth = useRef(stateDepth);

  useEffect(() => {
    observeAppLifeCycle();
    checkInternetConnectivity();
    BridgeUtils.onBackClick(handleBackClickApp);
  }, []);

  const onStateDepthChange = (depth) => {
    refStateDepth.current = depth;
    setStateDepth(depth);
  };

  const checkInternetConnectivity = () => {
    BridgeUtils.checkInternetConnectivity((checkInternetPermission) => {
      setIsInternetAvailable(checkInternetPermission);
    });
  };
  const observeAppLifeCycle = () => {
    BridgeUtils.observeAppLifeCycle((appStatus) => {
      console.log('observeAppLifeCycle callBck called with - ', appStatus);
      setAppLifeCycle(appStatus);
    });
  };

  const handleBackClickApp = () => {
    console.log(refStateDepth, 'refStateDepth');
    if (refStateDepth.current > 0) {
      switch (refStateDepth.current) {
        case 1: {
          onStateDepthChange(0);
          return false;
        }
        default: {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  return (
    <ParentContext.Provider
      value={{
        appLifeCycle,
        isInternetAvailable,
        onStateDepthChange,
        stateDepth,
      }}
    >
      {children}
    </ParentContext.Provider>
  );
};

export const useGameContainerContext = () => {
  return useContext(ParentContext);
};
