import React, { useState, useEffect, useRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { adjustImage } from '../utils';

function Image({ src, alt }) {
    const [imageError, setImageError] = useState(false);
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");

    const onImageLoad = () => {
        const imageNode = document.getElementById("option-image");
        let newimgwidth, newimgheight;
        [newimgwidth, newimgheight] = adjustImage(
            imageNode.height,
            imageNode.width,
            300,
            window.innerWidth - 120
        );
        setHeight(newimgheight);
        setWidth(newimgwidth);
    };

    const style = {
        display: !src || imageError ? "none" : "block",
    }
    // console.log({ src })

    return (
        <LazyLoadImage id="option-image" src={src} width={width} height={height} className="image" alt={alt || "hint"} style={style} onLoad={() => onImageLoad()} onError={() => setImageError(true)} />
    )
}

export default Image;
