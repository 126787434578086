import React, { useState, useEffect } from 'react';
import SplitterLayout from 'react-splitter-layout';
// import GameContainer from '../games/GameContainer';
import MetadataGenerator from './MetadataGenerator';
// import CONST from '../utils/Constants';
import EditorTemplateTypes from './../../utils/EditorTemplateTypes';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import Game from "./../../gameContainer";
import 'react-splitter-layout/lib/index.css';
import './styles/EditorContainer.scss';

const defaultQuestionData = {
  id: '',
  template: '',
  container: {
    evaluate: true,
    metadata: {},
    answers: {},
    editor_context: {},
  },
};

function EditorContainer({
  data,
  templateType,
  updateRawData,
}) {
  const [type, setType] = useState('');
  const [questionData, setQuestionData] = useState({});
  const [editorData, setEditorData] = useState({});
  const [storyData, setStoryData] = useState({});
  useEffect(() => {
    setType(templateType);
    if (templateType === "read_aloud_mcq") {
      const qData = data || defaultQuestionData;
      qData.template = templateType;
      setQuestionData(qData);
      setEditorData(qData?.container?.metadata);
    } else {
      data.template = templateType;
      setEditorData(data.context)
      setStoryData(data);
    }
  }, [templateType, data]);

  const updateQuestionData = (metadata) => {
    setEditorData(metadata);
    if (templateType === "read_aloud_mcq") {
      setQuestionData((prevData) => {
        let updateMetaData = _cloneDeep(prevData);
        updateMetaData['container']['metadata'] = metadata;
        return updateMetaData;
      });
    } else {
      setStoryData((prevData) => {
        let updateMetaData = _cloneDeep(prevData);
        updateMetaData.context = { ...metadata }
        return updateMetaData;
      })
    }
  };

  const getEditorRawQuestionData = () => {
    if (templateType === "read_aloud_mcq") {
      if (validatQuestionData(questionData.container?.metadata, questionData.container?.answers)) {
        updateRawData &&
          updateRawData(questionData);
      }
    } else {
      const pages = _get(
        storyData,
        'context.pages',
        ''
      );
      const title = _get(
        storyData,
        'context.title',
        ''
      );
      if (validatQuestionData(storyData.context)) {
        updateRawData &&
          updateRawData({
            context: { pages: pages, title: title },
          });
      }
    }
  };

  const validatQuestionData = (metadata, answers) => {
    let str = '';
    const { disableAnswers } = EditorTemplateTypes[type];
    let TemplateInfo = _get(
      EditorTemplateTypes[type], 'editorComponents', []);
    let foundCorrectOption = false;
    TemplateInfo.forEach((obj) => {
      const key = obj.keyName;
      const label = obj.labelName;
      const minCount = _get(obj, 'content_guidelines.minCount', 1);
      const showCondition = _get(obj, 'content_guidelines.showCondition', '');
      const contentTypeConfig = _get(obj, 'content_guidelines.contentType', {});
      const name = showCondition.keyName;
      const value = metadata[name];
      let validaterFn = obj.validaterFn;
      const selectedContentTypeOptionKey = _get(
        metadata,
        [key, contentTypeConfig.key],
        null
      );
      const selectedContentTypeOption = selectedContentTypeOptionKey
        ? contentTypeConfig.options.find(
          (el) => el.key === selectedContentTypeOptionKey
        )
        : null;
      if (selectedContentTypeOption && selectedContentTypeOption.validaterFn) {
        validaterFn = selectedContentTypeOption.validaterFn;
      }
      const isTextOptionalContentOption = _get(
        selectedContentTypeOption,
        'isTextOptional',
        false
      );
      const isTextOptional = _get(obj, 'isTextOptional', false);

      const validateText = !isTextOptionalContentOption && !isTextOptional;

      if (validaterFn) {
        const errors = validaterFn({
          template: obj,
          metadata,
          answers,
          option: metadata[key],
        });
        if (errors) {
          str += errors;
        }
      }
      if (showCondition && showCondition.value !== value) {
        return;
      }
      if (
        (obj.type === 'textEditor' || obj.type === 'combinedTextEditor') &&
        validateText
      ) {
        if (
          metadata[key].context.html
            .replace(/&nbsp;/g, '')
            .replace(/(<br ?\/?>)*/g, '')
            .trim() === ''
        ) {
          str += `Please add ${label} \n`;
        }
      } else if (obj.type === 'addOptions' || obj.type === 'addOptionsV2') {
        if (metadata[key]) {
          metadata[key].forEach((option, idx) => {
            if (option.id === answers?.correct?.[0]?.id) {
              foundCorrectOption = true;
            }
            if (option.type === 'text') {
              if (
                option.context.html
                  .replace(/&nbsp;/g, '')
                  .replace(/(<br ?\/?>)*/g, '')
                  .trim() === ''
              ) {
                str += `${label} ${idx + 1} can't be empty \n`;
              }
            }
          });

          if (metadata[key].length < minCount) {
            str += `Minimum ${minCount} ${label} have to be added \n`;
          }
        } else {
          str += `Minimum ${minCount} ${label} have to be added \n`;
        }
      }
    });
    if (
      answers &&
      !disableAnswers &&
      foundCorrectOption === false &&
      !metadata['allCorrect']
    ) {
      str += `Please select answers \n`;
    }
    if (str) {
      alert(str);
    } else {
      return true;
    }
  };

  const updateAnswer = (data) => {
    try {
      if (!(data && typeof data === 'object' && data.constructor === Object)) {
        throw new Error('Parameter is not a Object');
      }
      setQuestionData((prevData) => {
        let updateMetaData = { ...prevData };
        updateMetaData['container']['answers'] = data;
        return updateMetaData;
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <SplitterLayout secondaryInitialSize={360} secondaryMinSize={360}>
      <div className={'editor-container'}>
        {type && (
          <MetadataGenerator
            type={type}
            updateQuestionData={updateQuestionData}
            metadata={{ ...editorData }}
          />
        )}
        {type && (
          <div className="submit-btn-wrapper">
            <button className="submit-btn" onClick={getEditorRawQuestionData}>
              Submit
            </button>
          </div>
        )}
      </div>
      <div className={'game-container'}>
        <Game mode="editor" templateType={templateType} storyData={{ payload: storyData }} questionData={questionData} updateAnswer={updateAnswer} />
      </div>
    </SplitterLayout>
  );
}

export default EditorContainer;
