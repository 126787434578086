import React from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import './RubricPayload.css';
import FormHelperText from "@material-ui/core/FormHelperText";
import _clonedeep from "lodash/cloneDeep"
function RubricPayload(props) {
    const SerializedSendingPayload = data => {
        let clonedData = _clonedeep(data)
        let multipliers = clonedData.multipliers
        let scores = clonedData.scores
        Object.keys(multipliers).forEach(item => {
            multipliers[item] = parseFloat(multipliers[item])
        })
        scores.forEach(item => {
            item.score = parseFloat(item.score)
            item.weightage.forEach((weightage,id) => {
                item.weightage[id] = parseFloat(weightage)
            })
        })
        return clonedData
    }
    const { onChange, value, name, errors } = props
    const onSubmit = (values) => {
       let serializedData= SerializedSendingPayload(values)
        onChange({ target: { value: serializedData, name } });
    }

    const payloadSchema = Object.keys(value).length > 0 ? value : {
        multipliers: {
            l1: "",
            l2: "",
            l3: ""
        },
        scores: [
            { count: 1, score: '', weightage: [null] },
            { count: 2, score: '', weightage: [null, null] },
            { count: 3, score: '', weightage: [null, null, null] }
        ]
    }

    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                ...arrayMutators
            }}
            initialValues={payloadSchema}
            render={({
                handleSubmit,
                form: {
                    mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
            }) => {
                return (
                    <form onChange={handleSubmit}>
                        <React.Fragment>
                            <Field name="multipliers">
                                {({ input, meta, name }) => (
                                    <div key={name} className="multiWrapper">
                                        <label className="textLevel">Multipliers</label>
                                        <div className="contentsWrapper">
                                            <label className="textLevel">L1
                                            <Field
                                                    className="textbox"
                                                    name={`${input.name}.l1`}
                                                    component="input"
                                                    placeholder="l1"
                                                    type="number"
                                                />
                                            </label>
                                            <label className="textLevel">L2
                                            <Field
                                                    className="textbox"
                                                    name={`${input.name}.l2`}
                                                    component="input"
                                                    placeholder="l2"
                                                    type="number"
                                                />
                                            </label>
                                            <label className="textLevel">L3
                                            <Field
                                                    className="textbox"
                                                    name={`${input.name}.l3`}
                                                    component="input"
                                                    placeholder="l3"
                                                    type="number"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </Field>
                            <hr></hr>
                            <div className="contentsWrapper marginTop20">
                                <label className="textLevel">Scores</label>
                                <FieldArray name="scores">
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <>
                                                <div key={name}>
                                                    <label className="textLevel">Count
                                                    <Field
                                                            name={`${name}.count`}
                                                            component="input"
                                                            placeholder={`count${index + 1}`}
                                                            disabled
                                                        />
                                                    </label>
                                                    <label className="textLevel">Score
                                                    <Field
                                                            name={`${name}.score`}
                                                            component="input"
                                                            placeholder="score"
                                                            type="number"
                                                        />
                                                    </label>
                                                    <FieldArray name={`${name}.weightage`}>
                                                        {({ fields }) =>
                                                            fields.map((name, index) => (
                                                                <>
                                                                    <div key={name}>
                                                                        <label className="textLevel">Skill. #{index + 1}
                                                                            <Field
                                                                                name={`${name}`}
                                                                                component="input"
                                                                                placeholder="weightage"
                                                                                type="number"
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            ))}
                                                    </FieldArray>
                                                </div>
                                            </>
                                        ))}
                                </FieldArray>
                            </div>
                            <hr></hr>
                            {errors && <FormHelperText error> {JSON.stringify(errors)} </FormHelperText>}
                        </React.Fragment>
                    </form>
                );
            }}
        />
    );
}

export default RubricPayload;
