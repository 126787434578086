import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import compose from "recompose/compose";
import { connect } from "react-redux";

import { selectSchema, selectRootAbilities } from "../Redux/SchemaRedux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";

import TitleBar from "../Components/TitleBar";
import Loading from "../Components/Loading";
import SearchInput from "../Components/SearchInput";

import AddIcon from "@material-ui/icons/Add";
import ImportIcon from "@material-ui/icons/ArrowDownward";
import ListIcon from "@material-ui/icons/List";

const styles = theme => ({
  container: {
    width: "100%"
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  }
});

class SchemaBrowserPage extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: "" };
  }

  componentDidMount() {
    document.title = 'CMS :: Resources';
  }

  searchFilter = schema => {
    if (this.state.searchText.length === 0) {
      return true;
    }

    return schema.label.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0;
  };

  render() {
    const { schemaList, prefix = "", classes, disableEditor, rootAbilities } = this.props;

    if (!schemaList) return <Loading center />;

    return (
      <React.Fragment>
        <TitleBar
          title="Resources"
          subtitle={
            <SearchInput
              value={this.state.searchText}
              onChange={event => {
                this.setState({ searchText: event.target.value });
              }}
            />
          }
        />
        <Table className={classes.table} size="small">
          <TableBody>
            {schemaList.filter(this.searchFilter).map(schema => {
              return (
                <TableRow key={schema.name}>
                  <TableCell>{schema.label}</TableCell>
                  <TableCell align="right">
                    <Button className={ classes.btn } color="primary" component={Link} to={`${prefix}/resources/${schema.name}`}>
                      <ListIcon className={ classes.leftIcon }/> List
                    </Button>
                  </TableCell>
                  {!disableEditor && (
                  [<TableCell key='add' align="right">
                      { rootAbilities.resource_create[schema.name] && <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        className={ classes.btn }
                        to={`${prefix}/resources/${schema.name}/new`}
                      >
                        <AddIcon className={ classes.leftIcon }/> Create
                      </Button> }
                  </TableCell>,
                  <TableCell key='import' align="right">
                      { rootAbilities.resource_import[schema.name] && <Button
                        color="primary"
                         className={ classes.btn }
                        component={Link}
                        to={`${prefix}/resources/${schema.name}/import`}
                      >
                        <ImportIcon className={ classes.leftIcon }/> Import
                      </Button> }
                  </TableCell>
                  ])} 
                </TableRow> 
              );
            })}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = (state, ownProps) => {
  return {
    schemaList: selectSchema(state),
    rootAbilities: selectRootAbilities(state)
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SchemaBrowserPage);
