import React from 'react';
import Loadable from 'react-loadable';
import CONST from "../utils/Constants";
import moize from 'moize'

const getLoadableComponent = (componentType, mode, children) => {
  return Loadable({
    render: (loaded, props) => {
      const Component = loaded.default;
      return (
        <>
          {children}
          <Component {...props} />
        </>
      );
    },
    loader: () => import(`./${componentType}/${mode}`),
    loading(props) {
      if (props.error) {
        return <div>Oops Something went wrong</div>;
      }
      return null;
    },
    delay: 100,
  });
};


const getMoizedLoadableComponent = moize((componentType, mode) => {
    return Loadable({
      loader: () => import(`./${componentType}/${mode}`),
      loading(props) {
        if (props.error) {
          return <div>Oops Something went wrong</div>;
        }
        return null;
      },
      delay: 0
    });
})

const loadComponent = (type, mode, children) => mode === CONST.MODE.EDITOR && type !== 'drag' ? getMoizedLoadableComponent(type, mode) : getLoadableComponent(type, mode, children)

export default ({ type, mode, children, ...rest }) => {
  const LoadableComponent = loadComponent(type, mode, children);
  return <LoadableComponent type={type} {...rest} />;
};