import { getCleanedMigratedData } from './index';
// import DumData from '../utils/DummyData';
// import {
// dummySuccessCallback,
// dummyTTSCallback,
// dummyFn,
// dummyObserveAppLifeCycle
//  }
// from './dummyBridgeHelpers';

let dsBridge = require('dsbridge');

export default class BridgeUtils {
  static getResourceData = (resourceType) => {
    let resData = dsBridge.call('getResourceData');
    let data = resData ? JSON.parse(resData) : {};

    // let data = DumData['enunciate_v2'];
    // console.log(data);

    return getCleanedMigratedData(data, resourceType);
  };

  static getConfigData = () => {
    let resData = dsBridge.call('getConfig');
    try {
      const response = JSON.parse(resData);
      return response;
    } catch (e) {
      return {};
    }
  };

  static sendEvaluationData = (obj) => {
    console.log('sendEvaluationData', JSON.stringify(obj));
    dsBridge.call('sendEvaluationData', obj);
  };

  static sendUiEvent = (value, data = null) => {
    console.log('close webview');
    let obj = {
      id: value,
      data: data,
    };
    dsBridge.call('sendUiEvent', obj);
  };

  static sendAnalyticsEvent = (obj) => {
    console.log('sendOlap', obj);
    dsBridge.call('sendAnalyticsEvent', obj);
  };

  static startAudio = (value, callback) => {
    console.log('Start Audio URL : ', value);
    let obj = {
      url: value,
    };
    //dummySuccessCallback(callback);
    dsBridge.call('startAudio', obj, callback);
  };

  static stopAudio = () => {
    console.log('stop Audio....');
    dsBridge.call('stopAudio');
  };

  static showDictionary = (word, context, callback) => {
    const obj = {
      word: word,
      context: context,
    };
    console.log(obj);
    dsBridge.call('showDictionary', obj, callback);
  };

  static adjustHeight = (height) => {
    console.log('adjustHeight', height);
    dsBridge.call('adjustHeight', { height: height });
  };

  static startListening = () => {
    dsBridge.call('startListening');
  };

  static startListeningWithPermission = (callback) => {
    dsBridge.call('startListeningWithPermission', callback);
  };

  static stopListening = () => {
    dsBridge.call('stopListening');
  };

  static speechToText = (callback) => {
    // dummyTTSCallback(callback);
    dsBridge.register('speechToText', callback);
  };

  static startPronunciationAnalysis = (refText) => {
    dsBridge.call('startPronunciationAnalysis', refText);
  };
  static startPronunciationAnalysisWithPermission = (refText, callback) => {
    dsBridge.call(
      'startPronunciationAnalysisWithPermission',
      refText,
      callback
    );
  };

  static stopPronunciationAnalysis = (callback) => {
    // dummyFn(callback);
    dsBridge.call('stopPronunciationAnalysis', null, callback);
  };

  static observeAppLifeCycle = (callback) => {
    //dummyObserveAppLifeCycle(callback);
    dsBridge.register('observeAppLifeCycle', callback);
  };

  static checkInternetConnectivity = (callback) => {
    dsBridge.register('checkInternetConnectivity', callback);
  };

  static onBackClick = (fn) => {
    dsBridge.register('onBackPressed', fn);
  };
}
