import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router';
import { history } from "./Redux/createStore";
import { AppContainer } from "react-hot-loader";
import Routes from "./Routes";
import Theme from "./Theme";
import store from "./store";

import { register as registerServiceWorker } from "./serviceWorker";



registerServiceWorker({
	onUpdate: (registration) => store.dispatch({ type: "APP_UPDATE", registration })
});

const App = () => {
	return (
		<Provider store={store}>
			<AppContainer>
				<ConnectedRouter history={history}>
					<Theme>
						<Routes />
					</Theme>
				</ConnectedRouter>
			</AppContainer>
		</Provider>
	);
};

export default App;