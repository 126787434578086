import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import loadscript from "load-script";
import AppConfig from "../Config/AppConfig";
import { Creators as SessionCreators, selectSessionState } from "../Redux/SessionRedux";

import compose from "recompose/compose";
import { connect } from "react-redux";

import Loading from "../Components/Loading";

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    width: 300,
    margin: "auto",
    marginTop: theme.spacing(3)
  }),
  spacedDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  textField: {
    width: "100%"
  }
});

var cInst;
window.gpAsyncInit = function() {
  cInst && cInst.setState({ gpLoaded: true });
};

const SpacedDivider = withStyles(styles)(({ classes }) => (
  <div className={classes.spacedDivider}>
    <Divider />
  </div>
));

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
  }

  componentDidMount() {
    loadscript("https://apis.google.com/js/client:plus.js?onload=gpAsyncInit");
    cInst = this;
  }

  componentWillUnmount() {
    cInst = null;
  }

  onLoginClick = () => {
    window.gapi.auth.authorize(
      {
        immediate: false,
        response_type: "code",
        cookie_policy: "single_host_origin",
        client_id: AppConfig.GOOGLE_CLIENT_ID,
        scope: "email profile"
      },
      this.loginCallback
    );
  };

  loginCallback = response => {
    if (response && !response.error) {
      let responseCopy = { ...response };
      delete responseCopy["g-oauth-window"];
      this.props.createSession(responseCopy);
    } else {
      // google authentication failed
      console.error(response.error);
      this.setState({ gpLoginError: response.error })
    }
  };

  onLoginEmailPassClick = () => {
    const { email, password } = this.state;
    this.props.createSession({ email, password, password_login: true });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes, sessionRequests } = this.props;
    const { gpLoaded, gpLoginError } = this.state;
    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography variant="h3">
            Login
          </Typography>
          <SpacedDivider />
          <Grid container justify="center" direction="column" alignItems="center" spacing={16}>
            <Grid item>
              <Button
                variant="contained"
                disabled={!gpLoaded}
                color="primary"
                onClick={this.onLoginClick}
              >
                Login via Google
              </Button>

              { gpLoginError &&
                <Typography component="p" color="secondary">Oops. Try again?</Typography>
              }
            </Grid>
            {/* <Grid item>
              <Typography>Or</Typography>
            </Grid>
            <Grid item>
              <TextField
                className={classes.textField}
                label="Email"
                name="email"
                value={this.state.email}
                onChange={this.onChange}
              />
              <TextField
                className={classes.textField}
                type="password"
                label="Password"
                name="password"
                value={this.state.password}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onLoginEmailPassClick}
                disabled={this.state.email.length === 0 || this.state.password.length === 0}
              >
                Login
              </Button>
            </Grid> */}
          </Grid>
          {sessionRequests.fetching && <Loading center />}
          {sessionRequests.error && (
            <Typography component="p" color="secondary">Could not login with given credentials.</Typography>
          )}
        </Paper>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createSession: p => dispatch(SessionCreators.request(p))
});

const mapStateToProps = (state, ownProps) => {
  return {
    sessionRequests: selectSessionState(state)
  };
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginPage);