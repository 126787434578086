import { put, call } from "redux-saga/effects";

export function* logoutSaga(entity, setHeader, logoutUser, data) {
    yield put({ type: 'ACTION_CABLE_DISCONNECT' })
    

	const response = yield call(logoutUser, data);
	if (response.ok || response.status === 401) {
		//already logged out :D
		yield put(entity.success(response.data));
		setHeader("Authorization", "");
	} else {
		yield put(entity.failure(response.data));
	}
}
