// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    request: ["params"],
    success: ["params", "data", "entities", "meta"],
    failure: ["params", "error"]
  },
  { prefix: "FILES_LIST_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
  return state.merge({ listing: true, error: null });
};

const success = (state: Object, { data, meta }: Object) => {
  if (meta.pagination.current_page === 1)
    return state.merge({ listing: false, data: data.files, error: null, meta });
  else
    return state.merge({
      listing: false,
      data: state.data.concat(data.files),
      error: null,
      meta
    });
};

const failure = (state: Object, { error }: Object) => {
  return state.merge({ listing: false, error });
};

export default {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure
};
