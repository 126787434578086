import React from "react";

import compose from "recompose/compose";
import { connect } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from "@material-ui/core/ListItemText";
import UserAvatar from "./UserAvatar"

import {
	selectActionCableConnectionState,
	selectActionCableActiveUsers
} from "../Redux/ActionCableRedux";

const OnlineUsersList = ({
	currentUser,
	connected,
	iconClassName,
	activeUsers
}) => (
	<List>
	{ activeUsers.filter(user => user.id !== currentUser.id).map(user => (
		<ListItem dense key={ user.id }>
			<ListItemAvatar>
				<UserAvatar user={ user } tooltip={ false }/>
			</ListItemAvatar>
			<ListItemText
			primary={ user.name }
			secondary={ (user.roles || []).map(x => x.underscoreToLabel()).join(", ") }
			/>
		</ListItem>
	))}
	</List>
);

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = (state, ownProps) => {
	return {
		connected: selectActionCableConnectionState(state),
		activeUsers: selectActionCableActiveUsers(state)
	};
};

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(OnlineUsersList);
