import React from 'react';
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import style from "./Skillmap.module.css";
import FormHelperText from "@material-ui/core/FormHelperText";
import _clonedeep from "lodash/cloneDeep"

function Skillmap(props) {
    const { spec: { choices }, onChange, value, errors } = props
    // const { has_many } = columns[3]

    // const config = has_many.columns
    // const choice = config[0].choices
    const skillMap = choices
    // const data = {}
    const { name } = props

    const onSubmit = (values) => {
        console.log("Values", values)
        let serializedData= serializeSendingData(values)
        onChange({ target: { value: serializedData, name } });
    }
    const serializeSendingData = data =>{
        let label= ['primary_skill', 'secondary_skill', 'tertiary_skill']
        let clonedData = _clonedeep(data)
        clonedData.number_of_skills_tested=parseInt(clonedData.number_of_skills_tested)
        for(let i =clonedData.number_of_skills_tested;i<label.length;i++)
        {
            delete clonedData[label[i]]
        }
        return clonedData
    }
    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                ...arrayMutators
            }}
            initialValues={value}
            render={({
                handleSubmit,
                form: {
                    mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
            }) => {
                return (
                    <form className={style.queryform} onChange={handleSubmit}>
                        <div className={style.fieldWrapper}>
                            <label className={style.textLevel}>Level of Difficulty</label>
                            <Field
                                name='level_of_difficulty'
                                component='select'
                                className="optionWrapper"
                            >
                                <option>Select the Level</option>
                                <option value='l1'>L1</option>
                                <option value='l2'>L2</option>
                                <option value='l3'>L3</option>
                            </Field>
                        </div>
                        <div className={style.fieldWrapper}>
                            <label className={style.textLevel}>Number of Skill Tested</label>
                            <Field
                                name='number_of_skills_tested'
                                component='select'
                                className={style.optionWrapper}
                            >
                                <option>Select the Level</option>
                                <option value='1'>One</option>
                                <option value='2'>Two</option>
                                <option value='3'>Three</option>
                            </Field>
                        </div>
                        <div style={{ display: "flex" }}>
                            {parseInt(values.number_of_skills_tested) === 1 && <ComponentA config={{ skillMap, label: ['primary'] }} />}
                            {parseInt(values.number_of_skills_tested) === 2 && <ComponentA config={{ skillMap, label: ['primary', 'secondary'] }} />}
                            {parseInt(values.number_of_skills_tested) === 3 && <ComponentA config={{ skillMap, label: ['primary', 'secondary', 'tertiary'] }} />}
                        </div>
                        {errors && <FormHelperText error> {JSON.stringify(errors)} </FormHelperText>}
                    </form>
                );
            }} />
    )
}
export default Skillmap;
const ComponentA = (props) => {
    const { label, skillMap } = props.config
    return (
        <>
            {label.map(i => {
                return (
                    <div className={style.fieldWrapper}>
                        <label className={style.textLevel}>{`${i} skill`}.</label>
                        <Field
                            className={style.optionWrapper}
                            name={`${i}_skill`}
                            component='select' >
                            <option>Select the Skills</option>
                            {skillMap.map(item => {
                                return <option value={item}>{item}</option>
                            })}
                        </Field>
                    </div>
                )
            })}
        </>
    )

}


