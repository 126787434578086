import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import compose from "recompose/compose";
import _get from "lodash/get";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ProgressButton from "./ProgressButton";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";

import VideoDurationInput from "./VideoDurationInput";
import ArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import WarningIcon from "@material-ui/icons/Error";
import { Link } from "react-router-dom";

const ResourceInfoPane = ({
    classes,
    pane,
    onPaneToggle,
    children,
    collapse,
    buttons
}) => (
    <Grid key={pane} className={classes.inlines}>
        <Typography variant="h5" className={classes.inlineTitle}>
            <Grid container justify="space-between">
                <Grid item>
                    <IconButton
                        onClick={onPaneToggle}
                        className={
                            collapse ? classes.collapsed : classes.uncollapsed
                        }
                    >
                        <ArrowIcon />
                    </IconButton>{" "}
                    {pane.underscoreToLabel()}
                </Grid>
                <Grid item>{buttons}</Grid>
            </Grid>
        </Typography>
        {!collapse && <div className={classes.infoPaneContent}>{children}</div>}
    </Grid>
);

class ResourceInfoPanes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paneToggle: {}
        };
    }

    onPaneToggle = pane => {
        let paneToggle = this.state.paneToggle || {};
        paneToggle[pane] = !(paneToggle[pane] || false);
        this.setState({ paneToggle });
    };

    renderPane(pane, paneData) {
        const {
            classes,
            actionRequests = {},
            abilities = {},
            isDirty
        } = this.props;
        if (!paneData) return "";

        const collapse = _get(this.state.paneToggle, pane, false);

        if (pane === "related_resources") {
            if (!paneData) return null;

            return (
                <ResourceInfoPane
                    pane={pane}
                    key={pane}
                    collapse={collapse}
                    classes={classes}
                    onPaneToggle={this.onPaneToggle.bind(this, pane)}
                >
                    <div>
                        {Object.keys(paneData).map(resource_type => {
                            const resources = paneData[resource_type];
                            if (resources.length === 0) return null;

                            return (
                                <div key={resource_type}>
                                    <Typography
                                        variant="h6"
                                        className={classes.resourceTitle}
                                    >
                                        {resource_type.underscoreToLabel()}
                                    </Typography>
                                    {(resources || []).map(resource => {
                                        return (
                                            <Chip
                                                icon={
                                                    resource.is_obsolete ? (
                                                        <WarningIcon />
                                                    ) : null
                                                }
                                                key={resource.id}
                                                className={classes.chip}
                                                color={
                                                    resource.is_obsolete
                                                        ? "secondary"
                                                        : "default"
                                                }
                                                clickable
                                                component={Link}
                                                to={`/resources/${resource_type}/${
                                                    resource.id
                                                }`}
                                                label={resource.nickname}
                                                variant="outlined"
                                            />
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </ResourceInfoPane>
            );
        } else if (
            pane === "interactive_video_questions" ||
            pane === "game_questions" ||
            pane === "worksheet_questions" ||
            pane === "game_map_totems"
        ) {
            return (
                <ResourceInfoPane
                    pane={pane}
                    key={pane}
                    collapse={collapse}
                    classes={classes}
                    onPaneToggle={this.onPaneToggle.bind(this, pane)}
                >
                    {paneData.map(p => (
                        <Chip
                            key={p}
                            icon={!p ? <WarningIcon /> : null}
                            color={!p ? "secondary" : "default"}
                            className={classes.chip}
                            label={p || <i>Not entered!</i>}
                            variant="outlined"
                        />
                    ))}
                </ResourceInfoPane>
            );
        } else if (pane === "interactive_video_pause_points") {
            return (
                <ResourceInfoPane
                    pane={pane}
                    key={pane}
                    collapse={collapse}
                    classes={classes}
                    onPaneToggle={this.onPaneToggle.bind(this, pane)}
                >
                    {paneData.map(p => (
                        <TextField
                            key={p}
                            name={""}
                            disabled={true}
                            className={classes.textFieldInline}
                            InputProps={{ inputComponent: VideoDurationInput }}
                            value={p * 1000}
                            label={""}
                            type="text"
                        />
                    ))}
                </ResourceInfoPane>
            );
        } else if (paneData && paneData.type === "packager") {
            const workflow = paneData.package;
            const isCancelled = workflow && workflow['done_state'] && workflow['done_state'].includes('cancel')
            const inProgress =
                (workflow && !workflow.is_done && !isCancelled) ||
                _get(
                    actionRequests,
                    paneData.packager_workflow + ".requesting"
                ) ||
                _get(
                    actionRequests,
                    paneData.repackager_workflow + ".requesting"
                );

            let error =
                _get(actionRequests, paneData.packager_workflow + ".error") ||
                _get(actionRequests, paneData.repackager_workflow + ".error");

            if (error) {
                if (error.action_result && error.action_result.error) {
                    error = error.action_result.error;
                } else {
                    error = JSON.stringify(error);
                }
            }

            return (
                <ResourceInfoPane
                    pane={pane}
                    key={pane}
                    collapse={collapse}
                    classes={classes}
                    onPaneToggle={this.onPaneToggle.bind(this, pane)}
                    buttons={[
                        abilities["action_" + paneData.packager_workflow] && (
                            <ProgressButton
                                color="secondary"
                                variant="contained"
                                key="package_button"
                                disabled={
                                    (!!workflow &&
                                        !abilities[
                                            "action_" +
                                                paneData.repackager_workflow
                                        ]) ||
                                    isDirty
                                }
                                error={error}
                                inProgress={!!inProgress}
                                onClick={
                                    workflow &&
                                    workflow.is_done &&
                                    (workflow.done_state === "success" || workflow.done_state === "failed") &&
                                    abilities[
                                        "action_" + paneData.repackager_workflow
                                    ]
                                        ? this.triggerAction.bind(
                                              this,
                                              paneData.repackager_workflow
                                          )
                                        : this.triggerAction.bind(
                                              this,
                                              paneData.packager_workflow
                                          )
                                }
                            >
                                {!inProgress && isCancelled && 'Package'}
                                {!workflow &&
                                    !inProgress &&
                                    !isDirty &&
                                    "Package"}
                                {inProgress && "Packaging ..."}
                                {workflow &&
                                    workflow.is_done &&
                                    workflow.done_state === "success" &&
                                    (abilities[
                                        "action_" + paneData.repackager_workflow
                                    ]
                                        ? "RePackage"
                                        : "Packaged")}
                                {workflow &&
                                    workflow.is_done &&
                                    workflow.done_state === "failed" &&
                                    (abilities[
                                        "action_" + paneData.repackager_workflow
                                    ]
                                        ? "RePackage"
                                        : "Package Failed!")}
                                {isDirty && " ... Save first"}
                            </ProgressButton>
                        )
                    ]}
                >
                    {workflow &&
                        workflow.is_done &&
                        workflow.done_state === "failed" &&
                        paneData.package.debug_log &&
                        this.renderTempDebugLog(paneData.package.debug_log)}
                    <div>
                        {Object.keys(paneData.packaged_attributes).map(
                            param => {
                                const paramValue =
                                    paneData.packaged_attributes[param];
                                var inputComponent;
                                if (param === "duration_ms") {
                                    inputComponent = VideoDurationInput;
                                }
                                return (
                                    <TextField
                                        key={param}
                                        name={param}
                                        disabled={true}
                                        className={classes.textField}
                                        InputProps={{ inputComponent }}
                                        value={paramValue || ""}
                                        label={param.underscoreToLabel()}
                                        type="text"
                                    />
                                );
                            }
                        )}
                    </div>
                </ResourceInfoPane>
            );
        }

        return (
            <ResourceInfoPane
                pane={pane}
                key={pane}
                collapse={collapse}
                classes={classes}
                onPaneToggle={this.onPaneToggle.bind(this, pane)}
            >
                <pre>{JSON.stringify(paneData, null, 2)}</pre>
            </ResourceInfoPane>
        );
    }

    renderTempDebugLog = log => {
        const { classes } = this.props;

        let parse;
        try {
            parse = JSON.parse(log);
        } catch (e) {
            return (
                <div className={classes.inlines}>
                    <pre>{log}</pre>
                </div>
            );
        }

        return (
            <div className={classes.inlines}>
                {parse.ios_log && (
                    <Button
                        color="secondary"
                        target="_blank"
                        component={"a"}
                        href={parse.ios_log.replace(
                            "s3://",
                            "https://s3.ap-southeast-1.amazonaws.com/"
                        )}
                    >
                        iOS Log
                    </Button>
                )}{" "}
                {parse.android_log && (
                    <Button
                        color="secondary"
                        target="_blank"
                        component={"a"}
                        href={parse.android_log.replace(
                            "s3://",
                            "https://s3.ap-southeast-1.amazonaws.com/"
                        )}
                    >
                        Android Log
                    </Button>
                )}
            </div>
        );
    };

    triggerAction = action => {
        this.props.doActionOnResource(action);
    };

    render() {
        const { schema, resource } = this.props;

        return schema.info_panes.map(pane =>
            this.renderPane(pane, resource[pane])
        );
    }
}

const styles = theme => ({
    collapsed: {
        transform: "rotate(-90deg)",
        transition: "transform 0.5s"
    },
    uncollapsed: {
        transition: "transform 0.5s"
    },
    textField: {
        margin: theme.spacing(1),
        width: "calc(100% - " + theme.spacing(1) + "px)"
    },
    textFieldInline: {
        margin: theme.spacing(1)
    },
    inlines: {
        padding: theme.spacing(2)
    },
    inlineTitle: {
        marginBottom: theme.spacing(1)
    },
    infoPaneContent: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(6)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
});

export default compose(withStyles(styles))(ResourceInfoPanes);
