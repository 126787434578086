import React, { useState } from 'react'
import "./headerWrapper.scss";
import useDidMountEffect from "./../../../utilComponents/hooks/useDidMountEffect";
import styled from 'styled-components';
import { useGameContext } from "./../../GameContext.jsx";
import HeaderMain from './headerMain';
import ProgressBar from './progressBar';



const Wrapper = styled.div`
  box-shadow: inset 0 -2px 2px 0 rgba(255, 255, 255, 0.31), 0 4px 6px 0 ${({ theme }) => theme.light3};
  background-image: linear-gradient(104deg, ${({ theme }) => theme.light2} 10%, ${({ theme }) => theme.primaryColor} 99%);
`;

const Trail = styled.div`
  background-color: ${({ theme }) => theme.light3};
`;

function HeaderWrapper({ hideProgressBar = false, hideScore = false, showMic = true }) {
    const [microphone, setMicrophone] = useState(true);
    const { closeGame, changeMicStatus, mode, setStarted, currentPage } = useGameContext();

    const onBack = () => {
        if (mode === "editor") {
            setStarted(false);
        } else {
            closeGame();
        }
    }

    useDidMountEffect(() => {
        changeMicStatus(microphone ? "on" : "off");
    }, [microphone]);

    return (
        <Wrapper className="header-wrapper">
            <HeaderMain onBack={onBack} showMic={showMic} hideScore={hideScore} micStatus={microphone} setMicStatus={setMicrophone} showTitle />
            {!hideProgressBar && <ProgressBar currentPage={currentPage} />}
        </Wrapper>
    )
}

export default HeaderWrapper;
