import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import compose from "recompose/compose";
import { connect } from "react-redux";

import { selectSchemaByName } from "../Redux/SchemaRedux";

import {
    ListCreators as ResourceListCreators,
    selectResources,
    selectResourcesRequestState
} from "../Redux/ResourcesRedux";
import {
    SearchCreators as ResourceSearchCreators,
    selectSearchResults
} from "../Redux/ResourcesRedux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import Loading from "../Components/Loading";
import TitleBar from "../Components/TitleBar";
import SearchInput from "../Components/SearchInput";
import Pagination from "../Components/Pagination";

import classnames from "classnames";
import { withRouter } from "react-router";
import _get from "lodash/get";
import qs from "qs";
import { Button } from "@material-ui/core";

const styles = theme => ({
    container: {
        width: "100%",
        minWidth: 600
    },
    createContainer: {
        display: "inline-flex",
        marginLeft: theme.spacing(1)
    },
    checkboxTD: {
        width: 64,
        userSelect: "none"
    },
    selectedRow: {
        background: theme.palette.primary["50"]
    },
    selectedListWrapper: {
        marginBottom: "30px"
    }
});

const SelectedList = ({ classes, listData, renderCheckbox, confirmSelectionButton }) => {
    const numSelected = listData.length;
    return <div className={classes.selectedListWrapper}>
        <Table size="small" className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell>
                        {numSelected > 0 &&
                            <span>
                                Selected Rows ({numSelected})
                        </span>
                        }
                    </TableCell>
                    <TableCell>
                        {confirmSelectionButton(listData)}
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {listData.map(r => (
                    <TableRow
                        key={r.id}
                    >
                        <TableCell
                            padding="checkbox"
                            classes={{
                                root: classes.checkboxTD
                            }}
                        >
                            {renderCheckbox(r)}
                        </TableCell>
                        <TableCell>{r.nickname}</TableCell>
                        <TableCell />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </div>
}

class ResourceBrowser extends Component {
    constructor(props) {
        super(props);
        this.state = { selected: [], q: props.searchInitialValue };
    }
    componentDidMount() {
        this.listResources(this.props);
    }

    getSearchState = (props) => {
        const { location, stateProvider } = props || this.props;
        if (stateProvider === "location") {
            const queryParams = qs.parse(location.search.substr(1));
            return queryParams;
        }
        else {
            return this.state;
        }
    }

    setSearchState = (params) => {
        const { location, stateProvider, history } = this.props;
        const queryParams = qs.parse(location.search.substr(1));

        const newParams = { ...queryParams, ...params };
        if (newParams.page === 1) {
            newParams.page = null;
        }
        if (!newParams.q || newParams.q.length === 0) {
            newParams.q = null;
        }

        if (stateProvider === "location") {
            if (newParams.q === null) {
                delete newParams.q;
            }

            if (newParams.page === null) {
                delete newParams.page;
            }

            history.replace(`?${qs.stringify(newParams)}`)
        }
        else {
            this.setState(newParams, () => {
                this.listResources(this.props);
            });
        }
    }

    listResources = props => {
        const { resourceName } = props;
        const searchState = this.getSearchState(props);

        if (searchState.q) {
            const params = {
                page: {
                    number: searchState.page || 1
                },
                resource_name: resourceName,
                query: searchState.q
            };
            this.props.searchResources(params);
        }
        else {
            const params = {
                page: {
                    number: searchState.page || 1,
                }
            };
            this.props.listResources(resourceName, params);
        }
    };

    handleSearch = event => {
        this.setSearchState({ q: event.target.value, page: 1 })

        if (this.state.searchError) {
            this.setState({ searchError: null })
        }
    };

    componentWillReceiveProps(nextProps) {
        if (
            this.props.resourcesRequests.searching &&
            !nextProps.resourcesRequests.searching
        ) {
            if (nextProps.resourcesRequests.error) {
                this.setState({ searchError: true });
                return;
            }

            const { schema } = nextProps;
            if (schema.has_studio && window.location.hash === "#auto-open-studio") {
                const { resourcesSearch = [] } = nextProps;

                if (resourcesSearch.length === 1) {
                    const resource_id = resourcesSearch[0].id;
                    this.props.history.replace(`/resources/${schema.name}/${resource_id}/studio`);
                }
            }

            if (window.location.hash === "#auto-open") {
                const { resourcesSearch = [] } = nextProps;
                if (resourcesSearch.length === 1) {
                    const resource_id = resourcesSearch[0].id;
                    this.props.history.replace(`/resources/${schema.name}/${resource_id}`);
                }
            }

            if (window.location.hash.startsWith("#auto-open-issue")) {
                const { resourcesSearch = [] } = nextProps;
                if (resourcesSearch.length === 1) {
                    const resource_id = resourcesSearch[0].id;
                    this.props.history.replace(`/resources/${schema.name}/${resource_id}` + window.location.hash);
                }
            }
        }

        if (this.props.location.search !== nextProps.location.search) {
            this.listResources(nextProps);
        }
    }

    onPageChange = page => {
        this.setSearchState({ page })
    };

    handleCheck = (res, event) => {
        let { selected } = this.state;
        const pos = selected.findIndex(r => r.id === res.id);

        if (pos >= 0) {
            selected.splice(pos, 1);
        } else {
            selected.push(res);
        }

        this.setState({ selected });
    };

    handleCheckAll = event => {
        var { resources, resourcesSearch } = this.props;
        const searchParams = this.getSearchState();

        if (searchParams.q) {
            resources = resourcesSearch;
        }

        const num = this.state.selected.length;
        const total = resources.length;

        if (num === total) {
            this.setState({ selected: [] });
        } else {
            this.setState({
                selected: resources.asMutable().slice(0)
            });
        }
    };

    renderCheckAllbox = (num, total) => (
        <Checkbox
            color="primary"
            name={"selectAll"}
            onChange={this.handleCheckAll}
            checked={num === total}
            indeterminate={num > 0 && num < total}
        />
    );

    renderCheckbox = r => (
        <Checkbox
            color="primary"
            name={"" + r.id}
            onChange={this.handleCheck.bind(this, r)}
            checked={this.state.selected.findIndex(res => r.id === res.id) !== -1}
        />
    );

    render() {
        let {
            schema,
            classes,
            selectButton,
            createButton,
            showCheckboxes,
            studioButton,
            resourcesRequests,
            showBreadcrumbs = true,
            prefix,
            selectionActionButtons,
            confirmSelectionButton
        } = this.props;
        const { searchError, selected } = this.state;
        const searchParams = this.getSearchState();

        const isSearch = (searchParams.q || "").length > 0;

        var { resources, resourcesSearch } = this.props;
        var pagination = _get(resourcesRequests, "meta.pagination");

        if (isSearch) {
            resources = resourcesSearch;
            pagination = _get(resourcesRequests, "searchMeta.pagination");
        }

        if (!schema) {
            console.log("NO SCHEMA!!");
            return <Loading center />;
        }

        const numSelected = selected.length;

        return (
            <Grid className={classes.container}>
                <TitleBar
                    breadcrumbs={showBreadcrumbs && [
                        { to: `${prefix}/resources`, title: "Resources" },
                        { title: schema.label },
                    ]}
                    title={!showBreadcrumbs && schema.label}
                    subtitle={
                        <div>
                            <SearchInput
                                onChange={this.handleSearch}
                                error={searchError}
                                initialValue={searchParams.q}
                                enablePaste={true}
                            />
                            {createButton && (
                                <div className={classes.createContainer}>
                                    {createButton}
                                </div>
                            )}
                        </div>
                    }
                />
                {(resourcesRequests.searching || resourcesRequests.listing) && <Loading center size={40} />}
                
                {selected.length > 0 && confirmSelectionButton ? < SelectedList classes={classes} listData={selected} renderCheckbox={this.renderCheckbox} confirmSelectionButton={confirmSelectionButton} /> : null}

                {resources &&
                    (resources.length > 0 ? (
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    {showCheckboxes && (
                                        <TableCell padding="checkbox">
                                            {this.renderCheckAllbox(
                                                numSelected,
                                                resources.length
                                            )}
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {numSelected > 0 ? (
                                            <span>
                                                {numSelected} {schema.label}{" "}
                                                selected

                                                { selectionActionButtons && selectionActionButtons(selected)}
                                            </span>
                                        ) : (
                                            "Nickname"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {pagination && <span>{pagination.total_count} {schema.label}</span>}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {resources.map(r => (
                                    <TableRow
                                        key={r.id}
                                        className={classnames({
                                            [classes.selectedRow]:
                                                selected.findIndex(res => r.id === res.id) >= 0
                                        })}
                                    >
                                        {showCheckboxes && (
                                            <TableCell
                                                padding="checkbox"
                                                classes={{
                                                    root: classes.checkboxTD
                                                }}
                                            >
                                                {this.renderCheckbox(r)}
                                            </TableCell>
                                        )}
                                        <TableCell>{r.nickname}</TableCell>
                                        <TableCell>{selectButton(r)}</TableCell>
                                        <TableCell>
                                            {studioButton &&
                                                schema.has_studio &&
                                                studioButton(r)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Typography
                            align="center"
                            color="secondary"
                            variant="caption"
                            component="p"
                        >
                            { isSearch ? "No results found" : "Nothing here"}
                        </Typography>
                    ))}
                {pagination && (
                    <Pagination
                        pagination={pagination}
                        onPageChange={this.onPageChange}
                    />
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    listResources: (r, s) => dispatch(ResourceListCreators.request(r, s)),
    searchResources: (b) => dispatch(ResourceSearchCreators.request(b))
});

const mapStateToProps = (state, ownProps) => {
    let { resourceName } = ownProps;

    return {
        schema: selectSchemaByName(state, resourceName),
        resources: selectResources(state, resourceName),
        resourcesSearch: selectSearchResults(state),
        resourcesRequests: selectResourcesRequestState(state)
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withRouter
)(ResourceBrowser);
