import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import CKEditorConfig from './CKEditorConfig';
import loadScript from 'load-script';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

var defaultScriptUrl =
  '//cdnjs.cloudflare.com/ajax/libs/ckeditor/4.6.2/ckeditor.js';

/**
 * @author codeslayer1
 * @description CKEditor component to render a CKEditor textarea with defined configs and all CKEditor events handler
 */
class CKEditor extends React.Component {
  constructor(props) {
    super(props);
    // Bindings
    this.onLoad = this.onLoad.bind(this);

    // State initialization
    this.state = {
      isScriptLoaded: this.props.isScriptLoaded,
      config: this.props.config,
    };
  }

  addDialogDefinition() {
    let { publicUrl } = this.props.config;
    window.CKEDITOR.plugins.addExternal(
      'fillintheblanks',
      `${publicUrl}/ckeditor/plugins/fillintheblanks/plugin.js`
    );
    window.CKEDITOR.plugins.addExternal(
      'audioBreak',
      `${publicUrl}/ckeditor/plugins/audio/plugin.js`
    );
    window.CKEDITOR.plugins.addExternal(
      'audioPhoneme',
      `${publicUrl}/ckeditor/plugins/audio/plugin.js`
    );
    window.CKEDITOR.plugins.addExternal(
      'audioProsody',
      `${publicUrl}/ckeditor/plugins/audio/plugin.js`
    );
    window.CKEDITOR.plugins.addExternal(
      'wordcount',
      `${window.location.origin}${publicUrl}/ckeditor/plugins/wordcount/`,
      'plugin.js'
    );
    window.CKEDITOR.plugins.addExternal(
      'notification',
      `${window.location.origin}${publicUrl}/ckeditor/plugins/notification/`,
      'plugin.js'
    );
    // window.CKEDITOR.replace('editor1', {
    //   extraPlugins: 'fillintheblanks'
    // })
    // Integrate Rails CSRF token into file upload dialogs (link, image, attachment and flash)

    window.CKEDITOR.on('dialogDefinition', function (ev) {
      // Take the dialog name and its definition from the event data.
      var dialogName = ev.data.name;
      var dialogDefinition = ev.data.definition;
      var content, upload;

      if (dialogName === 'link') {
        // FCKConfig.LinkDlgHideAdvanced = true
        dialogDefinition.removeContents('advanced');

        // FCKConfig.LinkDlgHideTarget = true
        dialogDefinition.removeContents('target');
        /*
          Enable this part only if you don't remove the 'target' tab in the previous block.

                // FCKConfig.DefaultLinkTarget = '_blank'
                // Get a reference to the "Target" tab.
                var targetTab = dialogDefinition.getContents( 'target' );
                // Set the default value for the URL field.
                var targetField = targetTab.get( 'linkTargetType' );
                targetField[ 'default' ] = '_blank';
          */
      }

      if (dialogName === 'image') {
        // FCKConfig.ImageDlgHideAdvanced = true
        dialogDefinition.removeContents('advanced');
        // FCKConfig.ImageDlgHideLink = true
        dialogDefinition.removeContents('Link');
      }

      if (dialogName === 'flash') {
        // FCKConfig.FlashDlgHideAdvanced = true
        dialogDefinition.removeContents('advanced');
      }

      if (
        window.CKEDITOR.tools.indexOf(
          ['link', 'image', 'attachment', 'flash'],
          dialogName
        ) > -1
      ) {
        content =
          dialogDefinition.getContents('Upload') ||
          dialogDefinition.getContents('upload');
        upload = content == null ? null : content.get('upload');
        if (
          upload &&
          upload.filebrowser &&
          upload.filebrowser['params'] === undefined
        ) {
          upload.filebrowser['params'] = CKEditorConfig.filebrowserParams();
          upload.action = CKEditorConfig.addQueryString(
            upload.action,
            upload.filebrowser['params']
          );
          console.log(upload);
        }
      }
    });
  }
  // load ckeditor script as soon as component mounts if not already loaded
  componentDidMount() {
    if (!this.props.isScriptLoaded) {
      loadScript(this.props.scriptUrl, this.onLoad);
    } else {
      this.onLoad();
    }
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.editorInstance && this.editorInstance.destroy();
  }

  componentWillReceiveProps(nextProps) {
    const pluginProps = [
      'removeButtons',
      'config',
      'enableParagraph',
      'charCount',
    ];
    if (!isEqual(pick(this.props, pluginProps), pick(nextProps, pluginProps))) {
      try {
        this.onLoad(nextProps, true);
      } catch (e) {
        console.log(e);
      }
    }
  }

  onLoad(props, isMounted) {
    if (this.unmounting) return;

    this.setState({
      isScriptLoaded: true,
    });

    if (!window.CKEDITOR) {
      console.error('CKEditor not found');
      return;
    }
    const propsFinal = props || this.props;
    let {
      removeButtons,
      config,
      enableParagraph = false,
      charCount,
      content,
      events,
    } = propsFinal;

    config['wordcount'] = {
      showParagraphs: false,
      showWordCount: false,
      showCharCount: true,
      countSpacesAsChars: true,
      countHTML: false,
      maxWordCount: -1,
      maxCharCount: charCount || -1,
    };
    config.allowedContent = true;
    config.enterMode = enableParagraph ? 1 : 2;
    if (removeButtons) {
      config = { ...config };
      let configremoveButtons = `${config.removeButtons},${removeButtons}`;
      config.removeButtons = configremoveButtons;
    }
    if (isMounted) {
      this.editorInstance && this.editorInstance.destroy();
    }
    this.editorInstance = window.CKEDITOR.replace(
      ReactDOM.findDOMNode(this),
      config,
      content
    );

    for (let event in events) {
      let eventHandler = events[event];
      this.editorInstance.on(event, eventHandler);
    }
    this.addDialogDefinition();
  }

  render() {
    return (
      <textarea
        style={{ border: 'none' }}
        name={this.props.name}
        id={this.props.name}
        value={this.props.content}
      />
    );
  }
}

CKEditor.defaultProps = {
  content: '',
  config: {},
  isScriptLoaded: false,
  scriptUrl: defaultScriptUrl,
  activeClass: '',
  events: {},
};

CKEditor.propTypes = {
  content: PropTypes.any,
  config: PropTypes.object,
  isScriptLoaded: PropTypes.bool,
  scriptUrl: PropTypes.string,
  activeClass: PropTypes.string,
  events: PropTypes.object,
};

export default CKEditor;
