import React from "react";
import fecha from "fecha";
import compose from "recompose/compose";

import { withRouter } from "react-router";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import RubricPayload from "./RubricPayload/RubricPayload";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";

import LinkIcon from "@material-ui/icons/Link";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import Search from "@material-ui/icons/Search";
import ArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import ViewListIcon from "@material-ui/icons/ViewList";
import ClearIcon from "@material-ui/icons/Clear";

import ProgressButton from "./ProgressButton";

import TagInput from "./TagInput";
import ResourceDataGrid from "./ResourceDataGrid";
import VideoDurationInput from "./VideoDurationInput";
import ImagePreview from "./ImagePreview";
import AudioPreview from "./AudioPreview";
import SnackContent from "./SnackContent";
import TSVtoArray from "./TSVParser";
import { transformRepresentation } from "./FormUtil";
import StringsTableForm from "./StringsTableForm";
import DecisionTreeForm from "./DecisionTreeForm";

import ResourceSearchDialog from "../Dialogs/ResourceSearchDialog";

import UndoService from "../Services/UndoService";
import LSBackupService from "../Services/LSBackupService";

import classNames from "classnames";
import _get from "lodash/get";
import Immutable from "seamless-immutable";

import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";
import {
    unityrgba2rgba,
    rgba2unityrgba,
    unityrgba2css
} from "../GameStudio/ColorUtils";

import { resolvePasteAutocomplete, fetchAutocomplete } from "../Services/Api";

import ElaGamesForm from "./ElaGamesForm";
import RCForm from "./RCForm";
import Skillmap from "./Skillmap/Skillmap";
import Spacy from "./elaRuleTag/Spacy"
import BooleanExpressions from "./BooleanExpressions";
import ReadAloudForm from "./ReadAloudForm";
import JsonEditor from "./JsonEditor";
class ResourceForm extends React.PureComponent {
    constructor(props) {
        super(props);
        const { schema, resource, resourceName } = props;
        const defaultResource = Immutable({ id: null, ...schema.defaults });
        const formResource = this.toFormRepresentation(
            resource || defaultResource,
            schema
        );

        if (!props.disableEditor) {
            this.lsBackupService = new LSBackupService({ coolDown: 6000 });
            this.undoService = new UndoService({ maxSize: 5, coolDown: 5000 });

            this.lsBackupService
                .get(resourceName, formResource)
                .then(({ resource, lsLoad, lsWarn }) => {
                    lsWarn && this.setState({ lsWarn });
                    if (lsLoad) {
                        this.setState({
                            resource,
                            lsLoad
                        });
                        this.props.onChange && this.props.onChange(resource);
                    }
                });
        }

        this.state = {
            resource: formResource,
            sectionToggle: Immutable({})
        };
    }

    componentDidMount() {
        let { disableEditor } = this.props;
        if (!disableEditor) {
            document.addEventListener("keydown", this.handleUndoMixinKeyDown);
        }
    }

    componentWillUnmount() {
        let { disableEditor } = this.props;
        if (!disableEditor) {
            document.removeEventListener(
                "keydown",
                this.handleUndoMixinKeyDown
            );

            this.state.touched && this.handleSave();
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            schema: nextSchema,
            disableEditor,
            resource: nextResource,
            saving: nextSaving,
            resourceName: nextResourceName,
            fetching: nextFetching,
            saveError
        } = nextProps;
        const {
            saving,
            fetching,
            resourceName,
            resource: currentResource
        } = this.props;

        if (
            !nextSaving &&
            ((fetching && !nextFetching) || nextResourceName !== resourceName)
        ) {
            const defaultResource = Immutable({
                id: null,
                ...nextSchema.defaults
            });

            if (!disableEditor) {
                this.lsBackupService = new LSBackupService({ coolDown: 6000 });
                this.undoService = new UndoService({
                    maxSize: 5,
                    coolDown: 5000
                });

                this.lsBackupService
                    .get(
                        nextResourceName,
                        this.toFormRepresentation(
                            nextResource || defaultResource,
                            nextSchema
                        )
                    )
                    .then(({ resource, lsLoad, lsWarn }) => {
                        this.setState({
                            resource,
                            lsLoad,
                            lsWarn
                        });
                    });
            } else {
                this.setState({
                    resource: this.toFormRepresentation(
                        nextResource || defaultResource,
                        nextSchema
                    )
                });
            }
        }

        if (saving && !nextSaving && !saveError) {
            const { resource } = this.state;
            this.lsBackupService.remove(resourceName, resource);

            nextResource &&
                this.setState({
                    resource: this.toFormRepresentation(
                        nextResource,
                        nextSchema
                    )
                });
        }

        if (nextResource !== currentResource) {
            const { resource } = this.state;
            console.log((currentResource, nextResource, resource));
        }
    }

    handleUndoMixinKeyDown = ev => {
        if (!(ev.ctrlKey || ev.altKey || ev.metaKey)) {
            return;
        }
        ev.which === 90 && this.handleUndo(ev);
        ev.which === 89 && this.handleRedo(ev);
        ev.which === 83 && this.handleSave(ev);
    };

    handleUndo(ev) {
        let resource = this.undoService.undo();
        if (resource) {
            this.setState({ resource });
            ev.preventDefault();
        }
    }

    handleRedo(ev) {
        let resource = this.undoService.redo();
        if (resource) {
            this.setState({ resource });
            ev.preventDefault();
        }
    }

    handleSave(ev) {
        const { resource } = this.state;
        const { resourceName } = this.props;
        this.lsBackupService.push(resourceName, resource, true);
        ev && ev.preventDefault();
    }

    setResource = (resource, undoParams) => {
        this.setState({ resource, touched: true });
        const { resourceName } = this.props;
        window.setTimeout(() => {
            this.undoService.push(resource, undoParams);
            this.lsBackupService.push(resourceName, resource);
        }, 0);
    };

    handleUpdate = (key, value, prefix, event) => {
        let resource = this.state.resource;
        if (key === "nickname") {
            value = this.slugify(value);
            this._preserveLocation = event.target.selectionStart;
            this._preserveInput = event.target;
        }

        const newResource = resource.setIn(prefix.concat([key]), value);
        this.setResource(newResource);

        if (this.state.lsLoad) {
            this.handleLSSnackClose(null, "content_changed");
        }
        this.props.onChange && this.props.onChange(newResource);
    };

    handleMultipleSelection = (key, value, prefix) => {
        if (Array.isArray(value)) {
            let newResource = this.state.resource;
            value.forEach((item) => {
                if (key === "nickname") {
                    item = this.slugify(item);
                }
                const obj = { id: null, [key]: item }
                newResource = newResource.setIn(prefix, obj);
                prefix[1] = prefix[1] + 1;
            })
            if (this.state.lsLoad) {
                this.handleLSSnackClose(null, "content_changed");
            }
            this.setResource(newResource);
            this.props.onChange && this.props.onChange(newResource);
        }
    };

    componentDidUpdate() {
        if (this._preserveInput && this._preserveLocation) {
            this._preserveInput.selectionStart = this._preserveInput.selectionEnd = this._preserveLocation;
            this._preserveInput = this._preserveLocation = null;
        }
    }

    slugify = text =>
        text
            .toString()
            .toLowerCase()
            .replace(/[_\s]+/g, "-") // Replace spaces with -
            .replace(/[^\w-]+/g, "") // Remove all non-word chars
            .replace(/--+/g, "-") // Replace multiple - with single -
            .replace(/^-+/, ""); // Trim - from start of text

    handleChange = (event, prefix) => {
        this.handleUpdate(event.target.name, event.target.value, prefix, event);
    };

    resolvePasteAutocompletes = (table, colSpecs) => {
        return new Promise((resolve, reject) => {
            const promises = colSpecs.map((spec, idx) => {
                if (spec.type !== "autocomplete") return null;

                const values = table.map(row => row[idx]);

                return resolvePasteAutocomplete(spec.source, {
                    params: {
                        values
                    }
                });
            });

            Promise.all(promises).then(responses => {
                colSpecs.forEach((spec, idx) => {
                    if (spec.type !== "autocomplete") return null;
                    if (responses[idx].status !== 200) {
                        reject();
                        return null;
                    }

                    table.forEach((row, rowIndex) => {
                        row[idx] = responses[idx].data.items[rowIndex];
                    });
                });

                resolve(table);
            }, reject);
        });
    };

    handleTablePaste = (event, schema, prefix, index) => {
        const { resource } = this.state;
        const paste = (event.clipboardData || window.clipboardData).getData(
            "text"
        );
        if (paste.indexOf("\t") === -1) {
            return;
        }

        event.preventDefault();
        const table = TSVtoArray(paste);

        const array = resource.getIn(prefix);

        const colSpecs = table[0].map((_, idx) => schema.columns[idx]);

        this.resolvePasteAutocompletes(table, colSpecs).then(
            table => {
                const newObjects = table.map(row =>
                    row.reduce((a, item, idx) => {
                        if (idx >= schema.columns.length) {
                            return a;
                        }
                        a[colSpecs[idx].name] = item;
                        return a;
                    }, {})
                );

                const newArray = array.asMutable();
                newArray.splice(index, 1, ...newObjects);

                this.setResource(resource.setIn(prefix, newArray), true);
            },
            failed => {
                this.setState({
                    lsWarn:
                        "Failed to paste due to missing items from autocomplete"
                });
            }
        );
    };

    handleCheckboxChange = (event, prefix) => {
        this.handleUpdate(event.target.name, event.target.checked, prefix);
    };

    showReferenceBrowser = (resource, key, prefix, hint) => {
        this.setState({
            showResourceSearch: {
                resourceName: resource,
                key,
                prefix,
                hint
            }
        });
    };

    clearReference = (key, prefix) => {
        this.handleUpdate(key, null, prefix);
    }

    handleSearchDialogClose = resource => {
        if (resource) {
            this.handleUpdate(
                this.state.showResourceSearch.key,
                resource,
                this.state.showResourceSearch.prefix
            );
        }
        if (Array.isArray(resource)) {
            this.handleMultipleSelection(
                this.state.showResourceSearch.key,
                resource,
                this.state.showResourceSearch.prefix
            );
        }

        this.setState({ showResourceSearch: null });
    };

    addInlineResource = (prefix, schema) => {
        let resource = this.state.resource;
        let existing = resource.getIn(prefix) || [];
        const newResource = resource.setIn(
            prefix,
            existing.concat({
                id: null,
                ...schema.defaults
            })
        );
        this.setResource(newResource, true);
    };

    clearInlineResource = (prefix, schema) => {
        let resource = this.state.resource;
        let existing = resource.getIn(prefix);
        const newResource = resource.setIn(
            prefix,
            existing.map((r) => ({
                ...r,
                _destroy: true
            }))
        );
        this.setResource(newResource, true);
    };

    deleteInlineResource = prefix => {
        const { resource } = this.state;
        const desPrefix = prefix.concat(["_destroy"]);
        const newResource = resource.setIn(
            desPrefix,
            !resource.getIn(desPrefix, false)
        );
        this.setResource(newResource, true);
    };

    toFormRepresentation = (resource, schema) => {
        return transformRepresentation(resource, schema);
    };

    toAPIRepresentation = (resource, schema) => {
        return transformRepresentation(resource, schema, { api: true });
    };

    onSectionToggle = prefix => {
        const { sectionToggle } = this.state;
        const newSectionToggle = sectionToggle.setIn(
            prefix,
            !sectionToggle.getIn(prefix, false)
        );
        this.setState({ sectionToggle: newSectionToggle });
    };

    handleLSSnackClose = (event, reason) => {
        if (reason === "clickaway") return;
        this.setState({ lsLoad: false, lsWarn: null });
    };

    onDiscardLS = () => {
        let { resource: propsResource, resourceName, schema } = this.props;
        let { resource } = this.state;

        this.lsBackupService.remove(resourceName, resource);
        if (propsResource) {
            this.setState({
                resource: this.toFormRepresentation(propsResource, schema),
                lsLoad: false
            });
        } else {
            this.setState({
                resource: this.toFormRepresentation(
                    { id: null, ...schema.defaults },
                    schema
                ),
                lsLoad: false
            });
        }

        this.props.onDiscardLS && this.props.onDiscardLS();
    };

    onSaveClick = () => {
        const { resource } = this.state;
        const { schema } = this.props;
        const apiResource = this.toAPIRepresentation(resource, schema);
        this.setState({ touched: false });
        this.props.onSave(apiResource);
    };

    onColorFocus = (prefix, key, event) => {
        this.setState({
            colorField: { key, prefix },
            colorTarget: event.target
        });
    };

    handleColorClose = () => {
        this.setState({ colorField: null, colorTarget: null });
    };

    onColorChange = color => {
        const { key: name, prefix } = this.state.colorField;
        this.handleChange(
            { target: { name, value: rgba2unityrgba(color.rgb) } },
            prefix
        );
    };

    renderMany = ({ resources, schema, prefix, label }) => {
        const { classes, disableEditor } = this.props;

        return (
            <div className={classes.inlineContent}>
                {resources.map((resource, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                resource?._destroy ? classes.markAsDeleted : null
                            }
                        >
                            {this.renderFields(
                                {
                                    resource,
                                    schema
                                },
                                prefix.concat([index])
                            )}
                        </div>
                    );
                })}
                {!disableEditor && (
                    <Grid container direction="row" justify="flex-start">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    this.addInlineResource(prefix, schema)
                                }
                            >
                                Add {label}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    };

    renderTable = ({ rows, schema, prefix, label }) => {
        const { classes, disableEditor } = this.props;

        return (
            <div className={classes.inlineContent}>
                <ResourceDataGrid
                    rows={rows}
                    prefix={prefix}
                    fieldRenderer={this.renderFieldSpec}
                    deleteRenderer={this.renderDelete}
                    schema={schema}
                    onPaste={(event, idx) =>
                        this.handleTablePaste(event, schema, prefix, idx)
                    }
                />
                {!disableEditor && (
                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    this.addInlineResource(prefix, schema)
                                }
                            >
                                Add {label}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                onClick={() =>
                                    this.clearInlineResource(prefix, schema)
                                }
                            >
                                <DeleteIcon /> Delete All {label}s
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    };

    renderFields = ({ resource, schema }, prefix = []) => {
        const { classes } = this.props;

        return (
            <Grid
                container
                direction={schema.columns.length > 2 || prefix.length === 0 ? "column" : "row"}
                key={prefix.join(".")}
                className={classes.inlineFields}
            >
                {prefix.length === 0 ? (<Grid container>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            className={classes.textField}
                            disabled
                            label="ID"
                            value={resource.id || "new"}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            className={classes.textField}
                            disabled
                            label="Created At"
                            value={
                                resource.created_at
                                    ? fecha.format(
                                        Date.parse(resource.created_at),
                                        "ddd MMMM Do, YYYY hh:mm A"
                                    )
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            className={classes.textField}
                            disabled
                            label="Updated At"
                            value={
                                resource.updated_at
                                    ? fecha.format(
                                        Date.parse(resource.updated_at),
                                        "ddd MMMM Do, YYYY hh:mm A"
                                    )
                                    : ""
                            }
                        />
                    </Grid>
                </Grid>
                ) : (
                    <div className={classes.idContainer}>
                        <TextField
                            className={classes.textField}
                            disabled
                            label="ID"
                            value={resource?.id || "new"}
                        />
                    </div>
                )}
                {schema.columns.map(spec =>
                    this.renderFieldSpec(resource, spec, prefix)
                )}
                {prefix.length > 0 && this.renderDelete(prefix)}
            </Grid>
        );
    };

    renderDelete = prefix => {
        const { disableEditor } = this.props;
        if (disableEditor) return null;

        const { resource } = this.state;
        const res = resource.getIn(prefix);
        return (
            <div>
                <IconButton onClick={() => this.deleteInlineResource(prefix)} color="secondary">
                    {res._destroy ? <RestoreIcon /> : <DeleteIcon />}
                </IconButton>
            </div>
        );
    };

    renderErrors = errors => {
        if (!errors) return null;

        const re = errors.reduce(
            (a, i, idx) => a.concat(i, <br key={idx} />),
            []
        );

        return re;
    };

    renderFieldSpec = (resource, spec, prefix, compact, opts) => {
        const { schema, classes, disableEditor, saveError, resource: origResource } = this.props;

        const isNew = resource?.id === null;

        var dataType = spec.type,
            hasManySchema = spec.has_many;

        const key = spec.name;

        var component,
            inputType,
            readonly = spec.readonly || false,
            reference = spec.references;
        var endAdornment, inputComponent, startAdornment;

        const frozenColumn = key === "nickname";
        const disabled =
            resource?._destroy || disableEditor || (!isNew && frozenColumn) || key === "unity_version";
        const label = compact ? null : key.underscoreToLabel();

        const normalizedKey = prefix.concat([key]);
        const railsErrorKey = normalizedKey
            .join(".")
            .replace(/\.(\d+)\./, ($0, $1) => `[${$1}].`);

        const errors = _get(saveError && saveError.errors, railsErrorKey);
        const issueKey = origResource ? ["resources", schema.name, origResource.nickname, ...normalizedKey] : null;

        var value = resource[key];

        if (typeof value === "undefined" || value === null) {
            value = "";
        }

        if (spec.table) {
            const tablePrefix = prefix.concat([spec.name]);

            return (
                <Grid key={spec.name} className={classes.inlineSection}>
                    <Typography variant="h5">{label}</Typography>
                    {this.renderTable({
                        rows: resource[spec.name],
                        schema: hasManySchema,
                        prefix: tablePrefix,
                        label: label.replace(/s$/, "")
                    })}
                </Grid>
            );
        }

        if (hasManySchema) {
            const hasManyPrefix = prefix.concat([spec.name]);

            const collapse = this.state.sectionToggle.getIn(
                hasManyPrefix,
                false
            );

            return (
                <Grid key={spec.name} className={classes.inlineSection}>
                    <Typography
                        variant="h5"
                        className={classes.inlineTitle}
                    >
                        <IconButton
                            onClick={() => this.onSectionToggle(hasManyPrefix)}
                            className={
                                collapse
                                    ? classes.collapsed
                                    : classes.uncollapsed
                            }
                        >
                            <ArrowIcon />
                        </IconButton>{" "}
                        {label}
                    </Typography>
                    {!collapse &&
                        this.renderMany({
                            resources: resource[spec.name] || [],
                            schema: hasManySchema,
                            prefix: hasManyPrefix,
                            label: label.replace(/s$/, "")
                        })}
                </Grid>
            );
        }

        if (dataType === "boolean") {
            component = (
                <FormGroup row classes={{ root: classes.checkBoxContainer }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={key}
                                disabled={disabled}
                                checked={value}
                                color="primary"
                                onChange={event =>
                                    this.handleCheckboxChange(event, prefix)
                                }
                                value="true"
                            />
                        }
                        label={label}
                    />
                </FormGroup>
            );
        } else if (dataType === 'rubric_config') {
            if (!value || !Array.isArray(value)) {
                value = Immutable([]);
            }
            component = (<RubricPayload
                value={resource.payload === undefined ? spec.defaults : resource.payload}
                errors={errors}
                label={label}
                name={key}
                onChange={event => this.handleChange(event, prefix)} />
            )
        }
        else if (dataType === 'ela_rule_tags') {
            if (!value || !Array.isArray(value)) value = [];
            const values = value.map(x => ({ label: x, value: x }));

            component = (
                <Spacy
                    title={spec.name}
                    value={resource[spec.name] === undefined ? [] : resource[spec.name]}
                    onChange={event => this.handleChange(event, prefix)}
                    name={key}
                />
            );
        }

        else if (dataType === "tags") {
            if (!value || !Array.isArray(value)) value = [];
            const values = value.map(x => ({ label: x, value: x }));

            component = (
                <TagInput
                    error={!!errors}
                    name={key}
                    disabled={disabled}
                    className={classes.textField}
                    label={label}
                    onChange={event => {
                        event.target.value = event.target.value.map(
                            x => x.label
                        );
                        this.handleChange(event, prefix);
                    }}
                    helperText={this.renderErrors(errors)}
                    value={values}
                    {...opts}
                />
            );
        } else if (dataType === "choice") {
            component = (
                <FormGroup row classes={{ root: classes.checkBoxContainer }}>
                    <FormControl
                        className={classes.formControl}
                        error={!!errors}
                    >
                        <InputLabel htmlFor={`${key}-helper`} shrink={!!value || value === 0}>
                            {label}
                        </InputLabel>
                        <Select
                            value={value}
                            disabled={disabled}
                            onChange={event => this.handleChange(event, prefix)}
                            input={<Input name={key} id={`${key}-helper`} />}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {spec.choices.map(chVal => {
                                if (chVal.label && chVal.value) {
                                    return <MenuItem key={chVal.value} value={chVal.value}>
                                        {chVal.label}
                                    </MenuItem>
                                }
                                return <MenuItem key={chVal} value={chVal}>
                                    {chVal}
                                </MenuItem>
                            })}
                        </Select>
                        <FormHelperText>
                            {this.renderErrors(errors)}
                        </FormHelperText>
                    </FormControl>
                </FormGroup>
            );
        } else if (dataType === "tags_choice") {
            if (!value || !Array.isArray(value)) value = [];

            const choices = spec.choices.map(x => {
                if (x.label && x.value) {
                    return x;
                }

                return {
                    label: x,
                    value: x
                }
            });
            const values = choices.filter(x => value.indexOf(x.label) > -1);

            component = (
                <TagInput
                    error={!!errors}
                    name={key}
                    disabled={disabled}
                    className={classes.textField}
                    label={label}
                    choices={choices}
                    onChange={event => {
                        event.target.value = event.target.value.map(
                            x => x.label
                        );
                        this.handleChange(event, prefix);
                    }}
                    helperText={this.renderErrors(errors)}
                    value={values}
                    {...opts}
                />
            );
        } else if (dataType === "strings_table") {
            if (!value || !Array.isArray(value)) {
                value = Immutable([]);
            }
            component = (
                <StringsTableForm
                    value={value}
                    label={label}
                    disabled={disabled}
                    name={key}
                    onChange={event => this.handleChange(event, prefix)}
                />
            );
        } else if (dataType === "json_editor") {
            if (!value ) {
                value = Immutable({});
            }

            component = (
              <JsonEditor
                    value={value}
                    label={label}
                    error={!!errors}
                    helperText={this.renderErrors(errors)}
                    disabled={disabled}
                    vars={spec.vars}
                    name={key}
                    onChange={event => this.handleChange(event, prefix)}
                />
            );
        } else if (dataType === "decision_tree") {
            if (!value || !value.tree || !Array.isArray(value.tree)) {
                value = Immutable({ tree: [] });
            }

            component = (
                <DecisionTreeForm
                    value={value}
                    label={label}
                    error={!!errors}
                    helperText={this.renderErrors(errors)}
                    disabled={disabled}
                    vars={spec.vars}
                    name={key}
                    onChange={event => this.handleChange(event, prefix)}
                />
            );
        } else if (dataType === "switch_schema") {
            if (!value) {
                value = Immutable({});
            }
            const sw = resource[spec.source];
            const schema = spec.schemas[sw];
            const innerResource = value[sw] || Immutable({});
            const innerPrefix = prefix.concat([spec.name, sw]);

            component = <div className={classes.inlineSection}><Typography variant="caption">{label}</Typography>
                {schema && schema.columns.map(spec =>
                    this.renderFieldSpec(innerResource, spec, innerPrefix)
                )}
            </div>;

        } else if (dataType === "integer") {
            inputType = "number";
        } else if (dataType === "video_duration") {
            inputComponent = VideoDurationInput;
        } else if (dataType === "color") {
            startAdornment = (
                <InputAdornment position="start">
                    <div
                        onClick={this.onColorFocus.bind(this, prefix, key)}
                        className={classNames(classes.colorPreview, {
                            [classes.disabledColorPreview]: disabled
                        })}
                        style={{
                            backgroundColor: unityrgba2css(value)
                        }}
                    />
                </InputAdornment>
            );
        } else if (
            dataType === "autocomplete" ||
            dataType === "autocomplete_multiple"
        ) {
            if (
                !compact &&
                (spec.model === "takeaways" || spec.model === "chapters" || spec.model === "subjects" || spec.model === "concepts")
            ) {
                endAdornment = (
                    <InputAdornment position="end">
                        <Button
                            component={Link}
                            to={`/knowledge-graph/${spec.model}`}
                        >
                            <ViewListIcon />
                        </Button>
                    </InputAdornment>
                );
            }

            if (!value) {
                value = [];
            }

            if (typeof value !== "object") {
                value = { label: value };
            }

            if (!Array.isArray(value)) {
                value = [value];
            }

            component = (
                <TagInput
                    error={!!errors}
                    name={key}
                    disabled={disabled}
                    className={classes.textField}
                    maxItems={dataType === "autocomplete" ? 1 : null}
                    label={label}
                    onChange={event => {
                        if (dataType === "autocomplete") {
                            if (
                                event.target.value &&
                                event.target.value.length >= 1
                            ) {
                                event.target.value = event.target.value[0];
                            }
                        }
                        this.handleChange(event, prefix);
                    }}
                    helperText={this.renderErrors(errors)}
                    value={value}
                    InputProps={{
                        endAdornment
                    }}
                    choices={q =>
                        new Promise((resolve, reject) => {
                            fetchAutocomplete(spec.source, {
                                params: { q }
                            }).then(response => {
                                if (response.ok) {
                                    resolve(response.data.items);
                                } else {
                                    reject();
                                }
                            });
                        })
                    }
                    {...opts}
                />
            );
        } else if (dataType === "rc_editor") {
            if (!value || !Array.isArray(value)) {
                value = Immutable([]);
            }
            component = (
                <RCForm
                    value={resource.payload === undefined ? {} : resource.payload}
                    label={label}
                    name={key}
                    onChange={event => this.handleChange(event, prefix)}
                />
            );
        } else if (dataType === "read_aloud_editor") {
            if (!value || !Array.isArray(value)) {
                value = Immutable([]);
            }
            component = (
                <ReadAloudForm
                    value={resource.payload === undefined ? {} : resource.payload}
                    label={label}
                    name={key}
                    onChange={event => this.handleChange(event, prefix)}
                />
            );
        } else if (dataType === "skill_map") {
            if (!value || !Array.isArray(value)) {
                value = Immutable([]);
            }
            component = (
                <Skillmap
                    errors={errors}
                    spec={spec}
                    value={resource.skills === undefined ? {} : resource.skills}
                    label={label}
                    name={key}
                    onChange={event => this.handleChange(event, prefix)}
                />
            );
        } else if (dataType === "ela_games") {
            if (!value || !Array.isArray(value)) {
                value = Immutable([]);
            }
            component = (
                <ElaGamesForm
                    templateType={resource.template === undefined ? "" : resource.template}
                    value={resource}
                    label={label}
                    name={key}
                    disableEditor={disableEditor}
                    onChange={event => this.handleChange(event, prefix)}
                />
            );
        } else if (dataType === "boolean_expressions") {
            component = (
                <BooleanExpressions
                    errors={errors}
                    value={resource.recommendations ? resource.recommendations : []}
                    label={label}
                    disabled={disabled}
                    name={key}
                    defaultExpression={spec["default_expression"]}
                    types={spec["evaluation_types"]}
                    onChange={event => this.handleChange(event, prefix)}
                />
            )
        }
        else {
            inputType = "text";
        }

        if (reference) {
            const referencedNickname = resource[key] ? (
                resource[key].nickname
            ) : (
                <i>Not found!</i>
            );
            value = (resource[key] && resource[key].id) || "";
            const hint = resource[spec.hint] || "";

            startAdornment = (
                <InputAdornment
                    position="start"
                    classes={{
                        root: classes.referenceInputAdornment
                    }}
                >
                    <Button
                        component={Link}
                        to={`/resources/${reference.resource}/${value}`}
                        disabled={value.length === 0}
                        color="primary"
                        size="small"
                        classes={{ root: classes.referenceButton }}
                    >
                        {referencedNickname}
                        <LinkIcon className={classes.leftSpacing} />
                    </Button>
                    {!disableEditor && (
                        <IconButton
                            onClick={this.showReferenceBrowser.bind(
                                this,
                                reference.resource,
                                key,
                                prefix,
                                hint
                            )}
                        >
                            <Search />
                        </IconButton>
                    )}
                    {!disableEditor && (
                        <IconButton
                            onClick={this.clearReference.bind(
                                this,
                                key,
                                prefix
                            )}
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                </InputAdornment>
            );
        }

        if (!component)
            component = (
                <TextField
                    error={!!errors}
                    key={key}
                    multiline={dataType === "text"}
                    rows={3}
                    rowsMax={5}
                    name={key}
                    disabled={!!reference || disabled || readonly}
                    className={classes.textField}
                    value={value}
                    onChange={event => this.handleChange(event, prefix)}
                    label={label}
                    type={inputType}
                    InputLabelProps={{ shrink: value !== null }}
                    InputProps={{
                        startAdornment,
                        endAdornment,
                        inputComponent
                    }}
                    helperText={this.renderErrors(errors)}
                    {...opts}
                />
            );

        return (
            <div key={key} className={classNames(classes.fieldContainer, { "fc": !!issueKey })} data-issue-key={issueKey ? issueKey.join("/") : null}>
                {component}
                {(spec.panes || []).map(pane => {
                    if (pane === "image_preview") {
                        return <ImagePreview key={pane} src={value} />;
                    }
                    if (pane === "audio_preview") {
                        return <AudioPreview key={pane} src={value} />;
                    }
                    return null;
                })}
            </div>
        );
    };

    render() {
        const {
            schema,
            disableEditor,
            saveError,
            saving,
            classes
        } = this.props;
        const { resource, showResourceSearch, snack } = this.state;
        return (
            <Grid container>
                {this.renderFields({ resource, schema })}
                {!disableEditor && (
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignContent="flex-end"
                        className={classes.buttonContainer}
                    >
                        <Grid item>
                            <ProgressButton
                                disabled={snack}
                                inProgress={saving}
                                error={
                                    saveError &&
                                    (saveError.error ||
                                        "Unexpected error, could not save!")
                                }
                                onClick={this.onSaveClick}
                            >
                                {resource.id === null ? "Create" : "Save"}
                            </ProgressButton>
                        </Grid>
                    </Grid>
                )}
                <ResourceSearchDialog
                    open={!!showResourceSearch}
                    {...showResourceSearch}
                    onClose={this.handleSearchDialogClose}
                />
                {!disableEditor && (
                    <Popover
                        open={!!this.state.colorField}
                        anchorEl={this.state.colorTarget}
                        onClose={this.handleColorClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    >
                        {this.state.colorField && (
                            <SketchPicker
                                color={
                                    unityrgba2rgba(
                                        resource.getIn(
                                            this.state.colorField.prefix.concat(
                                                this.state.colorField.key
                                            )
                                        )
                                    ) || {}
                                }
                                onChange={this.onColorChange}
                            />
                        )}
                    </Popover>
                )}
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.lsLoad}
                    onClose={this.handleLSSnackClose}
                    message="Restored from local backup"
                    action={[
                        <Button
                            key="discard"
                            color="secondary"
                            onClick={this.onDiscardLS}
                        >
                            Discard
                        </Button>,
                        <Button
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleLSSnackClose}
                        >
                            Okay
                        </Button>
                    ]}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={!!this.state.lsWarn}
                    onClose={this.handleLSSnackClose}
                >
                    <SnackContent
                        variant="warning"
                        message={this.state.lsWarn}
                        action={[
                            <Button
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleLSSnackClose}
                            >
                                Okay
                            </Button>
                        ]}
                    />
                </Snackbar>
            </Grid>
        );
    }
}

const styles = theme => ({
    buttonContainer: {
        margin: theme.spacing(1)
    },
    textField: {
        margin: theme.spacing(1),
        width: "calc(100% - " + theme.spacing(1) + "px)"
    },
    checkBoxContainer: {
        marginLeft: theme.spacing(1)
    },
    referenceInputAdornment: {
        width: "50%",
        flexShrink: 0
    },
    referenceButton: {
        width: "100%"
    },
    inlineContent: {
        paddingTop: theme.spacing(1)
    },
    inlineTitle: {
        marginBottom: theme.spacing(1)
    },
    inlineFields: {
        padding: theme.spacing(2)
    },
    inlineSection: {
        padding: theme.spacing(1)
    },
    collapsed: {
        transform: "rotate(-90deg)",
        transition: "transform 0.5s"
    },
    uncollapsed: {
        transition: "transform 0.5s"
    },
    formControl: {
        minWidth: 200
    },
    resourceTitle: {
        textTransform: "capitalize"
    },
    colorPreview: {
        width: 24,
        height: 24,
        cursor: "pointer",
        border: "1px solid #999"
    },
    disabledColorPreview: {
        cursor: "default"
    },
    leftSpacing: {
        marginLeft: theme.spacing(2)
    },
    markAsDeleted: {
        background:
            "repeating-linear-gradient(-55deg, #ddd, #ddd 10px,#fff 10px,#fff 20px)"
    },
    fieldContainer: {
        flex: "1 0 auto",
        // display: "flex",
        // justifyContent: "space-between"
    },
    idContainer: {
        flex: "0 0 100px"
    }
});

export default compose(
    withRouter,
    withStyles(styles)
)(ResourceForm);
