import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";

const theme = createMuiTheme({
	palette: {
		primary: blue,
		error: red
	},
	typography: {
	    useNextVariants: true,
	},
});

export default ({ children }) => {
	return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
