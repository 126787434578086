import React from 'react';
import CKEditor from './CKEditor';
import CKEditorConfig from './CKEditorConfig';
import '../styles/CKEditorCard.scss';

function CKEditorCard({
  value,
  updateContent,
  name = '',
  label = '',
  charCount,
  removeButtons,
  enableParagraph,
  instruction,
  shouldShowInstruction,
  children,
  hideEditor,
}) {
  return (
    <div className={'editor-wrapper'}>
      <div className={'editorBox'}>
        {label && <label className={'text'}> {label} </label>}
        <div>
          {children}
          {!hideEditor && (
            <CKEditor
              config={CKEditorConfig}
              content={value}
              name={name}
              charCount={charCount}
              removeButtons={removeButtons}
              enableParagraph={enableParagraph}
              scriptUrl={CKEditorConfig.cdnUrl}
              events={{ change: updateContent }}
            />
          )}
        </div>
      </div>
      {instruction && shouldShowInstruction && (
        <label className={'text'}> {instruction} </label>
      )}
    </div>
  );
}

export default CKEditorCard;
