import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default props => {
	return (
		<SvgIcon {...props}>
			<path d="m15.085 11.998 3.637 6.282 1.758-6.282-1.758-6.28-3.637 6.28zm-1.773-1.02 3.639-6.28-6.336 1.623-4.578 4.658h7.277zm3.637 8.324-3.637-6.282h-7.276l4.578 4.657 6.336 1.624zm5.193-5.164-2.22 8.258-8.284-2.213-1.227-2.155-2.488.018-6.065-6.045 6.065-6.043h0l2.487.018 1.229-2.155 8.283-2.212 2.22 8.256-1.26 2.137 1.26 2.137" />
		</SvgIcon>
	);
};
