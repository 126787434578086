// @flow

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions(
  {
    request: ["params"],
    success: ["data"],
    failure: ["error"]
  },
  { prefix: "SESSION_LOGIN_" }
);

const { Types: LogoutTypes, Creators: LogoutCreators } = createActions(
  {
    request: [],
    success: ["data"],
    failure: ["error"]
  },
  { prefix: "SESSION_LOGOUT_" }
);

export { Types, LogoutTypes };

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: false,
  error: null
});

export const request = (state: Object, action: Object) => {
  return state.merge({ fetching: true, error: null });
};

export const success = (state: Object, { data }: Object) => {
  return state.merge({ fetching: false, data, error: null });
};

export const failure = (state: Object, { error }: Object) => {
  return state.merge({ fetching: false, data: false, error });
};

export const logoutSuccess = (state: Object, { data }: Object) => {
  return state.merge({ data: false });
};

export const key = "session";

export const selectSessionState = state => {
  let { error, fetching } = state[key];
  return { error, fetching };
};

export const selectCurrentUser = state => {
  return state[key].data && state[key].data.user;
};

export const selectAccessToken = state => {
  return state[key].data && state[key].data.access_token;
};

export { Creators, LogoutCreators };

export default createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [LogoutTypes.REQUEST]: logoutSuccess
});
