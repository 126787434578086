import React from "react";

import { withStyles } from "@material-ui/core/styles";

import UnknownFileIcon from "@material-ui/icons/Description";
import ImageIcon from "@material-ui/icons/Image";
import VideoIcon from "@material-ui/icons/Movie";
import AudioIcon from "@material-ui/icons/MusicVideo";
import LoopIcon from "@material-ui/icons/Loop";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import LinearProgress from "@material-ui/core/LinearProgress";

import ClickCopyText from "../Components/ClickCopyText";
import UnityIcon from "../Components/UnityIcon";

import classNames from "classnames";

export const MimeIcon = mime_type => {
  if (mime_type.startsWith("image/")) {
    return <ImageIcon />;
  }
  if (mime_type.startsWith("video/")) {
    return <VideoIcon />;
  }
  if (mime_type.startsWith("audio/")) {
    return <AudioIcon />;
  }

  switch (mime_type) {
    case "application/unity-asset-bundle":
    case "application/unity-manifest":
    case "application/unity-asset-package":
      return <UnityIcon />;

    default:
      return <UnknownFileIcon />;
  }
};


export function readableFileSize(size) {
  // Adapted from https://github.com/fkjaekel
  // https://github.com/TTLabs/EvaporateJS/issues/13
  var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = 0;
  while(size >= 1024) {
    size /= 1024;
    ++i;
  }
  return [size.toFixed(2).replace('.00', ''), units[i]].join(" ");
}

const FileCard = ({ file, classes }) => {
  const hasPreview = file.mime_type && file.mime_type.startsWith("image/");
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card classes={{ root: classes.card }}>
        <CardContent className={classes.content}>
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={8}>
              <Typography noWrap>
                <a href={file.url} className={classes.link} target="_blank" rel="noopener noreferrer">
                  {file.filename}
                </a>
              </Typography>
              { file.file_size && <Typography variant="caption">{ readableFileSize(file.file_size) }</Typography> }
            </Grid>
            <Grid item xs>
              <ClickCopyText
                className={classes.click2Copy}
                title={file.url}
                showCopy={!file.error}
                position={"left"}
                text={null}
                copyText={file.url}
              />
            </Grid>
            {!hasPreview && (
              <Grid item xs>
                {file.mime_type ? (
                  <Tooltip title={file.mime_type}>
                    <div className={classes.iconContainer}>
                      {MimeIcon(file.mime_type)}
                    </div>
                  </Tooltip>
                ) : (
                  <div className={classNames(classes.iconContainer)}>
                    <LoopIcon className={classes.slowRotate} />
                  </div>
                )}
              </Grid>
            )}
          </Grid>
          {file.uploading &&
            (file.error ? (
              "ERROR!"
            ) : (
              <LinearProgress
                variant={file.progress ? "determinate" : "indeterminate"}
                value={file.progress ? file.progress * 100 : null}
              />
            )) 
          }
        </CardContent>
        {hasPreview && <CardMedia className={classes.cover} image={file.url} />}
      </Card>
    </Grid>
  );
};

const styles = theme => ({
  click2Copy: {
    fontSize: 10,
    display: "inline-block"
  },
  cover: {
    height: 80,
    width: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: { width: "80%", flex: 1 },
  iconContainer: {
    display: "inline-block",
    "& svg": {
      position: "relative",
      top: "0.25em",
      marginRight: theme.spacing(1),
      color: "#333"
    }
  },
  card: { display: "flex", margin: theme.spacing(1) },
  link: {
    color: theme.palette.primary.main
  },
  slowRotate: {
    animation: "$rotation 5s infinite linear"
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(-359deg)"
    }
  }
});

export default withStyles(styles)(FileCard);
