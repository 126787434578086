import React from 'react'

function Sunrays({ colour }) {
    return (
        <div>
            < svg width="374" height="372" viewBox="0 0 374 372" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M164.181 0H209.181L189.181 180H184.181L164.181 0Z" fill="url(#paint0_linear_601_36)" />
                <path d="M164.181 372H209.181L189.181 192H184.181L164.181 372Z" fill="url(#paint1_linear_601_36)" />
                <path d="M45.375 62.9766L78.7854 32.8312L184.518 179.871L180.806 183.22L45.375 62.9766Z" fill="url(#paint2_linear_601_36)" />
                <path d="M294.577 339.17L327.987 309.025L192.557 188.781L188.844 192.13L294.577 339.17Z" fill="url(#paint3_linear_601_36)" />
                <path d="M0 170.115L9.17253 126.06L181.317 182.33L180.298 187.225L0 170.115Z" fill="url(#paint4_linear_601_36)" />
                <path d="M364.19 245.941L373.362 201.886L193.065 184.776L192.046 189.671L364.19 245.941Z" fill="url(#paint5_linear_601_36)" />
                <path d="M24.2939 271.715L8.64599 229.523L180.261 186.812L182 191.5L24.2939 271.715Z" fill="url(#paint6_linear_601_36)" />
                <path d="M373.079 142.358L357.431 100.167L195.619 181.51L197.358 186.198L373.079 142.358Z" fill="url(#paint7_linear_601_36)" />
                <path d="M85.0098 343.021L50.7353 313.862L182.605 189.724L186.414 192.964L85.0098 343.021Z" fill="url(#paint8_linear_601_36)" />
                <path d="M326.06 59.6855L291.785 30.5263L190.381 180.584L194.19 183.824L326.06 59.6855Z" fill="url(#paint9_linear_601_36)" />
                <defs>
                    <linearGradient id="paint0_linear_601_36" x1="191.681" y1="199" x2="191.181" y2="32" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_601_36" x1="193.681" y1="187.5" x2="191.181" y2="340" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_601_36" x1="199.102" y1="192.303" x2="86.858" y2="68.6479" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_601_36" x1="192.883" y1="182.425" x2="293.186" y2="297.324" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_601_36" x1="200.428" y1="183.756" x2="36.8317" y2="150.205" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_601_36" x1="189.577" y1="179.453" x2="338.365" y2="212.986" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_601_36" x1="201.312" y1="176.732" x2="44.9082" y2="235.272" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_601_36" x1="189.835" y1="178.856" x2="333.687" y2="128.171" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_601_36" x1="193.013" y1="173.633" x2="85.1805" y2="301.153" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_601_36" x1="184.038" y1="181.095" x2="284.759" y2="66.563" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color={colour} stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default Sunrays;
