import React, { useState, useEffect } from "react";
import Button from "../common/Button";
import "./Question.scss";
import parse from 'html-react-parser';
import Parser from 'html-react-parser';

const Question = ({ data, updateAnswer }) => {
  const [question, setQuestion] = useState("");
  const [questionImage, setQuestionImage] = useState("");
  const [instruction, setInstruction] = useState("");
  const [options, setOptions] = useState({});
  const [answer, setAnswer] = useState({});
  const [answerStatus, setAnswerStatus] = useState("");
  const [answered, setAnswered] = useState("");

  const onAnswer = (id) => {
    setAnswered(id);
    if (updateAnswer) {
      updateAnswer({
        correct: [
          {
            id: id,
          },
        ],
      })
    }
    else {
      if (answer === id) {
        setAnswerStatus("correct");
      } else {
        setAnswerStatus("wrong");
      }
    }
  }


  useEffect(() => {
    const question = data?.metadata?.question?.context?.html;
    const rawQuestionImage = data?.metadata?.questionImage?.context?.html;
    const instruction = data?.metadata?.instruction?.context?.html;
    const options = data?.metadata?.options || [];
    const answer = data?.answers?.correct?.[0].id;
    setOptions(options);
    setAnswer(answer);
    setQuestion(question);
    setInstruction(instruction);
    if (!rawQuestionImage) setQuestionImage("");
    parse(rawQuestionImage || "", {
      replace: (domNode) => {
        if (domNode.name === "img" && domNode.type === "tag") {
          setQuestionImage(domNode?.attribs?.src);
        }
      }
    });
  }, [data, data?.answers])

  const getBtnTheme = (optionId) => {
    let res = "secondary";
    if (updateAnswer && answer === optionId) {
      res = "success";
    } else {
      if (answerStatus === "correct" && (optionId === answer || optionId === answered)) res = "success";
      if (answerStatus === "wrong" && (optionId === answer || optionId === answered)) res = "error";
    }
    return res;
  }
  return (
    <div className="question-container">
      <div className="question-wrapper">
        {questionImage &&
          <img
            src={questionImage}
            className="question-image"
            alt="Question Image"
          />}
        <div className="content-body">
          <div className="instruction">
            {Parser(instruction || "")}
          </div>
          <div className="main-question">
            {Parser(question || "")}
          </div>
          <div className="options">
            {(Array.isArray(options) ? options : []).map((each, index) => {
              return (
                <Button
                  style={{ marginLeft: `${index * 20}px` }}
                  className="option"
                  key={each.id}
                  label={Parser(each.context.html || "")}
                  size="sm"
                  btnTheme={getBtnTheme(each.id)}
                  isBold
                  onClick={() => onAnswer(each.id)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
