import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default props => {
	return (
		<SvgIcon {...props}>
			<path fill="none" d="M0 0h24v24H0V0z"/>
  			<path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/>
  			<path d="M10 6.5v7l5.5-3.5z"/>
		</SvgIcon>
	);
};
