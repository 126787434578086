import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import fetchReducer, { Types as FetchTypes, Creators as FetchCreators } from "./fetch";
import createReducer_, { Types as CreateTypes, Creators as CreateCreators } from "./create";
import listReducer, { Types as ListTypes, Creators as ListCreators } from "./list";
import saveReducer, { Types as SaveTypes, Creators as SaveCreators } from "./save";
import searchReducer, { Types as SearchTypes, Creators as SearchCreators } from "./search";
import importReducer, { Types as ImportTypes, Creators as ImportCreators } from "./import";
import fetchRcMcqReducer, { Types as FetchRcMcqTypes, Creators as FetchRcMcqCreators } from "./fetch_rc_mcq"

import referencesReducer, {
  Types as ReferencesTypes,
  Creators as ReferencesCreators
} from "./references";
import actionReducer, {
  Types as ActionTypes,
  Creators as ActionCreators
} from "./actions";

import { selectNormalizrSchemaByName } from "../SchemaRedux";
import { workspace } from "../../Services/Api/Schema";

export const INITIAL_STATE = Immutable({
  data: false,
  error: null,
  resources: {},
  meta: {}
});

export const key = "resources";

export const selectResources = (state, resourceName) => {
  try {
    return denormalize(
      state[key].data[resourceName],
      selectNormalizrSchemaByName(state, resourceName),
      state.entities
    );
  } catch (e) {
    return null;
  }
};

export const selectSearchResults = (state) => {
  return state[key].search_results;
};

export const selectResourceById = (state, resourceName, id) => {
  const schema = selectNormalizrSchemaByName(state, resourceName);
  if (!schema) return null;

  return denormalize(
    state.entities.getIn([resourceName, parseInt(id, 10)]),
    schema[0],
    state.entities
  );
};

export const selectResourceReferencesById = (state, resourceName, id) => {
  const references = state.entities.getIn([resourceName, parseInt(id, 10), "references"]);
  if (!references) return null;

  return denormalize(references, [workspace], state.entities);
};

export const selectResourcesRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};

export { FetchCreators, FetchTypes };
export { FetchRcMcqCreators, FetchRcMcqTypes }
export { CreateCreators, CreateTypes };
export { ListCreators, ListTypes };
export { SaveCreators, SaveTypes };
export { SearchCreators, SearchTypes };
export { ReferencesCreators, ReferencesTypes };
export { ActionCreators, ActionTypes };
export { ImportCreators, ImportTypes };

export default createReducer(INITIAL_STATE, {
  ...fetchReducer,
  ...createReducer_,
  ...listReducer,
  ...saveReducer,
  ...searchReducer,
  ...actionReducer,
  ...referencesReducer,
  ...importReducer,
  ...fetchRcMcqReducer,
});
