import React, { PureComponent } from "react";

import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";

import ResourceBrowser from "../Components/ResourceBrowser";
import PageWrapper from "../Components/PageWrapper";
import ToolpoolPane from "../Components/ToolpoolPane";

import Backspace from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import ImportIcon from "@material-ui/icons/ArrowDownward";
import ResourceCartIcon from "@material-ui/icons/ShopTwoOutlined";

import { connect } from "react-redux";
import { selectRootAbilities } from "../Redux/SchemaRedux";


class ResourceBrowserPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { cartOpen: false };
    }

    onCartAdd = selected => {
        const {
            match: { params }
        } = this.props;

        selected.forEach(resource => {
            this.cartDrop({
                type: "resource",
                resource_id: resource.id,
                nickname: resource.nickname,
                resource_name: params.resource_name
            });
        });

        this.setState({ cartOpen: true });
        this.cartCloseTimer = window.setTimeout(() => {
            this.setState({ cartOpen: false });
        }, 4000);
    };

    onCartInteract = () => {
        window.clearTimeout(this.cartCloseTimer);
    };

    componentDidMount() {
        const {
            match: { params }
        } = this.props;

        document.title = 'CMS :: ' + params.resource_name.underscoreToLabel();
    }

    render() {
        const props = this.props;
        let { prefix = "", disableEditor, rootAbilities } = props;
        let {
            match: {
                params: { resource_name: resourceName }
            }
        } = props;

        const backButton = (
            <Button to={`${prefix}/resources`} component={Link}>
                <Backspace />
            </Button>
        );

        const createButton = !disableEditor && rootAbilities.resource_create[resourceName] && (
            <span>
            { rootAbilities.resource_import && <Button
                key="importButton"
                to={`${prefix}/resources/${resourceName}/import`}
                component={Link}
                color="primary"
            >
                <ImportIcon /> Import
            </Button> }
            <Button
                key="createButton"
                to={`${prefix}/resources/${resourceName}/new`}
                component={Link}
                color="primary"
            >
                <AddIcon /> Create
            </Button>
            </span>
        );

        const selectButton = r => (
            <Button
                color="primary"
                component={Link}
                to={`${prefix}/resources/${resourceName}/${r.id}`}
            >
                Open
            </Button>
        );

        const studioButton = r => (
            <Button
                color="primary"
                component={Link}
                to={`${prefix}/resources/${resourceName}/${r.id}/studio`}
            >
                Open Studio
            </Button>
        );

        const selectionActionButtons = selected => {
            return (
                <Button onClick={this.onCartAdd.bind(this, selected)}>
                    <ResourceCartIcon />
                </Button>
            );
        };

        return (
            <PageWrapper>
                <ResourceBrowser
                    prefix={prefix}
                    resourceName={resourceName}
                    createButton={createButton}
                    backButton={backButton}
                    selectButton={selectButton}
                    studioButton={disableEditor ? null : studioButton}
                    stateProvider={disableEditor ? "state" : "location"}
                    showCheckboxes
                    selectionActionButtons={selectionActionButtons}
                />
                <ToolpoolPane
                    open={!!this.state.cartOpen}
                    onClose={ () => this.setState({ cartOpen: false })}
                    cartDrop={ref => (this.cartDrop = ref)}
                    onCartInteract={this.onCartInteract}
                />
            </PageWrapper>
        );
    }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = (state, ownProps) => {
  return {
    rootAbilities: selectRootAbilities(state)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResourceBrowserPage);
