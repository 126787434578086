import _shuffle from 'lodash/shuffle';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import parse, { attributesToProps } from 'html-react-parser';

function videoComponentValidater({ template, answers, metadata }) {
  let errors = '';
  if (!_get(metadata, `${template.keyName}.context.dashVideoUrl`, '').trim()) {
    errors += 'Please add dash video url \n';
  }
  if (
    !_get(metadata, `${template.keyName}.context.dashVideoToken`, '').trim()
  ) {
    errors += 'Please add dash video token \n';
  }
  if (!_get(metadata, `${template.keyName}.context.hlsVideoUrl`, '').trim()) {
    errors += 'Please add HLS video url \n';
  }
  if (!_get(metadata, `${template.keyName}.context.hlsVideoToken`, '').trim()) {
    errors += 'Please add HLS video token \n';
  }
  return errors;
}

function imageAndAudioValidater({ template, answers, metadata }) {
  let errors = '';
  var imgRegex = new RegExp('<img[^>]*?>', 'g');

  const questionText = _get(metadata, `${template.keyName}.context.html`, '');
  const cleanedQuertionText = questionText
    .replace(imgRegex, '')
    .replace(/(&nbsp;)+/g, '')
    .replace(/(<br ?\/?>)*/g, '')
    .replace(/\s\s+/g, ' ')
    .trim();

  if (cleanedQuertionText) {
    alert(cleanedQuertionText);
    errors += 'Question can only contain image, no text allowed\n';
  }
  if (!_get(metadata, `${template.keyName}.context.audioText`, '').trim()) {
    errors += 'Please add audio text in question\n';
  }
  return errors;
}

function validateImage({ template, answers, metadata, type }) {
  const text = metadata[type]['context']['html'];
  let alertStatement = '';
  parse(text, {
    replace: (node) => {
      if (node.name === 'img' && node.type === 'tag') {
        let props = attributesToProps(node.attribs);
        const [imgwidth, imgheight] = [
          parseInt(props?.style?.width?.split('px')[0]),
          parseInt(props?.style?.height?.split('px')[0]),
        ];
        if (isNaN(imgwidth) || isNaN(imgheight)) {
          alertStatement = `Provide height and width for the ${type} image\n`;
        }
      }
    },
  });
  if (alertStatement !== '') {
    return alertStatement;
  }
  return '';
}
const CONTENT_TYPE_META_DATA = {
  key: 'contentType',
  defaultOption: 'textOnly',
};

const TEXT_ONLY_CONTENT = {
  key: 'textOnly',
  label: 'Text Only',
  removeButtons:
    'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
};

const AUDIO_ONLY_CONTENT = {
  key: 'audioOnly',
  label: 'Audio Only',
  voice: 'Matthew',
  removeButtons:
    'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
};

const TEXT_AND_AUDIO_CONTENT = {
  key: 'textAndAudio',
  label: 'Text and Audio Both',
  voice: 'Matthew',
  removeButtons:
    'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
};

const IMAGE_AND_AUDIO_CONTENT = {
  key: 'imageAndAudio',
  label: 'Image and Audio Both',
  voice: 'Matthew',
  removeButtons:
    'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
  extras: ['extraAudioText'],
  validaterFn: imageAndAudioValidater,
  extraAudioTextProps: {
    keyName: 'audioText',
    labelName: 'Audio Text',
    content_guidelines: {
      removeButtons:
        'Source,Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
      charCount: 60,
    },
  },
};

const VIDEO_ONLY_CONTENT = {
  key: 'videoOnly',
  label: 'Video Only',
  hideEditor: true,
  extras: ['dashVideoComponent', 'hlsVideoComponent'],
  validaterFn: videoComponentValidater,
  isTextOptional: true,
};

const TEXT_AND_VIDEO_CONTENT = {
  key: 'textAndVideo',
  label: 'Text And Video',
  extras: ['dashVideoComponent', 'hlsVideoComponent'],
  validaterFn: videoComponentValidater,
};

// read aloud
const CONTENT_TYPE_READALOUD = {
  ...CONTENT_TYPE_META_DATA,
  options: [TEXT_ONLY_CONTENT, AUDIO_ONLY_CONTENT, TEXT_AND_AUDIO_CONTENT],
};



export default {
  read_aloud: {
    hasBackground: false,
    disableAnswers: true,
    editorComponents: [
      {
        type: 'textEditor',
        keyName: 'title',
        labelName: 'Title',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
        },
      },
      {
        type: 'addOptions',
        keyName: 'pages',
        labelName: 'Page',
        content_guidelines: {
          removeButtons:
            'Source,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 200,
          minCount: 1,
        },
      },
    ],
  },
  read_aloud_mcq: {
    hasBackground: false,
    editorComponents: [
      {
        type: 'combinedTextEditor',
        keyName: 'questionImage',
        labelName: 'Question Image',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 78,
          contentType: IMAGE_AND_AUDIO_CONTENT,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'instruction',
        labelName: 'Instruction',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 78,
          contentType: IMAGE_AND_AUDIO_CONTENT,
        },
      },
      {
        type: 'combinedTextEditor',
        keyName: 'question',
        labelName: 'Question',
        content_guidelines: {
          removeButtons:
            'BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 78,
          contentType: IMAGE_AND_AUDIO_CONTENT,
        },
      },
      {
        type: 'addOptions',
        keyName: 'options',
        labelName: 'Options',
        content_guidelines: {
          removeButtons:
            'Image,BulletedList,NumberedList,Table,Blockquote,Bold,Italic,Strike,RemoveFormat,Scayt,fillInTheBlank',
          charCount: 69,
          maxCount: 5,
          minCount: 2,
        },
      },
    ],
  }
};
