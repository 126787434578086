// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
    {
        request: ["workspaceId","params"],
        success: ["params", "data", "entities", "meta"],
        failure: ["params", "error"]
    },
    { prefix: "WORKSPACES_UPDATE_" }
);

export { Creators, Types };

export const request = (state: Object, action: Object) => {
    return state.merge({ updating: true, error: null });
};

export const success = (state: Object, { data }: Object) => {
    return state.merge({ updating: false, error: null});
};

export const failure = (state: Object, { error }: Object) => {
    return state.merge({ updating: false, error });
};

export default {
    [Types.REQUEST]: request,
    [Types.SUCCESS]: success,
    [Types.FAILURE]: failure
};
