import { takeLatest, all, takeEvery } from "redux-saga/effects";
import {
    setHeader,
    fetchSchema,
    listWorkspaces,
    fetchWorkspace,
    createWorkspace,
    updateWorkspace,
    listFiles,
    listDetailFiles,
    createFile,
    swapFile,
    updateFile,
    importFile,
    listProjects,
    fetchProject,
    rebaseProject,
    createProject,
    publishProject,
    listProjectSnapshots,
    createProjectSnapshot,
    deleteProjectSnapshot,
    saveNodeResource,
    createNode,
    fetchNode,
    fetchNodeTree,
    moveNode,
    deleteNode,
    undeleteNode,
    revertNode,
    searchNode,
    listResources,
    fetchResource,
    fetchIvDetailsResource,
    createResource,
    saveResource,
    fetchResourceReferences,
    searchResource,
    doActionResource,
    importResource,
    fetchResourceUpdates,
    saveResourceUpdates,
    fetchGameTemplate,
    saveGameTemplate,
    listActivities,
    loginUser,
    logoutUser,
    createIvRequest
} from "../Services/Api";

import {
    ListTypes as WorkspacesListTypes,
    ListCreators as WorkspacesListCreators,
    FetchTypes as WorkspacesFetchTypes,
    FetchCreators as WorkspacesFetchCreators,
    CreateTypes as WorkspacesCreateTypes,
    CreateCreators as WorkspacesCreateCreators,
    UpdateTypes as WorkspaceUpdateTypes,
    UpdateCreators as WorkspaceUpdateCreators
} from "../Redux/WorkspacesRedux";

import {
    ListTypes as FilesListTypes,
    ListCreators as FilesListCreators,
    CreateTypes as FilesCreateTypes,
    CreateCreators as FilesCreateCreators,
    UpdateTypes as FilesUpdateTypes,
    UpdateCreators as FilesUpdateCreators,
    ImportTypes as FilesImportTypes,
    ImportCreators as FilesImportCreators,
    DetailListTypes as FilesDetailListTypes,
    DetailListCreators as FilesDetailListCreators
} from "../Redux/FilesRedux";

import {
    FetchTypes as ProjectsFetchTypes,
    FetchCreators as ProjectsFetchCreators,
    ListTypes as ProjectsListTypes,
    ListCreators as ProjectsListCreators,
    CreateTypes as ProjectsCreateTypes,
    CreateCreators as ProjectsCreateCreators,
    PublishTypes as ProjectsPublishTypes,
    PublishCreators as ProjectsPublishCreators,
    RebaseTypes as ProjectsRebaseTypes,
    RebaseCreators as ProjectsRebaseCreators
} from "../Redux/ProjectsRedux";

import {
    ListTypes as ProjectSnapshotsListTypes,
    ListCreators as ProjectSnapshotsListCreators,
    CreateTypes as ProjectSnapshotsCreateTypes,
    CreateCreators as ProjectSnapshotsCreateCreators,
    DeleteTypes as ProjectSnapshotsDeleteTypes,
    DeleteCreators as ProjectSnapshotsDeleteCreators
} from "../Redux/ProjectSnapshotsRedux";

import {
    FetchTypes as GameTemplatesFetchTypes,
    FetchCreators as GameTemplatesFetchCreators,
    SaveTypes as GameTemplatesSaveTypes,
    SaveCreators as GameTemplatesSaveCreators
} from "../Redux/GameTemplatesRedux";

import {
    AttachTypes as NodesAttachTypes,
    AttachCreators as NodesAttachCreators,
    FetchTypes as NodesFetchTypes,
    FetchCreators as NodesFetchCreators,
    FetchTreeTypes as NodesFetchTreeTypes,
    FetchTreeCreators as NodesFetchTreeCreators,
    CreateTypes as NodesCreateTypes,
    CreateCreators as NodesCreateCreators,
    MoveTypes as NodesMoveTypes,
    MoveCreators as NodesMoveCreators,
    DeleteTypes as NodesDeleteTypes,
    DeleteCreators as NodesDeleteCreators,
    UndeleteTypes as NodesUndeleteTypes,
    UndeleteCreators as NodesUndeleteCreators,
    RevertTypes as NodesRevertTypes,
    RevertCreators as NodesRevertCreators,
    SearchTypes as NodesSearchTypes,
    SearchCreators as NodesSearchCreators
} from "../Redux/NodesRedux";

import {
    Types as SchemaTypes,
    Creators as SchemaCreators
} from "../Redux/SchemaRedux";

import {
    Types as ResourcesFetchIvDetailsTypes,
    Creators as ResourcesFetchIvDetailsCreators
} from "../Redux/IvDetailsRedux";

import {
    Types as CreateIvRequestTypes,
    Creators as CreateIvRequestCreators
} from "../Redux/IvCreateRequestRedux";

import {
    Types as SessionLoginTypes,
    LogoutTypes as SessionLogoutTypes,
    LogoutCreators as SessionLogoutCreators,
    Creators as SessionLoginCreators
} from "../Redux/SessionRedux";

import {
    ListTypes as ActivitiesListTypes,
    ListCreators as ActivitiesListCreators
} from "../Redux/ActivitiesRedux";

import {
    Types as StartupTypes,
    Creators as StartupCreators
} from "../Redux/StartupRedux";

import { startupSaga } from "./StartupSaga";
import {
    entitiesSaga,
    entitiesUpdateSaga,
    entitiesSagaDebounced,
    entitiesSagaCleanup,
    entitiesActionDoneSaga
} from "./EntitiesSaga";
import { uploadSaga } from "./UploadSaga";
import { schemaSaga } from "./SchemaSaga";

import { loginSaga } from "./LoginSaga";
import { logoutSaga } from "./LogoutSaga";
import { updateClientSaga, announceAppearSaga } from "./ActionCableSaga";

import {
    ListTypes as ResourcesListTypes,
    ListCreators as ResourcesListCreators,
    FetchTypes as ResourcesFetchTypes,
    FetchCreators as ResourcesFetchCreators,
    CreateTypes as ResourcesCreateTypes,
    CreateCreators as ResourcesCreateCreators,
    SaveTypes as ResourcesSaveTypes,
    SaveCreators as ResourcesSaveCreators,
    ReferencesTypes as ResourcesReferencesTypes,
    ReferencesCreators as ResourcesReferencesCreators,
    SearchTypes as ResourcesSearchTypes,
    SearchCreators as ResourcesSearchCreators,
    ActionTypes as ResourcesActionTypes,
    ActionCreators as ResourcesActionCreators,
    ImportTypes as ResourcesImportTypes,
    ImportCreators as ResourcesImportCreators,
    FetchRcMcqCreators as ResourcesRcMcqFetchCreators,
    FetchRcMcqTypes as ResourcesRcMcqTypes,
} from "../Redux/ResourcesRedux";

import {
    FetchTypes as ResourceUpdatesFetchTypes,
    FetchCreators as ResourceUpdatesFetchCreators,
    SaveTypes as ResourceUpdatesSaveTypes,
    SaveCreators as ResourceUpdatesSaveCreators
} from "../Redux/ResourceUpdatesRedux";

export default function* root() {
    yield all([
        takeLatest(
            StartupTypes.STARTUP,
            startupSaga,
            StartupCreators,
            setHeader
        ),
        //Schema
        takeLatest(
            SchemaTypes.REQUEST,
            schemaSaga,
            SchemaCreators,
            StartupCreators,
            fetchSchema
        ),

        //Workspaces
        takeLatest(
            WorkspacesListTypes.REQUEST,
            entitiesSaga,
            WorkspacesListCreators,
            listWorkspaces
        ),
        takeLatest(
            WorkspacesFetchTypes.REQUEST,
            entitiesSaga,
            WorkspacesFetchCreators,
            fetchWorkspace
        ),
        takeLatest(
            WorkspacesCreateTypes.REQUEST,
            entitiesSaga,
            WorkspacesCreateCreators,
            createWorkspace
        ),
        takeLatest(
            WorkspaceUpdateTypes.REQUEST,
            entitiesSaga,
            WorkspaceUpdateCreators,
            updateWorkspace
        ),

        //Files
        takeLatest(
            FilesListTypes.REQUEST,
            entitiesSaga,
            FilesListCreators,
            listFiles
        ),
        takeLatest(
            FilesDetailListTypes.REQUEST,
            entitiesSaga,
            FilesDetailListCreators,
            listDetailFiles
        ),
        takeEvery(
            FilesCreateTypes.REQUEST,
            uploadSaga,
            FilesCreateCreators,
            createFile,
            swapFile
        ),
        takeEvery(
            FilesUpdateTypes.REQUEST,
            entitiesSaga,
            FilesUpdateCreators,
            updateFile
        ),
        takeLatest(
            FilesImportTypes.REQUEST,
            entitiesSaga,
            FilesImportCreators,
            importFile
        ),

        //Projects
        takeLatest(
            ProjectsListTypes.REQUEST,
            entitiesSaga,
            ProjectsListCreators,
            listProjects
        ),
        takeLatest(
            ProjectsFetchTypes.REQUEST,
            entitiesSaga,
            ProjectsFetchCreators,
            fetchProject
        ),
        takeLatest(
            ProjectsRebaseTypes.REQUEST,
            entitiesSaga,
            ProjectsRebaseCreators,
            rebaseProject
        ),
        takeLatest(
            ProjectsCreateTypes.REQUEST,
            entitiesSaga,
            ProjectsCreateCreators,
            createProject
        ),
        takeLatest(
            ProjectsPublishTypes.REQUEST,
            entitiesSaga,
            ProjectsPublishCreators,
            publishProject
        ),

        //ProjectSnapshots
        takeLatest(
            ProjectSnapshotsListTypes.REQUEST,
            entitiesSaga,
            ProjectSnapshotsListCreators,
            listProjectSnapshots
        ),
        takeLatest(
            ProjectSnapshotsCreateTypes.REQUEST,
            entitiesSaga,
            ProjectSnapshotsCreateCreators,
            createProjectSnapshot
        ),
        takeLatest(
            ProjectSnapshotsDeleteTypes.REQUEST,
            entitiesSaga,
            ProjectSnapshotsDeleteCreators,
            deleteProjectSnapshot
        ),

        //nodes
        takeLatest(
            NodesAttachTypes.REQUEST,
            entitiesSaga,
            NodesAttachCreators,
            saveNodeResource
        ),
        takeLatest(
            NodesCreateTypes.REQUEST,
            entitiesSaga,
            NodesCreateCreators,
            createNode
        ),
        takeLatest(
            NodesFetchTypes.REQUEST,
            entitiesSaga,
            NodesFetchCreators,
            fetchNode
        ),
        takeEvery(
            NodesFetchTreeTypes.REQUEST,
            entitiesSaga,
            NodesFetchTreeCreators,
            fetchNodeTree
        ),
        takeLatest(
            NodesMoveTypes.REQUEST,
            entitiesSaga,
            NodesMoveCreators,
            moveNode
        ),
        takeLatest(
            NodesDeleteTypes.REQUEST,
            entitiesSaga,
            NodesDeleteCreators,
            deleteNode
        ),
        takeLatest(
            NodesUndeleteTypes.REQUEST,
            entitiesSaga,
            NodesUndeleteCreators,
            undeleteNode
        ),
        takeLatest(
            NodesRevertTypes.REQUEST,
            entitiesSaga,
            NodesRevertCreators,
            revertNode
        ),
        takeLatest(
            NodesSearchTypes.REQUEST,
            entitiesSaga,
            NodesSearchCreators,
            searchNode
        ),

        //Generic entities
        takeEvery("UPDATE", entitiesUpdateSaga),
        takeEvery("ACTION_DONE", entitiesActionDoneSaga),
        takeEvery("ANNOUNCE_USERS", entitiesUpdateSaga),
        takeLatest("ANNOUNCE_UPDATE", updateClientSaga),
        takeLatest("ACTION_CABLE_CONNECTED", announceAppearSaga),

        //Session
        takeLatest(
            SessionLoginTypes.REQUEST,
            loginSaga,
            SessionLoginCreators,
            setHeader,
            loginUser
        ),
        takeLatest(
            SessionLogoutTypes.REQUEST,
            logoutSaga,
            SessionLogoutCreators,
            setHeader,
            logoutUser
        ),

        //Resources
        takeLatest(
            ResourcesListTypes.REQUEST,
            entitiesSaga,
            ResourcesListCreators,
            listResources
        ),
        takeLatest(
            ResourcesFetchTypes.REQUEST,
            entitiesSaga,
            ResourcesFetchCreators,
            fetchResource
        ),
        takeLatest(
            ResourcesFetchIvDetailsTypes.REQUEST,
            entitiesSaga,
            ResourcesFetchIvDetailsCreators,
            fetchIvDetailsResource
        ),
        takeLatest(
            CreateIvRequestTypes.REQUEST,
            entitiesSaga,
            CreateIvRequestCreators,
            createIvRequest
        ),
        takeLatest(
            ResourcesCreateTypes.REQUEST,
            entitiesSaga,
            ResourcesCreateCreators,
            createResource
        ),
        takeLatest(
            ResourcesSaveTypes.REQUEST,
            entitiesSaga,
            ResourcesSaveCreators,
            saveResource
        ),
        takeLatest(
            ResourcesReferencesTypes.REQUEST,
            entitiesSaga,
            ResourcesReferencesCreators,
            fetchResourceReferences
        ),
        takeLatest(
            ResourcesSearchTypes.REQUEST,
            entitiesSagaDebounced,
            ResourcesSearchCreators,
            searchResource
        ),
        takeLatest(
            ResourcesActionTypes.REQUEST,
            entitiesSaga,
            ResourcesActionCreators,
            doActionResource
        ),
        takeLatest(
            ResourcesImportTypes.REQUEST,
            entitiesSaga,
            ResourcesImportCreators,
            importResource
        ),

        //ResourceUpdates
        takeLatest(
            ResourceUpdatesFetchTypes.REQUEST,
            entitiesSaga,
            ResourceUpdatesFetchCreators,
            fetchResourceUpdates
        ),
        takeLatest(
            ResourceUpdatesSaveTypes.REQUEST,
            entitiesSaga,
            ResourceUpdatesSaveCreators,
            saveResourceUpdates
        ),

        //GameTemplates
        takeLatest(
            GameTemplatesFetchTypes.REQUEST,
            entitiesSaga,
            GameTemplatesFetchCreators,
            fetchGameTemplate
        ),
        takeLatest(
            GameTemplatesSaveTypes.REQUEST,
            entitiesSaga,
            GameTemplatesSaveCreators,
            saveGameTemplate
        ),
        //Activities
        takeLatest(
            ActivitiesListTypes.REQUEST,
            entitiesSaga,
            ActivitiesListCreators,
            listActivities
        ),
        //Ela RC MCQ Creator
        takeLatest(
            ResourcesRcMcqTypes.REQUEST,
            entitiesSaga,
            ResourcesRcMcqFetchCreators,
            fetchResource
        ),

        /// Clean up

        takeLatest("ENTITIES_CLEANUP", entitiesSagaCleanup)
    ]);
}
