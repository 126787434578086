import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";

import compose from "recompose/compose";
import { connect } from "react-redux";

import {
  FetchCreators as WorkspacesFetchCreators,
  selectWorkspacesById,
  selectWorkspacesRequestState
} from "../Redux/WorkspacesRedux";
import {
  FetchCreators as ProjectsFetchCreators,
  selectProjectsById,
  selectProjectsRequestState
} from "../Redux/ProjectsRedux";

import {
  selectNodeById,
  selectNodesSearchResults,
  CreateCreators as NodesCreateCreators,
  FetchTreeCreators as NodesFetchTreeCreators,
  MoveCreators as NodesMoveCreators,
  DeleteCreators as NodesDeleteCreators,
  UndeleteCreators as NodesUndeleteCreators,
  SearchCreators as NodesSearchCreators,
  selectNodesRequestState
} from "../Redux/NodesRedux";

import { Creators as EntitiesCreators } from "../Redux/EntitiesRedux"

import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";

import PageWrapper from "../Components/PageWrapper";
import TitleBar from "../Components/TitleBar";
import Loading from "../Components/Loading";
import SearchInput from "../Components/SearchInput";
import ToolpoolPane, {TOOLPOOL_WIDTH} from "../Components/ToolpoolPane";

import NodeResourcesPage from "./NodeResourcesPage";
import ResourceUpdatesPage from "./ResourceUpdatesPage";
import ProjectSnapshotsPage from "./ProjectSnapshotsPage";

import { SortableTreeWithoutDndContext as SortableTree } from "../Components/react-sortable-tree";
import NodeRendererDefault from "../Components/react-sortable-tree/node-renderer-default";
import {
  getNodeAtPath,
  map as treeMap,
  walk as treeWalk
} from "../Components/react-sortable-tree";
import {
  WindowScroller,
  List as VirtualList,
  AutoSizer
} from "react-virtualized";

import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import ResourceIcon from "../Components/ResourceIcon";
import LaunchIcon from "../Components/LaunchIcon";
import ResourceUpdateIcon from "@material-ui/icons/Feedback";
import CameraIcon from "@material-ui/icons/Camera";
import Lock from "@material-ui/icons/Lock";

import { Route, Switch, Link } from "react-router-dom";
import { withRouter } from "react-router";

import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import classnames from "classnames";

import "../Components/react-sortable-tree/react-sortable-tree.css";
import "./styles/rst.css";
import qs from "qs";

const DND_NODE_TYPE = "NodeType";

const styles = theme => ({
  flex: { display: "flex" },
  toolPoolOpen: {
    '@media (min-width: 1024px)': {
      paddingRight: TOOLPOOL_WIDTH
    }
  },
  toolPoolContainer: {
    height: '100%',
    display: 'none',
    '@media (min-width: 1024px)': {
      display: 'block'
    }
  },
  navBtn: {
    marginLeft: theme.spacing(1)
  },
  stickyHead: {
    position: "sticky",
    top: 8,
    zIndex: 1000,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  searchLabel: {
    display: "inline-block"
  },
  error: {
    background: theme.palette.error["500"],
    color: "white"
  },
  badge: {
    fontSize: 8,
    marginRight: theme.spacing(1)
  },
  isHighlighted: {
    boxShadow: "-5px 5px 0 0 #90f9ba"
  },
  isFocused: {
    boxShadow: "-5px 5px 0 0 #90f9ba"
  },
  isChanged: {
    "& .rst__rowContents": {
      backgroundColor: theme.palette.primary["50"],
      borderColor: theme.palette.primary.main
    }
  },
  isMoved: {
    opacity: "0.6 !important"
  },
  focusIcon: {
    transform: "scaleX(-1)"
  },
  breadcrumbs: {
    "&>a::after": {
      content: "'>'",
      paddingLeft: theme.spacing(2)
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  treeBtns: {
    margin: theme.spacing(1),
    display: "flex"
  },
  btnLeftIcon: {
    marginRight: theme.spacing(1)
  },
  strikedOut: {
    textDecoration: "line-through",
    backgroundImage:
      "linear-gradient(transparent 9px,#000 9px,#000 11px,transparent 11px);"
  },
  versionString: {
    fontSize: "0.5em",
    color: theme.palette.grey["500"],
    paddingLeft: theme.spacing(2)
  },
  frozenNode: {
    background: "#ccc"
  },
  rightListItem: {
    color: theme.palette.primary.main
  }
});

//const getNodeKey = ({ node }) => (node.is_moved ? `${node.synth_id}_moved` : node.synth_id);
const getNodeKey = ({ node }) => node.id;

class ProjectsTreePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debugMode: process.env.NODE_ENV === "development",
      treeData: null,
      expansionState: null,
      searchFoundCount: 0,
      cartOpen: true
    };
  }

  mergeStateNode = ({ node, path }) => {
    let expand = false;
    const nodeKey = getNodeKey({ node });

    if (this.state.expansionState === null) {
      expand = path.length < 2;
    } else {
      expand = this.state.expansionState[nodeKey];
    }

    node.expanded = expand;

    if (this.state.expansionState === null) {
      this._expanstionState[nodeKey] = expand;
    }

    if (node.lazy_children && !node.children) {
      node.children = this.lazyLoadNode.bind(this, node);
    }

    return node;
  };

  lazyLoadNode = (node, { done }) => {
    if (this.props.nodeRequests.fetching_tree[node.id]) {
      //already requested
      return;
    }

    this.props.fetchNodeTree(
      this.props.match.params.workspace_id,
      this.props.match.params.id,
      node.id
    );
  };

  mergeState = tree => {
    this._expanstionState = {};
    let newTree = treeMap({
      treeData: tree,
      getNodeKey,
      callback: this.mergeStateNode,
      ignoreCollapsed: false
    });

    if (this.state.expansionState === null) {
      this.setState({ expansionState: this._expanstionState });
    }

    return newTree;
  };

  projectTreeNormalize = (project, tree) => {
    if (tree.id === project.root_node_id) {
      return tree.children;
    } else {
      return [tree];
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.rootNode &&
      nextProps.project &&
      (!this.props.project ||
        !this.state.treeData ||
        this.props.nodeRequests.fetching_tree !==
          nextProps.nodeRequests.fetching_tree ||
        !_isEqual(this.props.rootNode, nextProps.rootNode))
    ) {
      if (!this.__isDragging) {
        this.setState({
          treeData: this.mergeState(
            this.projectTreeNormalize(nextProps.project, nextProps.rootNode)
          ),
          error: null,
          updatePending: false,
          breadcrumbs: nextProps.rootNode.breadcrumbs || this.state.breadcrumbs
        });
      } else {
        this.setState({ updatePending: true, error: null });
      }
    }

    const error = nextProps.nodeRequests.error;
    if (error) {
      const actions = [
        "attaching",
        "creating",
        "deleting",
        "moving",
        "reverting",
        "undeleting"
      ];
      for (var i = 0, l = actions.length; i < l; i++) {
        const action = actions[i];
        if (
          this.props.nodeRequests[action] &&
          !nextProps.nodeRequests[action]
        ) {
          this.setState({
            error
          });
        }
      }
    }

    let { match } = this.props;
    let { match: nextMatch } = nextProps;

    if (!match.isExact && nextMatch.isExact) {
      if (this.state.searchQuery) {
        this.setState(
          {
            searchQuery: null
          },
          () => {
            this.setState({
              treeData: this.mergeState(
                this.projectTreeNormalize(nextProps.project, nextProps.rootNode)
              )
            });
          }
        );
      }
    }

    if (match.params.root_node_id !== nextMatch.params.root_node_id) {
      this.props.fetchNodeTree(
        nextMatch.params.workspace_id,
        nextMatch.params.id,
        nextMatch.params.root_node_id,
        { breadcrumbs: 1 }
      );
    }

    if (this.props.location.search !== nextProps.location.search) {
      this.fetchSearchData(nextProps);
    }
  }

  fetchSearchData = props => {
    const {
      location,
      rootNode,
      match: { params }
    } = props || this.props;
    const queryParams = qs.parse(location.search.substr(1));
    const { q = "" } = queryParams;
    if (q.length > 0) {
      this.props.searchNode(
        params.workspace_id,
        params.id,
        params.root_node_id,
        queryParams
      );
    } 

    if (!rootNode || !this.state.treeData) {
      this.props.fetchNodeTree(
        params.workspace_id,
        params.id,
        params.root_node_id,
        { breadcrumbs: 1 }
      );
    }
  };

  onDragStateChanged = ({ isDragging, draggedNode }) => {
    this.__isDragging = isDragging;
    if (!isDragging && this.state.updatePending) {
      this.setState({
        treeData: this.mergeState(
          this.projectTreeNormalize(this.props.project, this.props.rootNode)
        ),
        error: null,
        updatePending: false
      });
    }
  };

  shiftState = event => {
    this.setState({ shiftKey: event.shiftKey });
  };

  componentDidMount() {
    let { workspace, project, fetchProject, fetchWorkspace } = this.props;
    let {
      match: { params }
    } = this.props;

    !workspace && fetchWorkspace(params.workspace_id);
    !project && fetchProject(params.workspace_id, params.id);

    this.fetchSearchData();

    document.addEventListener("keydown", this.shiftState);
    document.addEventListener("keyup", this.shiftState);
    this.props.subscribeChannel("NodeChannel");
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.shiftState);
    document.addEventListener("keyup", this.shiftState);
    this.props.unsubscribeChannel("NodeChannel");
    this.props.cleanupEntities("nodes");
  }

  onMoveNode = move => {
    const { treeData, prevPath, path, node } = move;
    if (!path) {
      //Was moved outside
      return;
    }

    let parentPath = [...path];
    parentPath.pop();
    let parentNode = getNodeAtPath({ treeData, path: parentPath, getNodeKey })
      .node;

    const siblings = parentNode.children;
    const index = siblings.findIndex(
      n => getNodeKey({ node: n }) === getNodeKey({ node })
    );

    let beforeNode = siblings[index - 1];
    let afterNode = siblings[index + 1];

    console.log(beforeNode, node, afterNode, parentNode);

    if (!prevPath) {
      //Insert new nodes!
      let resources = [];
      if (node.type === "node") {
        resources = [
          {
            resource_name: node.resource_name,
            resource_id: node.resource_id,
            scoped_id: node.scoped_id
          }
        ];
      } else if (node.type === "multiple_resources") {
        resources = node.resources;
      } else {
        resources = [
          { resource_name: node.resource_name, resource_id: node.resource_id }
        ];
      }

      const nodeParams = {
        after: _get(beforeNode, "id"),
        before: _get(afterNode, "id"),
        parent: _get(parentNode, "id"),
        resources
      };

      this.props.createNode(
        this.props.match.params.workspace_id,
        this.props.match.params.id,
        nodeParams
      );
    } else {
      const nodeParams = {
        after: _get(beforeNode, "id"),
        before: _get(afterNode, "id"),
        parent: _get(parentNode, "id"),
        resource_name: node.resource_name,
        resource_id: node.resource_id
      };

      this.props.moveNode(
        this.props.match.params.workspace_id,
        this.props.match.params.id,
        node.id,
        nodeParams
      );
    }
  };

  onDeleteNode = ({ node }) => {
    this.props.deleteNode(
      this.props.match.params.workspace_id,
      this.props.match.params.id,
      node.id
    );
  };

  onRestoreNode = ({ node }) => {
    this.props.undeleteNode(
      this.props.match.params.workspace_id,
      this.props.match.params.id,
      node.id
    );
  };

  clearError = () => {
    window.location.reload();
  };

  openNode = node => {
    // this.setState({
    //   searchQuery: { type: "nodeFocus", node: node },
    //   searchFocusOffset: 0
    // });
    
    const { history, workspace, project, rootNode } = this.props;
    if (node.resource_id)
      history.push(
        `/workspaces/${workspace.id}/projects/${project.id}/tree/${
          rootNode.id
        }/nodes/${node.id}/resources/${node.resource_name}/${node.resource_id}`
      );
    else
      history.push(
        `/workspaces/${workspace.id}/projects/${project.id}/tree/${
          rootNode.id
        }/nodes/${node.id}/resources`
      );
  };

  onVisibilityToggle = ({ node, expanded, path }) => {
    var expansionState = this.state.expansionState || {};
    expansionState[getNodeKey({ node })] = expanded;

    if (this.state.shiftKey) {
      treeWalk({
        treeData: [node],
        getNodeKey,
        callback: ({ node }) => {
          expansionState[getNodeKey({ node })] = expanded;
        },
        ignoreCollapsed: false
      });

      this.setState({ expansionState }, () => {
        const treeData = this.mergeState(this.state.treeData);
        this.setState({ treeData });
      });
    }
  };

  generateNodeProps = ({
    node,
    searchResults = false,
    isSearchMatch,
    isSearchFocus
  }) => {
    const {
      workspace,
      project,
      classes,
      projectsRequests,
      nodeSearchResults
    } = this.props;
    const abilities = _get(projectsRequests, "meta.abilities", {});

    const isGlobalSearchMatch =
      !searchResults &&
      nodeSearchResults &&
      nodeSearchResults.findIndex(n => n.id === node.id) >= 0;

    var buttons = [];

    if (!node.is_moved) {
      buttons.push(
        <IconButton
          color="primary"
          component={Link}
          to={`/workspaces/${workspace.id}/projects/${project.id}/tree/${
            node.id
          }`}
        >
          <LaunchIcon />
        </IconButton>
      );
    }

    if (node.resource_name === "frozens") {
      const title = (
        <div
          className={
            node.is_deleted && !node.is_moved ? classes.strikedOut : ""
          }
        >
          {node.nickname.underscoreToLabel()}
        </div>
      );

      return {
        title,
        buttons,
        className: classes.frozenNode
      };
    }

    if (
      abilities.destroy_node &&
      !project.is_published &&
      !project.is_frozen &&
      node.parent_id &&
      !node.is_moved &&
      (node.version_end === null || node.version_end === project.version)
    ) {
      buttons.push(
        node.is_deleted ? (
          <IconButton
            color="secondary"
            onClick={() => this.onRestoreNode({ node })}
          >
            <RestoreIcon />
          </IconButton>
        ) : (
          <IconButton
            color="secondary"
            onClick={() => this.onDeleteNode({ node })}
          >
            <DeleteIcon />
          </IconButton>
        )
      );
    }

    !project.is_published &&
      (node.version_end !== null && node.version_end !== project.version) &&
      buttons.push(
        <IconButton disabled>
          <Lock />
          {node.version_end}
        </IconButton>
      );

    !project.is_published &&
      (node.version_start > project.stable_version &&
        node.version_start !== project.version) &&
      buttons.push(
        <IconButton disabled>
          <Lock />
          {node.version_start}
        </IconButton>
      );

    buttons.push(
      <IconButton color="primary" onClick={this.openNode.bind(this, node)}>
        {node.resource_latest === false && !node.is_deleted ? (
          <ResourceUpdateIcon />
        ) : (
          <ResourceIcon />
        )}
      </IconButton>
    );

    if (this.state.debugMode)
      buttons.push(
        <code>
          <Badge
            className={classes.badge}
            title={node.synth_id || ""}
            badgeContent={node.synth_id || ""}
            color="primary"
            max={99999}
          >
            &nbsp;&nbsp;&nbsp;
          </Badge>
          <Badge
            className={classes.badge}
            title={node.id || ""}
            badgeContent={node.id || ""}
            color="secondary"
            max={99999}
          >
            &nbsp;&nbsp;&nbsp;
          </Badge>
          <Badge
            className={classes.badge}
            title={node.position || "0"}
            badgeContent={node.position || "0"}
            color="primary"
            max={99999}
          >
            &nbsp;&nbsp;&nbsp;
          </Badge>
          <Badge
            className={classes.badge}
            title={node.version_start || ""}
            badgeContent={node.version_start || ""}
            color="secondary"
            max={99999}
          >
            &nbsp;&nbsp;&nbsp;
          </Badge>
          <Badge
            className={classes.badge}
            title={node.version_end || "inf"}
            badgeContent={node.version_end || "inf"}
            color="primary"
            max={99999}
          >
            &nbsp;&nbsp;&nbsp;
          </Badge>
        </code>
      );

    const title = (
      <div
        className={node.is_deleted && !node.is_moved ? classes.strikedOut : ""}
      >
        {node.nickname || <i>None</i>}
      </div>
    );

    const subtitle =
      node.resource_name || (node.resource_id ? <i>Deprecated</i> : null);

    const isChanged =
      node.version_start >= project.version ||
      node.version_end === project.version;
    const className = classnames({
      [classes.isHighlighted]: isSearchMatch || isGlobalSearchMatch,
      [classes.isFocused]: isSearchFocus,
      [classes.isChanged]: isChanged,
      [classes.isMoved]: node.is_moved
    });

    return {
      title,
      subtitle,
      buttons,
      className
    };
  };

  searchMethod = ({ node, path, searchQuery }) => {
    if (!searchQuery) return false;
    if (searchQuery.type === "updateFocus")
      return node.resource_latest === false;
    if (searchQuery.type === "nodeFocus")
      return node.id === searchQuery.node.id;
    if (searchQuery.type === "query" && (searchQuery.value || "").length > 0)
      return (node.nickname || "").indexOf(searchQuery.value) > -1;
    return false;
  };

  handleSearch = event => {
    const {
      location,
      history,
      workspace,
      project,
      match: { params }
    } = this.props;
    const queryParams = qs.parse(location.search.substr(1));

    const newParams = { ...queryParams, q: event.target.value };

    if (!newParams.q || newParams.q.length === 0) {
      delete newParams.q;
    }

    history.replace(
      `/workspaces/${workspace.id}/projects/${project.id}/tree/${
        params.root_node_id
      }?` + qs.stringify(newParams)
    );
  };

  onWindowScroll = () => {
    if(this.state.searchQuery) {
      this.setState({
        searchQuery: null,
        searchFocusOffset: null
      })
    }
  }

  renderTree() {
    let { projectsRequests } = this.props;
    let { treeData, searchQuery, searchFocusOffset = 0 } = this.state;

    const abilities = _get(projectsRequests, "meta.abilities", {});

    return (
      <div className={"projectTree"}>
        {treeData && (
          <WindowScroller onScroll={ this.onWindowScroll }>
            {windowScrollerProps => (
              <SortableTree
                reactVirtualizedListProps={{
                  ...windowScrollerProps,
                  onScroll: ref => {
                    this.state.searchFoundCount > 0 &&
                    window.setTimeout(
                      () => windowScrollerProps.onChildScroll(ref),
                      0
                    )},
                  autoHeight: true,
                  autoWidth: true
                }}
                treeData={treeData}
                onChange={treeData => this.setState({ treeData })}
                onDragStateChanged={this.onDragStateChanged}
                shouldCopyOnOutsideDrop={true}
                dndType={DND_NODE_TYPE}
                searchMethod={this.searchMethod}
                searchQuery={searchQuery}
                searchFocusOffset={searchFocusOffset}
                searchFinishCallback={matches =>
                  this.setState({
                    searchFoundCount: matches.length,
                    searchFocusOffset:
                      matches.length > 0
                        ? this.state.searchFocusOffset % matches.length
                        : 0
                  })
                }
                canDrag={({ node }) => {
                  const project = this.props.project;
                  if (!abilities.update_node) return false;
                  if (node.is_deleted) return false;
                  if (node.is_moved) return false;
                  if (node.resource_name === "frozens") return false;
                  if (node.version_end !== null) return false;
                  if (
                    node.version_start > project.stable_version &&
                    node.version_start !== project.version
                  )
                    return false;

                  return (
                    (!node.item || !!node.item.parent_id) &&
                    !project.is_published &&
                    !project.is_frozen
                  );
                }}
                canDrop={({ nextParent }) => {
                  const project = this.props.project;
                  if (project.is_published || project.is_frozen) {
                    return false;
                  }

                  if (!abilities.create_node) return false;
                  if (!nextParent) {
                    return false;
                  }
                  if (nextParent.is_moved) {
                    return false;
                  }
                  if (typeof nextParent.children === "function") {
                    return false;
                  }
                  return true;
                }}
                onMoveNode={this.onMoveNode}
                getNodeKey={getNodeKey}
                onVisibilityToggle={this.onVisibilityToggle}
                generateNodeProps={this.generateNodeProps}
              />
            )}
          </WindowScroller>
        )}
      </div>
    );
  }

  renderSearchResults() {
    const { nodeRequests, nodeSearchResults = [] } = this.props;

    return (
      <div>
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <VirtualList
                  autoHeight
                  height={height}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  rowCount={nodeSearchResults.length}
                  rowHeight={69}
                  rowRenderer={({ index, style, key }) => {
                    const node = nodeSearchResults[index];
                    const props = this.generateNodeProps({
                      node,
                      searchResults: true
                    });
                    return (
                      <div style={style} key={key}>
                        <NodeRendererDefault
                          key={node.id}
                          node={node}
                          connectDragPreview={x => x}
                          connectDragSource={x => x}
                          path={[]}
                          treeId={"0"}
                          treeIndex={0}
                          isOver={false}
                          isDragging={false}
                          didDrop={false}
                          scaffoldBlockPxWidth={0}
                          {...props}
                        />
                      </div>
                    );
                  }}
                  scrollTop={scrollTop}
                  width={width}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
        {!nodeSearchResults.searching && nodeSearchResults.length === 0 && (
          <Typography align="center" color="secondary" variant="caption">
            No Results Found
          </Typography>
        )}
        {nodeRequests.searching && <Loading center />}
      </div>
    );
  }

  toggleDebug = () => this.setState({ debugMode: !this.state.debugMode });

  render() {
    let {
      classes,
      project,
      projectsRequests,
      workspacesRequests,
      workspace = {},
      rootNode = {},
      location,
      nodeRequests,
      nodeSearchResults = [],
      match: { params }
    } = this.props;
    let { error } = this.state;

    const abilities = _get(projectsRequests, "meta.abilities", {});

    const queryParams = qs.parse(location.search.substr(1));

    const defaultBreadCrumbs = project ? [{ id: project.root_node_id }] : [];

    const treeFetching = nodeRequests.fetching_tree[params.root_node_id];

    const { breadcrumbs = defaultBreadCrumbs } = this.state;

    const defaultNodes =
      project && !project.is_published && abilities.create_node
        ? [{ type: "new_node" }]
        : [];

    return (
      <PageWrapper
        classes={{
          container: this.state.cartOpen ? classes.toolPoolOpen : ""
        }}
      >
        {(projectsRequests.fetching ||
          workspacesRequests.fetching ||
          treeFetching) && <Loading center />}
        {project && workspace && (
          <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TitleBar
                breadcrumbs={[
                  { to: "/workspaces", title: "Workspaces" },
                  {
                    to: `/workspaces/${workspace.id}/`,
                    title: workspace.name || "..."
                  },
                  { title: project.name || "..." }
                ]}
                title={
                  <div onDoubleClick={this.toggleDebug}>
                    <span className={classes.versionString}>{` Version ${
                      project.version
                    }`}</span>
                  </div>
                }
                subtitle={
                  <div className={classes.flex}>
                    <Button
                      component={Link}
                      className={classes.navBtn}
                      to={`/workspaces/${workspace.id}/projects/${
                        project.id
                      }/tree/${rootNode.id}/snapshots`}
                      color="primary"
                    >
                      <CameraIcon className={classes.btnLeftIcon} />
                      Snapshots
                    </Button>
                  </div>
                }
              />

              {error && (
                <Chip
                  classes={{ root: classes.error }}
                  label={JSON.stringify(error).substr(0, 100)}
                  onDelete={this.clearError}
                />
              )}

              <div>
                <Paper classes={{ root: classes.stickyHead }}>
                  <Grid container justify="space-between" direction="row">
                    <Grid item>
                      <div className={classes.breadcrumbs}>
                        <Button
                          disabled={project.root_node_id === rootNode.id}
                          component={Link}
                          to={`/workspaces/${workspace.id}/projects/${
                            project.id
                          }/tree/${project.root_node_id}`}
                        >
                          All
                        </Button>
                        {breadcrumbs.slice(1).map((node, idx) => {
                          if (idx > 0 && !node.nickname) {
                            return null;
                          }

                          return (
                            <Button
                              key={node.id + "_" + idx}
                              component={Link}
                              to={`/workspaces/${workspace.id}/projects/${
                                project.id
                              }/tree/${node.id}`}
                            >
                              {node.nickname || <i>None</i>}
                            </Button>
                          );
                        })}
                        {queryParams.q && (
                          <Typography
                            classes={{ root: classes.searchLabel }}
                            variant="button"
                          >
                            Searching <i>{queryParams.q}</i>
                            {nodeSearchResults.length > 0 &&
                              ` (${nodeSearchResults.length} Result${
                                nodeSearchResults.length === 1 ? "" : "s"
                              })`}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.treeBtns}>
                        <SearchInput
                          onChange={this.handleSearch}
                          initialValue={queryParams.q}
                          enablePaste
                        />
                        {!project.is_published &&
                          !project.is_frozen &&
                          rootNode && (
                            <Button
                              component={Link}
                              to={`/workspaces/${workspace.id}/projects/${
                                project.id
                              }/tree/${rootNode.id}/resource-updates`}
                              color="primary"
                            >
                              <ResourceUpdateIcon
                                className={classes.btnLeftIcon}
                              />
                              Resource Updates
                            </Button>
                          )}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>

                {!queryParams.q
                  ? this.renderTree()
                  : this.renderSearchResults()}
                
                <div className={ classes.toolPoolContainer }>
                  <ToolpoolPane
                    open={!!this.state.cartOpen}
                    defaultNodes={defaultNodes}
                    onCartInteract={this.onCartInteract}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {project && workspace && (
          <Switch>
            <Route
              path="/workspaces/:workspace_id/projects/:project_id/tree/:root_node_id/nodes/:node_id"
              render={props => (
                <NodeResourcesPage
                  workspace={workspace}
                  project={project}
                  rootNode={rootNode}
                  abilities={abilities}
                  {...props}
                />
              )}
            />
            <Route
              path="/workspaces/:workspace_id/projects/:project_id/tree/:root_node_id/resource-updates"
              render={props => (
                <ResourceUpdatesPage
                  workspace={workspace}
                  project={project}
                  abilities={abilities}
                  rootNode={rootNode}
                  {...props}
                />
              )}
            />
            <Route
              path="/workspaces/:workspace_id/projects/:project_id/tree/:root_node_id/snapshots"
              render={props => (
                <ProjectSnapshotsPage
                  workspace={workspace}
                  project={project}
                  rootNode={rootNode}
                  {...props}
                />
              )}
            />
          </Switch>
        )}
      </PageWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchWorkspace: w => dispatch(WorkspacesFetchCreators.request(w)),
  fetchProject: (w, p) => dispatch(ProjectsFetchCreators.request(w, p)),
  createNode: (a, b, c) => dispatch(NodesCreateCreators.request(a, b, c)),
  moveNode: (a, b, c, d) => dispatch(NodesMoveCreators.request(a, b, c, d)),
  searchNode: (a, b, c, d) => dispatch(NodesSearchCreators.request(a, b, c, d)),
  fetchNodeTree: (a, b, c, d) =>
    dispatch(NodesFetchTreeCreators.request(a, b, c, d)),
  deleteNode: (a, b, c) => dispatch(NodesDeleteCreators.request(a, b, c)),
  undeleteNode: (a, b, c) => dispatch(NodesUndeleteCreators.request(a, b, c)),
  subscribeChannel: a => dispatch(ActionCableCreators.subscribe(a)),
  unsubscribeChannel: a => dispatch(ActionCableCreators.unsubscribe(a)),
  cleanupEntities: a => dispatch(EntitiesCreators.cleanup(a))
});

const mapStateToProps = (state, ownProps) => {
  let {
    match: { params }
  } = ownProps;
  return {
    workspace: selectWorkspacesById(state, params.workspace_id),
    workspacesRequests: selectWorkspacesRequestState(state),
    projectsRequests: selectProjectsRequestState(state),
    project: selectProjectsById(state, params.id),
    rootNode: selectNodeById(state, params.id, params.root_node_id),
    nodeSearchResults: selectNodesSearchResults(state),
    nodeRequests: selectNodesRequestState(state)
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProjectsTreePage);
