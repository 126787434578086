import React, { useState, useEffect, forwardRef, useRef } from 'react';
import _get from 'lodash/get';
import StoryScreen from "./Story";
import BridgeUtils from '../../utils/JsBridge';
import { GameContext } from '../GameContext';
import AssessmentScreen from "./../AssessmentScreen"
import Questions from "./../Questions";
import { useGameStates } from '../../utilComponents/hooks/useGameStates';
import { Timer } from '../../utils';
import useDidUpdateEffect from '../../utilComponents/hooks/useDidMountEffect';
import Header from './Header';


// is it bookmarked 
// onbookmark callback
const ReadAloud = forwardRef(({ data, config, closeGame, setGameClosed, mode, templateType, questionData, updateAnswer }) => {
  const [started, setStarted] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [questionStarted, setQuestionStarted] = useState(false);
  const [result, setResult] = useState([]);
  const [storyDetails, setStoryDetails] = useState({});
  const [options, setOptions] = useState([]);
  const [initialPage, setInitialPage] = useState(1);
  const [questionsData, setQuestionsData] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [oneTimeHint1Timer, setOneTimeHint1Timer] = useState(null);
  const [oneTimeHint2Timer, setOneTimeHint2Timer] = useState(null);
  const readTimer = useRef(new Timer());
  const [bookmarkStatus, setBookmarkStatus] = useState(false);
  const { gamePaused } = useGameStates();

  useDidUpdateEffect(() => {
    if (gamePaused) {
      if (readTimer.current) readTimer.current.pause();
    }
    else {
      if (readTimer.current) readTimer.current.resume();
    }
  }, [gamePaused]);


  const clearOneTimeHintTimer = () => {
    clearTimeout(oneTimeHint2Timer);
    clearTimeout(oneTimeHint1Timer);
  }

  const textToSpeech = (text, cb) => {
    console.log(`%c ${text}`, "color:red");
    // if (mode === "story")
    //   BridgeUtils.textToSpeech(text, (isPlayed) => {
    //     if (isPlayed && cb) {
    //       cb();
    //     }
    //   });
  };

  const listenToOption = (optionId, cb) => {
    console.log("starting listening to option ", optionId);
    if (readTimer.current) readTimer.current.resume();
    if (mode === "story")
      BridgeUtils.startReadAlongwithPermission(optionId, (data) => {
        cb(data);
      });
  };


  const changeMicStatus = (status) => {
    BridgeUtils.changeMicStatus(status);
  }

  const stopListening = (cb) => {
    BridgeUtils.stopReadAlong((isStopped) => {
      if (isStopped) {
        cb();
      }
    });
  };

  const updateResultByPage = (data, page) => {
    const resultArr = [...result];
    resultArr[page - 1] = data;
    setResult(resultArr);
  }

  const handleScoring = (score) => {
    if (score > 0) {
      setTotalScore(prevState => prevState + score);
    }
  }

  useEffect(() => {
    const storyData = data?.payload?.['context'];
    const instruction = storyData?.instruction?.context.html || "";
    const title = storyData?.title?.context.html || "";
    const options = [...(storyData?.pages || [])];
    const writer = storyData?.writer?.context.html || "";
    const tags = storyData?.tags || "";
    const readTime = storyData?.read_time?.context.html || "";
    const titleImage = storyData?.title_image?.context.html || "";
    const level = storyData?.level?.context.html || "";
    setStoryDetails({ instruction, title, readTime, titleImage, level, tags, writer });
    setOptions(options);
    setStarted(true);
    setQuestionsData(data?.questionList?.questions || []);
  }, [data, config])


  useEffect(() => {
    // hint timeouts
    if (started) {
      setOneTimeHint1Timer(setTimeout(() => {
        textToSpeech('If you need help, tap my photo', () => {
          clearTimeout(oneTimeHint1Timer);
        })
        setOneTimeHint2Timer(setTimeout(() => {
          textToSpeech('To get reading help, tap the word', () => {
            clearTimeout(oneTimeHint2Timer);
          })
        }, 7000));
      }, 7000));
    }
  }, [started])


  const renderComponent = () => {
    if (questionStarted || templateType === "read_aloud_mcq") return <Questions questionsData={questionData ? [questionData] : questionsData} updateAnswer={updateAnswer} mode={mode} />
    else if (started && completed) return <AssessmentScreen data={{}} showQuestions={true} />
    else return <StoryScreen />
  }

  const onBookmark = (status) => {
    setBookmarkStatus(status);
    //@pending bookmark callback
  }

  const getRecommendedStories = () => {
    const recommendedStoriesDummy = [
      { difficulty: "Easy", title: "The Robin Hood", stars: "500", imgSrc: "https://i.ytimg.com/vi/-PwO_F7WIys/maxresdefault.jpg" },
      { difficulty: "Medium", title: "Humpty Dumpty", stars: "300", imgSrc: "https://i.ytimg.com/vi/qtZd2_m3i-M/maxresdefault.jpg" },
      { difficulty: "Easy", title: "Bruce Almighty", stars: "600", imgSrc: "https://m.media-amazon.com/images/M/MV5BNzMyZDhiZDUtYWUyMi00ZDQxLWE4NDQtMWFlMjI1YjVjMjZiXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_.jpg" },
      { difficulty: "Easy", title: "The Robin Hood", stars: "500", imgSrc: "https://i.ytimg.com/vi/-PwO_F7WIys/maxresdefault.jpg" },
      { difficulty: "Medium", title: "Humpty Dumpty", stars: "300", imgSrc: "https://i.ytimg.com/vi/qtZd2_m3i-M/maxresdefault.jpg" },
      { difficulty: "Easy", title: "Bruce Almighty", stars: "600", imgSrc: "https://m.media-amazon.com/images/M/MV5BNzMyZDhiZDUtYWUyMi00ZDQxLWE4NDQtMWFlMjI1YjVjMjZiXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_.jpg" }
    ]
    return recommendedStoriesDummy
    //@pending recommended stories callback
  }

  return (
    <>
      <GameContext
        data={data}
        config={config}
        storyDetails={storyDetails}
        options={options}
        setCompleted={setCompleted}
        textToSpeech={textToSpeech}
        setStarted={setStarted}
        initialPage={initialPage}
        noOfPages={options.length}
        listenToOption={listenToOption}
        stopListening={stopListening}
        updateResultByPage={updateResultByPage}
        setQuestionStarted={setQuestionStarted}
        handleScoring={handleScoring}
        totalScore={totalScore}
        closeGame={closeGame}
        result={result}
        setGameClosed={setGameClosed}
        changeMicStatus={changeMicStatus}
        clearOneTimeHintTimer={clearOneTimeHintTimer}
        oneTimeHint2Timer={oneTimeHint2Timer}
        oneTimeHint1Timer={oneTimeHint1Timer}
        mode={mode}
        readTimer={readTimer.current}
        bookmarkStatus={bookmarkStatus}
        onBookmark={onBookmark}
        getRecommendedStories={getRecommendedStories}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        {renderComponent()}
      </GameContext>
    </>
  );
});

export default ReadAloud;