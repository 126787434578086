import React from 'react';

export default function FibOptionButton(props) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 468 150" preserveAspectRatio="none">
            <defs>
                <filter id="nrwn7ckmab" width="100.9%" height="108.3%" x="-.4%" y="-4.1%" filterUnits="objectBoundingBox">
                    <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5"/>
                    <feOffset dy="-1" in="shadowBlurInner1" result="shadowOffsetInner1"/>
                    <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1"/>
                    <feColorMatrix in="shadowInnerInner1" values={props.values}/>
                </filter>
                <path id="rzh49mvt8a" d="M10.954 43.295c-.252 2.822-2.603 5.034-5.466 5.034S.274 46.117.022 43.295H0V36C0 16.118 16.118 0 36 0h396c19.882 0 36 16.118 36 36v7.295h-.022c-.252 2.822-2.603 5.034-5.466 5.034-3.031 0-5.489-2.48-5.489-5.538 0-.34.03-.674.09-.998v-3.718c0-14.911-12.089-27-27-27H37.977c-14.912 0-27 12.089-27 27v5.22h-.023z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="468" height="150" fill="#FFF" rx="36"/>
                <g fillRule="nonzero">
                    <path fill="#FFD691" d="M10.954 43.295c-.252 2.822-2.603 5.034-5.466 5.034S.274 46.117.022 43.295H0V36C0 16.118 16.118 0 36 0h396c19.882 0 36 16.118 36 36v7.295h-.022c-.252 2.822-2.603 5.034-5.466 5.034-3.031 0-5.489-2.48-5.489-5.538 0-.34.03-.674.09-.998v-3.718c0-14.911-12.089-27-27-27H37.977c-14.912 0-27 12.089-27 27v5.22h-.023z"/>
                    <use fill={props.fillcolor} xlinkHref="#rzh49mvt8a"/>
                    <use fill="#000" filter="url(#nrwn7ckmab)" xlinkHref="#rzh49mvt8a"/>
                </g>
            </g>
        </svg>
    );
}

FibOptionButton.defaultProps = {
    fillcolor: "#F4B78E",
    values: "0 0 0 0 0.616111866 0 0 0 0 0.2522094 0 0 0 0 0.00761921877 0 0 0 1 0"
}