import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";

const VersionNavButtons = ({
    classes,
    prefix = "",
    suffix = "",
    resource_name,
    prevIndex,
    nextIndex
}) => {
    return (
        <div className={classes.versionNavContainer}>
            <Tooltip title="Previous version">
                <Button
                    component={Link}
                    disabled={!prevIndex}
                    to={`${prefix}/resources/${resource_name}/${prevIndex}${suffix}`}
                >
                    <NavigateBefore />
                </Button>
            </Tooltip>
            <Tooltip title="Next version">
                <Button
                    component={Link}
                    disabled={!nextIndex}
                    to={`${prefix}/resources/${resource_name}/${nextIndex}${suffix}`}
                >
                    <NavigateNext />
                </Button>
            </Tooltip>
        </div>
    );
};

const styles = theme => ({
    versionNavContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
});

export default withStyles(styles)(VersionNavButtons);
