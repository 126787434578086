import React from 'react';

export default function McqOptionButton(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 960 150" preserveAspectRatio="none">
            <defs>
                <linearGradient id="kb7c9916ya" x1="6.703%" x2="101.896%" y1="49.605%" y2="50.316%">
                    <stop offset="0%" stopColor="#8DF8F1"/>
                    <stop offset="100%" stopColor="#2CDCCB"/>
                </linearGradient>
                <linearGradient id="npa2a29wab" x1="4.964%" x2="94.329%" y1="49.914%" y2="49.968%">
                    <stop offset="0%" stopColor={props.offset0StopColor}/>
                    <stop offset="100%" stopColor={props.offset100StopColor}/>
                </linearGradient>
                <filter id="ku6cyb0zjd" width="100.4%" height="108.3%" x="-.2%" y="-4.2%" filterUnits="objectBoundingBox">
                    <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5"/>
                    <feOffset dy="-1" in="shadowBlurInner1" result="shadowOffsetInner1"/>
                    <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1"/>
                    <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                </filter>
                <path id="r6xax1csgc" d="M959.973 43c.018.164.027.331.027.5 0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5c0-.169.01-.336.027-.5h-.084V31c0-11.046-8.954-20-20-20h-900c-11.046 0-20 8.954-20 20v10.706c.038.26.057.524.057.794 0 3.038-2.462 5.5-5.5 5.5-2.869 0-5.225-2.197-5.478-5H0V27C0 12.088 12.088 0 27 0h906c14.912 0 27 12.088 27 27v16h-.027z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="959.002" height="150" x=".998" fill="#FFF" rx="31"/>
                <g fillRule="nonzero">
                    <path fill="url(#kb7c9916ya)" d="M959.973 43c.018.164.027.331.027.5 0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5c0-.169.01-.336.027-.5h-.084V31c0-11.046-8.954-20-20-20h-900c-11.046 0-20 8.954-20 20v10.706c.038.26.057.524.057.794 0 3.038-2.462 5.5-5.5 5.5-2.869 0-5.225-2.197-5.478-5H0V27C0 12.088 12.088 0 27 0h906c14.912 0 27 12.088 27 27v16h-.027z"/>
                    <use fill="url(#npa2a29wab)" xlinkHref="#r6xax1csgc"/>
                    <use fill="#000" filter="url(#ku6cyb0zjd)" xlinkHref="#r6xax1csgc"/>
                </g>
            </g>
        </svg>
    );
}

McqOptionButton.defaultProps = {
    fillcolor: "#F4B78E",
    values: "0 0 0 0 0.616111866 0 0 0 0 0.2522094 0 0 0 0 0.00761921877 0 0 0 1 0"
}