import React, { createContext, useContext } from 'react';

const ParentContext = createContext(null);

export const GameContext = ({ children, ...props }) => {
    return (
        <ParentContext.Provider value={{ ...props }}>
            {children}
        </ParentContext.Provider>
    );
};

export const useGameContext = () => {
    return useContext(ParentContext);
};