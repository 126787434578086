import Immutable from "seamless-immutable"
import localForage from "localforage"
import { serverSkew } from "../Redux/actionCableMiddleware"

class LSBackupService {
    constructor({ coolDown }) {
        this.coolDown = coolDown;
        this.lastSave = null;
        this.prefix = "LSBack"
    }

    key = (resourceName, resource) => `${this.prefix}_${resourceName}_${resource.id}`;

    push(resourceName, resource, ignoreCoolDown) {
        const now = new Date();

        if (
            ignoreCoolDown ||
            !this.lastSave ||
            now.getTime() - this.lastSave > this.coolDown
        ) {
            var resourceClone = resource.setIn(["updated_at"], now.toISOString());

            try{
                return localForage.setItem(this.key(resourceName, resource), resourceClone).then(() => {
                    this.lastSave = now.getTime();  
                })
            } catch (e) {
                this.garbageCollect();
                return false;
            }
        }
    }

    remove(resourceName, resource) {
        return localForage.removeItem(this.key(resourceName, resource));
    }

    get(resourceName, resource) {
        return new Promise((resolve, reject) => {
            localForage.getItem(this.key(resourceName, resource)).then((lsResource) => {
                if (!lsResource) {
                    resolve({ resource, ls: false });
                    return;
                }

                if( Math.abs(serverSkew.skew) > 3600 * 1000 ) { // > 1 hour clock skew
                    console.error("Clock skew too great LSBackupService gave up!")
                    resolve({ resource, lsLoad: false, lsWarn: "Your system clock is out of sync. Local backups wont work" });
                    return;
                }

                const lsLocalTime = Date.parse(lsResource.updated_at);
                const inTime = Date.parse(resource.updated_at);
                const lsTime = lsLocalTime + serverSkew.skew;

                console.log(
                    "skew", serverSkew.skew,
                    "lstime", lsTime,
                    "intime", inTime,
                    inTime < lsTime ? "LSLoad" : "RemoteLoad",
                    lsResource.updated_at,
                    resource.updated_at
                );

                if (inTime > lsTime) {
                    this.remove(resourceName, resource);
                    resolve({ resource, lsLoad: false })
                    return;
                }

                resolve({ resource: Immutable(lsResource), lsLoad: true, originalResource: resource })
            },
            reject)    
        })
    }

    garbageCollect() {
        console.log("LS garbageCollect")
        const now = new Date();

        var lru = [];
        localForage.iterate(function(resource, key, iterationNumber) {
            if ( !key.startsWith(this.prefix) )
                return;

            const ts = Date.parse(resource.updated_at);
            if( (now - ts) < 5*60*1000 ) 
                return;

            if(iterationNumber > 128) {
                return "ok thats enough!"
            }

            lru.push({ key, ts })
        }).then(function(result) {
            lru.sort((x) => x.ts).slice(0,5).forEach((x) => {
                console.log("Removing", x.key)
                localForage.removeItem(x.key)
            })
        }).catch(function(err) {
            // This code runs if there were any errors
            console.log(err);
        });
        
    }
}

export default LSBackupService;
