import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import { project } from "../../Services/Api/Schema";

import fetchReducer, { Types as FetchTypes, Creators as FetchCreators } from "./fetch";
import listReducer, { Types as ListTypes, Creators as ListCreators } from "./list";
import createReducer_, { Types as CreateTypes, Creators as CreateCreators } from "./create";
import publishReducer, { Types as PublishTypes, Creators as PublishCreators } from "./publish";
import rebaseReducer, { Types as RebaseTypes, Creators as RebaseCreators } from "./rebase";

export const INITIAL_STATE = Immutable({
	data: false,
	error: null
});

export const selectProjectsById = (state, id) => {
	try {
		return denormalize(
			state.entities.projects.getIn([parseInt(id, 10)]),
			project,
			state.entities
		);
	} catch (e) {
		return null;
	}
};

export const selectProjectsRequestState = state => {
	let { data, ...other } = state[key];
	return other;
};

export const key = "projects";

export { FetchCreators, FetchTypes };
export { ListCreators, ListTypes };
export { CreateCreators, CreateTypes };
export { PublishCreators, PublishTypes };
export { RebaseCreators, RebaseTypes };

export default createReducer(INITIAL_STATE, {
	...fetchReducer,
	...listReducer,
	...createReducer_,
	...publishReducer,
	...rebaseReducer
});
