import { put, call, select } from "redux-saga/effects";
import Config from "../Config/AppConfig";

import { Creators as SchemaCreators, selectSchema } from "../Redux/SchemaRedux";
import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";

export function* loginSaga(entity, setHeader, loginUser, data) {
	const response = yield call(loginUser, data);
	if (response.ok) {
		const accessToken = response.data.access_token;
		setHeader("Authorization", accessToken);

		yield put(
			ActionCableCreators.init(
				Config.wsEndpoint + "?token=" + accessToken
			)
		);
		yield put(ActionCableCreators.subscribe("AnnounceChannel"));

		yield put(entity.success(response.data));
		const schema = yield select(selectSchema);
		if (!schema) {
			yield put(SchemaCreators.request());
		}
	} else {
		yield put(entity.failure(response.data));
	}
}
