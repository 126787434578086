import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import compose from "recompose/compose";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";


import PageWrapper from "../Components/PageWrapper";
import TitleBar from "../Components/TitleBar";
import UserAvatar from "../Components/UserAvatar";
import Loading from "../Components/Loading";

import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";
import {
	ListCreators as ActivityListCreators,
	selectActivities,
	selectActivitiesRequestState
} from "../Redux/ActivitiesRedux";

import { Creators as EntitiesCreators } from "../Redux/EntitiesRedux"

import fecha from "fecha";
import { Link } from "react-router-dom";
import _get from "lodash/get";

class HomePage extends Component {
	componentDidMount() {
		this.props.subscribeChannel("ActivityChannel");
		this.props.listActivities();

		window.scrollTo(0, 0);
        window.addEventListener("scroll", this.onScroll);
	}

	componentWillUnmount() {
		this.props.unsubscribeChannel("ActivityChannel");
		this.props.cleanupEntities("activities");
		window.removeEventListener("scroll", this.onScroll);
	}

	onScroll = event => {
        const { activitiesRequests } = this.props;
        const next_page = _get(activitiesRequests, "meta.pagination.next_page");
        if (!next_page || !this.paginationRef) return;

        const delta =
            this.paginationRef.offsetTop - document.documentElement.scrollTop - window.innerHeight;

        if (!activitiesRequests.listing && delta < 100) {
            const params = {
                page: {
                    number: next_page
                }
            };

            this.props.listActivities(params);
        }
    };

	render() {
		const { activities = [], classes, activitiesRequests } = this.props;
		const pagination = _get(activitiesRequests, "meta.pagination");

		return (
			<PageWrapper>
				<TitleBar title={`CMS ${new Date().getFullYear()}`} />
				{activitiesRequests.listing && <Loading center />}
				{activitiesRequests.pendingCount > 0 && (
					<Button variant="outlined" color="primary" onClick={ () => this.props.listActivities() } classes={{ root: classes.newActBtn }}>
						{activitiesRequests.pendingCount} New{" "}
						{activitiesRequests.pendingCount === 1
							? "activity"
							: "activities"}
					</Button>
				)}
				{activities && activities.map(this.renderActivity)}

				{pagination &&
	                pagination.next_page && (
	                    <div ref={ref => (this.paginationRef = ref)}>
	                        <Grid className={classes.pagination} container justify="center">
	                            <Grid item>
	                                <Loading size={32} />
	                            </Grid>
	                        </Grid>
	                    </div>
	                )}
			</PageWrapper>
		);
	}

	renderActivity = activity => {
		const { classes } = this.props;

		let action =
			activity.action[activity.action.length - 1] === "e"
				? activity.action + "d"
				: activity.action + "ed";

		let target = activity.target_url;
		if (
			!target &&
			(activity.actionable_type.substr(0, "Resource::".length) ===
			"Resource::")
		) {
			target = `/resources/${activity.resource_name}/${
				activity.actionable_id
			}`;
		} else {
		}

		const resName = activity.resource_name
			.underscoreToLabel()
			.replace(/ies$/, "y")
			.replace(/s$/, "");

		return (
			<Grid key={activity.id} container className={classes.activity}>
				<Grid item>
					<UserAvatar
						user={activity.user}
						className={classes.avatar}
					/>
				</Grid>
				<Grid item>
					{activity.user.name} {action}{" "}
					{target ? <Link to={target}>{resName}</Link> : resName}
					<br/>
					<Typography variant="caption">
						{fecha.format(
							Date.parse(activity.created_at),
							"ddd MMMM Do, YYYY hh:mm A"
						)}
					</Typography>
				</Grid>
			</Grid>
		);
	};
}

const styles = theme => ({
	avatar: { width: 32, height: 32, marginRight: theme.spacing(1) },
	activity: { padding: theme.spacing(1) },
	newActBtn: { width: '100%' }
});

const mapDispatchToProps = dispatch => ({
	subscribeChannel: a => dispatch(ActionCableCreators.subscribe(a)),
	unsubscribeChannel: a => dispatch(ActionCableCreators.unsubscribe(a)),
	listActivities: a => dispatch(ActivityListCreators.request(a)),
	cleanupEntities: a => dispatch(EntitiesCreators.cleanup(a))
});

const mapStateToProps = (state, ownProps) => ({
	activities: selectActivities(state),
	activitiesRequests: selectActivitiesRequestState(state)
});

export default compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)(HomePage);
