const dummyData = {
  readAloud: {
    id: 139, //story id
    nickname: 'read_aloud-hint-check',//story nickname
    tags: [], // story tags and genre
    title: 'Read Aloud Story', // about story
    description: '', //story discription
    thumbnail_url:
      'https://s3.ap-southeast-1.amazonaws.com/k123store.tllms.com/2020/09/23/58c894b9cce3b7f1/vlcsnap-2020-09-23-12h34m52s238.png',
    bundle_url:
      'https://s3.ap-southeast-1.amazonaws.com/k123store-dev.tllms.com/ela_bundles/rc-hint-check/139/qokd0f/3d1dd7732693bec6.json',
    payload: {
      context: {
        title: { context: { html: "Humpty dumpty" } },
        pages: [
          {
            id: 'a0b13de4-0166-4fb7-b6ba-9cf418109a2f',
            type: 'text',
            context: {
              html:
                '<img alt="" src="https://occ-0-1723-1722.1.nflxso.net/dnm/api/v6/E8vDc_W8CLv7-yMQu8KMEC7Rrr8/AAAABZIBF122GeT-sZ3bOkUxbfcthfQsvNkd6Q3nkiE7bJyLoMHXy8k8T2Ac4zbzVAPsADOwdofAazFaEHjmhPrj--ysWmtA.jpg?r=109" style="width: 1280px; height: 747px;" /><br /> \n It is the story about a lazy, irreverent slacker panda.',
            },
          },
          {
            id: 'a353cae0-39ea-423a-8b50-c124fedeb53e',
            type: 'text',
            context: {
              html:
                'shubhamx',
            },
          },
          {
            id: '9263f922-ea1b-4035-84c7-d5623ef2c689',
            type: 'text',
            context: {
              html: "hg"
            },
          },
          {
            id: 'ab988fdc-01f6-4403-85ff-0b62003685b8',
            type: 'text',
            context: {
              html:
                'The mic should be automatically  switched on when the story starts. ',
            },
          },
        ]
      },
    },
    created_at: '2021-02-15T08:53:23.543Z',
    updated_at: '2021-02-15T09:29:03.067Z',
    hint_info: {
      hint_ids: ['5882', '4131', '9185', '9232'],
      hint_values: [
        'Flowers may facilitate outcrossing (fusion of sperm and eggs from different individuals in a population) resulting from cross pollination or allow selfing (fusion of sperm and egg from the same flower) when self pollination occurs.',
        '𝘈 𝘧𝘭𝘰𝘸𝘦𝘳, 𝘴𝘰𝘮𝘦𝘵𝘪𝘮𝘦𝘴 𝘬𝘯𝘰𝘸𝘯 𝘢𝘴 𝘢 𝘣𝘭𝘰𝘰𝘮 𝘰𝘳 𝘣𝘭𝘰𝘴𝘴𝘰𝘮, 𝘪𝘴 𝘵𝘩𝘦 𝘳𝘦𝘱𝘳𝘰𝘥𝘶𝘤𝘵𝘪𝘷𝘦 𝘴𝘵𝘳𝘶𝘤𝘵𝘶𝘳𝘦 𝘧𝘰𝘶𝘯𝘥 𝘪𝘯 𝘧𝘭𝘰𝘸𝘦𝘳𝘪𝘯𝘨 𝘱𝘭𝘢𝘯𝘵𝘴 (𝘱𝘭𝘢𝘯𝘵𝘴 𝘰𝘧 𝘵𝘩𝘦 𝘥𝘪𝘷𝘪𝘴𝘪𝘰𝘯 𝘔𝘢𝘨𝘯𝘰𝘭𝘪𝘰𝘱𝘩𝘺𝘵𝘢, 𝘢𝘭𝘴𝘰 𝘤𝘢𝘭𝘭𝘦𝘥 𝘢𝘯𝘨𝘪𝘰𝘴𝘱𝘦𝘳𝘮𝘴).',
        '𝘮𝘦𝘤𝘩𝘢𝘯𝘪𝘴𝘮',
        '𝘍𝘭𝘰𝘸𝘦𝘳𝘴 𝘮𝘢𝘺 𝘧𝘢𝘤𝘪𝘭𝘪𝘵𝘢𝘵𝘦 𝘰𝘶𝘵𝘤𝘳𝘰𝘴𝘴𝘪𝘯𝘨 (𝘧𝘶𝘴𝘪𝘰𝘯 𝘰𝘧 𝘴𝘱𝘦𝘳𝘮 𝘢𝘯𝘥 𝘦𝘨𝘨𝘴 𝘧𝘳𝘰𝘮 𝘥𝘪𝘧𝘧𝘦𝘳𝘦𝘯𝘵 𝘪𝘯𝘥𝘪𝘷𝘪𝘥𝘶𝘢𝘭𝘴 𝘪𝘯 𝘢 𝘱𝘰𝘱𝘶𝘭𝘢𝘵𝘪𝘰𝘯) 𝘳𝘦𝘴𝘶𝘭𝘵𝘪𝘯𝘨 𝘧𝘳𝘰𝘮 𝘤𝘳𝘰𝘴𝘴 𝘱𝘰𝘭𝘭𝘪𝘯𝘢𝘵𝘪𝘰𝘯 𝘰𝘳 𝘢𝘭𝘭𝘰𝘸 𝘴𝘦𝘭𝘧𝘪𝘯𝘨 (𝘧𝘶𝘴𝘪𝘰𝘯 𝘰𝘧 𝘴𝘱𝘦𝘳𝘮 𝘢𝘯𝘥 𝘦𝘨𝘨 𝘧𝘳𝘰𝘮 𝘵𝘩𝘦 𝘴𝘢𝘮𝘦 𝘧𝘭𝘰𝘸𝘦𝘳) 𝘸𝘩𝘦𝘯 𝘴𝘦𝘭𝘧 𝘱𝘰𝘭𝘭𝘪𝘯𝘢𝘵𝘪𝘰𝘯 𝘰𝘤𝘤𝘶𝘳𝘴.',
      ],
    },
    user: {
      id: 295,
      name: 'Sushma Vasanthi',
      email: 'sushma.vasanthi@byjus.com',
      roles: ['read_all', 'editor', 'course_manager'],
      profile_pic_url:
        'https://lh3.googleusercontent.com/-4g2ubYQKK2Q/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucl1foj79LEvwPXIb3nmf5D8S4Xd_A/s96-c/s50-c/photo.jpg',
      web_client_version: 'development',
      teams: [{ id: 10, name: 'ELA', creator: 'Arun Prabhakar' }],
    },
    versions: [139],
    question_list_id: 52,
    questionList: {
      id: 52,
      list_type: 'read_aloud_mcq',
      data: null,
      teaching_unit_id: 70,
      questions: [
        {
          id: 241,
          rule_ids: [84],
          teaching_unit_id: 70,
          rawQuestion: {
            id: 215,
            template: 'read_aloud_mcq',
            payload: {
              container: {
                answers: {
                  correct: [
                    {
                      id: '232bf4b2-0dec-4d28-9691-981353fc261c',
                    },
                  ],
                  hint_id: '',
                },
                evaluate: true,
                metadata: {
                  options: [
                    {
                      id: '376634df-a9ea-4314-8124-32fc7ef09ed5',
                      type: 'text',
                      context: {
                        html: 'Zanzibar',
                      },
                    },
                    {
                      id: '232bf4b2-0dec-4d28-9691-981353fc261c',
                      type: 'text',
                      context: {
                        html: 'Dodoma',
                      },
                    },
                  ],
                  question: {
                    type: 'text',
                    context: {
                      html: 'What is the capital of Tanzania?',
                    },
                  },
                  questionImage: {
                    type: 'text',
                    context: {
                      html: '',
                    },
                  },
                  instruction: {
                    type: 'text',
                    context: {
                      html: 'Choose the correct option.',
                    },
                  },
                },
                external_data: {
                  type: 'read_aloud_mcq',
                  type_id: 10,
                  hasPayload: true,
                },
              },
            },
            buddy_audios: [],
            raw_question_audios: [],
          }
        },
        {
          id: 242,
          rule_ids: [84],
          teaching_unit_id: 70,
          type: 'AssessableQuestion',
          rawQuestion: {
            id: 216,
            template: 'read_aloud_mcq',
            payload: {
              container: {
                answers: {
                  correct: [
                    {
                      id: '599d1f1f-ce3a-4737-899f-349cc2128d44',
                    },
                  ],
                  hint_id: '',
                },
                evaluate: true,
                metadata: {
                  options: [
                    {
                      id: '340a9bd3-f128-4966-918a-1885dfbde97b',
                      type: 'text',
                      context: {
                        html: 'Mandazi is eaten as a snack or as a dessert.',
                      },
                    },
                    {
                      id: '599d1f1f-ce3a-4737-899f-349cc2128d44',
                      type: 'text',
                      context: {
                        html: 'More than 120 languages are spoken in Tanzania!',
                      },
                    },
                  ],
                  question: {
                    type: 'text',
                    context: {
                      html:
                        'Which of the following options suggests that Tanzania is a diverse country?',
                    },
                  },
                },
                external_data: {
                  type: 'read_aloud_mcq',
                  type_id: 10,
                  hasPayload: true,
                },
              },
            },
            buddy_audios: [],
            raw_question_audios: [],
          }
        },
        {
          id: 243,
          rule_ids: [84],
          teaching_unit_id: 70,
          type: 'AssessableQuestion',
          rawQuestion: {
            id: 217,
            template: 'read_aloud_mcq',
            payload: {
              container: {
                answers: {
                  correct: [
                    {
                      id: 'c627fb18-24d4-4970-b467-df6deccf4f7b',
                    },
                  ],
                  hint_id: '6861',
                },
                evaluate: true,
                metadata: {
                  options: [
                    {
                      id: 'c627fb18-24d4-4970-b467-df6deccf4f7b',
                      type: 'text',
                      context: {
                        html: 'sprinkled',
                      },
                    },
                    {
                      id: '22b0adde-687e-424a-86e5-1be7343a51f0',
                      type: 'text',
                      context: {
                        html: 'fragrance',
                      },
                    },
                    {
                      id: '9f159300-ab92-449b-b0f9-190a3e73dd1d',
                      type: 'text',
                      context: {
                        html: 'perfume',
                      },
                    },
                    {
                      id: '00ceab27-8521-4880-9231-b43b93e86202',
                      type: 'text',
                      context: {
                        html: 'uncover',
                      },
                    },
                  ],
                  question: {
                    type: 'text',
                    context: {
                      html:
                        'Find a word from the passage that is a synonym of &lsquo;sprayed&rsquo;.',
                    },
                  },
                },
                external_data: {
                  type: 'read_aloud_mcq',
                  type_id: 10,
                  hasPayload: true,
                },
              },
            },
            buddy_audios: [],
            raw_question_audios: [],
          }
        },
        {
          id: 244,
          rule_ids: [84],
          teaching_unit_id: 70,
          type: 'AssessableQuestion',
          rawQuestion: {
            id: 218,
            template: 'read_aloud_mcq',
            payload: {
              container: {
                answers: {
                  correct: [
                    {
                      id: 'b37a8584-9afd-43cd-94ea-f6956eef5b4a',
                    },
                  ],
                  hint_id: '',
                },
                evaluate: true,
                metadata: {
                  options: [
                    {
                      id: 'b37a8584-9afd-43cd-94ea-f6956eef5b4a',
                      type: 'text',
                      context: {
                        html:
                          'Both India and Tanzania have several local languages.',
                      },
                    },
                    {
                      id: 'fadc1a85-9654-4f12-a8a6-21120eb5860a',
                      type: 'text',
                      context: {
                        html: 'Both India and Tanzania are very colourful.',
                      },
                    },
                  ],
                  question: {
                    type: 'text',
                    context: {
                      html:
                        '<br />\n&lsquo;Tanzania is a diverse country like India.&rsquo;<br />\nWhy do you think Ananya said this?',
                    },
                  },
                },
                external_data: {
                  type: 'read_aloud_mcq',
                  type_id: 10,
                  hasPayload: true,
                },
              },
            },
            buddy_audios: [],
            raw_question_audios: [],
          }
        },
      ],
    },
    tableData: {
      id: 12,
    },
  },
};

export default dummyData;
