import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import _clonedeep from "lodash/cloneDeep";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  inline: {
    padding: theme.spacing(1)
  },
  rule: {
    fontWeight: "normal"
  },
  paddingLeft: {
    paddingLeft: theme.spacing(3)
  },
  fieldContainer2: {
    display: "flex",
    justifyContent: "space-between"
  },
  textField: {
    margin: theme.spacing(1),
    width: "calc(100% - " + theme.spacing(1) + "px)"
  }
});

function JsonEditor(props) {
  const { value, errors, vars, name, classes, disabled } = props;
  let data = _clonedeep(value);
  const handleChange = (event, key) => {
    let tmp = data;
    tmp[key] = event.target.value;
    props.onChange({ target: { name, value: tmp } });
  };
  const cast = (exp) => {
    switch (exp) {
      case "integer":
        return "number";
        break;
      case "string":
        return "text";
        break;
    }
  };
  return (
    <div className={classes.fieldContainer2}>
      {vars.map((attr, index) => (
        <TextField
          className={classes.textFieldInline}
          key={index}
          name={attr.name}
          disabled={disabled}
          error={!!errors}
          value={data[attr.name]}
          label={attr.name}
          type={cast(attr.type)}
          onChange={e => {
            handleChange(e, attr.name);
          }}
        />
      ))}
    </div>
  );
}

export default withStyles(styles)(JsonEditor);
