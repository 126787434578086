import React, { useRef, forwardRef, useImperativeHandle } from "react";
import Slider from "react-slick";
import "./style.scss";

const Pages = forwardRef(({ children, onPageChange, initialSlide }, ref) => {
    const sliderRef = useRef(null);
    // const [currentPage, setCurrentPage] = useState(initialPage)
    // const [touchStart, setTouchStart] = useState(0);
    // const [touchEnd, setTouchEnd] = useState(0);

    function handleTouchStart(e) {
        // setTouchStart(e.targetTouches[0].clientY);
    }

    function handleTouchMove(e) {
        // setTouchEnd(e.targetTouches[0].clientY);
    }

    function handleTouchEnd() {
        // const threshold = 150;
        // goToPage(5);
        // const diff = touchStart - touchEnd;
        // const pagesToSwipe = Math.floor((Math.abs(diff) - threshold) / 20);

        // if (diff > threshold) {
        //     if (currentPage)
        //         goToPage(pagesToSwipe);
        // }

        // if (diff < -threshold) {
        //     goToPage(currentPage - pagesToSwipe);
        // }
    }

    useImperativeHandle(ref, () => ({
        goToPrevPage,
        goToNextPage,
        goToPage
    }));

    const goToPage = (index) => {
        if (sliderRef.current)
            sliderRef.current.slickGoTo(index);
    }

    const goToNextPage = () => {
        if (sliderRef.current)
            sliderRef.current.slickNext();
    }
    const goToPrevPage = () => {
        if (sliderRef.current)
            sliderRef.current.slickPrev();
    }

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        touchThreshold: 20,
        initialSlide: initialSlide,
        afterChange: current => {
            // setCurrentPage(current);
            onPageChange(current + 1);
        }
    };

    return (
        <div className="swipe-detect" onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}>
            <Slider {...settings} ref={sliderRef} className="slides">
                {children}
            </Slider>
        </div>

    )
})

export default Pages;