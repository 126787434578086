import React from "react";
import PropTypes from "prop-types";
import keycode from "keycode";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Popper from '@material-ui/core/Popper';

import debounce from "lodash/debounce"


function renderInput(inputProps) {
  const { InputProps, classes, ref, disabled, isEmpty, ...other } = inputProps;

  return (
    <TextField
      {...other}
      disabled={ disabled }
      inputRef={ref}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: ((disabled && !isEmpty) ? classes.inputDisabledInput : classes.inputInput),
        },
        ...InputProps
      }}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem === suggestion);

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
};

function getSuggestions(choices, inputValue) {
  let count = 0;
  let prefixIndex = 0;
  let results = [];

  choices.forEach(suggestion => {
    const pos = suggestion.label
      .toLowerCase()
      .indexOf(inputValue.toLowerCase());
    const keep = (!inputValue || pos !== -1) && count < 5;

    if (keep) {
      if (pos === 0) {
        results.splice(prefixIndex, 0, suggestion);
        prefixIndex += 1;
      } else {
        results.push(suggestion);
      }
      count += 1;
    }

    return keep;
  });

  return results;
}

class DownshiftMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      choices: []
    };
  }

  handleKeyDown = event => {
    const { inputValue } = this.state;
    let { name, value } = this.props;
    if (value.length && !inputValue.length && keycode(event) === "backspace") {
      value = value.slice(0, value.length - 1);
      this.props.onChange({ target: { value, name } });
    }

    if (inputValue.length && keycode(event) === "enter") {
      let newValues = [], residuals = [];
      const choices = this.getChoices();

      inputValue.split(/\t/).forEach((eachValue) => {
        if(choices) {
          const suggestions = getSuggestions(choices, eachValue);
          if( suggestions.length > 0 && suggestions[0].label === eachValue ) {
            newValues.push(suggestions[0])
          }
          else {
            residuals.push(eachValue)
          }
        }
        else {
          newValues.push({ label: eachValue, value: eachValue })
        }
      })

      this.handleItems([...value, ...newValues]);

      this.setState({
        inputValue: residuals.join("\t")
      });
    }
  };

  handleInputChange = event => {
    const { maxItems, minLength=1 } = this.props;

    if( maxItems && this.props.value.length >= maxItems ) {
      event.preventDefault();
      return;
    }

    this.setState({ inputValue: event.target.value });

    if(typeof(this.props.choices) === 'function' && event.target.value.length >= minLength) {
      this.debouncedGetChoices( event.target.value );
    }
  };

  debouncedGetChoices = debounce(q => {
    this.props.choices(q).then(choices => this.setState({ choices }));
  }, 300);

  handleItems = (value) => {
    const { maxItems, name } = this.props;
    if(maxItems && value.length > maxItems) {
      return;
    }

    this.props.onChange({ target: { value, name }})
  }

  handleChange = item => {
    let { value } = this.props;

    if (!value.some(i => i.value === item.value)) {
      value = [...value, item];
    }

    this.setState({
      inputValue: ""
    });

    this.handleItems(value);
  };

  handleDelete = item => () => {
    let { name, value } = this.props;
    const selectedItem = [...value];
    selectedItem.splice(selectedItem.indexOf(item), 1);

    this.props.onChange({ target: { value: selectedItem, name } });
  };

  getChoices() {
    const { choices } = this.props;

    if(typeof(choices) === 'function') {
      return this.state.choices;
    }
    else {
      return choices;
    }
  }

  handleBlur = () => {
    this.handleKeyDown({ keyCode: 13, key: "enter" })
  }

  render() {
    const { classes, value, disabled, disableUnderline, InputProps, onClick=null, ...other } = this.props;
    const { inputValue } = this.state;
    const isEmpty = value.length === 0;

    delete other.onChange;
    delete other.choices;
    delete other.maxItems;

    const choices = this.getChoices() || [];

    return (
      <Downshift inputValue={inputValue} onChange={this.handleChange} selectedItem={value} itemToString={ item => item.value }>
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              disabled,
              isEmpty,
              InputProps: getInputProps({
                disableUnderline,
                startAdornment: value.map(item => (
                  <Chip
                    key={ item.value }
                    tabIndex={-1}
                    label={ item.label.substr(0, 100) }
                    className={classes.chip}
                    onDelete={disabled ? null : this.handleDelete(item)}
                    onClick={onClick ? () => onClick(item): null }
                  />
                )),
                onFocus: this.handleInputChange,
                onBlur: this.handleBlur,
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown,
                id: `${other.name}-integration-downshift-multiple`,
                ...InputProps
              }),
              ref: node => {
                this.popperNode = node;
              },
              ...other
            })}
            <Popper open={isOpen} anchorEl={this.popperNode}>
              <Paper className={classes.paper} square style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}>
                {getSuggestions(choices, inputValue2).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion }),
                    highlightedIndex,
                    selectedItem: selectedItem2
                  })
                )}
              </Paper>
            </Popper>
          </div>
        )}
      </Downshift>
    );
  }
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    zIndex: 1,
    marginLeft: theme.spacing(1),
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0.25)}px`
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 50,
    flexGrow: 1,
  },
  inputDisabledInput: {
    display: 'none'
  }
});

export default withStyles(styles)(DownshiftMultiple);
