// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    request: ["resourceName", "resourceID", "studio"],
    success: ["params", "data", "entities", "meta"],
    failure: ["params", "error"]
  },
  { prefix: "RESOURCES_FETCH_RC_MCQ_" }
);

export { Creators, Types };

export const request = (state: Object, action: Object) => {
  return state.merge({ fetching_rc_mcq: true, error: null });
};

export const success = (state: Object, { data, meta }: Object) => {
  return state.merge({ fetching_rc_mcq: false, error: null, meta });
};

export const failure = (state: Object, { error }: Object) => {
  return state.merge({ fetching_rc_mcq: false, error });
};

export default {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure
};
