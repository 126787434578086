import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

function UserAvatar({ user, tooltip=true, ttip_placement="left", ...other }) {
	var proxiedPictureURL =
		process.env.NODE_ENV === "production" && user.profile_pic_url
			? user.profile_pic_url.replace("https://", "/im/")
			: user.profile_pic_url;

	const AvatarElement = <Avatar alt={user.name} src={proxiedPictureURL} {...other} />;
	if(!tooltip) { 
		return AvatarElement;
	}
	
	return (
		<Tooltip title={user.name || "Unknown"} placement={ttip_placement}>
			{ AvatarElement }
		</Tooltip>
	);
}

export default UserAvatar;
