import Constants from './Constants';

export function getCleanedData(data) {
  const cleanedData = {
    resourceData: {},
    qListId: null,
  };
  if (data.hasOwnProperty('question_list')) {
    cleanedData.resourceData = data['question_list']['questions'];
    cleanedData.qListId = data['question_list'].id;
  } else if (data.hasOwnProperty('question')) {
    cleanedData.resourceData = data['question'];
  } else if (data.hasOwnProperty('reading_comprehension')) {
    cleanedData.resourceData = data['reading_comprehension'];
  }
  return cleanedData;
}

export function getCleanedMigratedData(data, resourceType) {
  const cleanedData = {
    resourceData: {},
    qListId: null,
    listType: null,
  };
  cleanedData.listType = data?.listType;

  switch (resourceType) {
    case Constants.RESOURCE_TYPE.READING_COMPREHENSION:
      cleanedData.resourceData = data;
      break;

    case Constants.RESOURCE_TYPE.QUESTION_LIST:
      cleanedData.resourceData = data['questions'];
      cleanedData.qListId = data['nickname'];
      break;

    case Constants.RESOURCE_TYPE.QUESTION:
      cleanedData.resourceData = data;
      break;
    default:
      break;
  }

  return cleanedData;
}

export function getAudioUrlFromRawAudios(audios = [], attribute_name) {
  const audioList = audios.filter(
    (obj) => obj['attribute_name'] === attribute_name
  );
  let audioUrl = null;
  if (audioList.length > 0) {
    audioUrl = audioList.pop().audio_url;
  }
  return audioUrl;
}

export function getContentType(metaData) {
  const allTypes = {}
  Object.keys(metaData).forEach((data) => {
    allTypes[data] = metaData[data].contentType;
  })
  return allTypes;
}
