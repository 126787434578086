// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		progress: ["params", "data", "entities"],
		swap: ["old_id", "new_id", "entities"],
		success: ["params", "data", "entities", "meta"],
		failure: ["params", "error", "entities"]
	},
	{ prefix: "FILES_UPLOAD_" }
);

export { Types, Creators };

const progress = (state: Object, action: Object) => {
	return state.merge({ uploading: true, error: null });
};

const success = (state: Object, { data }: Object) => {
	return state.merge({ uploading: false, error: null });
};

const failure = (state: Object, { error }: Object) => {
	return state.merge({ uploading: false, error });
};

const swap = (state: Object, { new_id, old_id }: Object) => {
	const existingData = state.data;
	const filteredData = existingData.filter(x => (x !== old_id && x !== new_id))

	return state.merge({
		data: [new_id, ...filteredData]
	})
}

export default {
	[Types.PROGRESS]: progress,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure,
	[Types.SWAP]: swap,
};
