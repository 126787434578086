import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import { activity } from "../../Services/Api/Schema";

import listReducer, { Types as ListTypes, Creators as ListCreators } from "./list";

export const INITIAL_STATE = Immutable({
  data: false,
  pendingCount: 0,
  error: null
});

export const selectActivities = state => {
  try {
    return denormalize(state[key].data, [activity], state.entities);
  } catch (e) {
    return null;
  }
};

export const selectActivitiesRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};

export const key = "activities";

export { ListCreators, ListTypes };

const activityReducer = (state: Object, action: Object) => {
  return state.merge({ pendingCount: state.pendingCount + 1 });
}

export default createReducer(INITIAL_STATE, {
  ...listReducer,
  'ACTIVITY': activityReducer
});
