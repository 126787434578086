// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["fileId", "params"],
		success: ["params", "data", "entities"],
		failure: ["params", "error"]
	},
	{ prefix: "FILES_UPDATE_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
	return state.merge({ updating: true, error: null });
};

const success = (state: Object, { data }: Object) => {
	return state.merge({ updating: false, error: null });
};

const failure = (state: Object, { error }: Object) => {
	return state.merge({ updating: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
