import { useState, useEffect } from 'react';
import BridgeUtils from '../../utils/JsBridge';

export function useGameStates() {
  const [isInternetAvailable, setIsInternetAvailable] = useState(true);
  const [appLifeCycle, setAppLifeCycle] = useState('Active');
  const [gamePaused, setGamePaused] = useState(false);
  const [onCall, setOnCall] = useState(false);

  useEffect(() => {
    observeAppLifeCycle();
    checkInternetConnectivity();
    onCallListener();
  }, []);

  const checkInternetConnectivity = () => {
    BridgeUtils.checkInternetConnectivity((checkInternetPermission) => {
      console.log('check internet connectivity ', checkInternetPermission);
      setIsInternetAvailable(checkInternetPermission);
    });
  };

  const observeAppLifeCycle = () => {
    BridgeUtils.observeAppLifeCycle((appStatus) => {
      console.log('observeAppLifeCycle callBck called with - ', appStatus);
      setAppLifeCycle(appStatus);
    });
  };

  const onCallListener = () => {
    BridgeUtils.onCallListener((callStatus) => {
      console.log('callStatus', callStatus);
      changeOnCallStatus(callStatus);
    });
  };

  const changeOnCallStatus = (value) => {
    console.log('change call status', value);
    setOnCall(value);
  };

  useEffect(() => {
    setGamePaused((s) => (isInternetAvailable === false ? true : false));
  }, [isInternetAvailable]);

  useEffect(() => {
    setGamePaused((s) => (appLifeCycle.toLowerCase() === 'inactive' ? true : false));
  }, [appLifeCycle]);

  useEffect(() => {
    setGamePaused((s) => (onCall === true ? true : s));
    if (onCall !== null) setOnCall(null);
  }, [onCall]);

  return {
    gamePaused,
    setGamePaused,
    onCall,
    isInternetAvailable,
    appLifeCycle,
    changeOnCallStatus,
  };
}
