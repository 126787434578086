// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: [],
		success: ["params", "data", "entities", "meta"],
		failure: ["params", "error"]
	},
	{ prefix: "GAME_TEMPLATES_LIST_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
	return state.merge({ listing: true, error: null });
};

const success = (state: Object, { data, meta }: Object) => {
	return state.merge({
		listing: false,
		data: data.game_templates,
		error: null,
		meta
	});
};

const failure = (state: Object, { error }: Object) => {
	return state.merge({ listing: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
