import React from "react";

import { withStyles } from "@material-ui/core/styles";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ContentCopy from "@material-ui/icons/FileCopyOutlined";

const ClickCopyText = ({
	text,
	showCopy = true,
	copyText = null,
	title = "Click to Copy!",
	placement = "top",
	variant = "caption",
	classes,
	...other
}) => (
	<Typography variant={variant} {...other}>
		{text}
		{showCopy && (
			<Tooltip
				title={title}
				placement={placement}
				classes={{ tooltip: classes.customWidth }}
			>
				<CopyToClipboard text={copyText || text}>
					<IconButton>
						<ContentCopy className={ classes.smallIcon }/>
					</IconButton>
				</CopyToClipboard>
			</Tooltip>
		)}
	</Typography>
);

const styles = theme => ({
	customWidth: {
		maxWidth: 'none'
	},
	smallIcon: { fontSize: 16 }
});

export default withStyles(styles)(ClickCopyText);
