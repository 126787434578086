import { useTheme } from "styled-components";
import React from "react";
import styled, { css } from "styled-components";
import "./Button.scss";


const getBtnTheme = (theme) => ({
  primary: {
    background: `linear-gradient(to right, ${theme.light1}, ${theme.primaryColor})`,
    color: '#fff',
    border: "solid 2px #fff",
    boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.14), inset 0 8px 6px 0 rgba(255, 255, 255, 0.25)"
  },
  primaryOutlined: {
    background: '#fff',
    color: theme.primaryColor,
    border: `solid 2px ${theme.primaryColor}`,
    boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.14), inset 0 8px 6px 0 rgba(255, 255, 255, 0.25)"
  },
  secondary: {
    background: '#fff',
    color: "#333333",
    border: "solid 2px #caefeb",
    boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.14), inset 0 8px 6px 0 rgba(255, 255, 255, 0.25)"
  },
  success: {
    background: "linear-gradient(to left, #10b216 153%, #10b269 5%)",
    color: "white",
    border: "solid 2px #fff",
    boxShadow: "0 4px 8px 0 rgba(118, 180, 157, 0.3), inset 0 9px 8px 0 rgba(255, 255, 255, 0.18)"
  },
  error: {
    border: "solid 2px #ff92a6",
    background: "#fff",
    color: "#e77287",
    boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.14), inset 0 8px 6px 0 rgba(255, 255, 255, 0.25)"
  }
});

const ButtonComponent = styled.button`
  border-radius: 30px;
  font-family: 'Baloo 2';
  font-weight: ${({ isBold }) => (isBold ? "Bold" : 500)};
  font-size: 18px;
  box-shadow: ${({ btnTheme }) => (btnTheme.boxShadow)};
  border: ${({ btnTheme }) => (btnTheme.border)};
  background: ${({ btnTheme }) => (btnTheme.background)};
  display:flex;
  justify-content: ${({ textAlign }) => textAlign === "right" ? "flex-start" : "center"};
  align-items: center;
  color:${({ btnTheme }) => (btnTheme.color)};
  padding:0 15px;
  ${({ size }) => {
    switch (size) {
      case "sm":
        return css`
           min-width: 126px;
           min-height:48px;
        `;
      case "md":
        return css`
           min-width: 250px;
           min-height:48px;
        `;
      case "lg":
        return css`
           min-width: 312px;
           min-height:52px;
           font-size: 15px;
        `;
    }
  }}
  width:${({ fullWidth }) => fullWidth && "100%"};
`;

const Button = ({
  onClick,
  label,
  size = "md",
  type,
  isBold = false,
  textAlign = "center",
  disable,
  btnTheme,
  prefixIcon,
  ...rest
}) => {
  const theme = useTheme();
  const buttonTheme = getBtnTheme(theme);

  return (
    <ButtonComponent
      className="button"
      onClick={onClick}
      btnTheme={buttonTheme[btnTheme]}
      disabled={disable}
      theme={theme}
      size={size}
      textAlign={textAlign}
      {...rest}
    >
      {prefixIcon}
      <div style={{ marginLeft: prefixIcon ? "10px" : "0px" }}>
        {label}
      </div>
    </ButtonComponent >
  );
};

export default Button;
