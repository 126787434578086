let config;

if (process.env.REACT_APP_ENV === "staging") {
  config = {
    yellowBox: true,
    reduxLogging: true,
    useReactotron: true,
    apiBaseURL: "https://k123cms-api-staging.tllms.com",
    elaApiBaseURL: "https://ela-api-staging.tllms.com",
    ela_api_key: "WbMIB73KqiUonFxHt+Dwjhl+tOSE8XUtOQIfFK5DVT8=",
    wsEndpoint: "https://k123cms-api-staging.tllms.com/cable/",
    watershedEndpoint: "wss://k123cms-api.tllms.com/watershed",
    GOOGLE_CLIENT_ID:
      "413500028523-ja2gbki5ftnbhp3k69t6r1l69h03puhi.apps.googleusercontent.com",
    VERSION: "development"
  };
} else if (process.env.NODE_ENV === "production") {
  config = {
    yellowBox: false,
    reduxLogging: false,
    useReactotron: false,
    apiBaseURL: "https://k123cms-api.tllms.com",
    ela_api_key: "USW0Wwon7BykmiJWFz7X2u2N914UmGJZ+/k3x2N80WE=",
    elaApiBaseURL: "https://ela-api.tllms.com",
    wsEndpoint: "wss://k123cms-api.tllms.com/cable/",
    watershedEndpoint: "wss://k123cms-api.tllms.com/watershed/ws",
    GOOGLE_CLIENT_ID:
      "293659410087-m1r353kj5rgocdqm5jubir58j3j0dp50.apps.googleusercontent.com",

    RAVEN_DSN: "https://4b5059c4d025499f9a53d56e1fd226e6@sentry.tllms.com/3",
    VERSION: process.env.REACT_APP_BUILD_VERSION
  };
} else {
  config = {
    yellowBox: true,
    reduxLogging: true,
    useReactotron: true,
    apiBaseURL: "http://localhost:3000",
    wsEndpoint: "http://localhost:3000/cable/",
    watershedEndpoint: "ws://localhost:9090/",
    GOOGLE_CLIENT_ID:
      "293659410087-0pl5ukaumi5522lu6osr6houk68088vj.apps.googleusercontent.com",
    VERSION: "development"
  };
}

export default config;
