import React from 'react';

export default function CloseIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
      <g fill="none" fillRule="evenodd">
        <path
          fillRule="nonzero"
          stroke="#FFF"
          strokeOpacity=".01"
          strokeWidth=".5"
          d="M.25.25h39.5v39.5H.25z"
        />
        <path
          fill="#FFF"
          fillOpacity=".5"
          d="M21.415 20l6.292 6.293a1 1 0 0 1-1.415 1.415L20 21.414l-6.293 6.292a1 1 0 0 1-1.415-1.415L18.587 20l-6.293-6.293a1 1 0 0 1 1.415-1.415L20 18.587l6.293-6.293a1 1 0 0 1 1.415 1.415L21.414 20z"
        />
      </g>
    </svg>
  );
}
