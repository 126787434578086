// @flow

import { createReducer, createActions } from "reduxsauce";

import Immutable from "seamless-immutable";

const { Types, Creators } = createActions(
    {
        request: ["resourceID", "resourceType"],
        success: ["data", "result"],
        failure: ["error"]
    },
    { prefix: "IV_DETAILS" }
);

export { Creators, Types };

export const INITIAL_STATE = Immutable({
    fetching: false,
    data: false,
    error: null
});

export const request = (state: Object, action: Object) => {
    return state.merge({ fetching: true, error: null });
};

export const success = (state: Object, { data, result}: Object) => {
    return state.merge({ fetching: false, data: result.data, error: null });
};

export const failure = (state: Object, { error }: Object) => {
    return state.merge({ fetching: false, data: false, error });
};

export const key = "ivDetails";

export default createReducer(INITIAL_STATE, {
    [Types.REQUEST]: request,
    [Types.SUCCESS]: success,
    [Types.FAILURE]: failure,
});
