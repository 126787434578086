import React, {useState} from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CmsCard from "./CmsCard";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ContentCopyIcon from "@material-ui/icons/FileCopyOutlined";
import ArchiveIcon from '@material-ui/icons/Archive';
import UnArchiveIcon from '@material-ui/icons/Unarchive';

import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TextField from "@material-ui/core/TextField";
import {IconButton} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => {
    return {
        card: {
            minHeight: "135px",
            backgroundColor: props => props.is_archived ? theme.palette.grey['200'] : "inherit"
        },
        button: {
            "&:hover": {
                backgroundColor: "transparent"
            }
        }

    }
})
export default function Workspace(props) {
    const classes = useStyles(props);
    const options = []
    const {
        id, name, schema_type, onClone, onArchive, onUpdate,
        hasCreateAccess, is_archived, key, hasUpdateAccess
    } = props;

    const [newName, setNewName] = useState(name);
    const [shouldEdit, setShouldEdit] = useState(false)

    const handleOnClickRename = () => {
        setShouldEdit(!shouldEdit)
        setNewName(name)
    }

    const handleOnClickDone = () => {
        if(name !== newName)
            onUpdate(newName)
        setShouldEdit(!shouldEdit)
    }

    const openWorkspaceButton = <Button key="open" color="primary" component={Link} to={`/workspaces/${id}`}>
        Open Workspace <PlayArrowIcon/>
    </Button>;

    const cloneOption = {
        label:
            <Button aria-label="Clone Workspace" key="copy" startIcon={<ContentCopyIcon/>} className={classes.button}>
                Clone
            </Button>
        ,
        onChange: onClone,
        value: 'clone'
    }
    const archiveOption = {
        label:
            <Button aria-label="Archive" key="archive"
                    color="secondary"
                    startIcon={<ArchiveIcon/>} className={classes.button}>
                Archive
            </Button>,
        onChange: onArchive,
        value: 'Archive_unArchive'
    }

    const unArchiveOption = {
        label:
            <Button aria-label="UnArchive" key="unarchive"
                    color="primary"
                    startIcon={<UnArchiveIcon/>} className={classes.button}>
                Unarchive
            </Button>,
        onChange: onArchive,
        value: 'Archive_unArchive'
    }

    const updateOption = {
        label:
            <Button aria-label="UnArchive" key="unarchive"
                    color="primary"
                    startIcon={<EditOutlinedIcon/>} className={classes.button}>
                Rename
            </Button>,
        onChange: handleOnClickRename,
        value: 'rename'
    }

    if(hasUpdateAccess) {
        is_archived ? options.push(unArchiveOption) : options.push(archiveOption)
        options.push(updateOption)
    }

    if (!is_archived && hasCreateAccess)
        options.push(cloneOption)

    return (
        <CmsCard buttons={[openWorkspaceButton]} classes={classes} key={key} menuItems={options}>
            {shouldEdit ?
                <Grid container>
                    <TextField value={newName} variant="outlined"
                               onChange={event => setNewName(event.target.value)}/>
                    <IconButton onClick={handleOnClickDone} color="secondary">
                        <DoneIcon />
                    </IconButton>
                    <IconButton onClick={handleOnClickRename} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Grid> :
                <Typography variant="h5">{name}</Typography>
            }
            <Typography variant="caption">{schema_type || "Deprecated"}</Typography>
        </CmsCard>
    );
}
