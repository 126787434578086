import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import ResourceBrowser from "../Components/ResourceBrowser";
import { withRouter } from "react-router";
import { multiSelectEnabledResourcesMap } from "./../Components/elaRuleTag/constValue";
class ResourceSearchDialog extends Component {
  handleCancel = () => {
    this.props.onClose();
  };

  handleOk = resource => {
    this.props.onClose(resource);
  };

  handleCreateNew = () => {
    this.props.onClose();
    const { history, resourceName } = this.props;
    history.push(`/resources/${resourceName}/new`);
  }

  render() {
    const { resourceName, open, hint = "" } = this.props;

    const selectButton = r => (
      <Button variant="contained" color="primary" onClick={() => this.handleOk(r)}>
        Choose
      </Button>
    );

    const confirmSelectionButton = r => (
      <Button variant="outlined" color="primary" onClick={() => this.handleOk(r)}>
        Confirm Selection
      </Button>
    );

    return (
      <Dialog
        maxWidth="md"
        onEntering={this.handleEntering}
        onClose={this.handleCancel}
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogContent>
          <ResourceBrowser
            showBreadcrumbs={false}
            resourceName={resourceName}
            selectButton={selectButton}
            searchInitialValue={ hint }
            showCheckboxes={multiSelectEnabledResourcesMap.includes(this.props.prefix?.[0]) ? true : false}
            confirmSelectionButton={confirmSelectionButton}
          />
          <DialogActions>
            <Button color="primary" onClick={this.handleCreateNew}>Create New</Button>
            <Button onClick={this.handleCancel}>Cancel</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withRouter(ResourceSearchDialog);
