import React from 'react';

export default function PlayIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M49.929 35.023l-37.136 23.96c-1.485.957-3.466.53-4.424-.955-.334-.517-.511-1.12-.511-1.735V4.343c0-1.768 1.433-3.2 3.2-3.2.615 0 1.218.177 1.735.51l37.136 23.96c2.599 1.676 3.346 5.142 1.67 7.74-.432.67-1.001 1.239-1.67 1.67z"
          opacity="1"
          {...props}
        />
      </g>
    </svg>
  );
}
