import { getCleanedMigratedData } from './index';
import DummyBridgeUtils from './dummyBridgeHelpers';
import { isDevEnv } from "./../config";
let dsBridge = require('dsbridge');

const consoleBridgeCall = (name, data) => {
  if (isDevEnv)
    console.log(
      `%c ${name} %c ${JSON.stringify(data)}`,
      `background: #222; color: #bada55`,
      ` background: blue; color: yellow`
    );
};
export default class BridgeUtils {
  static getResourceData = (resourceType) => {
    let data = {};
    if (isDevEnv) {
      data = DummyBridgeUtils.dummyResourceData[resourceType];
    } else {
      let resData = dsBridge.call('getResourceData');
      data = resData ? JSON.parse(resData) : {};
    }
    consoleBridgeCall('getResourceData', data);
    return getCleanedMigratedData(data, resourceType);
  };

  static getConfigData = () => {
    let resData = {};
    if (isDevEnv) {
      resData = DummyBridgeUtils.dummyGameConfig;
    } else {
      resData = dsBridge.call('getConfig');
    }
    consoleBridgeCall('getConfigData', resData);
    try {
      const response = resData;
      return response;
    } catch (e) {
      return {};
    }
  };

  static sendEvaluationData = (obj) => {
    consoleBridgeCall('sendEvaluationData', JSON.stringify(obj));
    dsBridge.call('sendEvaluationData', obj);
  };

  static sendGameData = (obj) => {
    consoleBridgeCall('sendEvaluationData', JSON.stringify(obj));
    dsBridge.call('sendGameData', obj);
  };

  static sendUiEvent = (value, data = null) => {
    let obj = {
      id: value,
      data: data,
    };
    consoleBridgeCall('close webview', obj);
    dsBridge.call('sendUiEvent', obj);
  };

  static sendAnalyticsEvent = (obj) => {
    consoleBridgeCall('sendOlap', obj);
    dsBridge.call('sendAnalyticsEvent', obj);
  };

  static startAudio = (value, callback) => {
    let obj = {
      url: value,
    };
    if (isDevEnv) {
      DummyBridgeUtils.dummySuccessCallback(callback);
      consoleBridgeCall('startAudio', value);
    } else {
      dsBridge.call('startAudio', obj, callback);
    }
  };

  static startSfxAudio = (value) => {
    consoleBridgeCall('IC_MCQ startSfxAudio Audio URL : ', value);
    let obj = {
      url: value,
    };
    dsBridge.call('startSfxAudio', obj);
  };

  static stopAudio = () => {
    consoleBridgeCall('stop Audio....');
    dsBridge.call('stopAudio');
  };

  static showDictionary = (word, context, callback) => {
    const obj = {
      word: word,
      context: context,
    };
    consoleBridgeCall('showDictionary', obj);
    dsBridge.call('showDictionary', obj, callback);
  };

  static adjustHeight = (height) => {
    consoleBridgeCall('adjustHeight', height);
    dsBridge.call('adjustHeight', { height: height });
  };

  static speechToText = (callback) => {
    if (isDevEnv) {
      DummyBridgeUtils.speechToText(callback);
    } else {
      dsBridge.register('speechToText', callback);
    }
  };

  static observeAppLifeCycle = (callback) => {
    if (isDevEnv) {
      DummyBridgeUtils.observeAppLifeCycle(callback);
    } else {
      dsBridge.register('observeAppLifeCycle', callback);
    }
  };

  static checkInternetConnectivity = (callback) => {
    if (isDevEnv) {
      DummyBridgeUtils.checkInternetConnectivity(callback);
    } else {
      dsBridge.register('checkInternetConnectivity', callback);
    }
  };

  static onCallListener = (callback) => {
    if (isDevEnv) {
      DummyBridgeUtils.onCallListener(callback);
    } else {
      dsBridge.register('onCallListener', callback);
    }
  };

  static onBackClick = (fn) => {
    dsBridge.register('onBackPressed', fn);
  };

  static onGameStatusChange = (callback) => {
    if (isDevEnv) {
      DummyBridgeUtils.onGameStatusChange(callback);
    } else {
      dsBridge.call('onGameStatusChange', callback);
    }
  };

  static textToSpeech = (text, callback) => {
    if (isDevEnv) {
      DummyBridgeUtils.textToSpeech(text, callback);
    } else {
      dsBridge.call('textToAudio', text, callback);
    }
  };

  static startReadAlongwithPermission = (optionId, callback) => {
    consoleBridgeCall("JS-bridge- start read along", optionId);
    if (isDevEnv) {
      DummyBridgeUtils.startListening(callback);
    } else {
      dsBridge.call('startReadAlongwithPermission', optionId, callback);
    }
  };

  static stopReadAlong = (callback) => {
    consoleBridgeCall("JS-bridge- Stop read along");
    if (isDevEnv) {
      DummyBridgeUtils.stopReadAlong(callback);
    } else {
      dsBridge.call('stopReadAlong', null, callback);
    }
  };

  static onBuddyClicked = (callback) => {
    consoleBridgeCall("JS-bridge- onBuddyClick");
    if (isDevEnv) {
      DummyBridgeUtils.onBuddyClicked(callback);
    } else {
      dsBridge.register('onBuddyClicked', callback);
    }
  };

  static changeMicStatus = (value) => {
    consoleBridgeCall("JS-bridge- change mic status");
    if (isDevEnv) {
      console.log({ value })
      // DummyBridgeUtils.stopReadAlong(callback);
    } else {
      dsBridge.call('changeMicStatus', value);
    }
  };
}
