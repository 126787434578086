// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["resourceName", "params"],
		success: ["params", "data", "entities", "meta"],
		failure: ["params", "error"]
	},
	{ prefix: "RESOURCES_LIST_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
	return state.merge({ listing: true, error: null });
};

const success = (state: Object, { params, data, meta }: Object) => {
	return state.merge({
		listing: false,
		data: { [params.resourceName]: data[params.resourceName] },
		error: null,
		meta
	});
};

const failure = (state: Object, { error }: Object) => {
	return state.merge({ listing: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
