import React, { Component, PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import ClearAllIcon from "@material-ui/icons/DeleteSweep";
import CloseIcon from "@material-ui/icons/Close";
import ResourceCartIcon from "@material-ui/icons/ShopTwoOutlined";
import MenuIcon from "@material-ui/icons/Menu";

import { DropTarget, DragSource } from "react-dnd";

import compose from "recompose/compose";
import classnames from "classnames";

const DND_NODE_TYPE = "NodeType";
const nodeSourceSpec = {
    // This needs to return an object with a property `node` in it.
    // Object rest spread is recommended to avoid side effects of
    // referencing the same object in different trees.
    beginDrag: componentProps => ({
        node: { id: Math.random(), ...componentProps.node }
    })
};

const nodeConnectDrag = (connect /* , monitor */) => ({
    connectDragSource: connect.dragSource()
    // Add props via react-dnd APIs to enable more visual
    // customization of your component
    // isDragging: monitor.isDragging(),
    // didDrop: monitor.didDrop(),
});

const nodeDropSpec = {
    drop(props, monitor, component) {
        const node = monitor.getItem();
        console.log("node dropped", node);
        component.didDrop(node);
        return { ...node, treeId: null };
    }
};

const nodeConnectDrop = connect => ({
    connectDropTarget: connect.dropTarget()
});

export const TOOLPOOL_WIDTH = 280;

const styles = theme => ({
    dropBin: {
        width: "100%",
        flexGrow: 1,
        overflow: "scroll"
    },
    rootNodeView: {
        width: TOOLPOOL_WIDTH,
        height: "100vh"
    },
    list: {
        flexGrow: 0
    },
    list2: {
        flexGrow: 1,
        display: "flex",
        padding: theme.spacing(1)
    },
    chip: {
        display: "inline-block",
        cursor: "move",
        fontSize: 12,
        margin: theme.spacing(0.5)
    },
    pushedOut: {
        right: -TOOLPOOL_WIDTH
    },
    cartTitle: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    avatar: {
        fontSize: "0.9em",
        backgroundColor: theme.palette.primary.main,
        color: "#fff"
    },
    avatarSecondary: {
        fontSize: "0.9em",
        backgroundColor: theme.palette.secondary.main,
        color: "#fff"
    },
    cartTitleContainer: {
        flexShrink: 0
    },
    resIcon: {
        position: "relative",
        top: theme.spacing(1),
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1)
    },
    draggableIcon: {
        position: "relative",
        cursor: "move",
        top: theme.spacing(1),
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: "#fff"
    }
});

class DraggableNode extends Component {
    renderAvatar(resourceName) {
        if (resourceName === undefined || resourceName === null) return "????";
        return resourceName.substr(0, 4);
    }

    render() {
        const { classes, node, connectDragSource, onDelete } = this.props;

        let chip;
        if (node.type === "new_node") {
            chip = (
                <div className={classes.chip}>
                    <Chip
                        variant="outlined"
                        avatar={
                            <Avatar
                                className={classes.avatar}
                                title={"New Node"}
                            >
                                +
                            </Avatar>
                        }
                        label={<i>New Node</i>}
                    />
                </div>
            )
        }
        else if (node.type === "node") {
            chip = (
                <div className={classes.chip}>
                    <Chip
                        variant="outlined"
                        avatar={
                            <Avatar
                                className={classes.avatarSecondary}
                                title={node.resource_name}
                            >
                                {this.renderAvatar(node.resource_name)}
                            </Avatar>
                        }
                        label={<span>Node "{node.nickname}"</span>}
                        onDelete={onDelete}
                        color="secondary"
                    />
                </div>
            );
        } else {
            chip = (
                <div className={classes.chip}>
                    <Chip
                        variant="outlined"
                        avatar={
                            <Avatar
                                className={classes.avatar}
                                title={node.resource_name}
                            >
                                {this.renderAvatar(node.resource_name)}
                            </Avatar>
                        }
                        label={node.nickname}
                        onDelete={onDelete}
                    />
                </div>
            );
        }

        return connectDragSource(chip);
    }
}

const DraggableNodeComponent = compose(
    withStyles(styles),
    DragSource(DND_NODE_TYPE, nodeSourceSpec, nodeConnectDrag)
)(DraggableNode);

const nodesSourceSpec = {
    // This needs to return an object with a property `node` in it.
    // Object rest spread is recommended to avoid side effects of
    // referencing the same object in different trees.
    beginDrag: componentProps => ({
        node: {
            id: Math.random(),
            type: "multiple_resources",
            resources: componentProps.nodes
        }
    })
};

const nodesConnectDrag = (connect /* , monitor */) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview()
    // Add props via react-dnd APIs to enable more visual
    // customization of your component
    // isDragging: monitor.isDragging(),
    // didDrop: monitor.didDrop(),
});

class DraggableIcon extends Component {
    render() {
        const {
            classes,
            connectDragSource,
            connectDragPreview,
            nodes
        } = this.props;
        return connectDragPreview(
            <span>
                <Typography className={classes.cartTitle} variant="overline">
                    {connectDragSource(
                        <span>
                            <MenuIcon className={classes.draggableIcon} />
                        </span>
                    )}
                    {nodes.length} Resource{nodes.length !== 1 && "s "}
                </Typography>
            </span>
        );
    }
}

const DraggableIconComponent = compose(
    withStyles(styles),
    DragSource(DND_NODE_TYPE, nodesSourceSpec, nodesConnectDrag)
)(DraggableIcon);

class NodeDropBin extends Component {
    didDrop = node => {
        const { type, resource_name, resource_id, nickname, scoped_id } = node.node;
        if(type === "new_node") {
            return;
        }

        const cartNode = {
            type: "node",
            scoped_id,
            resource_name,
            resource_id,
            nickname
        };
        this.props.addToCart(cartNode);
        const cartResource = {
            type: "resource",
            resource_name,
            resource_id,
            nickname
        };
        this.props.addToCart(cartResource);
    };

    render() {
        const { classes, connectDropTarget, nodes, onDelete } = this.props;

        return connectDropTarget(
            <div className={classes.dropBin}>
                {nodes.map(node => {
                    if (node.type === "node") {
                        return (
                            <DraggableNodeComponent
                                key={node.scoped_id}
                                node={node}
                                onDelete={onDelete.bind(this, node)}
                            />
                        );
                    }

                    return (
                        <DraggableNodeComponent
                            key={node.resource_name + ":" + node.resource_id}
                            node={node}
                            onDelete={onDelete.bind(this, node)}
                        />
                    );
                })}
            </div>
        );
    }
}

const NodeDropBinComponent = compose(
    withStyles(styles),
    DropTarget(DND_NODE_TYPE, nodeDropSpec, nodeConnectDrop)
)(NodeDropBin);

class ToolpoolPane extends PureComponent {
    constructor(props) {
        super(props);
        const nodes = JSON.parse(window.localStorage["nodes"] || "[]");
        this.state = { nodes };
    }

    onDelete = node => {
        this.setState(
            { nodes: this.state.nodes.filter(n => n !== node) },
            this.syncLS
        );
    };

    syncLS = () => {
        console.log(this.state.nodes);
        window.localStorage["nodes"] = JSON.stringify(this.state.nodes);
        window.dispatchEvent(new Event("resource_cart_change"));
    };

    onStorageEvent = event => {
        if (event.key === "nodes") {
            this.setState({ nodes: JSON.parse(event.newValue) });
        }
    };

    onLocalStorageEvent = event => {
        this.setState({
            nodes: JSON.parse(window.localStorage["nodes"] || [])
        });
    };

    componentDidMount() {
        window.addEventListener("storage", this.onStorageEvent);
        window.addEventListener(
            "resource_cart_change",
            this.onLocalStorageEvent
        );

        this.props.cartDrop && this.props.cartDrop(this.addToCart);
    }

    componentWillUnmount() {
        window.removeEventListener("storage", this.onStorageEvent);
        window.removeEventListener(
            "resource_cart_change",
            this.onLocalStorageEvent
        );

        this.props.cartDrop && this.props.cartDrop(null);
    }

    addToCart = resource => {
        let exists = false;
        if (resource.type === "node") {
            exists = this.state.nodes.some(
                n =>
                    n.type === resource.type &&
                    n.scoped_id === resource.scoped_id
            );
        } else {
            exists = this.state.nodes.some(
                n =>
                    n.type === resource.type &&
                    n.resource_name === resource.resource_name &&
                    n.resource_id === resource.resource_id
            );
        }

        if (exists) {
            return;
        }

        this.setState(
            ({ nodes }) => ({ nodes: nodes.concat(resource) }),
            this.syncLS
        );
    };

    clearAll = event => {
        event.preventDefault();

        this.setState({ nodes: [] }, this.syncLS);
    };

    onCloseClick = event => {
        event.preventDefault();

        this.props.onClose && this.props.onClose();
    };

    render() {
        const {
            classes,
            open = true,
            onCartInteract,
            defaultNodes = []
        } = this.props;
        const { nodes } = this.state;

        return (
            <Drawer
                open={true}
                variant="persistent"
                classes={{
                    paper: classnames(classes.rootNodeView, {
                        [classes.pushedOut]: !open
                    })
                }}
                anchor="right"
                ref={ref =>
                    this.props.nodesPresent && this.props.nodesPresent()
                }
            >
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    classes={{ container: classes.cartTitleContainer }}
                >
                    <Grid item>
                        {nodes.length > 0 ? (
                            <DraggableIconComponent nodes={nodes} />
                        ) : (
                            <Typography
                                className={classes.cartTitle}
                                variant="overline"
                            >
                                <ResourceCartIcon className={classes.resIcon} />
                                Resource Cart
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        {nodes.length > 0 && (
                            <Tooltip title="Clear all">
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={this.clearAll}
                                >
                                    <ClearAllIcon />
                                </Button>
                            </Tooltip>
                        )}
                        {this.props.onClose && (
                            <Tooltip title="Close">
                                <Button
                                    color="primary"
                                    size="small"
                                    onClick={this.onCloseClick}
                                >
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
                <Divider />
                <div
                    className={classes.list2}
                    onMouseOver={() => {
                        onCartInteract && onCartInteract();
                    }}
                >
                    <NodeDropBinComponent
                        nodes={nodes.length === 0 ? defaultNodes : nodes}
                        addToCart={this.addToCart}
                        onDelete={this.onDelete}
                    />
                </div>
            </Drawer>
        );
    }
}

export default compose(withStyles(styles))(ToolpoolPane);
