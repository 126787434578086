import React from "react";

import compose from "recompose/compose";
import { connect } from "react-redux";

import {
	selectActionCableConnectionState,
	selectActionCableActiveUsers
} from "../Redux/ActionCableRedux";



import AirdropIcon from "./AirdropIcon";
import AirdropOffIcon from "@material-ui/icons/PortableWifiOff";
import Badge from "@material-ui/core/Badge";

const ActionCableStatus = ({
	connected,
	Component,
	iconClassName,
	activeUsers=[],
	currentUser
}) => {
	activeUsers = activeUsers.filter((u) => u.id !== currentUser.id);

	return <Component>
		<Badge
			badgeContent={activeUsers.length > 0 ? activeUsers.length : "" }
			color={activeUsers.length > 0 && connected ? "primary" : "default"}
		>
			{connected ? (
				<AirdropIcon className={iconClassName} />
			) : (
				<AirdropOffIcon className={iconClassName} />
			)}
		</Badge>
	</Component>
};

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = (state, ownProps) => {
	return {
		connected: selectActionCableConnectionState(state),
		activeUsers: selectActionCableActiveUsers(state)
	};
};

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ActionCableStatus);
