import React, { Component } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

class ResourceUpdate extends Component {
  render() {
    const {
      onChange,
      selected,
      resource_name: resourceName,
      from: fromResource,
      to: toResource,
      errors
    } = this.props;
    const name = resourceName + "::" + fromResource.id;
    const value = resourceName + "::" + toResource.id;
    const error = errors && errors[name];

    return (
      <FormControl component="fieldset" error={!!error}>
        <FormControlLabel
          control={
            <Switch
              checked={!!selected[name]}
              onChange={onChange}
              name={name}
              value={value}
            />
          }
          label={toResource.nickname}
        />
        <FormHelperText>{error && `Unable to update resource for node ${error}`}</FormHelperText>
      </FormControl>
    );
  }
}

export default ResourceUpdate;
