import React from "react";
import styled from "styled-components";
import microphone from "./../../../assets/images/microphone.svg";
import "./headerMain.scss";



const MicrophoneBox = styled.div`
  background-image: linear-gradient(to bottom, ${({ theme }) => theme.light3}, ${({ theme }) => theme.light1});
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.dark1}, inset 0 1px 0 0 rgba(255, 255, 255, 0.44); 
`;

const MicrophoneShine = styled.div`
   opacity: 0.3;
   background:${({ theme }) => theme.light3} ;
`;


const Microphone = () => {
  return <MicrophoneBox className="microphone-box">
    <MicrophoneShine className="shine" />
    <img className="microphone" src={microphone} />
  </MicrophoneBox>
}


export default Microphone;