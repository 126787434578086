import ActionCable from "actioncable";

let actionCable;
let subscriptions = {};

let serverSkew = {
	skew: 0
}

const middleware = store => next => incomingAction => {
	const action = incomingAction;
	const dispatch = (type, payload) => store.dispatch({ type, ...payload });
	let sub;

	switch (action.type) {
		case "ACTION_CABLE_INIT":
			actionCable = ActionCable.createConsumer(action.wsURL);
			break;

		case "ACTION_CABLE_SUBSCRIBE":
			sub = actionCable.subscriptions.create(action.channel, {
				connected: () => dispatch('ACTION_CABLE_CONNECTED', {channel: action.channel}),
		        disconnected: () => dispatch('ACTION_CABLE_DISCONNECTED'),
		        received: (msg) => store.dispatch({ channel: action.channel, ...msg }),
		        rejected: () => {
		        	throw new Error(
      					`ActionCable: Attempt to connect was rejected.
      					(Channel: ${action.channel})`)
    			},
			})
			subscriptions[JSON.stringify(action.channel)] = sub;
			break;

		case "ACTION_CABLE_UNSUBSCRIBE":
			actionCable.subscriptions.remove(subscriptions[JSON.stringify(action.channel)])
			break;

		case "ACTION_CABLE_PERFORM":
			sub = subscriptions[JSON.stringify(action.channel)];
			sub.perform(action.action, action.data)
			break;

		case "ACTION_CABLE_SEND":
			sub = subscriptions[JSON.stringify(action.channel)];
			sub.send(action.action, action.data);
			break;

		case 'ACTION_CABLE_DISCONNECT':
			actionCable.disconnect();
			break;

		default:
			break;
	}

	if( action.server_now > 1000) {
		serverSkew.skew = (+new Date()) - action.server_now // + if ahead , - if behind	
	}

	return next(action);
};

export { serverSkew }
export default middleware;
