import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import compose from "recompose/compose";
import { connect } from "react-redux";

import {
    FetchCreators as WorkspacesFetchCreators,
    selectWorkspacesRequestState,
    selectWorkspacesById
} from "../Redux/WorkspacesRedux";
import { ListCreators as ProjectsListCreators } from "../Redux/ProjectsRedux";
import { CreateCreators as ProjectsCreateCreators } from "../Redux/ProjectsRedux";
import { PublishCreators as ProjectsPublishCreators } from "../Redux/ProjectsRedux";
import {
    RebaseCreators as ProjectsRebaseCreators,
    selectProjectsRequestState
} from "../Redux/ProjectsRedux";
import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";

import TitleBar from "../Components/TitleBar";
import Loading from "../Components/Loading";
import Project from "../Components/Project";
import PageWrapper from "../Components/PageWrapper";

import CreateNewFolder from "@material-ui/icons/CreateNewFolder";

import { withRouter } from "react-router";

import _get from "lodash/get";

const styles = theme => ({
    workspaceContainer: {
        marginTop: theme.spacing(3)
    },
    createProjectInput: {
        width: "100%",
        fontSize: "1em"
    },
    pagination: {
        padding: theme.spacing(2)
    }
});

class WorkspacesDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectName: ""
        };
    }
    componentDidMount() {
        const { workspace, match } = this.props;

        !workspace && this.props.fetchWorkspace(this.props.match.params.id);

        this.props.listProjects(match.params.id);

        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.onScroll);
        this.props.subscribeChannel("NodeChannel")

        workspace && (document.title = `Workspace :: ${workspace.name}`)
    }

    componentWillUnmount() {
        this.props.unsubscribeChannel("NodeChannel")
        window.removeEventListener("scroll", this.onScroll);
    }

    onPublish = (id, event) => {
        this.props.publishProject(this.props.match.params.id, id);
    };

    onRebase = (id, event) => {
        this.props.rebaseProject(this.props.match.params.id, id);
    };

    onProjectCreate = () => {
        this.props.createProject(this.props.match.params.id, {
            name: this.state.projectName
        });
    };

    onScroll = event => {
        const { projectRequests, match } = this.props;
        const next_page = _get(projectRequests, "meta.pagination.next_page");
        if (!next_page || !this.paginationRef) return;

        const delta =
            this.paginationRef.offsetTop - document.documentElement.scrollTop - window.innerHeight;

        if (!projectRequests.listing && delta < 100) {
            const params = {
                page: {
                    number: next_page
                }
            };

            this.props.listProjects(match.params.id, params);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.projectRequests.creating && !nextProps.projectRequests.creating) {
            if (nextProps.projectRequests.error) {
                this.setState({ createError: nextProps.projectRequests.error });
            } else {
                this.setState({ projectName: "" });
            }
        }

        if (this.props.projectRequests.publishing && !nextProps.projectRequests.publishing) {
            if (nextProps.projectRequests.error) {
                this.setState({
                    publishError: nextProps.projectRequests.error
                });
            }
        }

        if (this.props.projectRequests.rebasing && !nextProps.projectRequests.rebasing) {
            if (nextProps.projectRequests.error) {
                this.setState({
                    rebaseError: nextProps.projectRequests.error
                });
            }
        }
    }

    render() {
        let { classes, workspace, workspaceRequests, projectRequests } = this.props;
        let { createError, publishError, rebaseError } = this.state;

        let projects = workspace && workspace.projects ? workspace.projects : [];

        const hasCreateProjectAccess = _get(projectRequests, "meta.abilities.create_project");
        const hasPublishAccess = _get(projectRequests, "meta.abilities.publish_project");
        const hasRebaseAccess = _get(projectRequests, "meta.abilities.rebase_project");
        const hasUpdateAccess = _get(projectRequests, "meta.abilities.update_project");

        const pagination = _get(projectRequests, "meta.pagination");

        return (
            <PageWrapper>
                {(workspaceRequests.fetching || projectRequests.listing) && <Loading center />}
                {workspace && (
                    <div>
                        <TitleBar breadcrumbs={[
                            { to: "/workspaces", title: "Workspaces" },
                            { title: workspace.name }
                        ]} />
                        {rebaseError && rebaseError.error}
                        {hasCreateProjectAccess && (
                            <Project
                                name={
                                    <Button color="primary">
                                        <CreateNewFolder /> Create new Project
                                    </Button>
                                }
                                buttons={
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={this.onProjectCreate}
                                    >
                                        Create
                                    </Button>
                                }
                                description={
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Project Name"
                                                error={!!createError}
                                                helperText={
                                                    createError &&
                                                    JSON.stringify(createError.errors)
                                                }
                                                name="projectName"
                                                value={this.state.projectName}
                                                className={classes.createProjectInput}
                                                type="text"
                                                onChange={event =>
                                                    this.setState({
                                                        [event.target.name]: event.target.value
                                                    })
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />
                        )}

                        {projects.length > 0 &&
                            projects.map((project, index) => (
                                <Project
                                    workspace={workspace}
                                    {...project}
                                    hasUpdateAccess={hasUpdateAccess}
                                    onPublish={this.onPublish}
                                    onRebase={this.onRebase}
                                    hasPublishAccess={hasPublishAccess}
                                    hasRebaseAccess={hasRebaseAccess}
                                    publishing={ projectRequests.publishing }
                                    canPublish={
                                        index === projects.length - 1 ||
                                        (projects[index + 1] && projects[index + 1].is_published)
                                    }
                                    publishError={publishError}
                                    rebaseError={rebaseError}
                                    key={project.id}
                                    onClone={this.onClone}
                                />
                            ))}
                        {pagination &&
                            pagination.next_page && (
                                <div ref={ref => (this.paginationRef = ref)}>
                                    <Grid className={classes.pagination} container justify="center">
                                        <Grid item>
                                            <Loading size={32} />
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                    </div>
                )}
            </PageWrapper>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    fetchWorkspace: w => dispatch(WorkspacesFetchCreators.request(w)),
    listProjects: (w, pg) => dispatch(ProjectsListCreators.request(w, pg)),
    createProject: (w, p) => dispatch(ProjectsCreateCreators.request(w, p)),
    publishProject: (w, p) => dispatch(ProjectsPublishCreators.request(w, p)),
    rebaseProject: (w, p) => dispatch(ProjectsRebaseCreators.request(w, p)),
    subscribeChannel: (a) => dispatch(ActionCableCreators.subscribe(a)),
    unsubscribeChannel: (a) => dispatch(ActionCableCreators.unsubscribe(a)),
});

const mapStateToProps = (state, ownProps) => {
    let {
        match: { params }
    } = ownProps;
    return {
        workspace: selectWorkspacesById(state, params.id),
        workspaceRequests: selectWorkspacesRequestState(state),
        projectRequests: selectProjectsRequestState(state)
    };
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(WorkspacesDetailPage);
