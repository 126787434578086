import React, { useEffect, useRef } from "react";
import { useState } from 'react';
import { themeArr } from './components/themes';
import { ThemeProvider } from 'styled-components';
import './App.css';
import BridgeUtils from "./utils/JsBridge";
import { isDevEnv } from "./config";
import ReadAloud from "./components/MainLayout"

const ThemeHandler = ({ setTheme, setGameClosed }) => {
    return <div>
        <div className="theme-title">
            Colour Themes
      </div>
        <div className="themes">
            {themeArr.map((item, index) => {
                return <div key={index} className="theme-box" style={{ background: item.primaryColor }} onClick={() => {
                    setTheme(index);
                    setGameClosed(false);
                }} />
            })}
        </div>
    </div >
}

function GameContainer({ mode, templateType, storyData, questionData, updateAnswer }) {
    const [themeInd, setThemeInd] = useState(0);
    const [gameData, setGameData] = useState({});
    const [gameConfig, setGameConfig] = useState({});
    const [gameClosed, setGameClosed] = useState(true);
    const gameRef = useRef(null);

    useEffect(() => {
        const data = storyData || BridgeUtils.getResourceData("readAloud")?.resourceData;
        const config = BridgeUtils.getConfigData();
        const themeColour = data?.['payload']?.['context']?.["theme_colour"];
        const themeInd = themeArr.findIndex((item) => {
            return item.primaryColor === themeColour
        })
        setThemeInd(themeInd < 0 ? 0 : themeInd);
        setGameData(data);
        setGameConfig(config);
    }, [storyData])

    const closeGame = () => {
        setGameClosed(true);
        BridgeUtils.sendUiEvent('close');
    }

    return (
        <div style={{ width: "100%" }}>
            <ThemeProvider theme={themeArr[themeInd]}>
                {gameClosed && isDevEnv && mode === "story" ? <ThemeHandler setTheme={setThemeInd} setGameClosed={setGameClosed} /> :
                    <ReadAloud data={gameData || {}}
                        config={gameConfig} closeGame={closeGame} ref={gameRef} setGameClosed={setGameClosed} mode={mode} templateType={templateType} questionData={questionData} updateAnswer={updateAnswer} />}
            </ThemeProvider>
        </div>
    );
}

export default GameContainer;