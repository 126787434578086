// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["workspaceId", "projectId", "nodeId", "params"],
		success: ["params", "data", "entities", "meta"],
		failure: ["params", "error"]
	},
	{ prefix: "NODES_FETCH_TREE_" }
);

export { Types, Creators };

export const request = (state: Object, { nodeId }: Object) => {
	const fetching_tree = state.fetching_tree;
	return state.merge({ fetching_tree: fetching_tree.merge({ [nodeId]: true }), error: null });
};

export const success = (state: Object, { params, data }: Object) => {
	const fetching_tree = state.fetching_tree;
	return state.merge({ fetching_tree: fetching_tree.without(params.nodeId), error: null });
};

export const failure = (state: Object, { params, error }: Object) => {
	const fetching_tree = state.fetching_tree;
	return state.merge({ fetching_tree: fetching_tree.without(params.nodeId), data: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
