import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import _get from 'lodash/get';
import ReadAloudText from './Page';
import './style.scss';
import Pages from "./Slider";
import Header from './Header';
import wave from "./../../assets/images/wave.svg";
import { useGameContext } from "../GameContext";
import buddy from "./../../assets/images/buddy.png";
import BridgeUtils from '../../utils/JsBridge';
import { isDevEnv } from '../../config';

const Story = forwardRef(({ }, ref) => {
    const sliderRef = useRef(null);
    const [listToRender, setListToRender] = useState([]);
    const { initialPage, options, textToSpeech, updateResultByPage, currentPage, setCurrentPage, mode } = useGameContext();
    const pageRef = useRef(null);

    useEffect(() => {
        BridgeUtils.onBuddyClicked(onClickBuddy);
        setCurrentPage(initialPage);
    }, []);

    useEffect(() => {
        const res = [];
        options.map((optionData, index) => {
            const shouldRenderContent = currentPage === index + 1 ? true : false;
            const align = "left";
            const { id } = optionData;
            res.push(
                <div id={id} key={`${id}_${index}`}>
                    <div
                        key={index}
                        className={`text-wrapper readText ${align ? `text-${align}` : ''}`}
                    >
                        {shouldRenderContent &&
                            <ReadAloudText
                                optionData={{ ...optionData }}
                                goToNextPage={goToNextPage}
                                currentPage={currentPage}
                                ref={pageRef}
                            />
                        }
                    </div>
                </div>
            );
        })
        setListToRender(res);
    }, [currentPage, options])

    const getOptionId = (page) => {
        return options[page - 1]?.["id"]
    }

    const goToNextPage = () => {
        console.log("go to next page")
        if (currentPage < options.length) {
            updateResult();
            setCurrentPage(currentPage + 1);
            textToSpeech('Continue reading');
            sliderRef.current.goToNextPage();
        }
    };


    const updateResult = () => {
        const result = pageRef.current?.pageStatus;
        const wordArr = Array.isArray(result.words) ? result.words : [];
        result.words = wordArr?.map((item) => {
            if (item.status === "correct") {
                item.isEvaluated = true;
            }
            return item;
        })
        updateResultByPage(result, currentPage);
    }
    const onPageChange = (val) => {
        updateResult();
        setCurrentPage(val);
    }



    const onClickBuddy = () => {
        let count = 0;
        let toSpeak = "";
        const promptWord = pageRef.current?.promptWord;
        const { sentences } = pageRef.current?.pageStatus;
        sentences.map((item) => {
            if (promptWord >= count && promptWord <= count + item.wordsCount) {
                toSpeak = item.sentence;
            }
            count += item.wordsCount;
        })
        textToSpeech(toSpeak);
        return true;
    }

    return (
        <>
            <Header currentPage={currentPage} />
            {listToRender.length !== 0 &&
                <div className="readAloudContainer" >
                    <Pages onPageChange={onPageChange} ref={sliderRef} initialSlide={currentPage - 1}>
                        {listToRender}
                    </Pages>
                    <img src={wave} className="wave" />
                </div>
            }
            {isDevEnv && mode === "story" &&
                <div onClick={onClickBuddy} className="dummy-buddy" >
                    <img src={buddy} style={{ width: "100px" }} />
                </div>
            }

            {mode === "editor" &&
                <div className="editor-page-navigation-button">
                    <button className="btn" onClick={() => sliderRef.current.goToPrevPage()}>Prev</button>
                    <button className="btn" onClick={() => sliderRef.current.goToNextPage()}>Next</button>
                </div>
            }
        </>
    );
});

export default Story;
