import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Loadable from "react-loadable";

import MainLayout from "./Layouts/MainLayout";

import LodableLoading from "./Components/LodableLoading";

import NotFoundPage from "./Pages/NotFoundPage";
import WorkspacesPage from "./Pages/WorkspacesPage";
import ProjectsPage from "./Pages/ProjectsPage";
import ProjectsTreePage from "./Pages/ProjectsTreePage";
import WorkspacesDetailPage from "./Pages/WorkspacesDetailPage";
import EnsureLoggedIn from "./Pages/EnsureLoggedIn";
import ResourcesPage from "./Pages/ResourcesPage";
import HomePage from "./Pages/HomePage";

import { injector } from "./store";

const WorkspaceDetailRouter = () => (
    <Switch>
        <Route
            exact
            path="/workspaces/:id"
            component={WorkspacesDetailPage}
        />
        <Route
            exact
            path="/workspaces/:workspace_id/projects/:id"
            component={ProjectsPage}
        />
        <Route
            path="/workspaces/:workspace_id/projects/:id/tree/:root_node_id"
            component={ProjectsTreePage}
        />
    </Switch>
);

const WorkspaceRouter = () => (
    <Switch>
        <Route exact path="/workspaces" component={WorkspacesPage} />
        <Route path="/workspaces/:id" component={WorkspaceDetailRouter} />
    </Switch>
);

const UsersRouter = Loadable({
    loader: () =>
        import("./Users/Routes").then(injector),
    loading: LodableLoading
});

const ResourcesRouter = () => (
    <Switch>
        <Route path="/resources" component={ResourcesPage} />
    </Switch>
);


const IssuesRouter = Loadable({
    loader: () =>
        import("./Issues/Routes").then(injector),
    loading: LodableLoading
});


const GameStudioRouter = Loadable({
    loader: () => import("./GameStudio/Routes"),
    loading: LodableLoading
});

const KgItemsRouter = Loadable({
    loader: () =>
        import("./KgItems/Routes").then(injector),
    loading: LodableLoading
});

const AppsRouter = Loadable({
    loader: () => import("./Apps/Routes").then(injector),
    loading: LodableLoading
});

const FilesRouter = Loadable({
    loader: () => import("./Files/Routes").then(injector),
    loading: LodableLoading
});

const ProjectSchemaRouter = Loadable({
    loader: () => import("./ProjectSchema/Routes").then(injector),
    loading: LodableLoading
});

const PackagesRouter = Loadable({
    loader: () =>
        import("./Packages/Routes").then(injector),
    loading: LodableLoading
});

const TeamsRouter = Loadable({
    loader: () =>
        import("./Teams/Routes").then(injector),
    loading: LodableLoading
})


const MainRouter = () => (
    <EnsureLoggedIn>
        <MainLayout>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/workspaces" component={WorkspaceRouter} />
                <Route path="/users" component={UsersRouter} />
                <Route path="/teams" component={TeamsRouter} />
                <Route path="/issues" component={IssuesRouter} />
                <Route path="/resources" component={ResourcesRouter} />
                <Route path="/game-studio" component={GameStudioRouter} />
                <Route path="/knowledge-graph" component={KgItemsRouter} />
                <Route path="/applications" component={AppsRouter} />
                <Route path="/files" component={FilesRouter} />
                <Route path="/project-schemas" component={ProjectSchemaRouter} />
                <Route path="/packages" component={PackagesRouter} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </MainLayout>
    </EnsureLoggedIn>
);

export default withRouter(MainRouter);
