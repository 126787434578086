class UndoService {
  constructor({ maxSize, coolDown }) {
    this.maxSize = maxSize + 1;
    this.coolDown = coolDown;
    this.iid = null;
    this.undoQueue = new Array(maxSize);
    this.pointer = -1;
    this.lastSave = null;
  }

  push(resource, ignoreCoolDown) {
    if (ignoreCoolDown || !this.lastSave || new Date().getTime() - this.lastSave > this.coolDown) {
      this.pointer = (this.pointer + 1) % this.maxSize;
      this.undoQueue[this.pointer] = resource;
      this.undoQueue[(this.pointer + 1) % this.maxSize] = null;
      this.lastSave = new Date().getTime();
    }
  }

  undoRedo(direction) {
    const nextPointer = (this.pointer + direction) % this.maxSize;
    const resource = this.undoQueue[nextPointer];
    if (resource) {
      this.pointer = nextPointer;
      return resource;
    }
  }

  undo() {
    return this.undoRedo(-1);
  }

  redo() {
    return this.undoRedo(1);
  }
}

export default UndoService;
