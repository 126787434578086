import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import Question from './Question'
import Header from "../MainLayout/Header";
import wave from "../../assets/images/wave.svg";

function Questions({ questionsData, updateAnswer, mode }) {
    const [currentQuestionInd, setCurrentQuestionInd] = useState(0);
    const [questionsToRender, setQuestionsToRender] = useState([]);

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchThreshold: 20,
        initialSlide: 0,
        afterChange: current => {
            setCurrentQuestionInd(current);
        }
    };

    useEffect(() => {
        const res = [];
        questionsData.map((optionData, index) => {
            const shouldRenderContent = currentQuestionInd === index ? true : false;
            const { id } = optionData;
            const qData = optionData?.rawQuestion?.payload?.container;
            res.push(
                <div id={id} key={`${id}_${index}`}>
                    <div
                        key={index}
                        className={`text-wrapper readText`}
                    >
                        {shouldRenderContent &&
                            <Question data={qData} />
                        }
                    </div>
                </div>
            );
        })
        setQuestionsToRender(res);
    }, [questionsData, currentQuestionInd])

    const renderQuestion = () => {
        if (mode === "story") {
            return questionsToRender;
        }
        return <Question data={questionsData[currentQuestionInd]?.container} updateAnswer={updateAnswer} />;
    }

    const renderDots = () => {
        return <div className="dots-container">
            {questionsData.length > 1 && questionsData.map((item, index) => {
                return (
                    <div className={`dot ${index === currentQuestionInd && "active"}`}></div>
                )
            })}
        </div>
    }

    return <div className="questions-wrapper">
        <Header
            isScoreboard={false}
            showProgress={false}
            source="questions"
            hideProgressBar
            hideScore
            showMic={false}
        />
        {renderDots()}
        <Slider {...settings}>
            {renderQuestion()}
        </Slider>
        <img src={wave} className="wave" alt="" />
    </div>
}

export default Questions
