import Immutable from "seamless-immutable";
import { createActions } from "reduxsauce";
import { Types as SchemaTypes } from "./SchemaRedux";
import {
  CreateTypes as ProjectCreateTypes,
  RebaseTypes as ProjectRebaseTypes,
  ListTypes as ProjectListTypes
} from "./ProjectsRedux";


const { Types, Creators } = createActions(
  {
    cleanup: ["entity"]
  },
  { prefix: "ENTITIES_" }
);

export { Types, Creators }

const entitiesReducer = (state: Object = Immutable({}), reducerParams: Object) => {
  const { type, entities } = reducerParams;

  if( type === Types.CLEANUP ) {
    return state.setIn([entities], {});
  }

  if (entities) {
    const { entities: entitiesObj, schema } = entities;

    Object.keys(entitiesObj).forEach(key => {
      if (schema[key]) {
        const existingState = state[key] || Immutable({});
        var newState = existingState.merge(entitiesObj[key]);
        state = state.setIn([key], newState);
      }
    });
  }

  if (type === SchemaTypes.SUCCESS) {
    const { schema } = reducerParams;
    Object.keys(schema).forEach(key => {
      if (Array.isArray(schema[key])) {
        const existingState = state[key];
        if (!existingState) {
          state = state.setIn([key], {});
        }
      }
    });
    return state;
  }

  if (type === ProjectCreateTypes.SUCCESS) {
    const { params, data } = reducerParams;
    const prepend = (projects, id) => {
      /* handle race */
      if (projects[0] === id) {
        return projects;
      }

      return [id, ...projects];
    };
    return state.updateIn(["workspaces", params.workspaceId, "projects"], prepend, data.project);
  }

  if (type === ProjectRebaseTypes.SUCCESS) {
    const { params, data } = reducerParams;
    return state.setIn(["workspaces", params.workspaceId, "projects"], data.projects);
  }

  if (type === ProjectListTypes.SUCCESS) {
    const { params, data, meta } = reducerParams;
    if (meta.pagination.current_page === 1)
      return state.setIn(["workspaces", params.workspaceId, "projects"], data.projects);
    else {
      let projects = state.getIn(["workspaces", params.workspaceId, "projects"]);
      return state.setIn(
        ["workspaces", params.workspaceId, "projects"],
        projects.concat(data.projects)
      );
    }
  }

  return state;
};

export default entitiesReducer;
