import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './style.scss';
import _get from 'lodash/get';
import { uniqueId } from 'lodash';
import { commonPrefixUtil, getFinalWord } from '../../../utils';
import usePrevious from '../../../utilComponents/hooks/usePrevious';
import useDidMountEffect from '../../../utilComponents/hooks/useDidMountEffect';
import Image from '../../../utilComponents/Image';
import { useGameContext } from "./../../GameContext";
import { processText, charsToIgnore } from "./utils"
import CoinAnimation from "./CoinAnimation";

import { parse } from 'node-html-parser';


const PAGE_COMPLETETION_MARGIN = 4;

const ReadAloudText = forwardRef(({
  optionData,
  goToNextPage,
  currentPage
}, ref) => {
  const [imgSrc, setImgSrc] = useState("");
  const [words, setWords] = useState([]);
  const [promptWord, setPromptWord] = useState(0);
  const [transcript, setTranscript] = useState("");
  const [isAttemped, setIsAttemped] = useState(false);
  const [isLastCompleted, setIsLastCompleted] = useState(false);
  const wordsLookupRef = useRef({});
  const wrongWordsRef = useRef({});
  const oldTranscript = usePrevious(transcript);
  const [sentences, setSentences] = useState([]);
  const { textToSpeech, options, result, listenToOption, stopListening, setCompleted, handleScoring, clearOneTimeHintTimer, oneTimeHint2Timer, oneTimeHint1Timer, mode, readTimer } = useGameContext();
  const userInteractionTimerRef = useRef(null);
  const wordsCountRef = useRef(0);
  const pageChangeTimerRef = useRef(null);
  const firstTimeWordHintCompleted = useRef(null);


  useImperativeHandle(ref, () => ({
    pageStatus: { words: words, promptWord: promptWord, pageImage: imgSrc, wrongWords: wrongWordsRef.current, sentences: sentences, isLastCompleted: isLastCompleted },
    promptWord: promptWord
  }));

  const getOptionId = (page) => {
    return options[page - 1]?.["id"]
  }

  const startListening = (pageNo = currentPage) => {
    listenToOption(getOptionId(pageNo), (data) => {
      setTranscript(data.response?.Text);
    })
  }

  useEffect(() => {
    startListening();
  }, []);

  const onLastPageComplete = () => {
    stopListening(() => {
      setCompleted(true);
    })
  }

  // useEffect(() => {
  //   if (oneTimeHint1Timer) {
  //     userInteractionTimerRef.current = setTimeout(() => {
  //       if (firstTimeWordHintCompleted.current !== true) {
  //         textToSpeech(`read this out loud`, () => {
  //           setTimeout(() => {
  //             textToSpeech(words[promptWord]?.word)
  //           }, 500);
  //         });
  //         firstTimeWordHintCompleted.current = true;
  //       }
  //       else {
  //         textToSpeech(words[promptWord]?.word);
  //       }
  //       clearTimeout(userInteractionTimerRef.current);
  //     }, 14000)
  //   } else {
  //     userInteractionTimerRef.current = setTimeout(() => {
  //       if (firstTimeWordHintCompleted.current !== true) {
  //         textToSpeech(`read this out loud`, () => {
  //           setTimeout(() => {
  //             textToSpeech(words[promptWord]?.word)
  //           }, 500);
  //         });
  //         firstTimeWordHintCompleted.current = true;
  //       }
  //       else {
  //         textToSpeech(words[promptWord]?.word);
  //       }
  //       clearTimeout(userInteractionTimerRef.current);
  //     }, 7000)
  //   }
  // }, [oneTimeHint1Timer])



  useEffect(() => {
    // console.log(`%c ${transcript}`, 'background: red; color: white');
    if (transcript && transcript !== oldTranscript) {
      let commonPrefix = commonPrefixUtil(oldTranscript, transcript);
      let lastPrefixSpace = commonPrefix.lastIndexOf(" ");
      let lengthToIgnore = lastPrefixSpace;
      const newWords = lengthToIgnore === 0 ? transcript : transcript.substring(lengthToIgnore, transcript.length);
      const arr = newWords?.split(" ");
      let wordsCopy = [...words];
      let promptInd = promptWord;
      const wrongWords = { ...wrongWordsRef.current };
      let lastCorrect = 0;
      // console.log(`%c ${newWords}`, 'background: #222; color: #bada55');

      if (promptWord !== words.length) {
        arr.map((item) => {
          let found = false;
          for (let i = promptInd; i <= promptInd + 3; i++) {
            const word = wordsCopy[i]?.word;
            if (promptInd > wordsCopy.length || !wordsCopy[i]) break;
            if (getFinalWord(item) === word) {


              //if user started interacting dont give one time hints
              if (promptInd === 0) {
                clearOneTimeHintTimer();
              }
              clearTimeout(userInteractionTimerRef.current);
              userInteractionTimerRef.current = setTimeout(() => {
                if (firstTimeWordHintCompleted.current !== true) {
                  textToSpeech(`read this out loud`, () => {
                    setTimeout(() => {
                      textToSpeech(wordsCopy[promptInd]?.word)
                    }, 500);
                  });
                  firstTimeWordHintCompleted.current = true;
                }
                else {
                  textToSpeech(wordsCopy[promptInd]?.word);
                }
                clearTimeout(userInteractionTimerRef.current);
              }, 10000)

              promptInd = i + 1;
              if (wordsCopy[i].status !== "correct") {
                playCoinAnimation(i)
                wordsCopy[i].status = "correct";
                handleScoring(1);
              }
              lastCorrect = i;
              found = true;
              break;
            }
            else {
              wordsCopy[i].status = "wrong";
            }
          }

          if (!found) {
            for (let i = promptInd - 8; i <= promptInd; i++) {
              if (getFinalWord(item) === wordsCopy[i]?.word) {

                if (wordsCopy[i].status !== "correct") {
                  playCoinAnimation(i)
                  wordsCopy[i].status = "correct";
                  handleScoring(1);
                }
                lastCorrect = i;
                found = true;
                break;
              }
            }
          }
        });
      }
      else if (promptWord === words.length) {
        arr.map((item) => {
          const word = getFinalWord(item);
          if (wrongWords.hasOwnProperty(word)) {
            if (Array.isArray(wrongWords[word])) {
              const index = wrongWords[word]?.[0]?.index;

              if (wordsCopy[index].status !== "correct") {
                playCoinAnimation(index)
                wordsCopy[index].status = "correct";
                handleScoring(1);
              }

              wrongWords[word].shift();
              const arr = wrongWords[word];
              wrongWords[word] = [...arr];
              if (wrongWords[word].length === 0) {
                delete wrongWords[word];
              }
            }
            else {
              const index = wrongWords[word]?.index;
              if (wordsCopy[index]) {
                if (wordsCopy[index].status !== "correct") {
                  playCoinAnimation(index)
                  wordsCopy[index].status = "correct";
                  handleScoring(1);
                }
              }
              delete wrongWords[word];
            }
          }
        });
      }
      setPromptWord(promptInd);
      setWords(wordsCopy);
      wrongWordsRef.current = wrongWords;
      setIsAttemped(false);
    }
  }, [transcript, promptWord, words])


  const playCoinAnimation = (id) => {
    const lottie = document.getElementById(`coin-lottie-${id}`);
    if (lottie && lottie.style)
      lottie.style.display = "block";

    setTimeout(() => {
      if (lottie && lottie.style)
        lottie.style.display = "none";
    }, 400)
  }

  useDidMountEffect(() => {
    const length = wordsCountRef.current;
    if (length > 0 && promptWord === length && !isLastCompleted) {
      setIsLastCompleted(true);
      onPageComplete();
      // console.log("page completed")
    }

    if (promptWord > Math.abs((length - PAGE_COMPLETETION_MARGIN))) {
      clearTimeout(pageChangeTimerRef.current);
      pageChangeTimerRef.current = setTimeout(() => {
        setIsLastCompleted(true);
        onPageComplete();
        // console.log("page completed")
      }, 10000)
    }
  }, [promptWord, isLastCompleted]);


  const processRawText = (data) => {
    const { sentencesDetails, allWords } = processText(data);
    let arr = allWords;
    let wordsArr = [];
    let wordsLookup = {};
    let index = 0;
    arr.forEach((item) => {
      if (item && !charsToIgnore.hasOwnProperty(item)) {
        let obj = {
          id: uniqueId(index),
          displayText: item,
          // eliminate special characters from prefix and suffix
          word: getFinalWord(item),
          status: "",
          index: index
        }

        wordsArr.push(obj);
        index++;
        //lookup
        const val = wordsLookup[obj.word];
        if (val && Array.isArray(val)) {
          wordsLookup[obj.word] = [...val, obj]
        }
        else wordsLookup[obj.word] = [obj];
      }
      return null;
    })
    wordsLookupRef.current = wordsLookup;
    setWords(wordsArr);
    wordsCountRef.current = wordsArr.length;
    setSentences(sentencesDetails);
  }

  useEffect(() => {
    const { context } = optionData;
    const html = _get(context, 'html', '');
    const currentPageResult = Array.isArray(result) && result[currentPage - 1] ? result[currentPage - 1] : {};
    if (currentPageResult.hasOwnProperty("words") && mode !== "editor") {
      const { words, promptWord, pageImage, wrongWords, sentences, isLastCompleted } = currentPageResult;
      setWords(words);
      wordsCountRef.current = words.length;
      setIsAttemped(true);
      setIsLastCompleted(isLastCompleted)
      setPromptWord(promptWord);
      setImgSrc(pageImage);
      setSentences(sentences);
      wrongWordsRef.current = wrongWords;
    } else {
      if (html) {
        const root = parse(html);
        const text = root.text;
        const img = root.getElementsByTagName("img")?.[0]?.getAttribute("src");
        setImgSrc(img || "");
        processRawText(text || "");
      } else {
        setWords([]);
      }
    }
  }, [optionData])

  const onClickWord = (word) => {
    textToSpeech(word);
  }

  // on first time completetion of a page
  const onPageComplete = () => {
    const wrongWords = {};
    words.forEach((item) => {
      const currWord = item.word;
      const status = item.status;
      if (status === "wrong") {
        if (wrongWords.hasOwnProperty(currWord)) {
          if (Array.isArray(wrongWords[currWord])) {
            wrongWords[currWord] = [...wrongWords[currWord], item]
          }
          else {
            wrongWords[currWord] = [wrongWords[currWord], item]
          }
        }
        else {
          wrongWords[currWord] = item;
        }
      }
    })
    wrongWordsRef.current = wrongWords;
    readTimer.pause();
    navigatePage();
  }

  const getClassName = (item) => {
    if (mode === "editor") {
      return "";
    }
    const { index, status } = item;
    if (index === promptWord) return "prompt"
    else if (status === "correct") return "correct";
    else if (index < promptWord && status === "wrong") return "wrong";
    else return "";
  }

  const successfullyMoveToNextPage = () => {
    // console.log("successfullyMoveToNextPage")
    if (currentPage === options.length) {
      textToSpeech("Awesome! You have completed the story", () => {
        onLastPageComplete();
      });
    } else {
      textToSpeech("Excellent! You are reading really well.", () => {
        goToNextPage();
      });
    }
  }

  const navigatePage = () => {
    let firstWrongInd = -1;
    const correctCount = words.filter(each => {
      if (each.status === "wrong" && firstWrongInd < 0) {
        firstWrongInd = each.index;
      }
      return each.status === 'correct';
    }).length;
    const percent = (correctCount / words.length) * 100;
    if (percent < 75) {
      textToSpeech(`Good! Can you Read this word out loud!`);
      setTimeout(() => {
        textToSpeech(words[firstWrongInd].word);
      }, 2000)
    }
    if (percent > 75) {
      successfullyMoveToNextPage();
    }
  }

  // console.log("wordssssssss", { words })
  return <div className="page-content">
    <div>
      <Image src={imgSrc} alt="hint" />
      <div className="words" style={{ maxHeight: imgSrc && "200px", overflow: "scroll" }}>
        {words.map((item, index) => {
          return <span onClick={() => onClickWord(item.word)} className={`word ${getClassName(item)}`}>
            {/* <Coin
              id={`coinElement-${index}`}
              src={coin}
              className={`${(!isAttemped && item.status === 'correct') ? 'coin coin-show-animation' : 'coin'}`}
              alt=""
              style={item.isEvaluated ? { display: 'none' } : {}}
              state={getClassName}
              delay={`${0.6 + index * 0.05}`}
              isFloat={floatToTop && item.status === 'correct'}
              Distance={scoreBoardDistance}
              onAnimationEnd={(e) => {
                
              }}
            /> */}

            <div className="coin-lottie" id={`coin-lottie-${index}`} style={{ display: "none" }}>
              <CoinAnimation />
            </div>
            {item.displayText}
          </span>
        })}
      </div>
      <div className="dialog" />
    </div >
  </div >
});

export default ReadAloudText;
