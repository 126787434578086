import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import { file } from "../../Services/Api/Schema";

import createReducer_, { Types as CreateTypes, Creators as CreateCreators } from "./create";
import uploadReducer, { Types as UploadTypes, Creators as UploadCreators } from "./upload";
import listReducer, { Types as ListTypes, Creators as ListCreators } from "./list";
import detailListReducer, { Types as DetailListTypes, Creators as DetailListCreators } from "./detail_list";
import updateReducer, { Types as UpdateTypes, Creators as UpdateCreators } from "./update";
import importReducer, { Types as ImportTypes, Creators as ImportCreators } from "./import";

export const INITIAL_STATE = Immutable({
  data: false,
  error: null
});

export const key = "files";

export const selectFiles = state => {
  try {
    return denormalize(state[key].data, [file], state.entities);
  } catch (e) {
    return null;
  }
};

export const selectDetailFiles = state => {
  try {
    return denormalize(state[key].detail_data, [file], state.entities);
  } catch (e) {
    return null;
  }
};

export const selectFilesById = (state, id) =>
  denormalize(state.entities.files.getIn([id]), file, state.entities);

export const selectFilesRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};

export { CreateCreators, CreateTypes };
export { UploadCreators, UploadTypes };
export { ListCreators, ListTypes };
export { UpdateCreators, UpdateTypes };
export { ImportCreators, ImportTypes };
export { DetailListTypes, DetailListCreators };

export default createReducer(INITIAL_STATE, {
  ...createReducer_,
  ...listReducer,
  ...uploadReducer,
  ...updateReducer,
  ...importReducer,
  ...detailListReducer,
});
