import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default props => {
	return (
		<SvgIcon {...props}>
			<path d="M5 19H19V5h-7V3H19c1.11 0 2 .9 2 2v14c0 1.1-.89 2-2 2h-14c-1.1 0-2-.9-2-2v-7h2v7zM10 3v2h-3.59l9.83 9.83-1.41 1.41L5 6.41V10h-2V3h7z"/>
		</SvgIcon>
	);
};
