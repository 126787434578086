import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import { selectNormalizrSchemaByName } from "../SchemaRedux";

import fetchReducer, { Types as FetchTypes, Creators as FetchCreators } from "./fetch";
import saveReducer, { Types as SaveTypes, Creators as SaveCreators } from "./save";

export const INITIAL_STATE = Immutable({
  data: false,
  error: null
});

export const key = "resource_updates";

export { FetchCreators, FetchTypes };
export { SaveCreators, SaveTypes };

export const entitiesReducer = (
  state: Object = Immutable({}),
  { type, params, data, entities }: Object
) => {
  if (entities && entities.resource_updates) {
    return state.merge(entities.resource_updates);
  }

  return state;
};

export default createReducer(INITIAL_STATE, {
  ...fetchReducer,
  ...saveReducer
});

export const selectResourceUpdates = state => {
  try {
    return denormalize(
      state[key].data,
      selectNormalizrSchemaByName(state, "resource_updates"),
      state.entities
    );
  } catch (e) {
    return null;
  }
};

export const selectResourceUpdatesRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};
