import React from 'react';
import './Header.scss';
import { CloseIcon } from '../v2/Icons';
import BridgeUtils from '../../utils/JsBridge';
import { EVENT_ID, PHYLUM, COUNTER } from '../../utils/Olaps';

function Header({ sendOlapEvents, currentQueIndex, noOfQuestions,onGameEnd, children }) {
  const closeGame = () => {
    BridgeUtils.stopAudio();

    sendOlapEvents({
      u_event_id: EVENT_ID.CLOSE_ID,
      phylum: PHYLUM.CLICK,
      counter: COUNTER.CLOSE_PRESSED,
      variety: currentQueIndex + 1,
      record: noOfQuestions,
    });
    BridgeUtils.sendUiEvent('close');
    onGameEnd();
  };

  return (
    <div className="header-container">
      <div className="header-box" onClick={closeGame}>
        <CloseIcon />
      </div>
      <div className="header-content">{children}</div>
      <div className="header-box"></div>
    </div>
  );
}

export default Header;
