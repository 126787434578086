import { put, call, take, select } from "redux-saga/effects";
import { LogoutCreators, selectAccessToken } from "../Redux/SessionRedux";
import { normalize } from "normalizr";
import { buffers, eventChannel, END } from "redux-saga";
import { UploadCreators } from "../Redux/FilesRedux";
import { selectNormalizrSchema } from "../Redux/SchemaRedux";

export function* uploadSaga(entity, apiFunc, swapFunc, data) {
  const response = yield call(apiFunc, data);
  if (response.ok) {
    const schemaList = yield select(selectNormalizrSchema);
    const { result, entities } = normalize(response.data, schemaList);
    yield put(entity.success(data, result, { entities, schema: schemaList }));

    yield call(uploadFileSaga, response.data.file, data.file, swapFunc);
  } else {
    if (response.status === 401) {
      yield put(LogoutCreators.request());
    } else {
      yield put(entity.failure(data, response.data));
    }
  }
}

export function* uploadFileSaga(data, fileToUpload, swapFunc) {
  var channel;

  const schemaList = yield select(selectNormalizrSchema);
  
  data.uploading = true;
  data.progress = 0;
  const { result, entities } = normalize({ file: data }, schemaList);
  yield put(
    UploadCreators.progress(data, result, { entities, schema: schemaList })
  );

  if (data.multipart) {
    const accessToken = yield select(selectAccessToken);
    channel = yield call(
      createMultipartUploadFileChannel,
      data,
      accessToken,
      fileToUpload
    );
  } else channel = yield call(createUploadFileChannel, data, fileToUpload);

  while (true) {
    const { progress = 0, err, success, newName } = yield take(channel);

    if (err) {
      data.error = err;
      data.uploading = false;
      const { entities } = normalize({ file: data }, schemaList);
      yield put(
        UploadCreators.failure(data, err, { entities, schema: schemaList })
      );
      return;
    }

    if (success) {
      data.done = true;
      data.uploading = false;
      const { result, entities } = normalize({ file: data }, schemaList);
      yield put(
        UploadCreators.success(data, result, { entities, schema: schemaList })
      );
      return;
    }

    if (newName) {
      const response = yield call(swapFunc, {
        swapParams: {
          old_id: data.id,
          new_key: newName,
          bucket: data.multipart.bucket
        }
      });
      if (response.ok) {
        const { result, entities } = normalize(response.data, schemaList);
        yield put(
          UploadCreators.swap(data.id, result.file, {
            entities,
            schema: schemaList
          })
        );
        data = response.data.file;
      }
    }

    data.uploading = true;
    data.progress = progress;
    const { result, entities } = normalize({ file: data }, schemaList);

    yield put(
      UploadCreators.progress(data, result, { entities, schema: schemaList })
    );
  }
}

function createUploadFileChannel({ presigned_url }, file) {
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    const onFailure = (e: ProgressEvent) => {
      emitter({ err: new Error("Upload failed") });
      emitter(END);
    };

    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    xhr.open("PUT", presigned_url, true);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.send(file);
    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}

function parseS3URL(url) {
  //@js-s3-parse - expects path style
  const parser = document.createElement("a");
  parser.href = url;
  const path = parser.pathname.substr(1);
  const i = path.indexOf("/");
  return {
    s3_bucket: path.slice(0, i),
    s3_key: path.slice(i + 1)
  };
}

function createMultipartUploadFileChannel(
  { multipart, url, acl },
  accessToken,
  file
) {
  return eventChannel(emitter => {
    const ev = import("./multipart");
    const onFailure = e => {
      emitter({ err: new Error("Upload failed") });
      emitter(END);
    };
    const evLoaded = exp => {
      const config = {
        ...multipart,
        encodeFilename: false
      };
      exp.evaporateMaker(config).then(evaporate => {
        const urlparts = parseS3URL(url);

        evaporate
          .add(
            {
              name: urlparts.s3_key,
              file: file,
              nameChanged: newName => emitter({ newName }),
              progress: progress => emitter({ progress }),
              beforeSigner: xhr => {
                xhr.setRequestHeader("Authorization", accessToken);
              },
              xAmzHeadersAtInitiate: {
                "x-amz-acl": acl || "private"
              }
            },
            {
              bucket: urlparts.s3_bucket
            }
          )
          .then(() => {
            emitter({ success: true });
            emitter(END);
          }, onFailure);
      });
    };
    ev.then(evLoaded, onFailure);

    return () => {
      //TODO: Cancel
    };
  }, buffers.sliding(2));
}
