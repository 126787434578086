export const charsToIgnore = {
    "\n": true
}

export const processText = (txt) => {
    let words = [];
    let allWords = [];
    let word = "";
    let sentence = "";
    let sentences = [];
    let i = 0;
    let len = 0;

    const pushWord = (arr, item) => {
        if (item && !charsToIgnore.hasOwnProperty(item)) {
            arr.push(item);
            return arr;
        }
        else return arr;
    }
    while (txt.charAt(i)) {
        let char = txt.charAt(i);
        sentence += char;

        if (char === " ") {
            words = pushWord(words, word);
            word = "";
        }
        else {
            word += char;
        };

        if (char === ".") {
            words = pushWord(words, word);
            word = "";
            sentences.push({
                sentence: sentence,
                words: words,
                wordsCount: words.length
            })
            sentence = "";
            allWords = [...allWords, ...words];
            words = [];
        }

        i++; len++;
    }

    if (word) {
        words = pushWord(words, word);
    }

    if (words.length !== 0) {
        allWords = [...allWords, ...words];
        sentences.push({
            sentence: sentence,
            words: [...words],
            wordsCount: words.length
        })
    }
    return { sentencesDetails: sentences, allWords: allWords };
};