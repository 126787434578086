import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LockOpen from "@material-ui/icons/LockOpen";
import Lock from "@material-ui/icons/Lock";
import { Link } from "react-router-dom";
import classnames from "classnames";
import TextField from '@material-ui/core/TextField';

import ProjectChange from "./ProjectChanges";
import ProjectValidationErrors from "./ProjectValidationErrors";

import ClickCopyText from "./ClickCopyText";
import ProgressButton from "./ProgressButton";

import fecha from "fecha";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { updateProject } from "../Services/Api";
import { IconButton } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flex: 1,
  },
  version: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#333",
    textAlign: 'right',
    flex: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign: "right",
    flex: 1,
  },
  published: {
    backgroundColor: "#eee"
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  menuButton: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButtonClicked: false,
      projectName: this.props.name,
      nameError: false,
      anchorEl: null,
      open: false
    }
  }

  handleClick = (event) => {
    event.stopPropagation()
    this.setState({
      anchorEl: event.currentTarget,
      open: !this.state.open
    });
  };

  handleClose = (event) => {
    this.setState({
      anchorEl: null,
      open: !this.state.open
    });
  };


  render() {
    let {
      classes,
      version,
      id,
      workspace,
      workspace_id,
      is_published,
      canPublish,
      hasPublishAccess,
      hasRebaseAccess,
      onPublish,
      s3_url,
      buttons,
      description,
      onRebase,
      publishError,
      schema,
      root_node_id,
      publishing,
      updated_at,
      hasUpdateAccess
    } = this.props;
    const menuOptions = <Menu
      id="update_menu"
      anchorEl={this.state.anchorEl}
      keepMounted
      open={this.state.open}
      onClose={this.handleClose}
    >
      <MenuItem key={'rename'} onClick={this.handleClose}>
        <Button startIcon={<EditOutlinedIcon color="secondary" />} className={classes.menuButton}
          onClick={(event) => {
            this.handleEditClick(event)
            this.handleClose(event)
            }}>
          Rename
        </Button>
      </MenuItem>
    </Menu>
    return (
      <Accordion
        classes={{
          root: classnames(classes.root, { [classes.published]: is_published })
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.content }}>
          {hasUpdateAccess && !this.state.clickedEdit &&
            <span>
              <IconButton
                aria-label="Edit options"
                aria-controls="Action Menu"
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              {menuOptions}
            </span>
          }
          {this.state.clickedEdit ?
            <div className={classes.heading}>
              <TextField variant="outlined" onClick={event => event.stopPropagation()}
                error={this.state.nameError}
                helperText={this.state.nameError}
                value={this.state.projectName} size="small"
                required
                onChange={(event) => {
                  this.setState({
                    projectName: event.target.value
                  })
                }} />
              <IconButton onClick={this.handleProjectNameChange} color="secondary">
                <DoneIcon />
              </IconButton>
              <IconButton onClick={this.handleEditClick} color="primary">
                <ClearIcon />
              </IconButton>
            </div> :
            <Typography className={classes.heading}>
              {this.state.projectName}
            </Typography>
          }
          {version && (
            <Typography className={classes.version}>Version {version}</Typography>
          )}
          {id && (
            <Typography className={classes.secondaryHeading}>
              <Button
                color="primary"
                component={Link}
                to={`/workspaces/${workspace.id
                  }/projects/${id}/tree/${root_node_id}`}
              >
                Open Project{" "}
                {is_published ? (
                  <Lock className={classes.rightIcon} />
                ) : (
                    <LockOpen className={classes.rightIcon} />
                  )}
              </Button>
            </Typography>
          )}
        </AccordionSummary>
        {schema && (
          <AccordionDetails>
            <Typography variant="caption">
              {schema.name + " Version " + schema.version}
            </Typography>
          </AccordionDetails>
        )}
        {is_published && (
          <div>
            <AccordionDetails>
              <Grid item xs={9}>
                <ClickCopyText text={s3_url} />
              </Grid>
              {updated_at && (
                <Grid item xs={3}>
                  <Typography variant="caption">
                    Updated on{" "}
                    {fecha.format(
                      Date.parse(updated_at),
                      "ddd MMMM Do, YYYY hh:mm A"
                    )}
                  </Typography>
                </Grid>
              )}
            </AccordionDetails>
            <Divider />
            {
              this.state.showButtonClicked ?
                <Fragment>
                  <ProjectChange
                    workspace_id={workspace_id}
                    root_node_id={root_node_id}
                    project_id={id}
                    version={version}
                    is_published={is_published}
                  />
                  <AccordionActions>
                    <Button
                      classes={{ root: classes.button }}
                      color="primary"
                      variant="contained"
                      onClick={() => this.setState({ showButtonClicked: false })}
                    >
                      Hide Changes
                    </Button>
                  </AccordionActions>
                </Fragment>
                :
                <AccordionActions>
                  <Button
                    classes={{ root: classes.button }}
                    color="primary"
                    variant="contained"
                    onClick={() => this.setState({ showButtonClicked: true })}
                  >
                    Show Changes
                  </Button>
                </AccordionActions>
            }
          </div>
        )}

        {description && (
          <AccordionDetails>{description}</AccordionDetails>
        )}
        {!is_published && (
          <div>
            {publishError && publishError.errors && (
              <ProjectValidationErrors
                project={this.props}
                errors={publishError.errors}
              />
            )}
            <Divider />
            {id && (
              this.state.showButtonClicked ?
                <Fragment>
                  <ProjectChange
                    workspace_id={workspace_id}
                    root_node_id={root_node_id}
                    project_id={id}
                    version={version}
                    is_published={is_published}
                  />
                  <AccordionActions>
                    <Button
                      classes={{ root: classes.button }}
                      color="primary"
                      variant="contained"
                      onClick={() => this.setState({ showButtonClicked: false })}
                    >
                      Hide Changes
                    </Button>
                  </AccordionActions>
                </Fragment>
                :
                <AccordionActions>
                  <Button
                    classes={{ root: classes.button }}
                    color="primary"
                    variant="contained"
                    onClick={() => this.setState({ showButtonClicked: true })}
                  >
                    Show Changes
                  </Button>
                </AccordionActions>
            )}
            <AccordionActions>
              {id &&
                (canPublish
                  ? hasPublishAccess && (
                    <ProgressButton
                      inProgress={publishing}
                      classes={{ root: classes.button }}
                      color="secondary"
                      variant="contained"
                      error={publishError && publishError.errors && "Failed!"}
                      onClick={event => onPublish(id, event)}
                    >
                      Publish
                    </ProgressButton>
                  )
                  : hasRebaseAccess && (
                    <Button
                      classes={{ root: classes.button }}
                      color="primary"
                      variant="contained"
                      onClick={event => onRebase(id, event)}
                    >
                      Rebase
                    </Button>
                  ))}
              {buttons}
            </AccordionActions>
          </div>
        )}
      </Accordion>
    );
  }

  handleEditClick = (event) => {
    event.stopPropagation()
    this.setState({
      clickedEdit: !this.state.clickedEdit,
      projectName: this.props.name,
      nameError: false
    })
  }
  handleProjectNameChange = event => {
    event.stopPropagation();
    const { id, workspace_id } = this.props
    updateProject(
      workspace_id,
      id,
      {
        name: this.state.projectName
      }
    ).then(response => {
      response.data.error ?
        this.setState({
          nameError: response.data.error
        }) :
        this.setState({
          nameError: false,
          clickedEdit: !this.state.clickedEdit
        })
    })
  }
}

export default withStyles(styles)(Project);
