import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import compose from "recompose/compose";

class AudioPreview extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onLoad = () => {
		this.setState({
			error: false,
			//width: this.img.naturalWidth,
			//height: this.img.naturalHeight
		});
	};

	onError = event => {
		this.setState({ error: true });
	};

	render() {
		const { classes, src } = this.props;
		const { error = false } = this.state;

		if (!src || src.length === 0) return null;

		return (
			<div className={classes.flex}>
				<div className={classes.previewCanvas}>
					<audio
						ref={ref => (this.audio = ref)}
						src={src}
						controls
						controlsList="nodownload"
						preload="none"
						alt="preview"
						onLoadedData={this.onLoad}
						onError={this.onError}
					/>
				</div>
				<div className={classes.attrs}>
					<Typography variant="caption">
						{error && "Audio failed to load"}
					</Typography>
				</div>
			</div>
		);
	}
}

const styles = theme => ({
	flex: { display: "flex", padding: theme.spacing(1) },
	attrs: { padding: theme.spacing(1) },
	previewCanvas: {
		display: "inline-block",
	}
});

export default compose(withStyles(styles))(AudioPreview);
