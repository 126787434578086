import React from 'react';

export default function StopIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          rx="0"
          width="60"
          height="60"
          x="0"
          y="0"
          opacity="1"
          {...props}
        />
      </g>
    </svg>
  );
}
