import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import compose from "recompose/compose";
import { connect } from "react-redux";

import { withRouter } from "react-router";
import TitleBar from "../Components/TitleBar";

import { Route, Switch, Link } from "react-router-dom";

import SchemaBrowserPage from "./SchemaBrowserPage";
import ResourceBrowserPage from "./ResourceBrowserPage";
import ResourceEditorPage from "./ResourceEditorPage";

import { fetchNodePreviousVersion } from "../Services/Api";
import _get from "lodash/get"

import {
  selectNodeById,
  selectNodesRequestState,
  RevertCreators as NodeRevertCreators,
  FetchCreators as NodesFetchCreators
} from "../Redux/NodesRedux";

const styles = theme => ({
  drawerPaper: {
    width: 320,
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
    height: "100vh",
    overflow: "auto",
    position: "relative",
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: 500
    },
    [theme.breakpoints.up("lg")]: {
      width: 800
    }
  },
  revertContainer: {
    padding: theme.spacing(3)
  }
});

class NodeResourcesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { match, history, node, location } = nextProps;

    if (match.isExact && node) {
      // duh , redirect?
      if (node.resource_id) {
        history.push(`${location.pathname}/resources/${node.resource_name}/${node.resource_id}`);
      } else {
        history.push(`${location.pathname}/resources`);
      }
    }

    if( !node ) {
      const { node_id: currentNodeId } = this.props.match.params;
      const { node_id: nextNodeId } = nextProps.match.params;
      if (currentNodeId !== nextNodeId) {
        const { workspace_id, project_id } = this.props.match.params;
        this.props.fetchNode(workspace_id, project_id, nextNodeId);
      }
    }
  }

  componentDidMount() {
    if (!this.props.node) {
      const { workspace_id, project_id, node_id } = this.props.match.params;
      this.props.fetchNode(workspace_id, project_id, node_id);
    }
  }

  onRevertClick = () => {
    const { project, workspace, node } = this.props;
    this.props.revertNode(workspace.id, project.id, node.id);
  };

  loadPreviousVersion = () => {
    const {
      workspace_id: workspaceId,
      project_id: projectId,
      node_id: nodeId,
      root_node_id: rootNodeId
    } = this.props.match.params;
    const { history } = this.props;
    fetchNodePreviousVersion({ workspaceId, projectId, nodeId }).then(response => {
      if (response.ok) {
        const nodeId = response.data;
        history.push(`/workspaces/${workspaceId}/projects/${projectId}/tree/${rootNodeId}/nodes/${nodeId}`);
      } else {
        this.setState({ previousVersionMissing: true });
      }
    });
  };

  render() {
    const { classes, project, workspace, node, rootNode, abilities } = this.props;
    const { previousVersionMissing = false } = this.state;

    const parentPath = `/workspaces/${workspace.id}/projects/${project.id}/tree/${rootNode.id}`;
    const prefix = node ? `${parentPath}/nodes/${node.id}` : "";
    const hasRevertAccess = _get(abilities,"update_node");
    const editable = !project.is_published && !project.is_frozen && hasRevertAccess;
    return (
      <Drawer
        variant="persistent"
        anchor="right"
        open={!!node}
      >
        {node && (
          <div className={classes.drawerPaper}>
            <TitleBar
              title={`Node ${node.id}`}
              subtitle={
                <div>
                  {editable &&
                    node.version_start === project.version &&
                    (previousVersionMissing ? (
                      <Button disabled color="primary">
                        Previous Version
                      </Button>
                    ) : (
                      <Button color="primary" onClick={this.loadPreviousVersion}>
                        Previous Version
                      </Button>
                    ))}
                  <Button color="default" component={Link} to={parentPath}>
                    Close
                  </Button>
                </div>
              }
            />
            {editable &&
              node.version_end &&
              node.version_end <= project.version && (
                <Grid
                  className={classes.revertContainer}
                  container
                  alignContent="center"
                  justify="center"
                >
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={this.onRevertClick}>
                      Revert to this Version of Node
                    </Button>
                  </Grid>
                </Grid>
              )}
            <Switch>
              <Route
                exact
                path="/workspaces/:workspace_id/projects/:project_id/tree/:root_node_id/nodes/:node_id/resources"
                render={props => <SchemaBrowserPage prefix={prefix} {...props} disableEditor />}
              />
              <Route
                exact
                path="/workspaces/:workspace_id/projects/:project_id/tree/:root_node_id/nodes/:node_id/resources/:resource_name"
                render={props => <ResourceBrowserPage prefix={prefix} {...props} disableEditor />}
              />
              <Route
                exact
                path="/workspaces/:workspace_id/projects/:project_id/tree/:root_node_id/nodes/:node_id/resources/:resource_name/new"
                render={props => (
                  <ResourceEditorPage
                    prefix={prefix}
                    project={project}
                    node={node}
                    {...props}
                    disableEditor
                  />
                )}
              />
              <Route
                exact
                path="/workspaces/:workspace_id/projects/:project_id/tree/:root_node_id/nodes/:node_id/resources/:resource_name/:resource_id"
                render={props => (
                  <ResourceEditorPage
                    prefix={prefix}
                    abilities={abilities}
                    project={project}
                    node={node}
                    {...props}
                    disableEditor
                  />
                )}
              />
            </Switch>
          </div>
        )}
      </Drawer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  revertNode: (w, p, n) => dispatch(NodeRevertCreators.request(w, p, n)),
  fetchNode: (a, b, c) => dispatch(NodesFetchCreators.request(a, b, c))
});

const mapStateToProps = (state, ownProps) => {
  let {
    match: { params }
  } = ownProps;
  return {
    node: selectNodeById(state, params.project_id, params.node_id),
    nodeRequests: selectNodesRequestState(state)
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NodeResourcesPage);
