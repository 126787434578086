import Immutable from 'seamless-immutable';

export const transformRepresentation = (resource, schema, opts) => {
    if (!opts) opts = {};

    var out = schema.columns.asMutable().reduce(
        (out, spec) => {
            const hasManySchema = spec.has_many;
            const reference = spec.references;
            const key = spec.name;

            if(!resource.hasOwnProperty(key)) {
                return out;
            }

            if (opts.api) {
                if (spec.readonly) {
                    return out;
                }

                if (spec.type === "autocomplete") {
                    out[spec.field] = resource[key]? resource[key].value: null;
                    return out;
                }

                if (spec.type === "autocomplete_multiple") {
                    out[spec.field] = resource[key]? resource[key].map(x => x.value) : null;
                    return out;
                }
            }

            if (reference) {
                out[reference.field] = resource[key]
                    ? resource[key][reference.id_key]
                    : null;
            }

            if (hasManySchema) {
                const arr = (resource[key] || []).map(res =>
                    transformRepresentation(res, hasManySchema)
                );
                out[key] = arr;
            } else {
                const val = resource[key];
                out[key] = val;
            }

            return out;
        },
        {
            id: resource.id,
            created_at: resource.created_at,
            updated_at: resource.updated_at,
            _destroy: resource._destroy
        }
    );

    return Immutable(out);
};
