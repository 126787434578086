import React, {Component} from 'react'
import IconButton from "@material-ui/core/IconButton";
import FilterList from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";

class MultiSelectFilter extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,

        selectedLabels: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func
    }

    constructor(props) {
        super(props)
        this.state = {
            openFilterMenu: false,
            anchorElForFilter: null,
            selectAll: true,
            indeterminate: false,
            checkBoxOptions: Array(this.props.labels.length).fill(true)
        }
    }

    componentDidMount() {
        const {selectedLabels, labels, onChange} = this.props

        if (selectedLabels) {
            const check_ops = this.state.checkBoxOptions.map((_v, _i) => selectedLabels.includes(labels[_i]));
            this.setState({
                checkBoxOptions: check_ops,
                selectAll: check_ops.every(Boolean),
                indeterminate: !check_ops.every(Boolean)
            })
            onChange(labels, selectedLabels)
        }
    }

    handleFilterClick = event => {
        this.setState({
            openFilterMenu: !this.state.openFilterMenu,
            anchorElForFilter: event.currentTarget
        })
    }

    handleFilterClose = event => {
        this.setState({
            openFilterMenu: !this.state.openFilterMenu,
            anchorElForFilter: null
        })
    }

    render() {
        const {name, labels} = this.props
        return (
            <div>
                {name}
                <IconButton onClick={this.handleFilterClick}
                            aria-controls="filter-options" aria-haspopup="true">
                    <FilterList fontSize="large" color={this.state.selectAll ? "primary" : "secondary"}/>
                </IconButton>
                <Menu
                    id="filter-options"
                    keepMounted
                    anchorEl={this.state.anchorElForFilter}
                    open={this.state.openFilterMenu}
                    onClose={this.handleFilterClose}
                >
                    <MenuItem onClick={this.handleChangeOnAll}>
                        <Checkbox
                            checked={this.state.selectAll}
                            color="primary"
                            indeterminate={this.state.indeterminate}
                            inputProps={{'aria-label': 'indeterminate checkbox'}}
                        />
                        All
                    </MenuItem>
                    {
                        labels.map((label, index) =>
                            <MenuItem onClick={this.handleChangeOnEach(index)}>
                                <Checkbox
                                    checked={this.state.checkBoxOptions[index]}
                                    color="primary"
                                    inputProps={{'aria-label': 'indeterminate checkbox'}}
                                />
                                {label}
                            </MenuItem>)
                    }
                </Menu>
            </div>
        );
    }

    handleChangeOnAll = (event) => {
        const {onChange, labels} = this.props
        const past_values = labels.filter((value, index) => this.state.checkBoxOptions[index])
        if (typeof onChange === 'function')
            onChange(past_values, !this.state.selectAll ? labels : [])
        this.setState({
            selectAll: !this.state.selectAll,
            checkBoxOptions: this.state.checkBoxOptions.fill(!this.state.selectAll),
            indeterminate: false
        })
    }

    handleChangeOnEach = (index) => {
        return (event) => {
            const {onChange, labels} = this.props
            const new_values = this.state.checkBoxOptions.map((value, _i) => index === _i ? !value : value)
            const past_values = labels.filter((value, index) => this.state.checkBoxOptions[index])

            this.setState({
                checkBoxOptions: new_values,
                selectAll: new_values.every(Boolean),
                indeterminate: !new_values.every(Boolean) && new_values.find(Boolean)
            })
            const curr_values = labels.filter((value, index) => new_values[index])
            if (typeof onChange === 'function')
                onChange(past_values, curr_values)
        }
    }
}

export default MultiSelectFilter;