// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["workspaceId", "projectId", "nodeId"],
		success: ["params", "data", "entities", "meta"],
		failure: ["params", "error"]
	},
	{ prefix: "NODES_DELETE_" }
);

export { Types, Creators };

export const request = (state: Object, action: Object) => {
	return state.merge({ deleting: true, error: null });
};

export const success = (state: Object, { data }: Object) => {
	return state.merge({ deleting: false, error: null });
};

export const failure = (state: Object, { error }: Object) => {
	return state.merge({ deleting: false, data: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
