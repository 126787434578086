import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Editor from "../ela-lib/src/components/editorComponents/EditorContainer";
import _clonedeep from "lodash/cloneDeep"
const styles = theme => ({
	inlineList: {
		paddingLeft: theme.spacing(2)
	},
	inline: {
		paddingRight: theme.spacing(5),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	ckeditor: {
		position: "relative",
		paddingBottom: theme.spacing(60)
	}
});

class RCForm extends Component {
	constructor(props) {
		super(props);
		// this.childState = React.useRef(null);
	}

	handleChange = (prefix, event) => {
		const { value, name } = this.props;
		let newValue = value.setIn(
			prefix.concat(event.target.name),
			event.target.value
		);
		this.props.onChange({ target: { value: newValue, name } });
	};

	updateRawData = (dataReceived) => {
		const { name } = this.props;
		console.log("[RCForm]---DATA - ", dataReceived);
		let reading_comprehension = {
			version: "1.0",
			context: dataReceived.context
		};
		console.log("[RCForm]-- Data - ", reading_comprehension);
		this.props.onChange({ target: { value: reading_comprehension, name } });
	};

	render() {
		const { value, classes, label } = _clonedeep(this.props);
		return (
			<div className={classes.inline}>
				<div className={classes.ckeditor}>
					<Typography variant="caption">{label}</Typography>
					<Editor
						// childRef={this.childState}
						templateType={"rc"}
						data={value ? value : {}}
						updateRawData={this.updateRawData}
					/>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(RCForm);
