import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  flex: {
    flex: 1
  },
  breadcrumb: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: '60vw',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

function TitleBar(props) {
  const { classes, title, breadcrumbs, subtitle, position = "static" } = props;
  return (
    <div className={classes.root}>
      <AppBar position={position} color="default">
        <Toolbar>
          {breadcrumbs &&
            breadcrumbs.map((b, idx) => {
              if (!b) return null;

              if (b.to)
                return (
                  <span key={idx}>
                    <Button component={Link} to={b.to}>
                      {b.title}
                    </Button>{" "}
                    ::
                  </span>
                );
              else
                return (
                  <Typography
                    className={classes.breadcrumb}
                    key={idx}
                    variant="h6"
                    color="inherit"
                  >
                    {b.title}
                  </Typography>
                );

            })}
          { title && <Typography variant="h6" color="inherit">
            {title}
          </Typography> }
          {subtitle && <Typography className={classes.flex}> </Typography>}
          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(TitleBar);
