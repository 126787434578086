import React from "react";
import MaskedInput from "react-text-mask";

function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function ms2Duration(value) {
  var v = parseInt(value, 10);

  const decimal = v % 1000;
  v = Math.floor(v / 1000);
  const seconds = v % 60;
  v = Math.floor(v / 60);
  const minutes = v % 60;
  const hours = Math.floor(v / 60);

  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(decimal, 3)}`;
}

export function Duration2ms(value) {
  const parts = value.split(/[.:]/).map(p => parseInt(p, 10));
  const retValue = parts[0] * 60 * 60 * 1000 + parts[1] * 60 * 1000 + parts[2] * 1000 + parts[3];
  console.log("Duration2ms", retValue);
  return retValue;
}

export function Duration2msValid(value) {
  if (value.trim().length !== "00:01:19.012".length) {
    return false;
  }

  const parts = value.split(/[.:]/);
  
  return parts.filter(x => isNaN(parseInt(x, 10)) || (x.trim() !== x)).length === 0;
}

class VideoDurationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  componentDidMount() {
    const { value, inputRef } = this.props;
    if (value !== "") {
      this.setState({ value: ms2Duration(value) });
    }

    inputRef(this._mi.inputElement);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      var value = nextProps.value;
      if (value !== "") {
        this.setState({ value: ms2Duration(value) });
      }
      else {
        this.setState({ value: null });
      }
    }
  }

  onChange = event => {
    const { onChange } = this.props;
    this.setState({ value: event.target.value });

    if (Duration2msValid(event.target.value)) {
      onChange({
        target: {
          name: event.target.name,
          value: Duration2ms(event.target.value)
        }
      });
    }
  };

  onBlur = (event) => {
    const { onChange } = this.props;
    onChange({
        target: {
        name: event.target.name,
        value: Duration2ms(event.target.value)
      }
    })
  }


  render() {
    const { inputRef, onChange, ...other } = this.props;
    const { value } = this.state;

    delete other.value;

    return (
      <MaskedInput
        {...other}
        value={value || ""}
        keepCharPositions={true}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        ref={ (ref) => this._mi = ref }
        mask={[/\d/, /\d/, ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/, ".", /\d/, /\d/, /\d/]}
        placeholderChar={"\u2000"}
        showMask
        />
    );
  }
}

export default VideoDurationInput;
