import React, { useEffect } from 'react';
import SpacyTags from "./SpacyTags"
import AddIcon from "@material-ui/icons/Add";
import style from "./style/style.module.css"
import _clonedeep from "lodash/cloneDeep";
function Spacy(props) {

    const { title, onChange, value, name } = _clonedeep(props);
    const [regexdata, setregexdata] = React.useState(value || []);
    const handleRegexData = () => {
        setregexdata([...regexdata, {}]);
        onChange({ target: { value: regexdata, name } })
    };
    const handledeleteregexrow = id => {
        let regex = [...regexdata];
        regex.splice(id, 1);
        setregexdata(regex);
        onChange({ target: { value: regexdata, name } })
        // props.handleChange(props.rowid, regex);
    };
    const handleRegex = (rowindex, rowkey, value) => {
        let list;
        if (rowkey === "content" && value) {
            list = value.split(",");
        }
        else if ((rowkey === "optional" || rowkey === "absence") && value) {
            list = value;
        }
        // else {
        //     list = [value.value];
        // }
        let obj;
        let regex = [...regexdata];
        if (regex[rowindex]) {
            regex[rowindex][rowkey] = list;
        } else {
            obj = {};
            obj[rowkey] = list;
            regex.push(obj);
        }
        if (regexdata[rowindex][rowkey] === undefined)
            delete regexdata[rowindex][rowkey];
        else if (regexdata[rowindex][rowkey].length === 0)
            delete regexdata[rowindex][rowkey];
        setregexdata(regex);
        onChange({ target: { value: regexdata, name } })
        // props.handleChange(props.rowid, regexdata);
    };

    const handleSelectMulti = (rowindex, rowkey, value) => {
        let list;
        if (value && value.length) list = value.map(v => v.value);

        let obj;
        let regex = [...regexdata]
        if (regex[rowindex]) {
            regex[rowindex][rowkey] = list;
        } else {
            obj = {};
            obj[rowkey] = list;
            regex.push(obj);
        }
        if (regexdata[rowindex][rowkey] === undefined) {
            delete regex[rowindex][rowkey];
        }
        if (
            regexdata[rowindex][rowkey] &&
            regexdata[rowindex][rowkey].length === 0
        ) {
            delete regex[rowindex][rowkey];
        }
        setregexdata(regex);
        onChange({ target: { value: regexdata, name } })
        // props.handleChange(props.rowid, regexdata);
    };
    return (
        <div className={style.container}>
            <div className={style.chiproot}>
                <div className={style.labeldropdown}>
                    <label className={style.labelblock} margin="normal">
                        {title}
                    </label>
                </div>
                {(value || []).map((val, i) => (
                    <React.Fragment key={i}>
                        <SpacyTags
                            title={title}
                            data={val}
                            handleSelectMulti={handleSelectMulti}
                            handleRegex={handleRegex}
                            key={i}
                            id={i}
                            handledeleteregexrow={handledeleteregexrow}
                        />
                        <hr width="90%" />
                    </React.Fragment>
                ))}
                <div className={style.chiptext} onClick={handleRegexData}>
                    <span>Click '+' to Start Adding </span>
                    <AddIcon />
                </div>
                {/* {props.error[props.title] && (
                <span id="error" class="error">
                    {props.error[props.title]}
                </span>
            )} */}
            </div>
        </div>
    );
}

export default Spacy;
