import React, {useEffect, useState} from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleDialog from './SimpleDialogBox';

const styles = theme => ({
  card: {
    display: "flex",
    margin: theme.spacing(1),
    justifyContent: "space-between",
    minWidth: 300
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
});

function CmsCard(props) {
  const {
    classes,
    children,
    buttons,
    menuItems,
    className
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose(){
    setAnchorEl(null);
  }

  function handleMenuItemClick(option){
    option.onChange && option.onChange();
    handleClose();
  }
  const menu = menuItems && menuItems.length > 0 && <div>
    <IconButton
        aria-label="more_card_actions"
        aria-controls="card_menu_actions"
        aria-haspopup="true"
        onClick={handleClick}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
        id="card_menu_actions"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
    >
      {menuItems.map((option) => (
          <MenuItem onClick={handleMenuItemClick.bind(this, option)} key={option.value}>
            {option.label}
          </MenuItem>
      ))}
    </Menu>
  </div>
  return (
    <Grid item>
      <Card classes={{ root: classes.card }} className={className}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            { children }
          </CardContent>
          <div className={classes.controls}>
              { buttons }
          </div>
        </div>
        { menu }
      </Card>
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(CmsCard);
