// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["params"],
		success: ["params", "data", "entities", "meta"],
		failure: ["params", "error"]
	},
	{ prefix: "RESOURCES_SEARCH_" }
);

export { Creators, Types };

export const request = (state: Object, action: Object) => {
	return state.merge({ searching: true, error: null });
};

export const success = (state: Object, { params, data, meta }: Object) => {
	return state.merge({
		searching: false,
		search_results: data.search_results,
		error: null,
		searchMeta: meta
	});
};

export const failure = (state: Object, { error }: Object) => {
	return state.merge({ searching: false, search: false, error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
