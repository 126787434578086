// This file is shared across the demos.

import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { withStyles } from "@material-ui/core/styles";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Group from "@material-ui/icons/Group";
import Code from "@material-ui/icons/Code";
import Subscriptions from "@material-ui/icons/Subscriptions";
import KnowledgeGraphIcon from "@material-ui/icons/DeviceHub";
import AppsIcon from "@material-ui/icons/Apps";
import PackageIcon from "@material-ui/icons/WidgetsOutlined";
import CommentModeIcon from "@material-ui/icons/Assignment";
import Tooltip from '@material-ui/core/Tooltip';
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';

//import Divider from '@material-ui/core/Divider'
import AppConfig from "../Config/AppConfig";

import { Link } from "react-router-dom";

const TopMenuItems = ({ abilities={}, classes }) => {
  const itemClasses = { primary: classes.primary };

  return (
    <div>
      {/* abilities.home && <ListItem button to="/" component={Link}>
        <ListItemIcon>
          <Home className={classes.primary} />
        </ListItemIcon>
        <ListItemText primary="Home" classes={itemClasses} />
      </ListItem> */}

      { abilities.workspaces && <ListItem button to="/workspaces" component={Link}>
          <ListItemIcon>
            <Tooltip title = "Workspaces" arrow>
              <DashboardIcon className={classes.primary} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Workspaces" classes={itemClasses} />
      </ListItem> }

      { abilities.resources && <ListItem button to="/resources" component={Link}>
        <ListItemIcon>
          <Tooltip title = "Resources" arrow>
            <Subscriptions className={classes.primary} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Resources" classes={itemClasses} />
      </ListItem> }

      { abilities.issues && <ListItem button to="/issues" component={Link}>
        <ListItemIcon>
          <Tooltip title = "Issues" arrow>
            <CommentModeIcon className={ classes.primary }/>
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Issues" classes={itemClasses} />
      </ListItem> }

      { abilities.knowledge_graph && <ListItem button to="/knowledge-graph" component={Link}>
        <ListItemIcon>
          <Tooltip title = "KnowledgeGraph" arrow>
            <KnowledgeGraphIcon className={classes.primary} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="KnowledgeGraph" classes={itemClasses} />
      </ListItem> }

      { abilities.apps && <ListItem button to="/applications" component={Link}>
        <ListItemIcon>
          <Tooltip title = "Applications" arrow>
            <AppsIcon className={classes.primary} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Applications" classes={itemClasses} />
      </ListItem> }

      { abilities.users && <ListItem button to="/users" component={Link}>
        <ListItemIcon>
          <Tooltip title = "Users" arrow>
            <Group className={classes.primary} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Users" classes={itemClasses} />
      </ListItem> }

      { abilities.teams && <ListItem button to="/teams" component={Link}>
        <ListItemIcon>
          <Tooltip title = "Teams" arrow>
            <SupervisedUserCircleRoundedIcon className={classes.primary} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Teams" classes={itemClasses} />
      </ListItem> }

      { abilities.packages && <ListItem button to="/packages" component={Link}>
        <ListItemIcon>
          <Tooltip title = "Packages" arrow>
            <PackageIcon className={classes.primary} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Packages" classes={itemClasses} />
      </ListItem> }

      { abilities.project_schema && <ListItem button to="/project-schemas" component={Link}>
        <ListItemIcon>
          <Tooltip title = "Project Schema" arrow>
            <Code className={classes.primary} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Project Schema" classes={itemClasses} />
      </ListItem> }

      <div className={classes.text} onClick={ (event) => {
        const el = event.currentTarget;
        const old = el.innerHTML;
        el.innerHTML=AppConfig.VERSION
        window.setTimeout(() => el.innerHTML = old, 5000);
      } }>{ AppConfig.VERSION === "development" ? "LOCAL CMS" : "K123 CMS" }</div>
    </div>
  );
};

const styles = theme => ({
  primary: { color: "white" },
  text: {
    writingMode: "vertical-rl",
    width: 60,
    textTransform: "uppercase",
    opacity: 0.5,
    marginTop: 30,
    fontSize: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default withStyles(styles)(TopMenuItems);
