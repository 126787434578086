export const adjustImage = (
  imgHeight,
  imgWidth,
  maxAllowedHeight,
  maxAllowedWidth
) => {
  const widthTarget = () => {
    const cutDownRatio = imgWidth / maxAllowedWidth;
    const newimgwidth = maxAllowedWidth;
    const newimgheight = imgHeight / cutDownRatio;
    return [newimgwidth, newimgheight];
  };

  const heightTarget = () => {
    const cutDownRatio = imgHeight / maxAllowedHeight;
    const newimgheight = maxAllowedHeight;
    const newimgwidth = imgWidth / cutDownRatio;
    return [newimgwidth, newimgheight];
  };

  let newimgwidth, newimgheight;
  const dimensionTarget = imgWidth > imgHeight ? 'width' : 'height';
  if (dimensionTarget === 'width') {
    // In this case, image is going to occupy most of the parent width and height is calculated based on the downsizing.
    [newimgwidth, newimgheight] = widthTarget();
    if (newimgheight > maxAllowedHeight) {
      // If new downsized image has height greater than parent height then re-calculate making height as the primary target.
      [newimgwidth, newimgheight] = heightTarget();
    }
  } else {
    // In this case, image is going to occupy most of the expected height and width is calculated based on the downsizing.
    [newimgwidth, newimgheight] = heightTarget();
    if (newimgwidth > maxAllowedWidth) {
      // If new downsized image has width greater than parent width then re-calculate making width as the primary target.
      [newimgwidth, newimgheight] = widthTarget();
    }
  }
  return [newimgwidth, newimgheight];
};
