import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  FetchCreators as ProjectsFetchCreators,
  selectProjectsById
} from "../Redux/ProjectsRedux";

import Loading from "../Components/Loading";

const styles = theme => ({});

class ProjectsPage extends Component {
  componentDidMount() {
    const {
      project,
      match: { params },
      history,
      fetchProject
    } = this.props;
    if (project) {
      history.replace(
        `/workspaces/${params.workspace_id}/projects/${project.id}/tree/${
          project.root_node_id
        }`
      );
    } else {
      fetchProject(params.workspace_id, params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const {
      project,
      match: { params }
    } = nextProps;
    if (project) {
      history.replace(
        `/workspaces/${params.workspace_id}/projects/${project.id}/tree/${
          project.root_node_id
        }`
      );
    }
  }

  render() {
    return <Loading center/>;
  }
}

const mapDispatchToProps = dispatch => ({
  fetchProject: (w, p) => dispatch(ProjectsFetchCreators.request(w, p))
});

const mapStateToProps = (state, ownProps) => {
  let {
    match: { params }
  } = ownProps;
  return {
    project: selectProjectsById(state, params.id)
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProjectsPage);
