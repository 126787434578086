import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import starIcon from "./../../../assets/images/star.svg"
import { useGameContext } from "./../../GameContext.jsx";
import "./headerMain.scss";

const ScoreBoxDiv = styled.div`
  background-image: linear-gradient(to bottom, ${({ theme }) => theme.light3}, ${({ theme }) => theme.light1});
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.dark1}, inset 0 1px 0 0 rgba(255, 255, 255, 0.44); 
`;


const ScoreShine = styled.div`
   opacity: 0.3;
   background:${({ theme }) => theme.light3} ;
`;

const ScoreBox = () => {
  const { totalScore } = useGameContext();
  const [score, setScore] = useState(0);
  const timer = useRef(null);

  useEffect(() => {
    let increment = 0;
    timer.current = setInterval(() => {
      if (increment < totalScore) {
        setScore(prevState => {
          increment = prevState + 1;
          return prevState + 1;
        });
      } else {
        clearInterval(timer.current);
      }
    }, 30);
    return () => {
      clearInterval(timer.current);
    }
  }, [totalScore]);

  return (
    <ScoreBoxDiv className="score-box" >
      <ScoreShine className="shine" />
      <img src={starIcon} className="coin-icon" alt="" />
      {`${score}`}
    </ScoreBoxDiv>
  );
}

export default ScoreBox;