import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";

import compose from "recompose/compose";
import { connect } from "react-redux";

import {
    ListCreators as WorkspacesListCreators,
    UpdateCreators as WorkspacesUpdateCreators,
    selectWorkspaces,
    selectWorkspacesRequestState
} from "../Redux/WorkspacesRedux";

import TitleBar from "../Components/TitleBar";
import Workspace from "../Components/Workspace";
import Loading from "../Components/Loading";
import SearchInput from "../Components/SearchInput";
import PageWrapper from "../Components/PageWrapper";
import NewWorkspaceForm from "../Components/NewWorkspaceForm";

import { withRouter } from "react-router";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import _debounce from "lodash/debounce"
import TableRow from "@material-ui/core/TableRow";
import MultiSelectFilter from "../Components/MulitSelectFilter";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import SimpleDialog from "../Components/SimpleDialogBox";

const styles = theme => ({
    createWSInput: {
        width: "80%",
        fontSize: "1em"
    },
    createContainer: {
        display: "inline-flex",
        marginLeft: theme.spacing(1)
    },
    loadButton: {
        padding: "2rem"
    },
    borderNone: {
        border: 'none'
    }
});

class WorkspacesPage extends Component {
    constructor(props) {
        super(props);
        this.state = { searchText: "", currentPage: 1, schema_type: null, archive: false, dialog: {
            open: false
        }};
        this.debouceListWorkspaces = _debounce(this.props.listWorkspaces, 500);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'CMS :: Workspaces';
        this.props.listWorkspaces({ page: { number: this.state.currentPage}});
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.workspacesRequests.creating &&
            !nextProps.workspacesRequests.creating
        ) {
            if (!nextProps.workspacesRequests.error) {
                this.setState({
                    sourceWorkspace: null,
                    openNewWorkspacePanel: false
                });
            }
        }
    }

    onCloneTrigger = (workspace, event) => {
        this.setState({
            sourceWorkspace: workspace,
            openNewWorkspacePanel: true
        });
    };

    handleClose = () => {
        this.setState({
            sourceWorkspace: null
        });
    };


    handleSearch = event => {
        this.debouceListWorkspaces(this.buildParams({
            pageNumber: 1,
            searchText: event.target.value,
            schemaTypes: this.state.schema_type,
            archive: this.state.archive
        }));
        this.setState({
            searchText: event.target.value,
            currentPage: 1
        });
    }

    handleNewWorkspace = event => {
        this.setState({ openNewWorkspacePanel: true });
    };

    handleWorkspacePanelClose = event => {
        this.setState({ openNewWorkspacePanel: false });
    };

    handleLoadMore = event => {
        this.debouceListWorkspaces(this.buildParams({
            pageNumber: this.state.currentPage + 1,
            searchText: this.state.searchText,
            schemaTypes: this.state.schema_type,
            archive: this.state.archive
        }));
        this.setState({
            currentPage: this.state.currentPage + 1
        })
    }

    filterOnChange = (pastValues, currentValues) => {
        currentValues = currentValues.join(',').replaceAll(/\s+/gi,"")

        this.debouceListWorkspaces(this.buildParams({
            pageNumber: 1,
            searchText: this.state.searchText,
            schemaTypes: currentValues,
            archive: this.state.archive
        }));
        this.setState({
            currentPage: 1,
            schema_type: currentValues
        })
    }

    handleChangeArchived = (event) => {
        this.debouceListWorkspaces(this.buildParams({
            pageNumber: 1,
            searchText: this.state.searchText,
            schemaTypes: this.state.schema_type,
            archive: !this.state.archive
        }));
        this.setState({
            archive: !this.state.archive,
            currentPage: 1
        })
    }

    buildParams({ pageNumber, searchText, schemaTypes, archive }){
        const params = {
            page: {
                number: pageNumber
            },
            q: searchText,
            archive
        }
        if(schemaTypes !== null)
            params["schema_type"] = schemaTypes

        return params
    }

    render() {
        let { classes, workspaces, workspacesRequests } = this.props;
        const pagination = _get(workspacesRequests, "meta.pagination");
        const schemaInactiveError = _get(workspacesRequests, "error.errors.project_schema_inactive[0]")
        const updateNameError = _get(workspacesRequests, "error.errors.name[0]")
        const schemas = ["Course Data","App Data","Lower Course Data","Ela Course Data"]
        const errors = schemaInactiveError || updateNameError
        if(workspaces){
            workspaces = _sortBy(workspaces,[workspace => workspace.name.toLowerCase()])
        }

        const hasCreateAccess = _get(
            workspacesRequests,
            "meta.abilities.create_workspace"
        );

        const hasUpdateAccess = _get(
            workspacesRequests,
            "meta.abilities.update_workspace"
        );

        return (
            <PageWrapper>
                <TitleBar
                    title="Workspaces"
                    subtitle={
                        <div>
                            <SearchInput onChange={this.handleSearch} />
                            {hasCreateAccess && (
                                <div className={classes.createContainer}>
                                    <Button
                                        color="primary"
                                        onClick={this.handleNewWorkspace}
                                    >
                                        Create New
                                    </Button>
                                </div>
                            )}
                        </div>
                    }
                />
                {workspacesRequests.listing && <Loading center />}
                <Grid container justify="flex-start">
                    {errors && <Chip color="secondary" label={errors} />}
                </Grid>
                <Grid container justify="flex-end">
                    <TableRow>
                        {hasUpdateAccess && <TableCell className={classes.borderNone}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.archive}
                                        name="Primary"
                                        onChange={this.handleChangeArchived}
                                        color="primary"
                                    />
                                }
                                label="Include Archived"
                            />
                        </TableCell>}
                        <TableCell className={classes.borderNone}>
                            <MultiSelectFilter name="Schema Type" labels={schemas} onChange={this.filterOnChange}/>
                        </TableCell>
                    </TableRow>
                </Grid>
                {workspaces && (
                        <div>
                            <Grid container>
                                {workspaces
                                    .map(w => (
                                        <Grid item lg={4} md={6} sm={12}>
                                            <Workspace
                                                hasCreateAccess={hasCreateAccess}
                                                hasUpdateAccess={hasUpdateAccess}
                                                key={w.id}
                                                onClone={this.onCloneTrigger.bind(
                                                    this,
                                                    w
                                                )}
                                                onArchive={this.onArchiveTrigger(w)}
                                                onUpdate={this.onUpdateTrigger(w)}
                                                {...w}
                                            />
                                        </Grid>
                                    ))}
                                <SimpleDialog title={this.state.dialog.title} description={this.state.dialog.description}
                                              open={this.state.dialog.open} handleOnClose={this.state.dialog.handleOnClose}
                                              handleOnDone={this.state.dialog.handleOnDone}/>   
                            </Grid>
                            { pagination && pagination['next_page'] &&
                                <Grid container justify="flex-end" className={classes.loadButton}>
                                    <Button color="primary" variant="contained" onClick={this.handleLoadMore}>
                                        Show More
                                    </Button>
                                </Grid>
                            }
                        </div>

                )}
                { hasCreateAccess && <Drawer
                    variant="temporary"
                    classes={{ paper: classes.rootNodeView }}
                    anchor="right"
                    open={this.state.openNewWorkspacePanel}
                    onClose={this.handleWorkspacePanelClose}
                >
                    <NewWorkspaceForm 
                        sourceWorkspace={ this.state.sourceWorkspace }
                    />
                </Drawer> }
            </PageWrapper>
        );
    }

    setDialogAndTriggerArchive = (w) => {
        let title, description;

        if(w['is_archived']){
            title = `Unarchive Workspace - ${w.name}`;
            description = `Note: unarchiving a workspace will make all projects under it editable.`;
        }
        else{
            title = `Archive Workspace - ${w.name}`;
            description = `Note: archiving a workspace will make all projects under it readonly.`;
        }
        
        const resetState = () => this.setState({
            ...this.state,
            dialog: {
                open: false
            }
        })

        this.setState({
            ...this.state,
            dialog:{
                title: title,
                description: description,
                open: !this.state.dialog.open,
                handleOnClose: () => {
                    resetState()
                },
                handleOnDone: () => {
                    this.props.updateWorkspace(w.id, {
                        "archive": !w['is_archived'],
                    }, {
                        ...w,
                        "is_archived": !w['is_archived']
                    });
                    resetState();
                }
            }
        })

    } 
    onArchiveTrigger = (w) => {
        return () => {
            this.setDialogAndTriggerArchive(w);
        }
    }

    onUpdateTrigger = (w) => {
        return name => {
            this.props.updateWorkspace(w.id,{
                name
            })
        }
    }
}

const mapDispatchToProps = dispatch => ({
    listWorkspaces: (params) => dispatch(WorkspacesListCreators.request(params)),
    updateWorkspace: (id, params, data) => dispatch(WorkspacesUpdateCreators.request(id, params, data))
});

const mapStateToProps = state => ({
    workspaces: selectWorkspaces(state),
    workspacesRequests: selectWorkspacesRequestState(state)
});

export default compose(
    withRouter,
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(WorkspacesPage);
