import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { denormalize } from "normalizr";

import { gameTemplate } from "../../Services/Api/Schema";

import fetchReducer, { Types as FetchTypes, Creators as FetchCreators } from "./fetch";
import createReducer_, { Types as CreateTypes, Creators as CreateCreators } from "./create";
import listReducer, { Types as ListTypes, Creators as ListCreators } from "./list";
import saveReducer, { Types as SaveTypes, Creators as SaveCreators } from "./save";

export const INITIAL_STATE = Immutable({
  data: false,
  error: null
});

export const key = "gameTemplates";

export const selectGameTemplates = state => {
  try {
    return denormalize(state[key].data, [gameTemplate], state.entities);
  } catch (e) {
    return null;
  }
};

export const selectGameTemplatesById = (state, id) => {
  try {
    return denormalize(
      state.entities.game_templates.getIn([parseInt(id, 10)]),
      gameTemplate,
      state.entities
    );
  } catch (e) {
    return null;
  }
};

export const selectGameTemplatesRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};

export { FetchCreators, FetchTypes };
export { CreateCreators, CreateTypes };
export { ListCreators, ListTypes };
export { SaveCreators, SaveTypes };

export default createReducer(INITIAL_STATE, {
  ...fetchReducer,
  ...createReducer_,
  ...listReducer,
  ...saveReducer
});
