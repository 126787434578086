// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    request: ["resourceName", "params"],
    success: ["params", "data", "entities"],
    failure: ["params", "error"]
  },
  { prefix: "RESOURCES_CREATE_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
  return state.merge({ creating: true, error: null, created_id: null });
};

const success = (state: Object, { data }: Object) => {
  //TODO: patch data[resourceName] to add the new one :/
  return state.merge({ creating: false, error: null, created_id: data[Object.keys(data)[0]] });
};

const failure = (state: Object, { error }: Object) => {
  return state.merge({ creating: false, error, created_id: null });
};

export default {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure
};
