// @flow

import { createReducer, createActions } from "reduxsauce";
import { schema } from "../Services/Api/Schema";
import { denormalize } from "normalizr";

const { Types, Creators } = createActions(
  {
    request: [],
    success: ["params", "data", "schema", "entities", "rootAbilities"],
    failure: ["params", "error"]
  },
  { prefix: "SCHEMA_" }
);

export { Types, Creators };

export const INITIAL_STATE = {
  fetching: false,
  data: false,
  error: null,
  rootAbilities: {}
};

export const request = (state: Object, action: Object) => {
  return { fetching: true, error: null };
};

export const success = (state: Object, { data, schema, rootAbilities }: Object) => {
  return { fetching: false, data: data.schema, schema: schema, error: null, rootAbilities };
};

export const failure = (state: Object, { error }: Object) => {
  return { fetching: false, data: false, error };
};

export const key = "schema";

export const selectSchema = state => {
  try {
    return denormalize(state[key].data, [schema], state.entities);
  } catch (e) {
    return null;
  }
};

export const selectSchemaByName = (state, name) => {
  if (!state.entities.schema) return null;
  return denormalize(state.entities.schema.getIn([name]), schema, state.entities);
};

export const selectNormalizrSchema = state => state[key].schema;
export const selectNormalizrSchemaByName = (state, name) =>
  state[key].schema && state[key].schema[name];

export const selectRootAbilities = (state) => 
  state[key].rootAbilities

export const selectSchemaRequests = state => {
  let { data, ...other } = state[key];
  return other;
};

export default createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure
});
