import Immutable from "seamless-immutable";
import { createReducer, createActions } from "reduxsauce";
import { denormalize } from "normalizr";

import { node } from "../../Services/Api/Schema";

import attachReducer, { Types as AttachTypes, Creators as AttachCreators } from "./attach";
import fetchReducer, { Types as FetchTypes, Creators as FetchCreators } from "./fetch";
import fetchTreeReducer, {
  Types as FetchTreeTypes,
  Creators as FetchTreeCreators
} from "./fetch_tree";
import createReducer_, { Types as CreateTypes, Creators as CreateCreators } from "./create";
import moveReducer, { Types as MoveTypes, Creators as MoveCreators } from "./move";
import deleteReducer, { Types as DeleteTypes, Creators as DeleteCreators } from "./delete";
import undeleteReducer, { Types as UndeleteTypes, Creators as UndeleteCreators } from "./undelete";
import revertReducer, { Types as RevertTypes, Creators as RevertCreators } from "./revert";
import searchReducer, { Types as SearchTypes, Creators as SearchCreators } from "./search";

export const INITIAL_STATE = Immutable({
  data: false,
  error: null,
  fetching_tree: {}
});

export const key = "nodes";

export const selectNodeById = (state, pid, id) =>
  denormalize(state.entities.nodes.getIn([`${pid}_${id}`]), node, state.entities);

export const selectNodesSearchResults = (state) => 
  denormalize(state[key].search_results, [node], state.entities);    

export const selectNodesRequestState = state => {
  let { data, ...other } = state[key];
  return other;
};

export { AttachCreators, AttachTypes };
export { MoveCreators, MoveTypes };
export { CreateCreators, CreateTypes };
export { DeleteCreators, DeleteTypes };
export { UndeleteCreators, UndeleteTypes };
export { RevertCreators, RevertTypes };
export { FetchCreators, FetchTypes };
export { SearchCreators, SearchTypes };
export { FetchTreeCreators, FetchTreeTypes };




//// clean up helpers

const { Types: CleanupTypes, Creators: CleanupCreators } = createActions(
  {
    cleanup: []
  },
  { prefix: "NODES_" }
);

export { CleanupTypes, CleanupCreators };

const cleanup = (state: Object, action: Object) => {
  return INITIAL_STATE;
}


export default createReducer(INITIAL_STATE, {
  ...createReducer_,
  ...moveReducer,
  ...deleteReducer,
  ...undeleteReducer,
  ...attachReducer,
  ...revertReducer,
  ...fetchReducer,
  ...fetchTreeReducer,
  ...searchReducer,
  [CleanupTypes.CLEANUP]: cleanup
});