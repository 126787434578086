import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";

import MenuIcon from "@material-ui/icons/Menu";
import CloudUpload from "@material-ui/icons/CloudUpload";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import compose from "recompose/compose";
import { connect } from "react-redux";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import { LogoutCreators, selectCurrentUser } from "../Redux/SessionRedux";
import { selectFilesRequestState } from "../Redux/FilesRedux";
import { selectRootAbilities } from "../Redux/SchemaRedux";
import { selectStartup } from "../Redux/StartupRedux";

import LeftNavItems from "../Components/LeftNavItems";
import UserAvatar from "../Components/UserAvatar";
import ActionCableStatus from "../Components/ActionCableStatus";
import OnlineUsersList from "../Components/OnlineUsersList";
import UploadDrawer from "../Dialogs/UploadDrawer";

import localForage from "localforage";
import { withRouter } from "react-router";

const drawerWidth = 220;
const drawerClosedWidth = 60;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: 1
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: "100vh"
  },
  menuButton: {
    marginRight: 36,
    color: "white"
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    height: "100%",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: 60
    }
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
    height: "100vh",
    overflow: "hidden",
    background: theme.palette.primary.main,
    color: "#fff"
  },
  uploadInner: {
    width: "100%",
    height: 260,
    overflow: "scroll"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  flex: {
    flex: 1
  },
  content: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    height: "100%",
    marginLeft: drawerClosedWidth,
    transition: theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentOpen: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  list: {
    flexGrow: 1
  },
  list2: {
    flexGrow: 0
  },
  whiteButton: { color: "white" },
  avatar: { width: 28, height: 28 },
  uploadDrawer: {
    left: drawerClosedWidth,
    boxShadow: "0px -3px 10px #999"
  },
  uploadSpacer: {
    height: 260
  },
  uploadActive: {
    animation: "$rotateUp 3s infinite linear",
    fontWeight: "bold",
    transformOrigin: "50% 50%"
  },
  "@keyframes rotateUp": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(359deg)"
    }
  }
});

const RefreshButton = withStyles({
  root: {
    color: "yellow"
  }
})(Button);

class MiniDrawer extends React.Component {
  state = {
    open: false,
    uploadPanelOpen: false
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget, showUserMenu: true });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      showUserMenu: false,
      showOnlineUsers: false
    });
  };

  onUploadClick = () => {
    this.setState({ uploadPanelOpen: !this.state.uploadPanelOpen });
  };

  onUploadPanelClose = () => {
    this.setState({ uploadPanelOpen: false });
  };

  handleLogout = () => {
    this.handleClose();
    localForage.clear();
    this.props.logoutUser();
  };

  handleProfile = () => {
    const { currentUser } = this.props;
    this.props.history.push(`/users/${currentUser.id}`);
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.checkOngoingUploads);
    navigator.serviceWorker &&
      navigator.serviceWorker.addEventListener("message", this.handleSWMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.checkOngoingUploads);
    navigator.serviceWorker &&
      navigator.serviceWorker.removeEventListener(
        "message",
        this.handleSWMessage
      );
  }

  checkOngoingUploads = event => {
    const { filesRequests } = this.props;
    if (filesRequests.uploading || filesRequests.creating) {
      event.returnValue = "Uploads have not finished. Sure you want to abort?";
      return "Uploads have not finished. Sure you want to abort?";
    }
  };

  handleSWMessage = event => {
    if (!event.data) {
      return;
    }

    switch (event.data) {
      case "reload-window":
        window.location.reload();
        break;
      default:
        // NOOP
        break;
    }
  };

  handleShowUsers = event => {
    this.setState({ anchorEl: event.currentTarget, showOnlineUsers: true });
  };

  serviceWorkerReload = () => {
    const waiting = this.props.startup.registration.waiting;
    waiting && waiting.postMessage("force-activate");
  };

  render() {
    const {
      classes,
      children,
      currentUser,
      filesRequests,
      rootAbilities
    } = this.props;
    const { anchorEl, open } = this.state;
    const uploadActive = filesRequests.uploading || filesRequests.creating;

    return (
      <DndProvider backend={HTML5Backend}>
        <div className={classes.root}>
          <CssBaseline />
          <div className={classes.appFrame}>
            <Drawer
              variant="permanent"
              classes={{
                paper: classNames(classes.drawerPaper, {
                  [classes.drawerPaperClose]: !this.state.open
                })
              }}
              open={this.state.open}
            >
              <div className={classes.drawerInner}>
                <div className={classes.menuContainer}>
                  <List className={classes.list}>
                    <ListItem button onClick={this.handleDrawerToggle}>
                      <ListItemIcon>
                        {open ? (
                          <ChevronLeftIcon className={classes.whiteButton} />
                        ) : (
                          <MenuIcon className={classes.whiteButton} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={`CMS ${new Date().getFullYear()}`}
                        classes={{ primary: classes.whiteButton }}
                      />
                    </ListItem>
                    <Divider />
                    <LeftNavItems abilities={rootAbilities} />
                  </List>
                  <List className={classes.list2}>
                    <ListItem button onClick={this.onUploadClick} component="a">
                      <ListItemIcon>
                        {uploadActive ? (
                          <Badge
                            badgeContent="↻"
                            color="primary"
                            classes={{ badge: classes.uploadActive }}
                          >
                            <CloudUpload className={classes.whiteButton} />
                          </Badge>
                        ) : (
                          <CloudUpload className={classes.whiteButton} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Upload Files"
                        classes={{ primary: classes.whiteButton }}
                      />
                    </ListItem>
                    <ListItem button onClick={this.handleShowUsers}>
                      <ActionCableStatus
                        Component={ListItemIcon}
                        currentUser={currentUser}
                        iconClassName={classes.whiteButton}
                      />
                      <ListItemText
                        primary={"Connection"}
                        classes={{ primary: classes.whiteButton }}
                      />
                    </ListItem>

                    <ListItem button onClick={this.handleMenu}>
                      <ListItemIcon>
                        <UserAvatar
                          user={currentUser}
                          className={classes.avatar}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={currentUser.name}
                        classes={{ primary: classes.whiteButton }}
                      />
                    </ListItem>
                  </List>
                </div>
              </div>
            </Drawer>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={!!this.state.showUserMenu}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleClose} disabled>
                <ListItemText
                  primary={currentUser.email}
                  secondary={(currentUser.roles || [])
                    .map(x => x.underscoreToLabel())
                    .join(" ")}
                />
              </MenuItem>
              <MenuItem onClick={this.handleProfile}>Profile</MenuItem>
              <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
            <main
              className={classNames(classes.content, {
                [classes.contentOpen]: this.state.open
              })}
            >
              {children}
              {this.state.uploadPanelOpen && (
                <div className={classes.uploadSpacer} />
              )}
            </main>
            <Drawer
              variant="persistent"
              anchor="bottom"
              open={this.state.uploadPanelOpen}
              classes={{ paper: classes.uploadDrawer }}
            >
              <div className={classes.uploadInner}>
                <UploadDrawer
                  onCancel={this.onUploadPanelClose}
                  open={this.state.uploadPanelOpen}
                />
              </div>
            </Drawer>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              open={this.props.startup.appUpdate}
              message="Update available, Refresh all open pages?"
              action={[
                <RefreshButton key="reload" onClick={this.serviceWorkerReload}>
                  Refresh
                </RefreshButton>
              ]}
            />
            <Popover
              open={!!this.state.showOnlineUsers}
              onClose={this.handleClose}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
            >
              {this.state.showOnlineUsers && (
                <OnlineUsersList currentUser={currentUser} />
              )}
            </Popover>
          </div>
        </div>
      </DndProvider>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(LogoutCreators.request())
});

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  filesRequests: selectFilesRequestState(state),
  startup: selectStartup(state),
  rootAbilities: selectRootAbilities(state)
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MiniDrawer);
