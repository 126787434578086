import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import compose from "recompose/compose";
import { connect } from "react-redux";

import { withRouter } from "react-router";

import TitleBar from "../Components/TitleBar";
import Loading from "../Components/Loading";
import ResourceUpdate from "../Components/ResourceUpdate";
import ProgressButton from "../Components/ProgressButton";

import _get from "lodash/get";

import {
    FetchCreators as ResourceUpdatesFetchCreators,
    SaveCreators as ResourceUpdatesSaveCreators,
    selectResourceUpdates,
    selectResourceUpdatesRequestState
} from "../Redux/ResourceUpdatesRedux";

const styles = theme => ({
    drawerPaper: {
        width: 320,
        display: "flex",
        flexWrap: "wrap",
        alignContent: "flex-start",
        height: "100vh",
        overflow: "auto",
        position: "relative",
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            width: 500
        },
        [theme.breakpoints.up("lg")]: {
            width: 800
        }
    },
    container: {
        padding: theme.spacing(1)
    },
    rootNodeView: {
        height: "auto",
        minHeight: "100%"
    }
});

class NodeResourcesUpdatePage extends Component {
    constructor(props) {
        super(props);
        this.state = { selected: {} };
    }

    componentDidMount() {
        const { project, workspace } = this.props;
        this.props.fetchResourceUpdates(workspace.id, project.id);
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.resourceUpdatesRequests.saving &&
            !nextProps.resourceUpdatesRequests.saving
        ) {
            //Updates are done!
            const resourceErrors = _get(
                nextProps.resourceUpdatesRequests,
                "error.errors",
                []
            );
            if (resourceErrors.length > 0) {
                this.setState({ errors: this.makeErrorDict(resourceErrors) });
            } else {
                this.setState({ selected: {}, errors: null });
            }
        }
    }

    makeErrorDict = errors => {
        return errors.reduce((a, e) => {
            a[e[1] + "::" + e[2]] = e[0];
            return a;
        }, {});
    };

    closeNodePanel = () => {
        const { history, project, workspace, rootNode } = this.props;
        history.push(
            `/workspaces/${workspace.id}/projects/${project.id}/tree/${
                rootNode.id
            }`
        );
    };

    onChange = event => {
        var { selected } = this.state;
        if (selected[event.target.name]) {
            delete selected[event.target.name];
        } else {
            selected[event.target.name] = event.target.value;
        }
        this.setState({ selected, errors: null });
    };

    onUpdateClick = event => {
        const { project, workspace } = this.props;
        const resource_updates = this.props.resourceUpdates
            .filter(update => {
                return this.state.selected[
                    `${update.resource_name}::${update.from.id}`
                ];
            })
            .map(update => ({
                resource_name: update.resource_name,
                from: update.from.id,
                to: update.to.id
            }));

        this.props.saveResourceUpdates(workspace.id, project.id, {
            resource_updates
        });
    };

    onSelectAllClick = event => {
        const { resourceUpdates } = this.props;

        const selected = resourceUpdates
            .reduce((acc, rU) => {
                const {
                    resource_name: resourceName,
                    from: fromResource,
                    to: toResource
                } = rU;

                const name = resourceName + "::" + fromResource.id;
                const value = resourceName + "::" + toResource.id;

                acc[name] = value;
                return acc;
            }, {})
            .asMutable();

        this.setState({ selected });
    };

    render() {
        let { classes, resourceUpdatesRequests, resourceUpdates, abilities } = this.props;
        const { selected, errors } = this.state;
        const resourceCount = Object.keys(selected).length;
        //const prefix = `/workspaces/${workspace.id}/projects/${project.id}`;

        return (
            <Drawer
                variant="persistent"
                classes={{ paper: classes.rootNodeView }}
                anchor="right"
                open={true}
                onClose={this.closeNodePanel}
            >
                <div className={classes.drawerPaper}>
                    <TitleBar
                        title="Resource updates"
                        subtitle={[
                            abilities['mass_update_node'] && <Button
                                color="primary"
                                onClick={this.onSelectAllClick}
                                key="enable-all"
                            >
                                Enable All
                            </Button>,
                            <Button
                                key="close-node-panel"
                                color="default"
                                onClick={this.closeNodePanel}
                            >
                                Close
                            </Button>
                        ]}
                    />
                    <div className={classes.container}>
                        {resourceUpdatesRequests.fetching && <Loading center />}
                        {(resourceUpdates || []).map(resourceUpdate => {
                            return (
                                <ResourceUpdate
                                    key={resourceUpdate.id}
                                    {...resourceUpdate}
                                    onChange={this.onChange}
                                    selected={selected}
                                    errors={errors}
                                />
                            );
                        })}
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignContent="flex-end"
                            className={classes.buttonContainer}
                        >
                            <Grid item>
                                {abilities['mass_update_node'] && <ProgressButton
                                    disabled={resourceCount === 0}
                                    onClick={this.onUpdateClick}
                                    inProgress={resourceUpdatesRequests.saving}
                                >
                                    Update {resourceCount} Resource
                                    {resourceCount !== 1 ? "s" : ""}
                                </ProgressButton>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Drawer>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    fetchResourceUpdates: (w, p) =>
        dispatch(ResourceUpdatesFetchCreators.request(w, p)),
    saveResourceUpdates: (w, p, pms) =>
        dispatch(ResourceUpdatesSaveCreators.request(w, p, pms))
});

const mapStateToProps = (state, ownProps) => {
    return {
        resourceUpdates: selectResourceUpdates(state),
        resourceUpdatesRequests: selectResourceUpdatesRequestState(state)
    };
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(NodeResourcesUpdatePage);
