const constants = {
  MODE: {
    EDITOR: 'Editor',
    QUESTION: 'Question',
  },

  RESOURCE_TYPE: {
    QUESTION_LIST: 'QuestionList',
    QUESTION: 'Question',
    READING_COMPREHENSION: 'ReadingComprehension',
    TESTING: 'Testing',
    IN_CHAT_QUESTION: 'InChatQuestion',
    GAME: 'GAME',
    READ_ALOUD: 'readAloud',
  },

  QUESTION_TYPES: {
    BREVITY: 'brevity',
    IC_MCQ: 'ic_mcq',
    IC_MCQ_VERTICAL: 'ic_mcq_vertical',
    BUBBLE_BURST: 'bbq',
    RC_MCQ: 'rc_mcq',
  },
};

export default constants;