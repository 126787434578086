// @flow

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
	{
		request: ["workspaceId", "projectId"],
		success: ["params", "data", "entities"],
		failure: ["params", "error"]
	},
	{ prefix: "PROJECTS_PUBLISH_" }
);

export { Types, Creators };

const request = (state: Object, action: Object) => {
	return state.merge({ publishing: true, error: null });
};

const success = (state: Object, { data }: Object) => {
 return data.project ? state.merge({ publishing: false, error: null }) : state;
};

const failure = (state: Object, { data }: Object) => {
	return state.merge({ publishing: false, error: data.error });
};

export default {
	[Types.REQUEST]: request,
	[Types.SUCCESS]: success,
	[Types.FAILURE]: failure
};
