import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { WindowScroller } from "react-virtualized";
import {
	SortableTreeWithoutDndContext as SortableTree,
	removeNode,
	changeNodeAtPath,
	defaultGetNodeKey as getNodeKey
} from "../Components/react-sortable-tree";

const styles = theme => ({
	inline: {
		padding: theme.spacing(1)
	},
	rule: {
		fontWeight: "normal"
	},
	paddingLeft: {
		paddingLeft: theme.spacing(3)
	}
});

class DecisionTreeForm extends Component {
	generateNodeProps = ({ node, path }) => {
		const { classes, disabled, vars = [] } = this.props;
		const buttons = [
				!disabled && <IconButton
					color="secondary"
					onClick={() => this.onDeleteNode({ node, path })}
				>
					<DeleteIcon />
				</IconButton>
		];

		if (node.type === "LOGIC") {
			return {
				title: (
					<div className={classes.rule}>
						<Select disabled={ disabled } name="logic" value={node.logic} onChange={ this.onAttrChange.bind(this, path) }>
							<MenuItem value="AND">AND</MenuItem>
							<MenuItem value="OR">OR</MenuItem>
							<MenuItem value="XOR">XOR</MenuItem>
						</Select>
					</div>
				),
				buttons
			};
		}

		if (node.type === "RULE") {
			return {
				title: (
					<div className={classes.rule}>
						<Select  disabled={ disabled } name="variable" value={node.variable} onChange={ this.onAttrChange.bind(this, path) }>
							{vars.map(v => (
								<MenuItem key={v.name} value={v.name}>
									{v.name.toLowerCase().underscoreToLabel()}
								</MenuItem>
							))}
						</Select>
						<Select  disabled={ disabled } classes={{ root: classes.paddingLeft }} name="operation" value={node.operation} onChange={ this.onAttrChange.bind(this, path) }>
							<MenuItem value="="> = </MenuItem>
							<MenuItem value=">"> &gt; </MenuItem>
							<MenuItem value=">="> &ge; </MenuItem>
							<MenuItem value="<"> &lt; </MenuItem>
							<MenuItem value="<="> &le; </MenuItem>
							<MenuItem value="?"> present? </MenuItem>
							<MenuItem value="empty?"> empty? </MenuItem>
						</Select>
						<TextField name="value" disabled={ disabled || (node.operation === "?" || node.operation === "empty?") } value={node.value} onChange={ this.onAttrChange.bind(this, path) }/>
					</div>
				),
				buttons
			};
		}
	};

	onAttrChange = (path, event) => {
		const { value: { tree } } = this.props;
		const newNode = ({ node }) => ({
			...node,
			[event.target.name]: event.target.value
		});

		this.onTreeChange(changeNodeAtPath({ treeData: tree, path, getNodeKey, newNode }));
	}

	onTreeChange = tree => {
		const { name } = this.props;
		this.props.onChange({ target: { name, value: { tree } } });
	};

	onAddLogicClick = () => {
		const { value: { tree } } = this.props;
		this.onTreeChange([...tree, { type: "LOGIC", logic: "AND" }]);
	};

	onAddRuleClick = () => {
		const { value: { tree } } = this.props;

		this.onTreeChange([...tree, { type: "RULE" }]);
	};

	onDeleteNode = ({ node, path }) => {
		const { value: { tree: treeData } } = this.props;
		this.onTreeChange(
			removeNode({ treeData, path, getNodeKey }).treeData
		);
	};

	render() {
		const { value, classes, disabled, label, error, helperText } = this.props;
		const { tree } = value;

		return (
			<div className={classes.inline}>
				<Typography variant="caption">{label}</Typography>
				{ !disabled && <Button
					color="primary"
					size="small"
					onClick={this.onAddRuleClick}
				>
					<AddIcon /> Rule
				</Button> }
				&nbsp;
				{ !disabled && <Button
					color="primary"
					size="small"
					onClick={this.onAddLogicClick}
				>
					<AddIcon /> Logic
				</Button> }
				<div className={"projectTree"}>
					<WindowScroller>
						{windowScrollerProps => (
							<SortableTree
								reactVirtualizedListProps={{
									...windowScrollerProps,
									autoHeight: true,
									autoWidth: true
								}}
								treeData={tree}
								generateNodeProps={this.generateNodeProps}
								onChange={this.onTreeChange}
								maxDepth={3}
								canDrag={({ node, path }) => {
									if (disabled) return false;
									return true;
								}}
								canDrop={({ nextParent }) => {
									if(!nextParent) return true;
									return nextParent.type === 'LOGIC';
								}}
							/>
						)}
					</WindowScroller>
				</div>
				<FormHelperText error={ error }>{ helperText }</FormHelperText>
			</div>
		);
	}
}

export default withStyles(styles)(DecisionTreeForm);
