// u_event_id
export const EVENT_ID = {
  QUESTION_VIEW_ID: '6001700',
  CLOSE_ID: '6001701',
  SELECTED_OPTION_ID: '6001702',
  SUBMIT_ID: '6001703',
  EVALUATION_ID: '6001704',
  NEXT_AUTOPLAY_ID: '6001705',
  HINT_ID: '6001706',
  VIEW_RC_SCREEN_ID: '6002000',
  RC_START_TEST_ID: '6002100',
  RC_QUESTION_VIEWED: '6002200',
  CLICK_TO_PUSH_DOWN_RC_TEST: '6002700',
  RC_END_SCREEN: '6003000',
  CLICK_TO_CLOSE_RC: '6002400',
  RC_QUESTION_EVALUATION: '6002300',
  RC_READING_TIME: '6002800',
  RC_WORD_TAP_EVENT: '6002900',
  REPLAY_BUTTON_CLICKED: '6001707',
  BUBBLE_INTERACTION_ID: '6001708',
  GAME_FINISH: '6001710',
  BACKSPACE_USED_ON_INPUT_KEYBOARD: '6001711',
  GAME_LAUNCH: '6001712',
  RECORD_SCORE_ENUNCIATE: '6001714',
  PLAY_RECORDING_ENUNCIATE: '6001715',
  BOLO_WORD_EVALUATE: '6001716',
  MTF_OPTION_EVALUATE: '6001713',
};

// kingdom
export const KINGDOM = 'ela_games';
export const RC_KINGDOM = 'rc_interface';

//phylum
export const PHYLUM = {
  CLICK: 'click',
  VIEW: 'view',
  TRIGGER: 'trigger',
};

//counter
export const COUNTER = {
  QUESTION_VIEWED: 'question_viewed',
  CLOSE_PRESSED: 'close_button_pressed',
  OPTIONS_SELECTED: 'option_selected',
  SUBMIT_CLICKED: 'submit_button_clicked',
  QUESTION_EVALUATION: 'question_evaluation',
  NEXT_AUTOPLAY_CLICKED: 'next_option',
  HINT_TRIGGERED: 'hint_triggered',
  VIEW_RC_SCREEN: 'view_rc_screen',
  CLICK_RC_START_TEST: 'click_rc_start_test',
  CLICK_TO_PUSH_DOWN_RC_TEST: 'click_to_push_down_rc_test',
  VIEW_RC_END_SCREEN: 'view_rc_end_screen',
  CLICK_TO_CLOSE_RC: 'click_to_close_rc',
  CLICK_ON_ANSWER: 'click_on_answer',
  VIEW_RC_QUESTION: 'view_rc_question',
  RC_READING_TIME: 'rc_reading_time',
  CLICK_ON_WORD: 'click_on_word_to_find_its_meaning',
  REPLAY_OPTION: 'replay_option',
  BUBBLE_INTERACTIONS: 'bubble_interactions',
  GAME_FINISHED: 'game_finished',
  BACKSPACE_PRESSED: 'backspace_pressed',
  GAME_LAUNCHED: 'game_launched',
  ENUNCIATE_SCORE: 'enunciate_score',
  ENUNCIATE_PLAY_RECORDING: 'enunciate_play_recording',
  BOLO_WORD_EVALUATION: 'bolo_word_evaluation',
  MTF_OPTION_EVALUATION: 'mtf_option_evaluation',
};

//priority
export const PRIORITY = {
  HIGH: 'high',
  LOW: 'low',
};

// species
export const SPECIES = {
  TOP_BAR_BACK_BUTTON: 'top_bar_back_button',
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  START_BUTTON: 'start_button',
  TIME_OUT: 'time_out',
  READ_MODE: 'read_mode',
  TEST_MODE: 'test_mode',
};
