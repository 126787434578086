import { put, select } from "redux-saga/effects";
import Config from "../Config/AppConfig";
import { selectAccessToken } from "../Redux/SessionRedux";
import { Creators as SchemaCreators, selectSchema } from "../Redux/SchemaRedux";
import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";

export function* startupSaga(startupCreators, setHeader, data) {
  const accessToken = yield select(selectAccessToken);

  if (accessToken) {
    setHeader("Authorization", accessToken);

    yield put(
      ActionCableCreators.init(Config.wsEndpoint + "?token=" + accessToken)
    );

    const schema = yield select(selectSchema);
    if (!schema) yield put(SchemaCreators.request());

    yield put(startupCreators.inited());
  } else {
    yield put(startupCreators.inited());
  }
}
