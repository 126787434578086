import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import compose from "recompose/compose";
import { connect } from "react-redux";

import { selectSchemaByName, selectRootAbilities } from "../Redux/SchemaRedux";

import {
    FetchCreators as ResourcesFetchCreators,
    SaveCreators as ResourcesSaveCreators,
    CreateCreators as ResourcesCreateCreators,
    ReferencesCreators as ResourcesReferencesCreators,
    ActionCreators as ResourcesActionCreators,
    selectResourceById,
    selectResourcesRequestState,
    selectResourceReferencesById,
} from "../Redux/ResourcesRedux";

import { Creators as IvDetailsFetchCreators } from "../Redux/IvDetailsRedux"
import { Creators as IvRequestCreators } from "../Redux/IvCreateRequestRedux"
import { Creators as ActionCableCreators } from "../Redux/ActionCableRedux";
import { selectCurrentUser } from "../Redux/SessionRedux";

import { AttachCreators, selectNodesRequestState } from "../Redux/NodesRedux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import Loading from "../Components/Loading";
import TitleBar from "../Components/TitleBar";
import WorkspacePanel from "../Components/WorkspacePanel";
import UserAvatar from "../Components/UserAvatar";
import ResourceForm from "../Components/ResourceForm";
import ResourceInfoPanes from "../Components/ResourceInfoPanes";
import ProgressButton from "../Components/ProgressButton";
import SnackContent from "../Components/SnackContent";
import PageWrapper from "../Components/PageWrapper";
import ToolpoolPane, { TOOLPOOL_WIDTH } from "../Components/ToolpoolPane";
import VersionNavButtons from "../Components/VersionNavButtons";
import IssueList from "../Issues";

import ResourceCartIcon from "@material-ui/icons/ShopTwoOutlined";
import CommentModeIcon from "@material-ui/icons/Assignment";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from '@material-ui/core/Tooltip';

import _get from "lodash/get";

const styles = theme => ({
    flex: {
        display: "flex"
    },
    buttonContainer: {
        padding: theme.spacing(2)
    },
    editorPagePanelOpen: {
        paddingRight: TOOLPOOL_WIDTH
    }
});

class ResourceEditorPage extends Component {
    constructor(props) {
        super(props);
        this.state = { cartOpen: false, requestForIv: false };
    }

    componentDidMount() {
        let {
            match: { params },
            prefix,
            schema
        } = this.props;

        if (schema) {
            if (params.resource_id)
                this.props.fetchResource(
                    params.resource_name,
                    params.resource_id
                );
        }

        !prefix && window.scrollTo(0, 0);
        //if(!disableEditor) { //fetch references
        //    params.resource_id && this.props.fetchResourceReferences(params.resource_name, params.resource_id);
        //}

        this.props.subscribeChannel("ResourceChannel");
        if (schema.name === "interactive_videos" || schema.name === "ela_interactive_videos") {
            this.props.fetchIvDetails(params.resource_id, params.resource_name);
        }
    }

    componentWillUnmount() {
        this.props.unsubscribeChannel("ResourceChannel");
    }

    componentWillReceiveProps(nextProps) {
        let {
            match: { params: nextParams },
            schema: nextSchema
        } = nextProps;
        let {
            match: { params },
            prefix = "",
            schema,
            IvRequest
        } = this.props;

        if(IvRequest.fetching && IvRequest.error !== nextProps.IvRequest.error){
            alert(nextProps.IvRequest.error);
        }

        if (!schema && nextSchema) {
            if (params.resource_id)
                this.props.fetchResource(
                    params.resource_name,
                    params.resource_id
                );
        }

        if (
            this.props.resourcesRequests.creating &&
            !nextProps.resourcesRequests.creating
        ) {
            if (nextProps.resourcesRequests.error) {
                this.setState({ saveError: nextProps.resourcesRequests.error });
                return;
            }

            if (nextProps.resourcesRequests.created_id) {
                this.props.history.push(
                    `${prefix}/resources/${params.resource_name}/${
                        nextProps.resourcesRequests.created_id
                    }`
                );
                this.setState({
                    snack: schema.label + " Created!",
                    isDirty: false
                });
            }
            return;
        }

        if (
            this.props.resourcesRequests.saving &&
            !nextProps.resourcesRequests.saving
        ) {
            if (nextProps.resourcesRequests.error) {
                this.setState({ saveError: nextProps.resourcesRequests.error });
                return;
            }

            if (
                nextProps.resourcesRequests.saved_id &&
                this.props.resource.id !== nextProps.resourcesRequests.saved_id
            ) {
                this.props.history.replace(
                    `${prefix}/resources/${params.resource_name}/${
                        nextProps.resourcesRequests.saved_id
                    }`
                );
            }

            const { schema } = this.props;
            this.setState({ snack: schema.label + " Saved!", isDirty: false });
            return;
        }

        if (
            this.props.resourcesRequests.fetching &&
            !nextProps.resourcesRequests.fetching
        ) {
            if (nextProps.resourcesRequests.error) {
                this.setState({
                    fetchError: nextProps.resourcesRequests.error
                });
                return;
            }

            document.title = schema.label + " :: " + nextProps.resource.nickname;
            if(window.location.hash.startsWith("#auto-open-issue")) {
                this.onCommentMode();
            }
        }

        if (
            params.resource_name !== nextParams.resource_name ||
            params.resource_id !== nextParams.resource_id
        ) {
            if (nextParams.resource_id) {
                this.props.fetchResource(
                    nextParams.resource_name,
                    nextParams.resource_id
                );
                this.setState({ saveError: null, fetchError: null });
                !prefix && window.scrollTo(0, 0);
            }
        }

        if (
            this.props.nodesRequests.attaching &&
            !nextProps.nodesRequests.attaching
        ) {
            if (nextProps.nodesRequests.error) {
                this.setState({ attachError: nextProps.nodesRequests.error });
                return;
            }

            if (params.node_id !== nextProps.nodesRequests.attached_id) {
                const new_prefix = prefix.replace(
                    `nodes/${params.node_id}`,
                    `nodes/${nextProps.nodesRequests.attached_id}`
                );
                this.props.history.push(
                    `${new_prefix}/resources/${params.resource_name}/${
                        params.resource_id
                    }`
                );
            }
        }
    }

    onSave = resource => {
        let {
            match: { params }
        } = this.props;

        if (resource.id === null) {
            this.props.createResource(params.resource_name, resource);
        } else {
            this.props.saveResource(
                params.resource_name,
                resource.id,
                resource
            );
        }

        this.setState({ saveError: null });
    };

    onAttach = resourceId => {
        let {
            match: { params }
        } = this.props;
        this.props.attachResource(
            params.workspace_id,
            params.project_id,
            params.node_id,
            {
                resource_name: params.resource_name,
                resource_id: resourceId
            }
        );
    };

    handleSnackClose = () => {
        this.setState({ snack: null });
    };

    onCartAdd = () => {
        const { id: resource_id, nickname } = this.props.resource;
        const {
            match: { params }
        } = this.props;

        this.cartDrop({
            type: "resource",
            resource_id,
            nickname,
            resource_name: params.resource_name
        });
        this.setState({ cartOpen: true });
        this.cartCloseTimer = window.setTimeout(() => {
            this.setState({ cartOpen: false });
        }, 4000);
    };

    onCartInteract = () => {
        window.clearTimeout(this.cartCloseTimer);
    };

    onCommentMode = () => {
        this.setState({ showIssues: !this.state.showIssues });
    };

    getField = el => {
        while (el && el.tagName.toLowerCase() !== "body") {
            if (el.classList.contains("fc")) {
                return el;
            }
            el = el.parentNode;
        }

        return null;
    };

    handleChange = () =>
        !this.state.isDirty && this.setState({ isDirty: true });
    onDiscardLS = () => this.setState({ isDirty: false });

    handleIvlRequest = () => {
        let { match: { params }, IvDetails } = this.props;
        if(IvDetails.data.inprogress){
            return
        }
        if(this.props.resource === undefined){
            alert('Please create Interactive Video Resource first');
            return
        }
        this.props.createIvRequest(params.resource_id, params.resource_name);
        this.setState({
            requestForIv: true
        })
    }

    render() {
        const {
            schema,
            classes,
            prefix = "",
            resourcesRequests,
            nodesRequests,
            disableEditor,
            project,
            resourceReferences,
            node,
            currentUser,
            rootAbilities,
            abilities,
            resource = null,
            IvDetails,
            IvRequest = false
        } = this.props;
        const {
            match: { params }
        } = this.props;
        const {
            saveError,
            attachError,
            fetchError,
            isDirty,
            showIssues
        } = this.state;
        const isNew = !params.resource_id;
        if (!schema || (!isNew && !fetchError && resource === null))
            return <Loading center />;

        const saving = isNew
            ? resourcesRequests.creating
            : resourcesRequests.saving;
        var versionIndex = 0,
            isLatest = isNew,
            prevIndex = false,
            nextIndex = false;

        const canAttach = !isNew && project && !project.is_published;
        const isAttached =
            node &&
            (params.resource_id === "" + node.resource_id &&
                node.resource_name === params.resource_name);

        const user = _get(this, "props.resource.user");

        const versions = _get(this, "props.resource.versions");
        if (versions) {
            versionIndex = versions.indexOf(resource.id);
            isLatest = versionIndex === versions.length - 1;
            prevIndex = versions[versionIndex - 1];
            nextIndex = versions[versionIndex + 1];
        }

        const hasEditAccess = rootAbilities.resource_edit[params.resource_name];
        const hasAttachAccess = _get(abilities,"update_node");
        const ivInProgress = _get(IvDetails,"data.inprogress");
        const ivStatusRequestId = _get(IvDetails,"data.uid");
        const ivCreateRequestId =_get(IvRequest,"data.data.ivl_req_id");
        const ivRequestId = this.state.requestForIv ? ivCreateRequestId : ivStatusRequestId;
        const disableIvlRequest = !!(ivRequestId || ivInProgress)
        return (
            <PageWrapper
                classes={{
                    container: this.state.cartOpen
                        ? classes.editorPagePanelOpen
                        : ""
                }}
            >
                <TitleBar
                    breadcrumbs={[
                        { to: `${prefix}/resources`, title: "Resources" },
                        {
                            to: `${prefix}/resources/${params.resource_name}`,
                            title: schema.label
                        },
                        {
                            title: isNew
                                ? "New"
                                : _get(resource, "nickname", "-")
                        }
                    ]}
                    subtitle={
                        <span className={classes.flex}>
                            {
                                (schema.name === "interactive_videos" || schema.name === "ela_interactive_videos") &&
                                <Button
                                    color="primary"
                                    disabled={disableIvlRequest}
                                    onClick={this.handleIvlRequest}
                                >
                                    Submit IVL Request
                                </Button>
                            }
                            {schema.has_studio &&
                                params.resource_id &&
                                !disableEditor && (
                                    <Button
                                        color="primary"
                                        disabled={disableIvlRequest}
                                        component={Link}
                                        to={`${prefix}/resources/${
                                            params.resource_name
                                        }/${params.resource_id}/studio`}
                                    >
                                        Studio
                                    </Button>
                                )}
                            {resource && resource.id && this.onCartAdd && (
                                <Tooltip title = "Resources" arrow>
                                    <Button onClick={this.onCartAdd}>
                                        <ResourceCartIcon />
                                    </Button>
                                </Tooltip>
                            )}
                            {resource && resource.id && this.onCommentMode && (
                                <Tooltip title = "Comments" arrow>
                                    <Button onClick={this.onCommentMode}>
                                        <CommentModeIcon />
                                    </Button>
                                </Tooltip>
                            )}
                            {user && <UserAvatar user={user} />}
                            <VersionNavButtons
                                prefix={prefix}
                                prevIndex={prevIndex}
                                nextIndex={nextIndex}
                                resource_name={params.resource_name}
                            />
                        </span>
                    }
                />
                {resourcesRequests.fetching && <Loading center />}
                {fetchError ? (
                    <Typography
                        align="center"
                        color="secondary"
                        variant="caption"
                    >
                        Failed to load resource
                    </Typography>
                ) : (
                    <ResourceForm
                        schema={schema}
                        resource={resource}
                        disableEditor={
                            !isLatest || disableEditor || !hasEditAccess
                        }
                        saveError={saveError}
                        resourceName={params.resource_name}
                        onSave={this.onSave}
                        fetching={resourcesRequests.fetching}
                        saving={saving}
                        onChange={this.handleChange}
                        onDiscardLS={this.onDiscardLS}
                    />
                )}

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignContent="flex-end"
                    className={classes.buttonContainer}
                >
                    {resource && (
                        <Grid item>
                            {isLatest && disableEditor && (
                                <Button
                                    color="primary"
                                    component={Link}
                                    to={`/resources/${params.resource_name}/${
                                        resource.id
                                    }`}
                                >
                                    Edit
                                </Button>
                            )}

                            {node && hasAttachAccess && (
                                <ProgressButton
                                    disabled={isAttached || !canAttach}
                                    inProgress={nodesRequests.attaching}
                                    onClick={this.onAttach.bind(
                                        this,
                                        resource.id
                                    )}
                                    error={
                                        attachError &&
                                        JSON.stringify(attachError)
                                    }
                                >
                                    {isAttached ? "Attached" : "Attach"}
                                </ProgressButton>
                            )}
                        </Grid>
                    )}
                </Grid>
                {!isNew && resource && (
                    <ResourceInfoPanes
                        isDirty={isDirty}
                        schema={schema}
                        resource={resource}
                        disableEditor={!isLatest || disableEditor}
                        doActionOnResource={this.props.doActionOnResource.bind(
                            this,
                            params.resource_name,
                            resource.id
                        )}
                        actionRequests={resourcesRequests.actions}
                        abilities={resourcesRequests.meta.abilities}
                    />
                )}
                { ivRequestId &&
                <TextField
                    id="standard-basic" label="Interactive Video Processing Request Id"
                    disabled={true}
                    style={{width: '100%', marginLeft: '20px'}}
                    value={ivRequestId}
                />}
                {!disableEditor && resourceReferences && (
                    <Grid container direction="column">
                        <Typography variant="h6">
                            Resource references
                        </Typography>
                        {resourceReferences.map(workspace => (
                            <WorkspacePanel
                                key={workspace.name}
                                {...workspace}
                                description="."
                            />
                        ))}
                    </Grid>
                )}

                <ToolpoolPane
                    open={!!this.state.cartOpen}
                    onClose={() => this.setState({ cartOpen: false })}
                    onCartInteract={this.onCartInteract}
                    cartDrop={ref => (this.cartDrop = ref)}
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={!!this.state.snack}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                >
                    <SnackContent
                        variant="info"
                        message={this.state.snack}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleSnackClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        ]}
                    />
                </Snackbar>
                { showIssues && <IssueList
                    currentUser={currentUser}
                    selector=".fc"
                    onCloseClick={() => this.setState({ showIssues: false })}
                /> }
            </PageWrapper>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    createResource: (a, p) => dispatch(ResourcesCreateCreators.request(a, p)),
    attachResource: (a, b, c, d) =>
        dispatch(AttachCreators.request(a, b, c, d)),
    saveResource: (a, b, c) =>
        dispatch(ResourcesSaveCreators.request(a, b, c, false)),
    fetchResource: (a, b) => dispatch(ResourcesFetchCreators.request(a, b)),
    fetchIvDetails: (a, b) => dispatch(IvDetailsFetchCreators.request(a, b)),
    createIvRequest: (a, b) => dispatch(IvRequestCreators.request(a, b)),
    doActionOnResource: (a, b, c, d) =>
        dispatch(ResourcesActionCreators.request(a, b, c, d)),
    fetchResourceReferences: (a, b) =>
        dispatch(ResourcesReferencesCreators.request(a, b)),
    subscribeChannel: a => dispatch(ActionCableCreators.subscribe(a)),
    unsubscribeChannel: a => dispatch(ActionCableCreators.unsubscribe(a))
});

const mapStateToProps = (state, ownProps) => {
    let {
        match: { params }
    } = ownProps;
    return {
        schema: selectSchemaByName(state, params.resource_name),
        rootAbilities: selectRootAbilities(state),
        resource: selectResourceById(
            state,
            params.resource_name,
            params.resource_id
        ),
        resourceReferences: selectResourceReferencesById(
            state,
            params.resource_name,
            params.resource_id
        ),
        resourcesRequests: selectResourcesRequestState(state),
        nodesRequests: selectNodesRequestState(state),
        currentUser: selectCurrentUser(state),
        IvDetails: state.ivDetails,
        IvRequest: state.ivCreateRequest
    };
};

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(ResourceEditorPage);
