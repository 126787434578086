import React, { Fragment } from "react";

import { withRouter } from "react-router";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import Loading from "../Components/Loading";

import { Link } from "react-router-dom";
import _isEqual from "lodash/isEqual";

import compose from "recompose/compose";
import { connect } from "react-redux";
import {
  map as treeMap,
} from "../Components/react-sortable-tree";

import {
  selectWorkspacesById,
  selectWorkspacesRequestState
} from "../Redux/WorkspacesRedux";

import {
  selectProjectsById,
  selectProjectsRequestState
} from "../Redux/ProjectsRedux";

import {
  selectNodeById,
  selectNodesSearchResults,
  FetchTreeCreators as NodesFetchTreeCreators,
  selectNodesRequestState
} from "../Redux/NodesRedux";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "50%",
    flexShrink: 0
  },
  listChanges: {
    listStyleType: "none",
    marginLeft: "-60px"
  },
  sectionChanges: {
    paddingRight: "40px"
  },
  loadingButton: {
    margin: "20px"
  },
});

const getNodeKey = ({ node }) => node.id;

class ProjectChange extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      treeData: null,
      expansionState: null,
    }
  }

  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "None";
  }

  dfs(treeData, result){
    Object.values(treeData.children).forEach((child) => {
      if(child.is_deleted && !child.is_moved){
        if(!treeData.nickname){
          result.deletions[child.id] = child.nickname;
        }
        else{
          result.deletions[child.id] = treeData.nickname + ": " + child.nickname;
        }
      }
      else if(child.version_start >= this.props.version || child.version_end === this.props.version) {
        if(!treeData.nickname){
          result.updations[child.id] = child.nickname;
        }
        else{
          result.updations[child.id] = treeData.nickname + ": " + child.nickname;
        }
      }
      else{
        if("children" in child && child.children.length > 0){
          this.dfs(child, result);
        }
      }

    });
  }

  getChanges(){
    let result_list = {
      'deletions': {},
      'updations': {},
    };

    let {
      classes,
      workspace_id,
      project_id,
      is_published,
    } = this.props;

    this.dfs({"children": this.state.treeData}, result_list);

    let del_len = Object.keys(result_list.deletions).length;
    let upd_len = Object.keys(result_list.updations).length;

    return(
      result_list && (
        (del_len > 0 || upd_len > 0) ?
          <Fragment>
            <ExpansionPanelSummary>
              {
                is_published ?
                  <Typography className={classes.heading}>Latest Changes</Typography>
                :
                  <Typography className={classes.heading}>New Changes</Typography>
              }
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {
                  Object.entries(result_list).map((element, idx) => {
                    return(
                      Object.keys(element[1]).length > 0 && (
                        <section className = {classes.sectionChanges} key = {idx}>
                          <h3>{this.capitalize(element[0])+" :"}</h3>
                            <ul className = {classes.listChanges}>
                              {
                                Object.entries(element[1]).map(([k, v], index) => {
                                  return(
                                    <li key = {index}>
                                        <Tooltip title = "View">
                                          <Button
                                            color="primary"
                                            component={Link}
                                            to={`/workspaces/${
                                              workspace_id
                                            }/projects/${project_id}/tree/${k}`}
                                          >
                                            <LinkIcon />
                                          </Button>
                                        </Tooltip>
                                        {this.capitalize(v)}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                        </section>
                      )
                    )
                  })
                }
              </ExpansionPanelDetails>
          </Fragment>
        :
          <ExpansionPanelSummary>
              <Typography className={classes.heading}>No Changes Inside The Project</Typography>
          </ExpansionPanelSummary>
      )
    )
  }

  projectTreeNormalize = (project, tree) => {
    if (tree.id === project.root_node_id) {
      return tree.children;
    } else {
      return [tree];
    }
  };

  mergeState = tree => {
    this._expanstionState = {};
    let newTree = treeMap({
      treeData: tree,
      getNodeKey,
      callback: this.mergeStateNode,
      ignoreCollapsed: false
    });

    if (this.state.expansionState === null) {
      this.setState({ expansionState: this._expanstionState });
    }

    return newTree;
  };

  mergeStateNode = ({ node, path }) => {
    let expand = false;
    const nodeKey = getNodeKey({ node });

    if (this.state.expansionState === null) {
      expand = path.length < 2;
    } else {
      expand = this.state.expansionState[nodeKey];
    }

    node.expanded = expand;

    if (this.state.expansionState === null) {
      this._expanstionState[nodeKey] = expand;
    }

    if (node.lazy_children && !node.children) {
      node.children = this.lazyLoadNode.bind(this, node);
    }

    return node;
  };

  lazyLoadNode = (node) => {
    if (this.props.nodeRequests.fetching_tree[node.id]) {
      //already requested
      return;
    }

    this.props.fetchNodeTree(
      this.props.workspace_id,
      this.props.project_id,
      node.id
    );
  };

  componentWillReceiveProps(nextProps) {
    if(
      nextProps.rootNode &&
      nextProps.project &&
      (!this.props.project ||
        !this.state.treeData ||
        this.props.nodeRequests.fetching_tree !==
          nextProps.nodeRequests.fetching_tree ||
        !_isEqual(this.props.rootNode, nextProps.rootNode))
    ){
      this.setState({
        treeData: this.mergeState(
          this.projectTreeNormalize(nextProps.project, nextProps.rootNode)
        ),
      })
    }
  }

  componentDidMount() {
    this.props.fetchNodeTree(
      this.props.workspace_id,
      this.props.project_id,
      this.props.root_node_id,
      { breadcrumbs: 1 }
    );
  }

  render(){
    let { treeData } = this.state;
    let { classes } = this.props;

    return(
      treeData ?
        this.getChanges()
      :
        <div className = {classes.loadingButton}>
          <Loading size = {60}/>
        </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    workspace: selectWorkspacesById(state, ownProps.workspace_id),
    workspacesRequests: selectWorkspacesRequestState(state),
    projectsRequests: selectProjectsRequestState(state),
    project: selectProjectsById(state, ownProps.project_id),
    rootNode: selectNodeById(state, ownProps.project_id, ownProps.root_node_id),
    nodeSearchResults: selectNodesSearchResults(state),
    nodeRequests: selectNodesRequestState(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchNodeTree: (a, b, c, d) => dispatch(NodesFetchTreeCreators.request(a, b, c, d)),
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProjectChange);
