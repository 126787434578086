import React from "react";
import { withStyles } from "@material-ui/core/styles";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "50%",
    flexShrink: 0
  },
  version: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "10%",
    flexShrink: 0,
    color: "#333"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "33.33%",
    textAlign: "right",
    flexShrink: 0
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(2)
  }
});

const WorkspacePanel = ({
  classes,
  name,
  version,
  id,
  workspace,
  buttons,
  description,
  onRebase,
  disabled
}) => {
  return (
    <ExpansionPanel
      classes={{ root: classes.root }}
      disabled={disabled}
      defaultExpanded={disabled}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{name}</Typography>
        {version && (
          <Typography className={classes.version}>Version {version}</Typography>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{description}</ExpansionPanelDetails>
      {buttons && (
        <div>
          <Divider />
          <ExpansionPanelActions>{buttons}</ExpansionPanelActions>
        </div>
      )}
    </ExpansionPanel>
  );
};

export default withStyles(styles)(WorkspacePanel);
