/* Pagination Component
------------------------------------------------- */
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";

import _range from "lodash/range";

class Pagination extends PureComponent {
  setPage(page) {
    const { onPageChange, total_pages: totalPages } = this.props;
    if (page < 1 || page > totalPages) {
      return;
    }

    onPageChange(page);
  }

  getPager(totalPages, currentPage) {
    currentPage = currentPage || 1;
    const maxPageToShow = 16;

    var startPage, endPage;

    if (totalPages <= maxPageToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= (maxPageToShow/2+1)) {
        startPage = 1;
        endPage = maxPageToShow;
      } else if (currentPage + (maxPageToShow/2-1) >= totalPages) {
        startPage = totalPages - (maxPageToShow-1);
        endPage = totalPages;
      } else {
        startPage = currentPage - (maxPageToShow/2);
        endPage = currentPage + (maxPageToShow/2-1);
      }
    }

    // create an array of pages to ng-repeat in the pager control
    var pages = _range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      currentPage: currentPage,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      pages: pages,
    };
  }

  render() {
    const { current_page: currentPage, total_pages: totalPages } = this.props.pagination;

    var pager = this.getPager(totalPages, currentPage);

    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }
    const { classes } = this.props;
    const showLast = (totalPages - currentPage) >= 5;
    const showFirst = (currentPage > 6);

    return (
      <div className={classes.root}>
        <div className={classes.paginationContainer}>
          <ButtonGroup size="small">
          { showFirst && 
            <Button
            disabled={pager.currentPage === 1}
            onClick={() => this.setPage(1)}
          >
            <FirstPage /> First{" "}
          </Button>
          }
          <Button
            disabled={pager.currentPage === 1}
            onClick={() => this.setPage(pager.currentPage - 1)}
          >
            <NavigateBefore /> Previous{" "}
          </Button>
          { (pager.startPage > 1) && <Button disabled>...</Button> }
          {pager.pages.map(page => (
            <Button
              key={page}
              disabled={pager.currentPage === page}
              color={pager.currentPage === page ? "primary" : "inherit"}
              onClick={() => this.setPage(page)}
            >
              {page}
            </Button>
          ))}
          { (pager.endPage < pager.totalPages) && <Button disabled>...</Button> }
          <Button
            disabled={pager.currentPage === pager.totalPages}
            
            onClick={() => this.setPage(pager.currentPage + 1)}
          >
            Next <NavigateNext />
          </Button>
          { showLast && 
            <Button
            disabled={pager.currentPage === pager.totalPages}
            onClick={() => this.setPage(pager.totalPages)}
          >
            <LastPage /> Last{" "}
          </Button>
          }
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(2)
  }
});
export default withStyles(styles)(Pagination);
